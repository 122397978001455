<template>
  <div>
    <div class="row">
      <div class="col-12">
        <feed-dashboard-component></feed-dashboard-component>
      </div>
    </div>
  </div>
</template>

<script>




  export default {
    name: "FeedDashboard"
  }
</script>

<style scoped>

</style>
