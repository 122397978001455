<template>
  <div>
    <markdown-it-vue :content="content"/>
  </div>
</template>

<script>
  /*const MarkdownItVue =()=>import('markdown-it-vue')
  import 'markdown-it-vue/dist/markdown-it-vue.css'*/

 /* const MarkdownItVueLight  =()=>import('markdown-it-vue/dist/markdown-it-vue-light.umd.min.js')
  const MarkdownItMermaid = ()=>import('markdown-it-vue/src/markdown-it-plugin-mermaid');*/
  const MarkdownItVue =()=>import('markdown-it-vue')
  import 'markdown-it-vue/dist/markdown-it-vue.css'

  export default {
    name: "VueMarkdownViewer",
    props: {
      model: {
        type: String
      }
    },
    components: {
      MarkdownItVue
    },
    computed: {
      content: function () {
        return this.model?this.model:'';
      }
    }
  }
</script>

<style scoped>

</style>
