<template>
  <div class="diagram-container">
    <vue-diagram-editor :name="name" v-model="value" :elements="elements"></vue-diagram-editor>
    final val
    {{value}}
  </div>

</template>

<script>
import VueDiagramEditor from "vue-diagram/src/components/editor/VueDiagramEditor";
export default {
  name: "DiagramEditor",
  components:{VueDiagramEditor},
  data(){
    return {
      name: 'Workflow Diagram',
      value: [],
      elements:[
        {
          name: 'Start',
          description: 'Start the workflow',
          icon: 'far fa-circle'
        },
        {
          name: 'End',
          description: 'End the workflow',
          icon: 'fas fa-bullseye'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
