<template>
  <div class="chart-builder-editor-container">
    <chart-builder-e-chart-wrapper @contextchanged="onContextChanged" :chart-config="computedChartType"
                                   :dataset="computedDataset"
                                   :dimensions="computedDimensions"></chart-builder-e-chart-wrapper>

  </div>
</template>

<script>
const ChartBuilderEChartWrapper = () => import("./charts/echart/ChartBuilderEChartWrapper");

export default {
  name: "ChartEditor",
  components: {ChartBuilderEChartWrapper},
  props: {
    chartType:{
      type: Object
    },
    dimensionsMetrics: {
      type: Object
    },
    data: {
      type: Array
    }
  },
  data() {
    return {
      chartConfig: undefined,
      chartDataset: undefined,
      chartDimensions: undefined
    }
  },
  computed: {
    computedChartType: function(){
      return this.chartType;
    },
    computedDataset: function () {
      return this.chartDataset;
    },
    computedDimensions: function () {
      return this.chartDimensions;
    }
  },
  watch: {
    data: {
      handler: function (newVal, oldVal) {
        console.log('chartEditor data changed', newVal);
        this.chartDataset = newVal; //this.data;
      },
      deep: true
    },
    dimensionsMetrics: {
      handler: function (newVal, oldVal) {
        console.log('chartEditor dimensions changed', newVal);
        this.chartDimensions = this.dimensionsMetrics;
      },
      deep: true
    }
  },
  methods: {
    onContextChanged: function (context) {
      console.log('In Chart Editor context changed', context);
      this.$emit('contextchanged', {type: 'chartConfig', context: context});
    }
  }
}
</script>

<style scoped>

</style>
