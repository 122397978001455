<template>
  <div class="panel sequence-flow-panel">
    Sequence Panel - {{ elementType }}
    <schema-form :schema="schema" :form="form"></schema-form>
  </div>
</template>

<script>
import PanelMixin from "vue-diagram/src/bpmn/panel/components/mixins/panel.mixin";

export default {
  name: "SequenceFlowPanel",
  mixins:[PanelMixin]
}
</script>

<style scoped>

</style>
