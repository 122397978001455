import notifyMixin from "./notifyMixin";

const defaultMixins = {
  mixins:[notifyMixin],
  props: ['editMode', 'continuousEditMode',  'model', 'defaultMapper', 'dashboardName','dashid', 'dashboardServiceInstance', 'parentDashid', 'parentWidgetId', 'inputContext'],
  methods: {
    getId: function (prefix) {
      return prefix ? prefix + '-' + this.$widgetStore.id() : this.$widgetStore.id();
    },
    translate: function (key) {

    }
  }

}

export default defaultMixins
