<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="footer-container">

      </div>
    </div>
  </footer>

</template>

<script>
  import componentMixin from '../../componentMixin';
  export default {
    name: "VueDfAppFooter",
    mixins:[componentMixin]
  }
</script>

<style scoped>

</style>
