var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "editItemModal",
          attrs: {
            "hide-header-close": "",
            lazy: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "xl",
            title: "Edit Item",
          },
          on: {
            show: function ($event) {
              return _vm.clear()
            },
            ok: _vm.onOk,
          },
        },
        [
          _c(
            "div",
            [
              _c("schema-form", {
                attrs: {
                  form: _vm.schemaForm.form,
                  schema: _vm.schemaForm.schema,
                },
                on: { validationResult: _vm.onValidationResult },
                model: {
                  value: _vm.model,
                  callback: function ($$v) {
                    _vm.model = $$v
                  },
                  expression: "model",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }