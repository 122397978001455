var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "schema-editor-config" }, [
    _c("div", { staticClass: "schema-editor-config-wrapper" }, [
      _c(
        "div",
        { staticClass: "card p-3 rounded-0" },
        [
          _c("schema-form", {
            attrs: {
              form: _vm.internalSelectedItemForm,
              schema: _vm.internalSelectedItemSchema,
            },
            on: { validationResult: _vm.onValidationResult },
            model: {
              value: _vm.internalSelectedItemModel,
              callback: function ($$v) {
                _vm.internalSelectedItemModel = $$v
              },
              expression: "internalSelectedItemModel",
            },
          }),
        ],
        1
      ),
      _vm.selectedItemValidationResult
        ? _c("div", { staticClass: "p-3" }, [
            _vm._m(0),
            _c(
              "ul",
              { staticClass: "list-unstyled" },
              _vm._l(_vm.selectedItemValidationResult, function (error) {
                return _c(
                  "li",
                  { key: error.path, staticClass: "list-group-item" },
                  [
                    _c("div", [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Path: "),
                      ]),
                      _c("span", { staticClass: "text-primary" }, [
                        _vm._v(_vm._s(error.path)),
                      ]),
                    ]),
                    _c("div", [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Message: "),
                      ]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(error.message)),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span", [_vm._v("Validation result")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }