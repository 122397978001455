<template>
<div class="panel gateway-panel">
  Gateway Panel - {{ elementType }}
  <schema-form :schema="schema" :form="form"></schema-form>
</div>
</template>

<script>
import PanelMixin from "vue-diagram/src/bpmn/panel/components/mixins/panel.mixin";

export default {
  name: "GatewayPanel",
  mixins:[PanelMixin]
}
</script>

<style scoped>

</style>
