var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "foreignObject",
    {
      staticClass: "node",
      attrs: {
        x: _vm.data.x - _vm.margin,
        y: _vm.data.y - _vm.margin,
        width: _vm.data.width + _vm.margin * 2,
        height: _vm.data.height + _vm.margin * 2,
      },
      on: { mousedown: _vm.onMousedown },
    },
    [
      _c(
        "div",
        {
          staticClass: "outer",
          style: _vm.margin && `padding: ${_vm.margin}px`,
        },
        [
          _c(
            "div",
            { ref: "content", staticClass: "content" },
            [
              !_vm.$slots.default
                ? _c("div", { staticClass: "default-label" }, [
                    _vm._v("\n        " + _vm._s(_vm.data.id) + "\n      "),
                  ])
                : _vm._e(),
              _vm._t("default"),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }