import DatasetChart from './base/DatasetChart'

class EffectScatterChart extends DatasetChart{
  constructor() {
    super('EffectScatterChart');
  }

}


export default EffectScatterChart
