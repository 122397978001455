var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-df-app-navbar",
    {
      model: {
        value: _vm.showNavbar,
        callback: function ($$v) {
          _vm.showNavbar = $$v
        },
        expression: "showNavbar",
      },
    },
    [
      _c(
        "div",
        { staticClass: "navbar-wrapper" },
        [
          !this.layout.excludeSidebar
            ? _c("div", { staticClass: "navbar-minimize" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon btn-round",
                    attrs: { id: "minimizeSidebar" },
                    on: { click: _vm.minimizeSidebar },
                  },
                  [
                    _c("i", {
                      staticClass:
                        "fas fa-chevron-right text-center visible-on-sidebar-mini",
                    }),
                    _c("i", {
                      staticClass:
                        "fas fa-chevron-left text-center visible-on-sidebar-regular",
                    }),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "navbar-toggle" },
            [
              _c("vue-df-app-navbar-toggle-button", {
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleSidebar.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            _vm.homeComponent,
            {
              tag: "component",
              staticClass: "navbar-brand",
              attrs: { to: _vm.homeLink },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onHomeClick.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
        ],
        1
      ),
      _c("template", { slot: "navbar-menu" }, [
        _c(
          "ul",
          { staticClass: "navbar-nav" },
          [
            _vm._t("links", function () {
              return _vm._l(_vm.resolveItems(), function (item, index) {
                return _c(
                  "vue-df-app-navbar-item",
                  {
                    key: item.title + index,
                    attrs: {
                      "selected-route-config": _vm.selectedRouteConfig,
                      "app-config": _vm.appConfig,
                      config: item,
                      parent: null,
                    },
                    on: { selected: _vm.onSelect },
                  },
                  _vm._l(
                    _vm.resolveSubItems(item),
                    function (subItem, subIndex) {
                      return _c("vue-df-app-navbar-item", {
                        key: subItem.title + index + "-" + subIndex,
                        attrs: {
                          "selected-route-config": _vm.selectedRouteConfig,
                          "app-config": _vm.appConfig,
                          config: subItem,
                          parent: item,
                        },
                        on: { selected: _vm.onSelect },
                      })
                    }
                  ),
                  1
                )
              })
            }),
            _vm.userMenuConfig && _vm.userMenuConfig.placement === "NAVBAR"
              ? _c("li", { staticClass: "nav-link" }, [
                  _c(
                    "div",
                    { staticClass: "nav-item" },
                    [
                      _c("vue-df-user-info-component", {
                        attrs: {
                          "security-config": _vm.securityConfig,
                          "selected-route-config": _vm.selectedRouteConfig,
                          "app-config": _vm.appConfig,
                          config: _vm.userMenuConfig,
                          tag: "div",
                          "dropdown-class": "dd",
                          "dropdown-link-class": "aa",
                          position: "right",
                        },
                        on: { selected: _vm.onSelect },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }