const notifyMixin = {
  methods: {
    errorMessage: function (message) {
      this.notifyVueMessage(message, 'bottom', 'right', 'danger', 10000);
    },
    notifyMessage: function (message) {
      this.notifyVueMessage(message, 'bottom', 'right');
    },
    notifyVueMessage: function (message, verticalAlign, horizontalAlign, type, timeout, title, icon) {
      const that = this;
      switch (type) {
        case 'danger':
        case 'warning':
          this.$awn.warning(message, {
            position: 'botton-right',
            duration: timeout || 3000
          });
          break;
        default:
          this.$awn.success(message, {
            position: 'botton-right',
            duration: timeout || 3000
          });
      }

    }
  }
}

export default notifyMixin
