import { render, staticRenderFns } from "./VDTypesPanel.vue?vue&type=template&id=11831a95&scoped=true&"
import script from "./VDTypesPanel.vue?vue&type=script&lang=js&"
export * from "./VDTypesPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11831a95",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11831a95')) {
      api.createRecord('11831a95', component.options)
    } else {
      api.reload('11831a95', component.options)
    }
    module.hot.accept("./VDTypesPanel.vue?vue&type=template&id=11831a95&scoped=true&", function () {
      api.rerender('11831a95', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/vue-dashboard-framework-components/src/components/visualisationDesigner/visualDesigner/panels/VDTypesPanel.vue"
export default component.exports