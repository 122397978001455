var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "json-tree",
      class: { "json-tree-root": _vm.parsed.depth === 0 },
    },
    [
      _vm.parsed.primitive
        ? _c(
            "span",
            { staticClass: "json-tree-row", attrs: { tag: "span" } },
            [
              _vm._l(_vm.parsed.depth * 2 + 3, function (n) {
                return _c("span", { key: n, staticClass: "json-tree-indent" }, [
                  _vm._v(" "),
                ])
              }),
              _vm.parsed.key
                ? _c(
                    "span",
                    {
                      staticClass: "handle json-tree-key",
                      attrs: { title: _vm.parsed.path },
                    },
                    [_vm._v(_vm._s(_vm.parsed.key))]
                  )
                : _vm._e(),
              _vm.parsed.key
                ? _c("span", { staticClass: "json-tree-colon" }, [_vm._v(": ")])
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "json-tree-value",
                  class: "json-tree-value-" + _vm.parsed.type,
                  attrs: { title: `${_vm.parsed.value}` },
                },
                [_vm._v(_vm._s(`${_vm.parsed.value}`))]
              ),
              !_vm.parsed.last
                ? _c("span", { staticClass: "json-tree-comma" }, [_vm._v(",")])
                : _vm._e(),
              _c("span", { staticClass: "json-tree-indent" }, [_vm._v(" ")]),
            ],
            2
          )
        : _vm._e(),
      !_vm.parsed.primitive
        ? _c("span", { staticClass: "json-tree-deep" }, [
            _c(
              "span",
              {
                staticClass: "json-tree-row json-tree-expando",
                on: {
                  click: function ($event) {
                    _vm.expanded = !_vm.expanded
                  },
                  mouseover: function ($event) {
                    _vm.hovered = true
                  },
                  mouseout: function ($event) {
                    _vm.hovered = false
                  },
                },
              },
              [
                _c("span", { staticClass: "json-tree-indent" }, [_vm._v(" ")]),
                _c("span", { staticClass: "json-tree-sign" }, [
                  _vm._v(_vm._s(_vm.expanded ? "-" : "+")),
                ]),
                _vm._l(_vm.parsed.depth * 2 + 1, function (n) {
                  return _c(
                    "span",
                    { key: n, staticClass: "json-tree-indent" },
                    [_vm._v(" ")]
                  )
                }),
                _vm.parsed.key
                  ? _c("span", { staticClass: "json-tree-key" }, [
                      _vm._v(_vm._s(_vm.parsed.key)),
                    ])
                  : _vm._e(),
                _vm.parsed.key
                  ? _c("span", { staticClass: "json-tree-colon" }, [
                      _vm._v(": "),
                    ])
                  : _vm._e(),
                _c("span", { staticClass: "json-tree-open" }, [
                  _vm._v(_vm._s(_vm.parsed.type === "array" ? "[" : "{")),
                ]),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.expanded,
                        expression: "!expanded",
                      },
                    ],
                    staticClass: "json-tree-collapsed",
                  },
                  [
                    _vm._v(
                      " /* " +
                        _vm._s(_vm.format(_vm.parsed.value.length)) +
                        " */ "
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.expanded,
                        expression: "!expanded",
                      },
                    ],
                    staticClass: "json-tree-close",
                  },
                  [_vm._v(_vm._s(_vm.parsed.type === "array" ? "]" : "}"))]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.expanded && !_vm.parsed.last,
                        expression: "!expanded && !parsed.last",
                      },
                    ],
                    staticClass: "json-tree-comma",
                  },
                  [_vm._v(",")]
                ),
                _c("span", { staticClass: "json-tree-indent" }, [_vm._v(" ")]),
              ],
              2
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expanded,
                    expression: "expanded",
                  },
                ],
                staticClass: "json-tree-deeper",
              },
              [
                _c(
                  "draggable",
                  {
                    attrs: {
                      disabled: !_vm.isDraggable,
                      group: {
                        name: "dataFields",
                        pull: "clone",
                        put: false,
                        revertClone: true,
                      },
                      clone: _vm.onClone,
                      value: _vm.parsed.value,
                      move: _vm.onMove,
                      tag: "div",
                    },
                  },
                  _vm._l(_vm.parsed.value, function (item, index) {
                    return _c("draggable-json-tree", {
                      key: index,
                      attrs: {
                        "is-draggable": _vm.isDraggable,
                        kv: item,
                        level: _vm.level,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expanded,
                    expression: "expanded",
                  },
                ],
                staticClass: "json-tree-row",
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "json-tree-ending",
                    class: { "json-tree-paired": _vm.hovered },
                  },
                  [
                    _vm._l(_vm.parsed.depth * 2 + 3, function (n) {
                      return _c(
                        "span",
                        { key: n, staticClass: "json-tree-indent" },
                        [_vm._v(" ")]
                      )
                    }),
                    _c("span", { staticClass: "json-tree-close" }, [
                      _vm._v(_vm._s(_vm.parsed.type === "array" ? "]" : "}")),
                    ]),
                    !_vm.parsed.last
                      ? _c("span", { staticClass: "json-tree-comma" }, [
                          _vm._v(","),
                        ])
                      : _vm._e(),
                    _c("span", { staticClass: "json-tree-indent" }, [
                      _vm._v(" "),
                    ]),
                  ],
                  2
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }