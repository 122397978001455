<template>
  <draggable :id="id" class="column" :class="column.styleClass"
             :handle="'.adf-move'" :list="column.widgets" :group="{ name: 'widgets' }"
             :draggable="'.widget-container'" @end="onEnd">

      <vue-df-dashboard-widget v-for="(definition, index) in widgetList" :key="definition.id"
                               v-on:widgetremove="removeWidget"
                               :index="index"
                               :dashid="dashid"
                               :model="model"
                               :definition="definition"
                               :dashboard-name="dashboardName"
                               :default-mapper="defaultMapper"
                               :column="column"
                               :id="definition.id"
                               :edit-mode="editMode"
                               :input-context="inputContext"
                               :options="options"
                               :dashboard-service-instance="dashboardServiceInstance"
                               :permissions="permissions"
                               :parent-widget-id="parentWidgetId"
                               :parent-dashid="parentDashid"
                               :crud-service-name="crudServiceName"
                               :widget-state="widgetState()">

      </vue-df-dashboard-widget>


    <template v-if="column.rows">
      <vue-df-dashboard-row
        :model="model"
        :row="row"
        :options="options"
        :permissions="permissions"
        v-for="(row, index) in column.rows" :key="getRowFingerprint(row)+index"
        :edit-mode="editMode"
        :default-mapper="defaultMapper"
        :parent-widget-id="parentWidgetId"
        :parent-dashid="parentDashid"
        :dashboard-service-instance="dashboardServiceInstance"
        :continuous-edit-mode="continuousEditMode">
      </vue-df-dashboard-row>
    </template>

  </draggable>

</template>

<script>
  const VueDfDashboardWidget = ()=>import('./VueDfDashboardWidget');
  import defaultMixins from './defaultMixin'
  import internalMixins from './internalMixin'
  import draggable from 'vuedraggable';

  export default {
    name: "VueDfDashboardColumn",
    props: ['column', 'id', 'crudServiceName'],
    mixins: [defaultMixins, internalMixins],
    components: {
      draggable,
      'vue-df-dashboard-widget': VueDfDashboardWidget,
      'vue-df-dashboard-row': () => import('./VueDfDashboardRow')
    },
    computed: {
      widgetList: function(){
        return this.column.widgets;
      }
    },
    methods: {

      getRowFingerprint: function(row){
        return JSON.stringify(row);
      },
      getWidgetFingerprint: function(def){
        return JSON.stringify(def);
      },
      onEnd: function(event){

      },
      widgetState: function () {
        return {};
      },
      getDefinition: function (definition) {
        return definition;
      },
      removeWidget: function(event){
        const index = event.index;
        this.column.widgets.splice(index, 1);
      }
    }
  }
</script>

<style scoped>

 .dashboard-simple .column.col{
    padding: 0px;
  }

</style>
