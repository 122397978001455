<template>
  <div :id="editorId" :style="{height: height, width: width, position: position}"></div>
</template>

<script>
  import ace from 'brace'

  import('brace/mode/html')
  import 'brace/mode/javascript';
  import 'brace/mode/json';
  import 'brace/mode/css';
  import 'brace/mode/yaml';

  import 'brace/snippets/markdown';
  import 'brace/mode/markdown'

  import 'brace/theme/monokai'
  import 'brace/theme/xcode'
  import 'brace/ext/emmet'
  import 'brace/ext/language_tools';
  import 'brace/ext/searchbox';
  import 'brace/ext/static_highlight'

  import 'brace/snippets/css';
  import 'brace/snippets/javascript';
  import 'brace/snippets/html';
  import 'brace/snippets/json';
  import 'brace/snippets/yaml';

  export default {
    name: "VueAceEditor",
    props: {
      staticWordCompleter: {
        type: Object
      },
      editorId: null,
      content: {
        type: String
      },
      height: true,
      width: true,
      position: {
        type: String
      },
      fontSize: {
        type: Number,
        default: 14
      },
      config: {
        type: Object,
        default: function () {
          return {
            lang: 'json',
            theme: 'xcode',
            options: {
              useSoftTabs: true,
              tabSize: 2
            }
          }
        }
      }
    },
    data() {
      return {
        editor: Object,
        beforeContent: '',
        defaultConfig: {
          lang: 'json',
          theme: 'xcode',
          options: {
            useSoftTabs: true,
            tabSize: 2
          }
        }
      }
    },
    watch: {
      content: function (newVal, oldVal) {
        this.editor.setValue(newVal || '')
        this.editor.focus();
        this.editor.renderer.updateText();
      },
      'config.lang': {
        handler(newVal, oldVal) {
          /*require('brace/mode/' + newVal);
          require('brace/snippets/' + newVal);*/
          this.editor.getSession().setMode('ace/mode/' + newVal);
        },
      },
      'config.theme': {
        handler(newVal, oldVal) {
          //require('brace/theme/' + newVal);
          this.editor.setTheme('ace/theme/' + newVal);
        },
      }
    },
    beforeDestroy: function () {
      this.editor.destroy();
      this.editor.container.remove();
    },
    mounted() {
      const {
        lang,
        theme
      } = Object.assign({}, this.defaultConfig, this.config) //this.editorConfig

      /*lang === 'html' && require('brace/ext/emmet');
      require('brace/ext/language_tools');
      require('brace/mode/' + lang);
      require('brace/theme/' + theme);
      require('brace/snippets/' + lang);*/

      this.editor = ace.edit(this.$el);

      this.editor.setTheme('ace/theme/' + theme);
      this.editor.getSession().setMode('ace/mode/' + lang);
      this.editor.$blockScrolling = Infinity;

      this.editor.setFontSize(this.fontSize);
      if (this.content) {
        this.editor.setValue(this.content);
      }

      if (this.staticWordCompleter){
        this.editor.completers = [this.staticWordCompleter];
      }

      this.config.options && this.editor.setOptions(this.config.options);

      const that = this;
      if (this.config.onInput) {
        this.editor.on('input', _ => {
          that.beforeContent = this.editor.getValue()
          let val = that.editor.getValue();
          that.$emit('change-content', val);
        });
      } else {
        this.editor.on('blur', _ => {
          that.beforeContent = this.editor.getValue()
          let val = that.editor.getValue();
          that.$emit('change-content', val);
        });
      }
    }
  }
</script>
