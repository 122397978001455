import BaseChart from './BaseChart'

class SeriesChart extends BaseChart {
  constructor(chartType) {
    super(chartType);
  }

  __chartSeriesType() {
    return this.chartType.substring(0, this.chartType.length - 5).toLowerCase();
  }

  __defaultSeries(){
    const defaultSeries = {
      type: this.__chartSeriesType(),
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
    return defaultSeries;
  }

  createSeries(chartDefinition) {
    const series = [];
    const defSeries = this.__defaultSeries();
    if (this.metrics && this.metrics.length) {
      this.metrics.forEach((item) => {
        defSeries.smooth = true;
        defSeries.emphasis = {
          focus: 'series'
        };
        series.push(defSeries);
      });
    } else {
      series.push(defSeries);
    }

    console.log('updating series', this.metrics, series, this.dimensions);
    chartDefinition.series = series;
  }

  updateSeries(config) {
    const data = this.getChartConfigData();
    if (config.series && config.series.length) {
      config.series.forEach((series) => {
        if (data.stacked)
          series.stack = "1";
        if (data.area) {
          series.areaStyle = {}
        }
      });
    }
  }

  getChartDefinition() {
    let baseChart = super.getChartDefinition();
    this.createSeries(baseChart);
    this.updateSeries(baseChart);
    return baseChart;
  }

}


export default SeriesChart
