<template>
  <div>
    <div class="card">
      <div class="card-header">
        Header
        <hr/>
      </div>
      <div class="card-body">
        <legend>Body</legend>
        <div class="row">
          <div class="col-2">

          </div>
          <div class="col">
            <textarea style="width: 100%" cols="10" rows="10"></textarea>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VariousView",
  data(){
    return {

    }
  }
}
</script>

<style scoped>

</style>
