var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-resizable",
    {
      ref: "resizableComponent",
      staticClass: "resizable-chart",
      style: _vm.customStyle,
      attrs: {
        active: _vm.resizableActiveHandlers,
        width: _vm.computedWidth,
        height: _vm.computedHeight,
        "disable-attributes": _vm.disabledAttributes,
      },
      on: { "resize:end": _vm.eHandler },
    },
    [
      _c(
        "div",
        { staticClass: "chart-wrapper", class: _vm.activeClass },
        [
          _c("v-chart", {
            ref: "eChart",
            attrs: {
              id: _vm.id,
              group: _vm.group,
              "init-options": _vm.initOptions,
              "manual-update": _vm.manualUpdate,
              options: _vm.chartConfig,
              theme: _vm.theme,
              autoresize: true,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }