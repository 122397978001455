var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel task-panel" },
    [
      _vm._v("\n  Task Panel - " + _vm._s(_vm.elementType) + "\n  "),
      _c(_vm.taskComponent, {
        tag: "component",
        attrs: { modeler: _vm.modeler, element: _vm.element },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }