<template>
  <div class="data-editor-container">
    <b-tabs>
      <b-tab active title="Test Data">
        <div class="row">
          <div class="col-3">
            <div class="ace-editor-wrapper raw-data ml-2">
              <label class="control-label">Raw Data Editor</label>
              <button class="btn btn-sm btn-outline-secondary" type="button"
                      v-clipboard="() => {return this.rawContent; }">
                Copy!
              </button>
              <vue-ace-editor :config="editorConfig"
                              :content="strRawContent" :height="'94%'"
                              :position="'absolute'"
                              :width="'95%'"
                              @change-content="onRawDataAceValueChanged($event)">
              </vue-ace-editor>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <div class="ace-editor-wrapper expression-editor">
                  <label class="control-label">Expression</label>
                  <button class="btn btn-outline-secondary" type="button"
                          v-clipboard="() => {return this.strExpression; }">
                    Copy!
                  </button>
                  <vue-ace-editor :config="editorConfig"
                                  :content="strExpression" :height="'80%'"
                                  :position="'absolute'"
                                  :width="'95%'"
                                  @change-content="onExpressionAceValueChanged($event)">
                  </vue-ace-editor>
                </div>
                <div class="mb-2">
                  <button @click="onExpressionExecuteClick" class="btn btn-primary">Execute</button>
                  <hr/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="ace-editor-wrapper expression-result-editor">
                  <label class="control-label">1 Expression Result</label>
                  <button class="btn btn-outline-secondary" type="button"
                          v-clipboard="() => {return this.strExpressionResult; }">
                    Copy!
                  </button>
                  <button @click="applyTransformedData()" class="btn btn-warning">Apply</button>
                  <vue-ace-editor :config="editorConfig"
                                  :content="strExpressionResult" :height="'90%'"
                                  :position="'absolute'"
                                  :width="'95%'"
                                  @change-content="onExpressionResultAceValueChanged($event)">
                  </vue-ace-editor>
                </div>
              </div>
            </div>

          </div>
        </div>
      </b-tab>
      <b-tab title="Data extractor">
        <data-normalizer @apply="onNormalizedDataApplied" :raw-data="rawContent"></data-normalizer>
      </b-tab>
      <b-tab title="Data">
        <div class="row">
          <div class="col-3">
            <div class="ace-editor-wrapper raw-data ml-2">
              <label class="control-label">Sample Data Editor</label>
              <vue-ace-editor :config="editorConfig"
                              :content="strContent" :height="'95%'"
                              :position="'absolute'"
                              :width="'95%'"
                              @change-content="onAceValueChanged($event)">
              </vue-ace-editor>
            </div>
          </div>
          <div class="col-9">
            <property-editor :value="content" @input="onPropertiesChanged"></property-editor>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
const VueAceEditor = () => import('vue-json-schema-form-editor/src/aceEditor/VueAceEditor')
const PropertyEditor = () => import ('./PropertyEditor')
const {generateJson} = require('json-generator');

import {BTabs, BTab} from 'bootstrap-vue'
import DataNormalizer from "./editor/DataNormalizer";

export default {
  name: "DataEditor",
  props: ['rawValue', 'value'],
  components: {
    DataNormalizer,
    BTabs, BTab,
    VueAceEditor,
    PropertyEditor
  },
  data() {
    return {
      content: [
        {product: 'P1', category: 'C1', region: 'R1', sales: 123, cost: 24, amount: 12},
        {product: 'P1', category: 'C1', region: 'R2', sales: 234, cost: 124, amount: 23},
        {product: 'P1', category: 'C1', region: 'R2', sales: 234, cost: 104, amount: 23},
        {product: 'P2', category: 'C1', region: 'R1', sales: 45, cost: 24, amount: 3},
        {product: 'P2', category: 'C1', region: 'R3', sales: 123, cost: 54, amount: 12},
        {product: 'P3', category: 'C2', region: 'R1', sales: 123, cost: 44, amount: 12},
        {product: 'P3', category: 'C2', region: 'R2', sales: 345, cost: 14, amount: 28},
        {product: 'P3', category: 'C2', region: 'R3', sales: 15, cost: 30, amount: 1}
      ],
      rawContent: {},
      strContent: '{}',
      jsonSchema: {
        id: "id;objectId",
        children: [
          5,
          {
            name: "fullName",
            age: "int;0;10",
            travel:[
              5,
              {
                from:'random;["Sarajevo", "Tuzla", "Mostar", "Bihac"]',
                to:'random;["Mekka", "Istanbul", "NY", "San Francisco", "Delhi"]',
                year: "int;1970;2022",
                month: "int;1;12",
                hour: "int;0;23",
                timestamp: "int;1437747281000;1637747281000",
                days: "int;5;15"
              }
            ]
          }
        ],
        currentJob: {
          title: 'random;["developer", "medic", "teacher", "CEO"]',
          salary: "money;$ #.###,##"
        },
        jobs: [
          2,
          {
            title: 'random;["developer", "medic", "teacher", "CEO"]',
            salary: "money;$ #.###,##"
          }
        ],
        maxRunDistance: "float;1;20;1",
        cpf: "cpf",
        cnpj: "cnpj",
        pretendSalary: "money;$ #.###,##",
        age: "int;20;80",
        gender: "gender",
        firstName: "firstName",
        lastName: "lastName",
        phone: "maskInt;+55 (83) 9####-####",
        address: "address",
        hairColor: "color",
        year: "int;1970;2022",
        month: "int;1;12",
        hour: "int;0;23",
        timestamp: "int;1437747281000;1637747281000",
        location: {
          lat: "maskInt;##.##",
          lon: "maskInt;##.##"
        }
      },
      strRawContent: {},
      strExpression: '',
      strExpressionResult: '{}',
      expressionResultContent: {},
      editorConfig: {
        lang: 'json',
        onInput: false
      },
      properties: []
    }
  },
  mounted() {
    console.log('DataEditor created', this.value, this.rawValue);
    if (this.value) {
      this.content = this.value;
    }
    this.strContent = JSON.stringify(this.content, null, 2);

    if (this.rawValue) {
      this.rawContent = this.rawValue;
    } else {
      this.rawContent = this.generateArray();
    }
    this.strRawContent = JSON.stringify(this.rawContent, null, 2);
  },
  watch: {
    properties: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.properties = newVal;
        this.$emit('input', {content: this.content, properties: this.properties});
      }
    },
    content: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('input', {content: this.content, properties: this.properties});
      }
    }
  },
  methods: {
    onNormalizedDataApplied(data){
      if (data) {
        this.strContent = JSON.stringify(data, null, 2);
      }
    },
    generateArray(from = 0, to = 10) {
      const rndInt = Math.floor(Math.random() * to) + from
      const that = this;
      const res = [];
      for (let i = 0; i < rndInt; i++) {
        const generatedVal = generateJson(that.jsonSchema);
        res.push(generatedVal);
      }

      return res;
    },
    onAceValueChanged: function (value) {
      this.content = JSON.parse(value)
    },
    onRawDataAceValueChanged: function (value) {
      if (value)
        this.rawContent = JSON.parse(value)
    },
    onPropertiesChanged: function (props) {
      console.log('On props changed', props)
      this.properties = props;
    },
    onExpressionAceValueChanged: function (value) {
      this.strExpression = value;
    },
    onExpressionResultAceValueChanged: function (value) {
      /*if (value) {
        this.expressionResultContent = JSON.parse(value);
      }*/
      // this.strExpressionResult = value;
      if (value){
        this.expressionResultContent = value;
      }
    },
    applyTransformedData(){
      this.rawContent = JSON.parse(this.expressionResultContent, null, 2);
    },
    onExpressionExecuteClick: function () {

      const result = this.$jsulator.evaluate(this.strExpression, this.rawContent);
      console.log('onExpressionExecuteClick', this.strExpression, this.rawContent, result);
      let res = result;
      try {
        res = JSON.stringify(result, null, 2);
      } catch (e) {
      }
      this.strExpressionResult = res;
    }
  }
}
</script>

<style scoped>
.ace-editor-wrapper {
  width: 100%;
  display: inline-block;
  position: relative;
  min-height: 500px;
}

.ace-editor-wrapper.raw-data {
  height: 100%;
}

.ace-editor-wrapper.expression-editor {
  min-height: 200px;
}

/*.ace-editor-wrapper.expression-result-editor {
  min-height: 200px;
}*/


</style>
