var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vertical-accordion",
    [
      _c("accordion-item", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("\n      Types\n    ")]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [_c("v-d-types-panel")]
            },
            proxy: true,
          },
        ]),
      }),
      _c("accordion-item", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("\n      Editor\n    ")]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [_c("v-d-editor-panel")]
            },
            proxy: true,
          },
        ]),
      }),
      _c("accordion-item", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("\n      Properties\n    ")]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [_c("v-d-properties-panel")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }