<template>
  <div class="collapsible-container collapse-bar">
    <div class="header">
      <slot name="header">
        <span>
          <button @click="toogle">
            <i class="fas fa-cog"></i>
          </button>
        </span>
      </slot>
    </div>
    <collapse-transition>
      <slot>
        <div>This is some content</div>
      </slot>
    </collapse-transition>
    <div class="footer">
      <slot name="footer">
        <div>this is footer content</div>
      </slot>
    </div>
  </div>
</template>

<script>
  import {CollapseTransition} from 'vue2-transitions';

  export default {
    name: "CollapsibleContainer",
    components: {CollapseTransition},
    props: {
      side: {
        type: String,
        default: function () {
          return "left";
        }
      }
    },
    data() {
      return {
        isVisible: true
      }
    },
    methods: {
      toogle: function () {

      }
    }
  }
</script>

<style scoped>

</style>
