import DatasetChart from './base/DatasetChart'

class ParallelChart extends DatasetChart{
  constructor() {
    super('ParallelChart');
  }

}


export default ParallelChart
