<template>
  <div v-if="template">
    <v-runtime-template :template="template.view.viewTemplate"></v-runtime-template>
  </div>
</template>

<script>
  import  VRuntimeTemplate  from "v-runtime-template";
  export default {
    name: "VueKanbanItemView",
    props: ['item', 'template'],
    components:{  VRuntimeTemplate },
    data(){
      return {

      }
    }
  }
</script>

<style scoped>

</style>
