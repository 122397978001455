var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "column",
      class: _vm.column.styleClass,
      attrs: {
        id: _vm.id,
        handle: ".adf-move",
        list: _vm.column.widgets,
        group: { name: "widgets" },
        draggable: ".widget-container",
      },
      on: { end: _vm.onEnd },
    },
    [
      _vm._l(_vm.widgetList, function (definition, index) {
        return _c("vue-df-dashboard-widget", {
          key: definition.id,
          attrs: {
            index: index,
            dashid: _vm.dashid,
            model: _vm.model,
            definition: definition,
            "dashboard-name": _vm.dashboardName,
            "default-mapper": _vm.defaultMapper,
            column: _vm.column,
            id: definition.id,
            "edit-mode": _vm.editMode,
            "input-context": _vm.inputContext,
            options: _vm.options,
            "dashboard-service-instance": _vm.dashboardServiceInstance,
            permissions: _vm.permissions,
            "parent-widget-id": _vm.parentWidgetId,
            "parent-dashid": _vm.parentDashid,
            "crud-service-name": _vm.crudServiceName,
            "widget-state": _vm.widgetState(),
          },
          on: { widgetremove: _vm.removeWidget },
        })
      }),
      _vm.column.rows
        ? _vm._l(_vm.column.rows, function (row, index) {
            return _c("vue-df-dashboard-row", {
              key: _vm.getRowFingerprint(row) + index,
              attrs: {
                model: _vm.model,
                row: row,
                options: _vm.options,
                permissions: _vm.permissions,
                "edit-mode": _vm.editMode,
                "default-mapper": _vm.defaultMapper,
                "parent-widget-id": _vm.parentWidgetId,
                "parent-dashid": _vm.parentDashid,
                "dashboard-service-instance": _vm.dashboardServiceInstance,
                "continuous-edit-mode": _vm.continuousEditMode,
              },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }