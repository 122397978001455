var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pl-4 pr-4" },
    [
      _c("schema-form", {
        staticClass: "form-inline",
        attrs: { form: _vm.form, schema: _vm.schema },
        on: { validationResult: _vm.onValidationResult },
        model: {
          value: _vm.formModel,
          callback: function ($$v) {
            _vm.formModel = $$v
          },
          expression: "formModel",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }