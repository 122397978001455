import DatasetChart from './base/DatasetChart'

class RadarChart extends DatasetChart{
  constructor() {
    super('RadarChart');
  }

  __getRadarSeriesData(metricItem){
     const seriesData = {
       name: metricItem.title,
       value:[]
     }
     if (this.dataset){
       this.dataset.forEach((item)=>{
         seriesData.value.push(item[metricItem.title]);
       });
     }
     return seriesData;
  }

  __updateRadarSeries(chartConfigData){
    const series = [];

    if (this.metrics){
      this.metrics.forEach((item)=>{
        const radarSeries = {
          type: 'radar',

          tooltip: {
            trigger: 'item'
          },
          data: [this.__getRadarSeriesData(item)]
        }
        if (chartConfigData.area){
          radarSeries.areaStyle= {}
        }
        series.push(radarSeries);
      });
    }
    return series;
  }

  getChartDefinition() {
    const baseChart = super.getChartDefinition();
    const chartConfigData = this.getChartConfigData();

    console.log('Resolving Radar chart', baseChart, chartConfigData);

    if (!this.dimensions || !this.dimensions.length){
      return {};
    }

    const indicatorName = this.dimensions[0].title;
    const indicator = [];
    this.dimensions.forEach((dimension)=>{

    });
    this.dataset.forEach((item)=>{
      indicator.push({ name: item[indicatorName] });
    });

    /*if (!chartConfigData || !chartConfigData.indicator) {
      return {};
    }*/

    baseChart.radar = {
      indicator:indicator,
      shape: chartConfigData.shape?chartConfigData.shape:'circle'
    }
    delete baseChart.xAxis
    delete baseChart.yAxis;
    delete baseChart.dataset;

    baseChart.series = this.__updateRadarSeries(chartConfigData);

    return baseChart;
  }
}


export default RadarChart
