var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.template
    ? _c(
        "div",
        [
          _c("v-runtime-template", {
            attrs: { template: _vm.template.view.viewTemplate },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }