<template>
  <div>
    <schema-form v-model="startEndData" :schema="schema" :form="form"></schema-form>
  </div>
</template>

<script>
import StartEndMixin from "vue-diagram/src/bpmn/panel/components/mixins/startEnd.mixin";

export default {
  name: "IntermediateThrowEvent",
  mixins:[StartEndMixin],
  data(){
    return {
      startEndSchema: {
        type: Object,
        properties: {}
      },
      startEndForm: [],
    }
  }
}
</script>

<style scoped>

</style>
