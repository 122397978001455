<template>
  <div class="diagram-config-wrapper">

  </div>
</template>

<script>
export default {
  name: "VueDiagramConfig",
  props: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
