import DatasetChart from './base/DatasetChart'

class BoxPlotChart extends DatasetChart {
  constructor() {
    super('BoxPlotChart');
  }

  getSeries() {
    const series = {
      "type": "boxplot",
      "emphasis": {
        "focus": "series"
      },
      "name":"",
      "smooth": true
    }
    return [series];
  }

  getChartDefinition() {
    let baseChart = super.getChartDefinition();
    const series = this.getSeries();
    baseChart.series = series;
    return baseChart;
  }
}


export default BoxPlotChart
