<template>
  <div :class="['diagram-editor-container', containerClass]">
    <div :class="elementClass" v-if="enableElements">
      <vue-diagram-elements :elements="elements"></vue-diagram-elements>
    </div>
    <div :class="editorClass">
      <vue-diagram-editor-content v-model="value"></vue-diagram-editor-content>
    </div>
    <div :class="configClass" v-if="enableConfig">
      <vue-diagram-config></vue-diagram-config>
    </div>
  </div>
</template>

<script>
import VueDiagramElements from "@/lib/vue-diagram/src/components/editor/components/VueDiagramElements";
import VueDiagramEditorContent from "@/lib/vue-diagram/src/components/editor/components/VueDiagramEditorContent";
import VueDiagramConfig from "@/lib/vue-diagram/src/components/editor/components/VueDiagramConfig";
import 'bpmn-font/dist/css/bpmn.css'
import 'bpmn-font/dist/css/bpmn-codes.css'
import 'bpmn-font/dist/css/bpmn-embedded.css'


export default {
  name: "VueDiagramEditor",
  components: {VueDiagramConfig, VueDiagramEditorContent, VueDiagramElements},
  props: {
    isDroppable: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    containerClass: {
      type: String,
      default: function () {
        return 'container-class row';
      }
    },
    elementClass: {
      type: String,
      default: function () {
        return 'element-class col-2'
      }
    },
    editorClass: {
      type: String,
      default: function () {
        return 'editor-class col'
      }
    },
    configClass: {
      type: String,
      default: function () {
        return 'config-class col-3'
      }
    },
    enableElements: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    enableConfig: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    isDebug: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    elements:{
      type: Array,
      default: function () {
        return [];
      }
    },
    value: {
      type: Array,
      default: function () {
        return [];
      }
    },
    name: {
      type: String
    }
  },
  data() {
    return {
     // content: []
    }
  },
  /*mounted(){
    this.content = this.value;
  },
  watch: {
    content: {
      handler(newVal, oldVal){
        this.$emit('input', newVal);
      },
      deep: true
    }
  },*/
  watch: {
    value: {
      handler(newVal, oldVal){
        this.$emit('input', newVal);
      },
      deep: true
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
