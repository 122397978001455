var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "diagram-elements-container" }, [
    _c("label", [_vm._v("Elements")]),
    _c(
      "div",
      { staticClass: "elements-wrapper dragable-wrapper" },
      [
        _c(
          "draggable",
          {
            ref: "elements",
            staticClass: "list-group",
            attrs: {
              clone: _vm.clone,
              group: { name: "workflow", pull: "clone", put: false },
              move: _vm.onMove,
              id: "dragable",
              tag: "ul",
            },
            on: { end: _vm.onEnd, start: _vm.onStart },
            model: {
              value: _vm.elements,
              callback: function ($$v) {
                _vm.elements = $$v
              },
              expression: "elements",
            },
          },
          [
            _c(
              "transition-group",
              { attrs: { name: "flip-list", type: "transition" } },
              _vm._l(_vm.elements, function (item) {
                return _c(
                  "li",
                  {
                    key: item.name,
                    staticClass: "list-group-item",
                    attrs: { itemref: item },
                  },
                  [
                    _c("div", { attrs: { title: item.description } }, [
                      _c(
                        "span",
                        {
                          staticClass: "mr-2 text-muted",
                          staticStyle: { "font-size": "0.7rem" },
                        },
                        [_c("i", { class: item.icon || "fas fa-circle-notch" })]
                      ),
                      _c("span", [_vm._v(_vm._s(item.title || item.name))]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }