import Vue from "vue";
import {saveAs} from "file-saver";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from 'lodash/isEmpty';
import vueUtils from "vue-json-schema-form/src/utils/vueUtils";

const VueDfDashboardEdit = () => import("./VueDfDashboardEdit");
const VueDfDashboardAddWidget = () => import("./VueDfDashboardAddWidget");
const VueDfDashboardEditCustomWidget = () => import("./VueDfDashboardEditCustomWidget");
import VueDfDashboardRow from "./VueDfDashboardRow";
import DashboardService from "./dashboardService";
import dynamicCssManager from "./utils/dynamicCssManager";

const dashboardMixin = {
  props: ['categories', 'structures', 'crudServiceName', 'includeWidgets', 'options'],
  components: {
    VueDfDashboardEdit,
    VueDfDashboardAddWidget,
    VueDfDashboardRow,
    VueDfDashboardEditCustomWidget
  },
  data() {
    return {
      count: 0,
      isEditMode: false,
      dashboardCopy: {},
      widgets: {},
      /*addedWidgets: [],*/
      structuresCopy: {},
      uploadedFile: null,
      shouldMergeNewUpload: false,
      dashboardService: null
    }
  },
  watch: {
    dashid: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dashboardCopy = {};
        this.loadDashboardCopy();
      }
    }
  },
  created: function () {
    //  console.log('cerating dashboard', this);
    this.dashboardService = new DashboardService(this.dashid, this.dashboardName, this.dashboardServiceInstance, this.model.title);
    this.isEditMode = this.editMode || false;
    this.loadWidgets(); //TODO - do we need this ???
  },
  beforeMount() {

    this.loadDashboardCopy();
    this.loadStructures();
  },
  mounted() {
    this.loadCssContent(this.dashid, this.dashboardCopy);
    this.mergeContext();
  },
  computed: {
    dataId: function () {
      return {
        [this.dashid]: ''
      }
    }
  },
  methods: {
    mergeContext() {
      // console.log('Dashboard merge context', this.dashboardCopy, this.inputContext);
      if (this.dashboardCopy.context) {
        vueUtils.vueMerge(this.inputContext, this.dashboardCopy.context);
      }
    },
    importDashboard: function () {
      this.$refs.uploadModal.show();
    },
    importWidget: function () {
      this.$refs.uploadWidgetModal.show();
    },
    onWidgetUploadInput: function (event) {
      const that = this;
      this.$nextTick(function () {
        const reader = new FileReader();
        reader.onload = function (loadEvent) {
          const widgetObj = JSON.parse(loadEvent.target.result);

          let widgetClone = {}
          that.$widgetStore.vueJsonMerge(widgetClone, widgetObj);
          const widgetId = that.generateWidgetId();
          that.$set(widgetClone, 'id', widgetId);

          that.addNewWidgetToModel(widgetClone);
        }
        reader.readAsText(that.uploadedFile);
      });
    },
    onUploadInput: function (event) {
      const that = this;
      this.$nextTick(function () {
        const reader = new FileReader();
        reader.onload = function (loadEvent) {
          const dashObj = JSON.parse(loadEvent.target.result);
          if (!that.shouldMergeNewUpload) {
            const dashid = 'dash-' + that.$widgetStore.id();
            dashObj.dashid = dashid;
            // dashObj.title = dashObj.title + '-1';
          }
          let copy = {}
          that.$widgetStore.vueJsonMerge(copy, dashObj);
          that.$set(that, 'dashboardCopy', copy);
        }
        reader.readAsText(that.uploadedFile);
      });
    },
    exportDashboard: function () {
      const data = JSON.stringify(this.dashboardCopy);
      const blob = new Blob([data], {type: 'application/json;charset=utf-8'})
      saveAs(blob, this.dashboardCopy.title + '.json');
    },
    loadStructures: function () {
      if (!this.structures) {
        this.$widgetStore.vueJsonMerge(this.structuresCopy, this.$widgetStore.structures);
      } else {
        this.$widgetStore.vueJsonMerge(this.structuresCopy, this.structures);
      }
    },
    loadDashboardCopy: function () {
      // console.log('The model in dashboard copy', this.model);
      ++this.count;
      let copy = {}
      this.fixModelWidgets(this.model);
      this.$widgetStore.vueJsonMerge(copy, this.model);
      this.$set(this, 'dashboardCopy', copy);
      //console.log('loadDashboardCopy', this.model, copy, this.dashboardCopy);
    },
    loadCssContent: function (id, elementModel) {
      if (elementModel && (null !== elementModel.cssContent && undefined !== elementModel.cssContent)) {
        //  console.log('Css content', elementModel.cssContent);
        dynamicCssManager.appendChild(this, id, elementModel.cssContent);
      }
    },
    filterAddableWidgetsByPermission: function () {
      if (this.permissions && this.permissions.allowAllWidgets === false) {
        const toFilterWidgets = this.permissions.allowedWidgets;
        const widgetsToInclude = [];
        if (toFilterWidgets) {
          const that = this;
          toFilterWidgets.forEach(function (item) {
            if (item.widgetType) {
              item.widgetType.forEach(function (type) {
                if (that.widgets[type]) {
                  widgetsToInclude.push(that.widgets[type]);
                }
              });
            }
          });
        }
        this.$set(this, 'widgets', widgetsToInclude);
      }
    },
    loadWidgets: function () {
      // console.log('Loading widgets');
      const that = this;
      let widgetsToInclude = null
      if (this.includeWidgets && this.includeWidgets.length > 0) {
        widgetsToInclude = {};
        for (const prop in this.$widgetStore.widgets) {
          const widget = this.$widgetStore.widgets[prop];
          if (that.includeWidgets.indexOf(widget.type) > -1) {
            widgetsToInclude[prop] = widget;
          }
        }
      }

      if (isEmpty(widgetsToInclude)) {
        widgetsToInclude = this.$widgetStore.widgets;
      }
      widgetsToInclude = this.fixWidgetIds(widgetsToInclude);
      this.$widgetStore.vueJsonMerge(this.widgets, cloneDeep(widgetsToInclude));
      this.filterAddableWidgetsByPermission();
    },
    fixWidgetIds: function (widgets) {
      const that = this;
      //console.log('Fixing widget ids for widgets', this.dashboardName, this.dashid, widgets);
      for (const p in widgets) {
        const widget = widgets[p];
        if (!widget.id) {
          widget.id = that.generateWidgetId();
        }
      }
      return widgets;
    },
    fixModelWidgets: function (dashboard) {
      if (dashboard.rows) {
        const that = this;
        dashboard.rows.forEach(function (row) {
          if (row && row.columns) {
            row.columns.forEach(function (col) {
              if (col && col.widgets) {
                col.widgets = that.fixWidgetIds(col.widgets);
                that.dashboardService.addWidgets(col.widgets);
              }
            });
          }
        })
      }
      return dashboard;
    },
    cancelEditMode: function () {
      this.dashboardCopy = {};
      this.$widgetStore.vueJsonMerge(this.dashboardCopy, this.model);
      this.structuresCopy = {};
      this.loadStructures();

      this.isEditMode = false;
      this.$emit('toggleEditMode', this.isEditMode);

    },
    toggleEditMode: function () {
      if (this.isEditMode) {
        //   console.log('save dashboardCopy', this.dashboardCopy);
        //   console.log('test circural' + JSON.stringify(this.dashboardCopy));

        this.$widgetStore.vueJsonMerge(this.model, this.dashboardCopy);
        this.$emit('toggleSavedDashboard', this.model);
      } else {
        //enter edit mode
      }
      this.isEditMode = !this.isEditMode;
      this.$emit('toggleEditMode', this.isEditMode);
    },
    editCustomWidgetsDialog: function () {
      this.$refs.EditCustomWidgetsModal.show();
      this.$emit('editCustomWidgetsDialog')
    },
    editDashboardDialog: function () {
      console.log('Clicked edit dashboard dialog');
      this.$refs.EditDashboardModal.show();
      this.$emit('editDashboardDialog')
    },
    onDashboardHideTitleUpdate: function (event) {
      console.log('udating hide title', event);
      this.$set(this.dashboardCopy, 'hideTitle', event);
      this.$emit('dashboardchanged', this.dashboardCopy);
    },
    onDashboardTitleUpdate: function (event) {
      this.$set(this.dashboardCopy, 'title', event);
      // this.count += 1;
      this.$emit('dashboardchanged', this.dashboardCopy);
    },
    onDashboardContextUpdated: function (event) {
      this.onDashboardUpdated(event);
    },
    onDashboardCssUpdated: function (event) {
      // call du
      /*if (event && (null!==event.cssContent && undefined!==event.cssContent)){
        console.log('Css content', event.cssContent);
        dynamicCssManager.appendChild(this.$el, this.dashid, event.cssContent);
      }*/
      this.loadCssContent(this.dashid, event);
      this.onDashboardUpdated(event);
    },
    onDashboardUpdated: function (event) {
      this.$set(this, 'dashboardCopy', event);
      // this.count += 1;
      //   console.log('dashboard upraded', event);
      this.$emit('dashboardchanged', this.dashboardCopy);
    },
    addWidgetDialog: function () {
      this.$refs.InsertWidgets.show();
      this.$emit('addWidgetDialog')
    },
    generateWidgetId: function () {
      return 'widget-' + this.$widgetStore.id();
    },
    addWidget: function (widget) {
      const wDef = {};
      const widgetClone = cloneDeep(this.$widgetStore.widgets[widget.type]);
      const widgetId = this.generateWidgetId();
      this.$set(widgetClone, 'id', widgetId);
      this.$widgetStore.vueJsonMerge(wDef, widgetClone);

      this.addNewWidgetToModel(wDef);
    },
    addNewWidgetToModel: function (widget) {
      if (this.dashboardCopy) {
        let column = this.findFirstWidgetColumn(this.dashboardCopy);
        if (column) {
          if (!column.widgets) {
            column.widgets = Vue.observable([]);
          }
          column.widgets.push(widget);
          this.dashboardService.addWidget(widget);

          //   console.log('New widget added', cloneDeep(widget));
          this.notifyMessage('New widget loaded');

          // hack to force render
          this.$set(this.dashboardCopy, 'count', this.dashboardCopy.count ? this.dashboardCopy.count + 1 : 1);
        } else {
          console.error('could not find first widget column');
        }

      } else {
        console.error('model is undefined');
      }
    },
    findFirstWidgetColumn: function (model) {
      let column = null;
      if (!Array.isArray(model.rows)) {
        console.error('model does not have any rows');
        return null;
      }
      for (let i = 0; i < model.rows.length; i++) {
        const row = model.rows[i];
        if (Array.isArray(row.columns)) {
          for (let j = 0; j < row.columns.length; j++) {
            const col = row.columns[j];
            if (!col.rows) {
              column = col;
              break;
            }
          }
        }
        if (column) {
          break;
        }
      }
      return column;
    }
  }
}

export default dashboardMixin
