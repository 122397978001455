import Vue from "vue";
import {schemaFormPlugin} from "vue-json-schema-form/src/plugins";
import vueDashboardFrameworkPlugin from "vue-dashboard-framework";
import vueDashboardFrameworkPluginApp from "vue-dashboard-framework-app-components";
import vueDashboardFrameworkIntegrations from "vuedf-integrations";

const initAppViewMixin = {
  beforeCreate() {
    Vue.use(schemaFormPlugin);
    Vue.use(vueDashboardFrameworkPlugin, {
      eventBus: this.$appConfig.WSEventBusOptions
    });
    Vue.use(vueDashboardFrameworkPluginApp);
    Vue.use(vueDashboardFrameworkIntegrations);
  },
}

export default initAppViewMixin;
