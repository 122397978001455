import BaseChart from './base/BaseChart'

class ValueChart extends BaseChart {
  constructor() {
    super('ValueChart');
  }

  __prepareChartDefinition() {
    this.chartDefinition = {
      series: [
        {
          type: 'scatter',
          data: [[0,0.8]],
          symbolSize: 1,
          label: {
            normal: {
              show: true,
              formatter: '{title| }',
              color: '#000',
              rich: {
                title:{
                  fontSize: this.autoFontSize(400),
                  backgroundColor:{
                    image:"https://upload.wikimedia.org/wikipedia/commons/d/d3/User_Circle.png"
                  }
                }
              }
            }
          }
        },
        {
          type: 'scatter',
          data: [[0,0.0]],
          symbolSize: 1,
          label: {
            normal: {
              show: true,
              formatter: '{title|Increase}\n{term|$1024.12}',
              color: '#000',
              rich: {
                title:{
                  fontSize: this.autoFontSize(20),
                  padding: 5

                },
                term: {
                  fontSize: this.autoFontSize(80),
                  fontWeight: 'bold'
                }
              }
            }
          }
        },
        {
          type: 'scatter',
          data: [[0,-0.6]],
          symbolSize: 1,
          label: {
            normal: {
              show: true,
              formatter: '{term|60%}\t\t\t\t{title| }',
              color: '#000',
              rich: {
                title:{
                  fontSize: this.autoFontSize(80),
                  fontWeight: 'bold',
                  color: 'rgb(199,86,83)',
                  backgroundColor: {
                    image: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Caret_down_font_awesome.svg'
                  }
                },
                term: {
                  fontSize: this.autoFontSize(40),
                  fontWeight: 'bold',
                  color: 'rgb(107, 107, 107)'
                }
              }
            }
          }
        }
      ],
      xAxis: {
        axisLabel: {show: false},
        axisLine: {show: false},
        splitLine: {show: false},
        axisTick: {show: false},
        min: -1,
        max: 1
      },
      yAxis: {
        axisLabel: {show: false},
        axisLine: {show: false},
        splitLine: {show: false},
        axisTick: {show: false},
        min: -1,
        max: 1
      }
    }
  }

  __sumAllMetrics() {
    if (this.metrics && this.metrics.length) {

    } else {

    }
  }

  __addScatterSeries(values) {

  }

  getChartDefinition() {
    this.__prepareChartDefinition();
    console.log('Value Chart', this);

    let values;
    if (!this.getChartConfigData()) {
      const val = this.__sumAllMetrics();
    }
    return this.chartDefinition;
  }

}

export default ValueChart
