<template>
  <div class="ace-markdown-wrapper">
    <div class="ace-markdown-container">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="ace-markdown-editor-wrapper">
            <div class="ace-markdown-editor-container">
              <vue-ace-editor :width="'100%'" :height="'100%'" :position="'absolute'" :content="content"
                              v-on:input="onAceValueChanged($event)"
                              @change-content="onAceValueChanged($event)"
                              :config="config"></vue-ace-editor>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="ace-markdown-viewer-wrapper">
            <div class="ace-markdown-viewer-container">
              <markdown-it-vue :content="markdownContent"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  const VueAceEditor =()=>import('../aceEditor/VueAceEditor')
  import MarkdownItVue from 'markdown-it-vue'
 /* const MarkdownItVueLight  =()=>import('markdown-it-vue/dist/markdown-it-vue-light.umd.min.js')
  const MarkdownItMermaid = ()=>import('markdown-it-vue/src/markdown-it-plugin-mermaid');*/


  export default {
    name: "VueAceMarkdownEditor",
    props: ['value'],
    components: {
      VueAceEditor, MarkdownItVue
    },
    data() {
      return {
        content: '',
        markdownContent:'',
        config: {
          lang: 'markdown',
          onInput: true
        }
      }
    },

    created() {
      this.content = this.value?this.value:'### o';
    //  console.log('On Markdown Created', this.content);
    },
    methods: {
      onAceValueChanged: function (event) {
       // console.log('Markdown onAceValueChanged', event);
       // this.content = event;
        this.markdownContent = event;
        this.$emit('input', event);
      }
    }
  }
</script>

<style scoped>

  .ace-markdown-wrapper {
    /*overflow-y: scroll;*/
  }

  .ace-markdown-editor-wrapper {
      min-height: 400px;
  }
  .ace-markdown-editor-container {
/*    width: 90%;
    height: 100%;
    position: relative;*/
  }

  .ace-markdown-viewer-wrapper {
    padding: 10px;
    border-left: 1px solid #cdcdcd;
  }

  .ace-markdown-viewer-container {

  }

</style>
