<template>
  <div>
    <schema-form v-model="startEndData" :schema="schema" :form="form"></schema-form>
  </div>
</template>

<script>
import StartEndMixin from "vue-diagram/src/bpmn/panel/components/mixins/startEnd.mixin";

export default {
  name: "StartEvent",
  mixins:[StartEndMixin],
  data(){
    return {
      startEndSchema: {
        type: Object,
        properties: {
          formId:{
            type: 'string',
            title: 'Form Id',
            description: 'Enter form Id'
          },
          role:{
            type: 'string',
            title: 'Access Role',
            description: 'Define Role to start process.'
          },
          inputExpression:{
            type: 'string',
            title: 'Input Expression',
            description: 'Input expression will transform input data that will be provided to the element'
          },
          outputExpression:{
            type: 'string',
            title: 'Output Expression',
            description: 'Output expression will transform output data from the element and will be provided down to execution flow'
          }
        }
      },
      startEndForm: [
        {
          type: 'tabs',
          tabs:[
            {
              title: 'Details',
              items:[
                {
                  type: 'section',
                  sectionType: 'fieldset',
                  title: 'Details',
                  items:['role']
                },
                {
                  type: 'section',
                  sectionType: 'fieldset',
                  title: 'Form',
                  items:['formId']
                }
              ]
            },
            {
              title: 'In/Out',
              items:[
                {
                  type:'ace',
                  key: 'inputExpression',
                  style: 'height:200px;position:relative'
                },
                {
                  type:'ace',
                  key: 'outputExpression',
                  style: 'height:200px;position:relative'
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
