import DatasetReduce from '../datasetReduce'
import Charts from './charts'

class EChartUtils {
  constructor(jsulator) {
    this.datasetReduce = new DatasetReduce(jsulator);
    this.charts = new Charts(jsulator);
  }

  __updateChartSeries(chartConfig, dimensionMetrics) {
    if (dimensionMetrics && dimensionMetrics.metrics) {
      if (dimensionMetrics.metrics.length > 0) {
        chartConfig.series = [];
        dimensionMetrics.metrics.forEach(function (item) {
          const series = {
            type: 'bar'
          };
          chartConfig.series.push(series)
        });
      }
    }
    return chartConfig;
  }

  reduceDataset(dataset, dimensionMetrics) {
    if (!dataset){
      return dataset;
    }
    if (!dimensionMetrics) {
      dimensionMetrics = {
        dimensions: [],
        metrics: []
      }
    }
    console.log('Reducing dataset', dataset, dimensionMetrics);
    return this.datasetReduce.reduce(dataset, dimensionMetrics.dimensions, dimensionMetrics.metrics);
  }

  createConfig(chartConfig, dataset, dimensionMetrics, clientAreaSettings) {
    const preparedChart = this.charts.createConfig(chartConfig, dataset, dimensionMetrics, clientAreaSettings);
    return preparedChart;
  }
}

export default EChartUtils;
