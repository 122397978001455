<template>
  <div class="chart-designer-container">
    <div class="chart-designer-wrapper bg-white p-4">
      <vertical-accordion>
        <accordion-item >
          <template v-slot:header>
            Data Panel
          </template>
          <template v-slot>
            <chart-designer-data-panel @input="onDataPanelUpdate"></chart-designer-data-panel>
          </template>
        </accordion-item>
        <accordion-item visible>
          <template v-slot:header>
            Chart Panel
          </template>
          <template v-slot>
            <chart-designer-chart-panel :data-model="localDataModel"
                                        :properties-model="localPropertiesModel"></chart-designer-chart-panel>
          </template>
        </accordion-item>
        <accordion-item>
          <template v-slot:header>
            Properties Panel
          </template>
          <template v-slot>
            <chart-designer-properties-panel :properties-model="localPropertiesModel"
                                             @input="onPropertiesPanelUpdate"></chart-designer-properties-panel>
          </template>
        </accordion-item>
      </vertical-accordion>
    </div>
  </div>
</template>

<script>
  const CollapsibleContainer =()=>import('./ui/CollapsibleContainer')
  const ChartDesignerDataPanel =()=>import( './components/ChartDesignerDataPanel');
  const ChartDesignerChartPanel =()=>import('./components/ChartDesignerChartPanel');
  const ChartDesignerPropertiesPanel =()=>import( './components/ChartDesignerPropertiesPanel')
  const VerticalAccordion =()=>import( "../accordion/VerticalAccordion")
  const AccordionItem =()=>import( "../accordion/AccordionItem");


  export default {
    name: "ChartDesigner",
    components: {
      AccordionItem,
      VerticalAccordion,
      CollapsibleContainer, ChartDesignerDataPanel, ChartDesignerChartPanel, ChartDesignerPropertiesPanel},
    data() {
      return {
        localDataModel: undefined,
        localPropertiesModel: undefined,
        colStates: {
          dataPanel: {
            size: 'col-3'
          },
          propertiesPanel: {
            size: 'col-2'
          },
          chartPanel: {
            size: 'col-7'
          }
        }
      }
    },
    methods: {
      onDataPanelCogClick: function () {

      },
      onPropertiesPanelCogClick: function () {

      },
      onDataPanelUpdate: function (dataModel) {
        if (dataModel) {
          this.localDataModel = JSON.parse(dataModel);
        }
      },
      onPropertiesPanelUpdate: function (propertiesModel) {
        this.localPropertiesModel = propertiesModel;
      }
    }
  }
</script>

<style scoped>

  .chart-designer-container{
    min-height: 500px;
    overflow-y: auto;
  }

</style>
