<template>
  <div v-if="dataset || chartConfig" class="chart-container">
    THIS IS INTERESTING
    <div  @click.prevent="onContextChanged({computedChart, chartConfig, dataset, reducedDataset, dimensions})" class="chart-wrapper p-2">
      <v-chart :options="computedChart" :init-options="initOptions" autoresize ref="eChart"/>
    </div>

  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/map'
import 'echarts/lib/chart/radar'
import 'echarts/lib/chart/scatter'
import 'echarts/lib/chart/sankey'
import 'echarts/lib/chart/tree'
import 'echarts/lib/chart/effectScatter'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/polar'
import 'echarts/lib/component/geo'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/component/visualMap'
import 'echarts/lib/component/dataset'

import 'zrender/lib/svg/svg'

import EChartUtils from './eChartUtils'

export default {
  name: "ChartBuilderEChartWrapper",
  components: {
    'v-chart': VChart
  },
  data(){
    return {
      eChartUtils: null,
      reducedDataset: null,
      computedChart: {},
      computedChartOptions: {},
      initOptions:{
        renderer: 'svg'
      }// can be 'canvas' for fast data rendering on Web - SVG is better for mobile
    }
  },
  props: ['chartConfig', 'dataset', 'dimensions'],
  created() {
    this.eChartUtils = new EChartUtils(this.$jsulator);
  },
  watch:{
    /*computedChart:{
      handler(newVal){
        this.computedChartOptions = Object.assign({}, newVal);
      },
      deep: true
    },*/
    chartConfig:{
      handler(newVal, oldVal){
        this.computedChart = this.computeChart();
      },
      deep: true
    },
    dataset:{
      handler(newVal, oldVal){
        this.computedChart = this.computeChart();
      },
      deep: true
    },
    dimensions:{
      handler(newVal, oldVal){
        this.computedChart = this.computeChart();
      },
      deep: true
    }
  },
  methods:{
    getClientArea:function(){
      if (this.$el){
        return {
          offsetWidth: this.$el.offsetWidth,
          offsetHeight: this.$el.offsetHeight
        }
      }
    },
    computeChart: function () {

      this.reducedDataset = this.eChartUtils.reduceDataset(this.dataset, this.dimensions);
      console.log('Computing and reducing chart', this.getClientArea(), this.chartConfig, this.dataset, this.dimensions, this.reducedDataset);
      let val = this.eChartUtils.createConfig(this.chartConfig, this.reducedDataset, this.dimensions, this.getClientArea());
      console.log('Computed chart val', val);
      return Object.assign({}, val); //this.$widgetStore.getObject(val);
    },
    onContextChanged: function (context) {
      console.log('in chart contextchanged', context);
      //this.currentContext = context;
      this.$emit('contextchanged', context);
    }
  }
}
</script>

<style scoped>
.echarts {
  width: 100%;
  /*height: 100%;*/
}
</style>
