<template>
  <div :class="config.htmlClass" :style="config.style" class="app-container">
    <vue-df-app-layout :app-config="appConfig" :config="layoutConfig" :security-config="securityConfig"
                       :selected-route-config="selectedConfig" @selected="onSelected">
      <vue-df-app-content :app-config="appConfig" :config="config"
                          :dashboard-edit-mode="dashboardEditMode" :input-context="inputContext" :security-config="securityConfig"
                          :selected-route-config="selectedConfig" @click.native="toggleSidebar"
                          @dashboardupdated="onDashboardUpdated"></vue-df-app-content>
    </vue-df-app-layout>
  </div>
</template>

<script>
  import VueDfAppContent from "./UIComponents/Content/VueDfAppContent"
  import VueDfAppLayout from "./UIComponents/Layout/VueDfAppLayout";
  import componentMixin from './componentMixin'
  import permissionMixin from "./UIComponents/permissionMixin";
  import cloneDeep from 'lodash/cloneDeep'

  export default {
    name: "VueDfAppDashboard",
    components: {VueDfAppLayout, VueDfAppContent},
    mixins: [componentMixin, permissionMixin],
    props: {
      dashboardEditMode: {
        type: Boolean
      },
      parentDashboardName: {
        type: String
      },
      parentDashId: {
        type: String
      }
    },
    watch: {
      '$route': {
        handler(newVal, oldVal) {
          // if (!this.appConfig.isComponentMode) {
          if (newVal !== oldVal) {
        //    console.log('Route changed', newVal, oldVal);
            this.loadWidgetDashboard();
          }
        },
        immediate: true
      }
    },
    computed: {
      appDashboardName: function () {
        return this.appConfig.appDashboardName;
      },
      layoutConfig: function () {
        //  console.log('Config in dashboard', this.config, this.selectedConfig, this.selectedRouteConfig);
        return this.config.layoutSettings || {};
      }
    },
    data() {
      return {
        appServiceName: 'appHttpService',
        vueDfApplicationService: 'vueDfApplicationService',
        generatedCss: '',
        currentCss: '',
        selectedConfig: {}
      }
    },
    created() {
    //     console.log('Created AppDashboard', this.config);
      const that = this;
      this.$dashboardBus.$on('DASHBOARD_SAVED::' + this.parentDashboardName, that.onDashboardSave);
      if (this.config){

      }
    },
    beforeDestroy() {
      const that = this;
      this.$dashboardBus.$off('DASHBOARD_SAVED::' + this.parentDashboardName, that.onDashboardSave);
    },
    beforeMount() {
      // calculate CSS - generate and store into CSS property
      // property CSS will be edited/updated by the user - we will check if there is a change and
      this.generatedCss = this.generateCss();
      // console.log('generatedCss', this.generatedCss, this.config.css);
      if (this.config.css && this.config.css !== this.generatedCss) {
        this.generatedCss = this.config.css;
      } else {
        this.config.css = this.generatedCss;
      }
    },
    methods: {
      loadWidgetDashboard: function () {
        const metaDef = this.$route.meta.def;
        console.log('loadWidgetDashboard route metaDef', this.$route.meta, metaDef);
        if (metaDef)
          this.matchAndUpdateNavigationItem(metaDef);
      },
      getSecurityDef: function (parent, item, securitySettingsRoutePermissions) {

        if (securitySettingsRoutePermissions && securitySettingsRoutePermissions.length > 0) {
          for (let i = 0; i < securitySettingsRoutePermissions.length; i++) {
            const securityItem = securitySettingsRoutePermissions[i];
            const routes = securityItem.route;
            if (routes) {
              const findItem = parent ? parent.name + '=>' + item.name : item.name;
              console.log('Try to find in permisions', routes, findItem);
              let indexOfRouteInSecurity = routes.indexOf(findItem);
              if (indexOfRouteInSecurity > -1) {
                return securityItem.permissions;
              }
            }
          }
        }
        return null;
      },
      prepareMatchedRoutes: function (preparedMatchedRoutes, routeItems, dash, securitySettingsRoutePermissions) {
        const that = this;

        routeItems.forEach(function (item) {

          if (item.itemDropDownSettings && item.itemDropDownSettings.items && item.itemDropDownSettings.items.length > 0) {
            item.itemDropDownSettings.items.forEach(function (subItem) {
              const tmp = cloneDeep(item);
              if (!tmp.link) {
                tmp.link = ('/' + (tmp.name ? tmp.name.toLowerCase() : ''));
              }
              delete tmp.itemDropDownSettings;
              const obj = {};
              obj.parent = tmp;
              obj.item = subItem;
              obj.permissions = that.getSecurityDef(tmp, subItem, securitySettingsRoutePermissions);
              const objToSave = that.prepareRoute(obj, that.parentDashboardName, dash);
              preparedMatchedRoutes.push(objToSave);
            });
          } else {
            const obj = {};
            obj.parent = null;
            obj.item = cloneDeep(item);
            obj.permissions = that.getSecurityDef(null, item, securitySettingsRoutePermissions);
            const objToSave = that.prepareRoute(obj, that.parentDashboardName, dash);
            preparedMatchedRoutes.push(objToSave);
          }
        });
      },
      prepareRoute: function (matchedItem, parentDashboardName, currentDashboard) {
        const appName = this.appConfig.appDashboardName;
        const routeKey = (matchedItem.parent ? matchedItem.parent.name : '') + '=' + matchedItem.item.name;
        const objToSave = {
          parentDashboardName: parentDashboardName,
          parentDashid: currentDashboard.dashid,
          applicationName: appName,
          appRootPath: appName.toLowerCase().split(' ').join('-'),
          routeKey: routeKey,
          parent: matchedItem.parent,
          item: matchedItem.item,
          permissions: matchedItem.permissions
        }

        return objToSave;
      },
      onDashboardSave: function (dashboardModel, currentDashboardId) {
        console.log('On Dashboard saved: ' + this.parentDashboardName + ' = ' + currentDashboardId, dashboardModel);
        if (dashboardModel && this.parentDashId === currentDashboardId) {
          const that = this;
          dashboardModel.forEach(function (dash) {
            if (dash.dashid === that.parentDashId) {
              console.log('Current parent dashboard ', dash);
              const widget = that.$jsulator.evaluate('rows[0].columns[0].widgets[0]', dash);
              console.log('Found widget', widget);
              if (widget) {
                const sidebarItems = that.$jsulator.evaluate('config.data.layoutSettings.sidebarSettings.items', widget);
                const navBarItems = that.$jsulator.evaluate('config.data.layoutSettings.navBarSettings.items', widget);
                const userMenuItems = that.$jsulator.evaluate('config.data.layoutSettings.userMenuSettings.items', widget);
                const securitySettingsRoutePermissions = that.$jsulator.evaluate('config.data.securitySettings.routePermissions', widget);
                console.log('securitySettingsRoutePermissions', securitySettingsRoutePermissions);

                const preparedMatchedRoutes = [];

                if (sidebarItems && sidebarItems.length > 0) {
                  that.prepareMatchedRoutes(preparedMatchedRoutes, sidebarItems, dash, securitySettingsRoutePermissions);
                }
                if (navBarItems && navBarItems.length > 0) {
                  // TODO do the same - refactor
                  that.prepareMatchedRoutes(preparedMatchedRoutes, navBarItems, dash, securitySettingsRoutePermissions)
                }
                if (userMenuItems && userMenuItems.length > 0) {
                  that.prepareMatchedRoutes(preparedMatchedRoutes, userMenuItems, dash, securitySettingsRoutePermissions)
                }


                if (preparedMatchedRoutes && preparedMatchedRoutes.length > 0) {
                  /*preparedMatchedRoutes.forEach(function(route){
                    that.$services[that.appServiceName].saveRoute(route, function (res) {});
                  });*/
                  // unique key for dash+routes => appKey:tenantName:applicationName
                  const appName = that.appConfig.appDashboardName;

                  const fullDashWithRoutes = {
                    parentDashboardName: that.parentDashboardName,
                    applicationName: appName,
                    appRootPath: appName.toLowerCase().split(' ').join('-'),
                    dashboard: dash,
                    routes: preparedMatchedRoutes
                  }
                  console.log('fullDashWithRoutes to save', fullDashWithRoutes)
                  that.$services[that.appServiceName].saveDashboardAndRoutes(fullDashWithRoutes, function (res) {
                  });
                }
              }
            }
          });
        }
      },
      onDashboardUpdated: function (item) {
        console.log('onDashboardUpdate in AppContent', item);
        this.matchAndUpdateNavigationItem(item);
      },
      resolveRoutePermissionsForCurrentUser: function (item, parent) {
        if (!this.appConfig.isComponentMode) {
          const permissionList = this.findPermissionByRouteItem(item, parent);
          if (permissionList && permissionList.length > 0) {
            const rolePermissions = this.getRolePermissions(permissionList);
            return rolePermissions;
          }
        }
        return null;
      },
      matchAndUpdateNavigationItem: function (selected) {
       // console.log('Start with matching sidebar items', this.layoutConfig.sidebarSettings, selected);
        const matchedItem = {};
        const that = this;

        const matchItems = function (selectedItem, config) {
          if (selectedItem.parent) {
            if (config.items) {
              config.items.forEach(function (item) {
                if (item.name === selectedItem.parent.name && item.itemDropDownSettings && item.itemDropDownSettings.items) {
                  item.itemDropDownSettings.items.forEach(function (subItem) {
                    if (subItem.name === selectedItem.item.name) {
                      subItem.dashboard = selectedItem.item.dashboard;
                      const tmp = item;//cloneDeep(item);
                      const tmpSubItem = subItem;// cloneDeep(subItem);
                      //delete tmp.itemDropDownSettings;
                      matchedItem.parent = tmp;
                      tmpSubItem.security = that.resolveRoutePermissionsForCurrentUser(tmpSubItem, tmp);
                      matchedItem.item = tmpSubItem;
                    }
                  });
                }
              })
            }
          } else {
            if (config && config.items) {
              config.items.forEach(function (item) {
                if (item.name === selectedItem.item.name) {
                  const tmp = item;//cloneDeep(item);
                  tmp.dashboard = selectedItem.item.dashboard;
                  tmp.security = that.resolveRoutePermissionsForCurrentUser(tmp);
                  matchedItem.parent = null;
                  matchedItem.item = tmp; //cloneDeep(item);

                }
              })
            }
          }
        }

        matchItems(selected, this.layoutConfig.sidebarSettings);
        matchItems(selected, this.layoutConfig.navBarSettings);
        matchItems(selected, this.layoutConfig.userMenuSettings);

        //this.$nextTick(function () {
          that.$set(selected.item, 'security', matchedItem.item.security);
          if (selected.parent && matchedItem.parent) {
            that.$set(selected.parent, 'security', matchedItem.parent.security);
          }

          that.selectedConfig = selected;
       //   console.log('End with matching sidebar items', this.layoutConfig.sidebarSettings, selected, this.selectedConfig, matchedItem);
        //});
      },
      onSelected: function (selected) {
    //    console.log('OnSelected in AppDashboard', this.selectedConfig, selected);
        // update Security config
        this.selectedConfig = selected;
      },
      toggleSidebar() {
        if (this.$vueDfAppSidebar.showSidebar) {
          this.$vueDfAppSidebar.displaySidebar(false)
        }
      },

      generateCss: function () {
        let css = '.some-class { \n }\n';

        const cfg = this.config || {};
        const layout = cfg.layoutSettings || {};

        css += this.buildHtmlClassCss(cfg.htmlClass, [cfg.style]) + '\n';
        css += this.buildHtmlClassCss(layout.htmlClass, [layout.style, layout.backgroundColor]) + '\n';

        return css;
      },
      buildHtmlClassCss: function (htmlClass, style) {
        if (htmlClass) {
          let css = '.' + htmlClass + '{';
          if (style) {
            style.forEach(function (item) {
              if (item) {
                let tmp = item[item.length - 1] === ';' ? item : item + ';'
                css += '\n' + tmp;
              }
            });
          }
          css += '}';

          return css;
        }
        return '';
      }
    },
    head: {
      title: function () {
        return {
          inner: this.appDashboardName
        }
      },
      style: function () {
        return [
          {type: 'text/css', inner: this.generatedCss, undo: true}
        ]
      }
    }
  }
</script>

<style scoped>

  .dashboard-simple .app-container .app-main-panel {
    /*width: calc(100% - 280px);*/
  }
</style>
