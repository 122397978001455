var r = {
  '\'': /\\\'/g,
  '"': /\\\"/g,
};

const ObjectPath = {
  parse: function (str) {

    if (typeof str !== 'string') {
      throw new TypeError('ObjectPath.parse must be passed a string');
    }

    var i = 0;
    var parts = [];
    var d, b, q, c;
    while (i < str.length) {
      d = str.indexOf('.', i);
      b = str.indexOf('[', i);

      // we've reached the end
      if (d === -1 && b === -1) {
        parts.push(str.slice(i, str.length));
        i = str.length;
      }

      // dots
      else if (b === -1 || (d !== -1 && d < b)) {
        parts.push(str.slice(i, d));
        i = d + 1;
      }

      // brackets
      else {
        if (b > i) {
          parts.push(str.slice(i, b));
          i = b;
        }
        q = str.slice(b + 1, b + 2);
        if (q !== '"' && q !== '\'') {
          c = str.indexOf(']', b);
          if (c === -1) {
            c = str.length;
          } else { // added by Ninel to handle: item[form.key[1]] and similar
            if (str[c+1]===']'){
              c++;
            }
          }
          let next = str.slice(i + 1, c);
          //if (next)
          parts.push(next);
          i = (str.slice(c + 1, c + 2) === '.') ? c + 2 : c + 1;
        } else {
          c = str.indexOf(q + ']', b);
          if (c === -1) c = str.length;
          while (str.slice(c - 1, c) === '\\' && b < str.length) {
            b++;
            c = str.indexOf(q + ']', b);
          }
          parts.push(str.slice(i + 2, c).replace(r[q], q));
          i = (str.slice(c + 2, c + 3) === '.') ? c + 3 : c + 2;
        }
      }
    }

   // console.log('Parsed str', str, parts);

    return parts;
  },

  dotted: function (arr) {
    if (!Array.isArray(arr)) {
      arr = [arr];
    }

    let res = '';
    let prev = '';
    let i = 0;
    arr.forEach(function (item) {
      if (item === undefined || item === 'undefined') {
        console.error('ObjectPath dotted fn find undefined part of key', arr);
      }
      if (i > 0)
        prev = arr[i - 1];

      if (item) {
        res += item;
      } else {
        res = (res.substring(0, res.length - 1) + '[]')
      }

      if (i < arr.length - 1) {
        res += '.';
      }

      ++i;
    });

    return res;
  },

  // root === true : auto calculate root; must be dot-notation friendly
  // root String : the string to use as root
  stringify: function (arr, quote) {

    if (!Array.isArray(arr))
      arr = [arr.toString()];

    quote = quote === '"' ? '"' : '\'';

    return arr.map(function (n) {
      //if (n)
      return '[' + quote + (n.toString()).replace(new RegExp(quote, 'g'), '\\' + quote) + quote + ']';
    }).join('');
  },

  normalize: function (data, quote) {
    return ObjectPath.stringify(Array.isArray(data) ? data : ObjectPath.parse(data), quote);
  }
}

export default ObjectPath
