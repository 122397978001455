var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _vm.dashboardEditMode
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("schema-form", {
                attrs: { form: _vm.typeForm, schema: _vm.typeSchema },
                model: {
                  value: _vm.dashboardTypeModel,
                  callback: function ($$v) {
                    _vm.dashboardTypeModel = $$v
                  },
                  expression: "dashboardTypeModel",
                },
              }),
              _c("hr"),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        !_vm.routeEnabled
          ? _c("div", { staticClass: "not-authorized d-flex" }, [_vm._m(0)])
          : _vm._e(),
        _vm.routeEnabled
          ? _c(
              "div",
              [
                _vm.dashboardName
                  ? _c(_vm.resolveDashboardType(), {
                      key: _vm.key,
                      tag: "component",
                      attrs: {
                        permissions: _vm.routePermissions,
                        "dashboard-edit-mode": _vm.dashboardEditMode,
                        "dashboard-name": _vm.dashboardName,
                        "default-mapper": _vm.defaultMapper,
                        "input-context": _vm.inputContext,
                        "disable-add": _vm.disableAdd,
                        "disable-edit": _vm.disableEdit,
                        "crud-service-name": "http",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "w-100 d-flex justify-content-center align-items-center" },
      [_c("h6", [_vm._v("You are not authorized to access this page")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }