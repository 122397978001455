<template>
  <div class="template-container">
    <component :is="dynamicComponent" v-bind="dataId" v-if="dynamicComponent"/>
  </div>
</template>

<script>
// https://dev.to/patarapolw/vue-runtime-compiler-for-arbitary-markdown-4602
import Vue from 'vue'
import templateMixin from './templateMixin'
import dynamicCssManager from "vue-dashboard-framework/src/components/vuedf/utils/dynamicCssManager";
import DraggableJsonTree from "vue-json-schema-form/src/components/draggableJsonTree/DraggableJsonTree";
import functionDiscoveryMixin from "vue-json-schema-form/src/components/schemaForm/functionDiscoveryMixin";

Vue.component('json-tree', DraggableJsonTree)

export default {
  name: "VueDfTemplateWrapper",
  mixins: [templateMixin, functionDiscoveryMixin],
  props: {
    model: Object,
    meta: Object,
    options: Object,
    dataModel: [Object, Array]
  },
  beforeMount() {
    this.createDynamicComponent();
    //  console.log('On Created options', this.options);
  },
  mounted() {
    this.loadTemplateCss(this.model);
  },
  data() {
    return {
      dynamicComponent: null,
      templateId: 'tmpl-' + this.$widgetStore.id()
    }
  },
  computed: {
    dataId: function () {
      return {
        [this.templateId]: ''
      }
    }
  },
  beforeDestroy() {
    this.dynamicComponent = null;
  },
  methods: {
    loadTemplateCss: function (def) {
      const css = this.$jsulator.evaluate('$config.data.templateStyling$', def);
      if (null !== css && undefined !== css) {
        dynamicCssManager.appendChild(this, this.templateId, css);
      }
    },
    loadFunctions(def) {
      const functions = this.$jsulator.evaluate('$config.data.templateFunctions$', def);
      if (null !== functions && undefined !== functions) {
        return functions;
      }
    },
    createDynamicComponent() {
      const that = this;
      const functions = this.loadFunctions(this.model);
      const contextSafe = {
        console: console,
        model: this.model,
        _meta: this.meta,
        dataModel: this.dataModel,
        options: this.options,
        this: {},
        api: {
          jsulator: this.$jsulator,
          stringify: JSON.stringify,
          this: this,
          emitEventDirect(eventName, eventDefinition) {
            if (!eventDefinition) {
              eventDefinition = {};
            }
            eventDefinition.source = {
              id: that.model.id,
              dashid: that.model.dashid
            }

       //     console.log('Direct Emiting', eventName, eventDefinition);
            that.$widgetBus.$emit(eventName, eventDefinition);
          },
          emitEvent(eventDef) {
            console.log('Emiting event', eventDef);
            that.$emit('emitevent', eventDef);
          }
        }
      };

      const methods = this.compileFunctions(functions, contextSafe);
      methods.lookupMethodOnParentsInContext = this.lookupMethodOnParentsInContext;
      methods.lookupMethodOnParents = this.lookupMethodOnParents;

      //console.log('Methods compiled: ', methods, that.options.template);

      this.dynamicComponent = Vue.extend({
        computed: {
          model: function () {
            return that.model
          },
          meta: function () {
            return that.meta;
          },
          dataModel: {
            get() {
              return that.options.dataModel
            },
            set(val) {
              that.$set(that.options, 'dataModel', val);
            }

          }
        },
        created() {
        },
        template: this.options.template,
        methods: methods,
        mounted() {
          // console.log('Inner DynamicComponent mounted', this)
        }
      })
    }
  }
}
</script>

<style scoped>
.template-container ::v-deep .json-tree-root {
  min-width: 10px;

}

/*max-width: 100%;*/

</style>
