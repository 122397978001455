var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "event-log-container resizable-content" }, [
    _c("div", { staticClass: "event-log-wrapper" }, [
      _c("div", { staticClass: "card1" }, [
        _c("div", { staticClass: "card-header1" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm",
              on: {
                click: function ($event) {
                  _vm.isVisible = !_vm.isVisible
                },
              },
            },
            [_vm._v("X")]
          ),
          _c("span", [
            _vm._v("\n          DEBUG WINDOW - Event Log\n        "),
          ]),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-danger float-right",
              on: { click: _vm.clear },
            },
            [_c("i", { staticClass: "fas fa-trash" })]
          ),
        ]),
        _vm.isVisible
          ? _c("div", { staticClass: "card-log-body" }, [
              _c(
                "div",
                { staticClass: "card-log-body-container p-2" },
                [
                  _c("b-table", {
                    attrs: {
                      "sticky-header": "400px",
                      striped: "",
                      hover: "",
                      items: _vm.items,
                      fields: _vm.fields,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(commands)",
                          fn: function (row) {
                            return [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-info mr-2",
                                  on: { click: row.toggleDetails },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        row.detailsShowing ? "Hide" : "Show"
                                      ) +
                                      " Full\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "row-details",
                          fn: function (row) {
                            return [
                              _c("json-tree", {
                                attrs: { data: row.item, level: 2 },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-warning",
                                  on: { click: row.toggleDetails },
                                },
                                [_vm._v("Hide Details")]
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(emittedAt)",
                          fn: function (data) {
                            return [
                              _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$jsulator.evaluate(
                                        "TO_STRING(TO_DATE($value$), 'TT')",
                                        data
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "cell(definition)",
                          fn: function (data) {
                            return [
                              !_vm.isSmallData(data)
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.getSmallData(data.value)) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isSmallData(data)
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.prepareBigData(data.value)) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "cell(context)",
                          fn: function (data) {
                            return [
                              !_vm.isSmallData(data)
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.getSmallData(data.value)) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isSmallData(data)
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.prepareBigData(data.value)) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "cell(value)",
                          fn: function (data) {
                            return [
                              !_vm.isSmallData(data)
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.getSmallData(data.value)) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isSmallData(data)
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.prepareBigData(data.value)) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2362688856
                    ),
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }