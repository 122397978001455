<template>
  <div class="panel process-panel">
    Process Panel
    <schema-form v-model="formData"  :schema="schema" :form="form"></schema-form>
  </div>
</template>

<script>
import PanelMixin from "vue-diagram/src/bpmn/panel/components/mixins/panel.mixin";

export default {
  name: "ProcessPanel",
  mixins:[PanelMixin],
  data(){
    return {
      processSchema:{
        type: Object,
        properties:{
          versionTag:{
            type: 'string',
            title: 'Version Tag',
            description:'Process version tag'
          },
          isExecutable:{
            type: 'boolean',
            title: 'Is Executable',
            description: 'Is this process executable?'
          }
        }
      },
      processForm:[
        {
          type: 'section',
          sectionType: 'fieldset',
          title: 'Details',
          items:[
            'isExecutable',
            'versionTag'
          ]
        }
      ]
    }
  },
  watch:{
    'formData.version': function(val) {
      this.updateProperties({ version: val })
    },
    'formData.isExecutable': function(val) {
      this.updateProperties({ isExecutable: val })
    }
  },
  mounted(){
    console.log('mounted Process Panel')
    this.mountSchemaForm()
  },
  methods:{
    mountSchemaForm(){
      this.mergeObjects(this.schema.properties, this.processSchema.properties);
      this.form.push(...this.processForm);
    }
  }
}
</script>

<style scoped>

</style>
