var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "p-3" }, [
      _c("h5", [_vm._v("Generate and analyze data")]),
      _c("div", { staticClass: "nav btn-group" }, [
        _c("div", { staticClass: "nav-item" }, [
          _c(
            "button",
            { staticClass: "btn btn-primary", on: { click: _vm.refreshData } },
            [_vm._v("Generate")]
          ),
        ]),
      ]),
    ]),
    _c("hr"),
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4 p-2" }, [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c("h6", { staticClass: "label p-2" }, [
                _vm._v("Generated Data"),
              ]),
              _c("draggable-json-tree", {
                attrs: {
                  "is-draggable": false,
                  data: _vm.generatedData,
                  level: 1,
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col-4 p-2" }, [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c("h6", { staticClass: "label p-2" }, [_vm._v("Analyzed Data")]),
              _c("draggable-json-tree", {
                attrs: {
                  "is-draggable": false,
                  data: _vm.analyzedData,
                  level: 1,
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col-4 p-2" }, [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c("h6", { staticClass: "label p-2" }, [_vm._v("Grouped Data")]),
              _c("draggable-json-tree", {
                attrs: {
                  "is-draggable": false,
                  data: _vm.groupedDate,
                  level: 1,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }