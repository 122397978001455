<template>
  <component :app-config="resolvedAppConfig"
             :selected-route-config="resolvedSelectedRouteConfig"
             :user-menu-config="resolvedUserMenuConfig"
             :config="resolvedConfig" :is="resolveComponent()"
             :parent="parent" @selected="onSelect">
    <slot :name="name" :slot="name" v-for="(_, name) in $slots"/>
  </component>
</template>

<script>
  import componentMixin from '../../../componentMixin';
  import VueDfUserInfoComponentLinkItem from './VueDfUserInfoComponentLinkItem'
  import VueDfUserInfoComponentTemplateItem from './VueDfUserInfoComponentTemplateItem'
  import VueDfUserInfoComponentFormItem from  './VueDfUserInfoComponentFormItem'

  export default {
    name: "VueDfUserInfoComponentItem",
    mixins: [componentMixin],
    props: {
      parent: Object
    },
    components:{VueDfUserInfoComponentLinkItem,VueDfUserInfoComponentTemplateItem,VueDfUserInfoComponentFormItem},
    computed:{
      resolvedConfig: function(){
        return this.config;
      },
      resolvedAppConfig: function(){
        return this.appConfig;
      },
      resolvedSelectedRouteConfig: function(){
        return this.selectedRouteConfig;
      },
      resolvedUserMenuConfig: function(){
        return this.userMenuConfig;
      }
    },
    methods: {
      onSelect: function(selected){
        this.$emit('selected', selected);
      },
      resolveComponent: function () {
        const itemType = this.config.itemType;
        let item = 'vue-df-user-info-component-link-item';
        switch (itemType) {
          case 'FORM':
            item = 'vue-df-user-info-component-form-item';
            break;
          case 'TEMPLATE':
            item = 'vue-df-user-info-component-template-item';
            break;
        }
        return item;
      }
    }
  }
</script>

<style scoped>

</style>
