<template>
  <div class="schema-editor-config">
    <div class="schema-editor-config-wrapper ">
      <div class="card p-3 rounded-0">
        <schema-form :form="internalSelectedItemForm" :schema="internalSelectedItemSchema"
                     @validationResult="onValidationResult"
                     v-model="internalSelectedItemModel"></schema-form>
      </div>
      <div class="p-3" v-if="selectedItemValidationResult">
        <div><span>Validation result</span></div>
        <ul class="list-unstyled">
          <li :key="error.path" class="list-group-item" v-for="error in selectedItemValidationResult">
            <div><span class="text-muted">Path: </span><span class="text-primary">{{ error.path }}</span></div>
            <div><span class="text-muted">Message: </span><span class="text-danger">{{ error.message }}</span></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import schemaFormElements from 'vue-json-schema-form/src/components/schemaForm/schemaFormElements' //'../../schemaForm/schemaFormElements'
import forEach from 'lodash/forEach'
import merge from "lodash/merge"
import find from 'lodash/find'
import lastIndexOf from 'lodash/lastIndexOf'
import utils from 'vue-json-schema-form/src/utils/utils'

export default {
  name: "SfComponentConfig",
  components: {},
  props: {
    isDebug: {
      type: Boolean,
      default: function () {
        return true;
      }
    }
  },
  data() {
    return {
      selectedItem: {},
      selectedRawItem: {},
      selectedItemSchema: {},
      selectedItemForm: [],
      selectedItemModel: {},
      internalSelectedItemModel: {},
      internalSelectedItemSchema: {},
      internalSelectedItemForm: [],
      selectedItemValidationResult: null,
      defaultSchema: {
        readonly: {
          type: 'boolean',
          title: 'Readonly',
          description: 'Set readonly status'
        },
        hidden: {
          type: 'boolean',
          title: 'Hidden',
          description: 'Set hidden status'
        },
        condition: {
          type: 'string',
          title: 'Condition',
          description: 'Enter Condition to be resolved. Example: model.comments[form.arrayIndex].email'
        }
      },

      defaultTitleMapSchema: {
        readonly: {
          type: 'boolean',
          title: 'Readonly',
          description: 'Set readonly status'
        },
        hidden: {
          type: 'boolean',
          title: 'Hidden',
          description: 'Set hidden status'
        },
        condition: {
          type: 'string',
          title: 'Condition',
          description: 'Enter Condition to be resolved. Example: model.comments[form.arrayIndex].email'
        },
        lookupMethod: {
          type: 'string',
          title: 'Lookup method',
          description: 'Select to use Enum or TitleMap key/value array or custom function',
          enum: ['ENUM', 'TITLEMAP', 'FUNCTION'],
          default: 'ENUM'
        },
        callbackType: {
          type: 'string',
          title: 'Callback Type',
          description: 'Callback Function Type. Can be: <br /><strong>callback</strong> - function name should exists in the parent context; <br /><strong>callbackFunction:</strong> function that should exists in the parent context and returns titleMap; <br /><strong>function:</strong> function to be executed',
          enum: ['callback', 'callbackFunction', 'function'],
          default: 'callbackFunction'
        },
        onChangeCallbackType: {
          type: 'string',
          title: 'Callback Type',
          description: 'Callback Function Type. Can be: callback - function name should exists in the parent context; \nfunction: function to be executed',
          enum: ['callback', 'function'],
          default: 'callback'
        },
        enum: {
          type: 'array',
          title: 'Enum values',
          description: 'Enter enum values',
          items: {
            type: 'string'
          }
        },
        titleMap: {
          type: 'array',
          title: 'Title Map',
          description: 'Name/Value array defining lookup values',
          default: [],
          items: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                title: 'Name'
              },
              value: {
                type: 'string',
                title: 'Value'
              }
            }
          }
        },
        copyFullObject: {
          type: 'boolean',
          title: 'Copy full object',
          description: 'Should we copy full object'
        },
        options: {
          type: 'object',
          properties: {
            callback: {
              type: 'string',
              title: 'Callback  Name',
              description: 'Enter callback function name. Function should exists in the scope'
            },
            callbackFunction: {
              type: 'string',
              title: 'Callback Function Name',
              description: 'Enter callback function name. Function should exists in the parent scope'
            },
            fn: {
              type: 'string',
              title: 'Function and Body',
              description: 'Enter Function. Function will be executed.',
              default: '(vueThisControl, currentValue) => {  \n return [{"name":"n1", "value":"v1"}]; \n}'
            },
            resultExpression: {
              type: 'string',
              title: 'Result Expression',
              description: 'Use result expresion to extract valuable data from result of the function'
            }
          }
        },
        onChange: {
          type: 'object',
          properties: {
            copyTo: {
              type: 'array',
              title: 'Copy To',
              description: 'Define list of keys to which to copy value to',
              items: {
                type: 'string'
              }
            },
            remove: {
              type: 'array',
              title: 'Remove',
              description: 'Define list of keys to be removed when value has changed',
              items: {
                type: 'string'
              }
            },
            callback: {
              type: 'string',
              title: 'Callback',
              description: 'Define function name to be called on parent context providing current value, schemaKey and field meta data'
            },
            fn: {
              type: 'string',
              title: 'Function',
              description: 'Define function with body to be executed when value has changed'
            }
          }
        },
        hasEnum: {
          type: 'boolean',
          readonly: true,
          default: true
        }
      },
      defaultArrayTypeSchema: {
        key: {
          type: 'string',
          title: 'Property Key',
          description: 'Enter object property name'
        },
        title: {
          type: 'string',
          title: 'Title',
          description: 'Enter Title text of the field property'
        },
        description: {
          type: 'string',
          title: 'Description',
          description: 'Enter Description text of the field property'
        },
        default: {
          type: 'string',
          title: 'Default value',
          description: 'Default value in form: [] for empty array, or ["val1"] or [{"k1":"v1"},{"k2":"v2"}], etc'
        },
        required: {
          type: 'boolean',
          title: 'Required Fields',
          description: 'Check if this field is required',
          default: false
        },
        itemsType: {
          type: 'string',
          title: 'Items Inner Type',
          description: 'Select inner type for items. If SIMPLE selected, result model will use single component and it will be in form: [\'item1\', \'item2\'] while OBJECT type will persist key and other components',
          enum: ['OBJECT', 'SIMPLE'],
          default: 'OBJECT'
        },
        btnStyle: {
          type: 'object',
          properties: {
            add: {
              type: 'string',
              title: 'Add Button',
              description: 'Enter Add button class'
            },
            remove: {
              type: 'string',
              title: 'Remove Button',
              description: 'Enter Remove button class'
            }
          }
        },
        add: {
          type: 'string',
          title: 'Add text',
          description: 'Enter Add button text value'
        },
        remove: {
          type: 'string',
          title: 'Remove text',
          description: 'Enter Remove button text value'
        },
        minItems: {
          type: 'integer',
          title: 'Min Items',
          description: 'Enter min allowed number of items'
        },
        maxItems: {
          type: 'integer',
          title: 'Max Items',
          description: 'Enter max allowed number of items'
        }
      },
      defaultHtmlClassSchema: {
        htmlClass: {
          type: 'string',
          title: 'Html Class',
          description: 'Enter html class for the control container'
        },
        fieldHtmlClass: {
          type: 'string',
          title: 'Field Html Class',
          description: 'Enter html class for the field'
        },
        childClass: {
          type: 'string',
          title: 'Child Class',
          description: 'Child html Class, applicable if field is child of Section container.'
        },
        style: {
          type: 'string',
          title: 'CSS Style',
          description: 'Enter specific CSS style'
        }
      },
      defaultCssFormItems: [
        {
          key: 'htmlClass',
          isLazy: true
        },
        {
          key: 'fieldHtmlClass',
          isLazy: true
        },
        {
          key: 'childClass',
          isLazy: true
        },
        {
          key: 'style',
          isLazy: true
        }
      ],
      defaultHelpForm: [
        {
          type: 'tabs',
          tabs: [
            {
              title: 'Common',
              items: [
                {
                  key: 'key',
                  isLazy: true
                },
                {
                  key: 'title'
                },
                {
                  key: 'helpvalue',
                  type: 'ace',
                  style: 'height:400px',
                  config: {
                    lang: 'html'
                  }
                },
                'hidden',
                'readonly',
                'condition'
              ]
            },
            {
              title: 'CSS',
              items: []
            }
          ]
        }
      ],
      defaultTabsForm: [
        {
          type: 'tabs',
          tabs: [
            {
              title: 'Tabs',
              items: [
                {
                  key: 'tabs',
                  type: 'array',
                  items: [
                    {
                      key: 'tabs[].title'
                    },
                    {
                      key: 'tabs[].items',
                      visible: false
                    },
                    'tabs[].hidden',
                    'tabs[].readonly',
                    'tabs[].condition'
                  ]
                }

              ]
            },
            {
              title: 'Common',
              items: [
                'hidden',
                'readonly',
                'condition'
              ]
            },
            {
              title: 'CSS',
              items: [
                'tabListHtmlClass', 'tabHtmlClass', 'selectedTabHtmlClass'
              ]
            }
          ]
        }
      ],
      defaultArrayForm: [
        {
          type: 'tabs',
          tabs: [
            {
              title: 'Common',
              items: [
                'key', 'title', 'description',
                'required',
                'condition',
                'readonly',
                'hidden'
              ]
            },
            {
              title: 'Definition',
              items: [
                {
                  key: 'itemsType'
                },
                {
                  key: 'maxItems'
                },
                {
                  key: 'minItems'
                },
                'add',
                'remove',

              ]
            },
            {
              title: 'CSS',
              items: [
                {
                  key: 'btnStyle',
                  isLazy: true
                }
              ]
            }
          ]
        }
      ],
      defaultTabArrayForm: [
        {
          type: 'tabs',
          tabs: [
            {
              title: 'Common',
              items: [
                'key', 'title', 'description',
                'required',
                'condition',
                'readonly',
                'hidden'
              ]
            },
            {
              title: 'Definition',
              items: [
                {
                  key: 'itemsType'
                },
                {
                  key: 'maxItems'
                },
                {
                  key: 'minItems'
                },
                'add',
                'remove'
              ]
            },
            {
              title: 'CSS',
              items: [
                {
                  key: 'btnStyle',
                  isLazy: true
                },
                {
                  key: 'tabType',
                  isLazy: true
                }
              ]
            }
          ]
        }
      ],
      defaultSectionForm: [
        {
          type: 'tabs',
          tabs: [
            {
              title: 'Common',
              items: [
                {
                  key: 'sectionType'
                },
                {
                  key: 'title',
                  condition: "model.sectionType==='fieldset'"
                },
                'hidden',
                'readonly',
                'condition'
              ]
            },
            {
              title: 'CSS',
              items: [
                {
                  key: 'fieldSetClass',
                  condition: "model.sectionType==='fieldset'",
                  isLazy: true
                },

                {
                  key: 'sectionClass',
                  condition: "!model.sectionType || model.sectionType==='section'",
                  isLazy: true
                },
                {
                  key: 'sectionContainerClass',
                  isLazy: true
                },
                {
                  key: 'sectionChildClass',
                  isLazy: true
                },
                {
                  key: 'sectionChildStyle',
                  isLazy: true
                }
              ]
            }
          ]
        }
      ],
      defaultSchemaFormEditorForm: [
        {
          type: 'tabs',
          tabs: [
            {
              title: 'Common',
              items: [
                {
                  key: 'key',
                  isLazy: true
                },
                {
                  key: 'title',
                  isLazy: true
                },
                {
                  key: 'description',
                  isLazy: true
                },
                {key: 'condition', type: 'text', isLazy: true},
                'readonly', 'hidden'
              ]
            },
            {
              title: 'CSS',
              items: []
            },
            {
              title: 'Validation',
              items: [
                {
                  key: 'pattern',
                  isLazy: true
                },
                {
                  key: 'validationMessage',
                  isLazy: true
                },
                'required'
              ]
            }
          ],
        }
      ],
      defaultForm: [
        {
          type: 'tabs',
          tabs: [
            {
              title: 'Common',
              items: [
                {
                  key: 'key',
                  isLazy: true
                },
                {
                  key: 'title',
                  isLazy: true
                },
                {
                  key: 'description',
                  isLazy: true
                }, {
                  key: 'placeholder',
                  title: 'Placeholder',
                  isLazy: true
                },
                {key: 'default', type: 'text', isLazy: true},
                {key: 'condition', type: 'text', isLazy: true},
                'readonly', 'hidden'
              ]
            },
            {
              title: 'Enum',
              items: [],
              condition: 'model.hasEnum'
            },
            {
              title: 'CSS',
              items: []
            },
            {
              title: 'Validation',
              items: [
                {
                  key: 'pattern',
                  isLazy: true
                },
                {
                  key: 'validationMessage',
                  isLazy: true
                },
                'required'
              ]
            },
            {
              title: 'Other',
              items: []
            }
          ]
        }
      ],
      defaultTitleMapForm: [
        {
          type: 'tabs',
          tabs: [
            {
              title: 'Common',
              items: [
                {
                  key: 'key',
                  isLazy: true
                },
                {
                  key: 'title',
                  isLazy: true
                },
                {
                  key: 'description',
                  isLazy: true
                }, {
                  key: 'placeholder',
                  title: 'Placeholder',
                  isLazy: true
                },
                {key: 'default', type: 'text', isLazy: true},
                {key: 'condition', type: 'text', isLazy: true},
                'readonly', 'hidden',
                {
                  type: 'fieldset',
                  title: 'Configure Enum Lookup',
                  items: [
                    {
                      key: 'hasEnum',
                      title: 'Has Enum',
                      description: 'Select if you want to configure Enum values in separate Tab'
                    }
                  ]
                }
              ]
            },
            {
              title: 'Enum',
              items: [
                {
                  key: 'lookupMethod'
                },
                {
                  key: 'enum',
                  condition: 'model.lookupMethod==="ENUM"'
                },
                {
                  key: 'titleMap',
                  condition: 'model.lookupMethod==="TITLEMAP"',
                  type: 'array',
                  items: [
                    {
                      key: 'titleMap[].name',
                      type: 'text',
                      isLazy: true
                    },
                    {
                      key: 'titleMap[].value',
                      type: 'text',
                      isLazy: true
                    }
                  ]
                },
                {
                  key: 'callbackType',
                  condition: 'model.lookupMethod==="FUNCTION"'
                },
                {
                  key: 'options.callback',
                  condition: 'model.lookupMethod==="FUNCTION" && model.callbackType==="callback"',
                  type: 'textarea',
                  isLazy: true
                },
                {
                  key: 'options.callbackFunction',
                  condition: 'model.lookupMethod==="FUNCTION" && model.callbackType==="callbackFunction"',
                  type: 'textarea',
                  isLazy: true
                },
                {
                  key: 'options.fn',
                  condition: 'model.lookupMethod==="FUNCTION" && model.callbackType==="function"',
                  type: 'ace',
                  style: 'height:400px',
                  config: {
                    lang: 'javascript'
                  }
                },
                {
                  key: 'options.resultExpression',
                  condition: 'model.lookupMethod==="FUNCTION"',
                  type: 'textarea',
                  isLazy: true
                }
              ],
              condition: 'model.hasEnum'
            },
            {
              title: 'CSS',
              items: []
            },
            {
              title: 'Validation',
              items: [
                {
                  key: 'pattern',
                  isLazy: true
                },
                {
                  key: 'validationMessage',
                  isLazy: true
                },
                'required'
              ]
            },
            {
              title: 'On Change',
              items: [
                {
                  title: 'On Change',
                  type: 'fieldset',
                  items: [
                    {
                      type: 'tabs',
                      tabs: [
                        {
                          title: 'Updates',
                          items: [
                            {
                              key: 'onChange.remove',
                              isLazy: true
                            },
                            {
                              key: 'onChange.copyTo',
                              isLazy: true
                            }
                          ]
                        },
                        {
                          title: 'Functions',
                          items: [
                            {
                              key: 'onChangeCallbackType'
                            },
                            {
                              key: 'onChange.callback',
                              type: 'textarea',
                              isLazy: true,
                              condition: 'model.onChangeCallbackType==="callback"',
                            },
                            {
                              key: 'onChange.fn',
                              type: 'ace',
                              style: 'height:300px',
                              config: {
                                lang: 'javascript'
                              },
                              condition: 'model.onChangeCallbackType==="function"',
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      defaultActionsForm: [
        {
          type: 'tabs',
          tabs: [
            {
              title: 'CSS',
              items: []
            }
          ]
        }
      ],
      defaultButtonForm: [
        {
          type: 'tabs',
          tabs: [
            {
              title: 'Common',
              items: [
                {
                  key: 'title',
                  isLazy: true
                },
                {
                  key: 'condition',
                  isLazy: true
                },
                'readonly',
                'hidden'
              ]
            },
            {
              title: 'CSS',
              items: [
                {
                  key: 'icon',
                  isLazy: true
                },
                {
                  key: 'btnStyle',
                  isLazy: true
                }
              ]
            },
            {
              title: 'Function',
              items: [
                {
                  key: 'onClick',
                  items: [
                    {
                      key: 'onClick.callback',
                      isLazy: true
                    },
                    {
                      key: 'onClick.options',
                      type: 'ace',
                      style: "height:200px;position:relative"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      defaultSubmitButtonForm: [
        {
          type: 'tabs',
          tabs: [
            {
              title: 'Common',
              items: [
                {
                  key: 'title',
                  isLazy: true
                },
                {
                  key: 'condition',
                  isLazy: true
                },
                'readonly',
                'hidden'

              ]
            },
            {
              title: 'CSS',
              items: [
                {
                  key: 'icon',
                  isLazy: true
                },
                {
                  key: 'btnStyle',
                  isLazy: true
                }
              ]
            }
          ]
        }
      ],
      defaultResetButtonForm: [
        {
          type: 'tabs',
          tabs: [
            {
              title: 'Common',
              items: [
                {
                  key: 'title',
                  isLazy: true
                },
                {
                  key: 'condition',
                  isLazy: true
                },
                'readonly',
                'hidden'
              ]
            },
            {
              title: 'CSS',
              items: [
                {
                  key: 'icon',
                  isLazy: true
                },
                {
                  key: 'btnStyle',
                  isLazy: true
                }
              ]
            }
          ]
        }
      ],
      defaultFormActions: {
        type: 'actions',
        items: [
          {
            type: "submit",
            title: "Apply"
          },
          {
            type: "reset",
            title: "Clear"
          }
        ]
      }
    }
  },
  created() {
    const that = this;
    this.$bus.$on('selectedItem', that.onSelectedItem);
  },
  beforeDestroy() {
    const that = this;
    this.$bus.$off('selectedItem', that.onSelectedItem);
  },
  /*watch: {
    internalSelectedItemModel: {
      handler(newVal, oldVal) {
        console.log('internalSelectedItemModel CHANGED', newVal);
      },
      deep: true
    }
  },*/
  methods: {
    clearPrevious: function () {
      this.selectedItemModel = {};
      this.selectedItemForm = [];
      this.selectedItemSchema = {};
      this.selectedRawItem = {};
      //  this.selectedItem = {};
      this.internalSelectedItemModel = {};
      this.internalSelectedItemSchema = {};
      this.internalSelectedItemForm = [];
      this.selectedItemValidationResult = null
    },
    moveFields: function (source, target, fieldNameArray) {
      const that = this;
      forEach(fieldNameArray, function (fieldName) {
        if (undefined !== source[fieldName]) {
          target[fieldName] = source[fieldName];
          delete source[fieldName];
        }
      });
    },
    removeNullValueFields: function (fieldForm) {
      forEach(fieldForm, function (value, key) {
        if (fieldForm[key] === null || fieldForm[key] === undefined) {
          delete fieldForm[key];
        }
      });
    },
    updateDefaultValue: function (fieldSchema) {
//        console.log('Applying default for type: ' + fieldSchema.type + ' data: ' + fieldSchema.default, fieldSchema);

      switch (fieldSchema.type) {
        case 'number':
          try {
            const num = Number(fieldSchema.default);
            if (num !== 'NaN') {
              fieldSchema.default = num;
            } else {
              console.warn('Default value for Number is not valid');
              delete fieldSchema.default;
            }
          } catch (e) {
            console.warn('Default value for Number is not valid', e);
            delete fieldSchema.default;
          }
          break;
        case 'integer':
          try {
            fieldSchema.default = parseInt(fieldSchema.default);
          } catch (e) {
            console.warn('Default value for Integer is not valid', e);
            delete fieldSchema.default;
          }
          break;
        case 'array':
          try {
            fieldSchema.default = JSON.parse(fieldSchema.default)
          } catch (e) {
            console.warn('Default value for Array is not valid', e);
            delete fieldSchema.default;
          }
          break;
        case 'boolean':
          try {
            fieldSchema.default = fieldSchema.default.toLowerCase() === 'true' ? true : false;
          } catch (e) {
            console.warn('Default value for Boolean is not valid', e);
            delete fieldSchema.default;
          }
          break;
        case 'dateTimeRange':
          break;
        case 'date':
        case 'datetime':
          try {
            fieldSchema.default = new Date(fieldSchema.default);
          } catch (e) {
            console.warn('Default value for Date/Datetime is not valid', e);
            delete fieldSchema.default;
          }
          break;
      }
    },
    isSame: function (titleMap) {
      for (let i = 0; i < titleMap.length; i++) {
        const map = titleMap[i];
        if (!map.name || !map.value || map.name !== map.value) {
          return false;
        }
      }
      return true;
    },
    updateTabsControl: function (selectedItemModelClone) {
      //  console.log('Updating TABS', selectedItemModelClone);
      if (selectedItemModelClone.type === 'tabs' && selectedItemModelClone.tabs) {
        selectedItemModelClone.tabs.forEach(function (item) {
          if (!item.items) {
            item.items = [];
          }
        })
      }
    },
    updateEnum: function (selectedItemModelClone) {
      //   console.log('Updating enum', selectedItemModelClone);

      if (selectedItemModelClone.hasEnum) {
        let enums = [];
        if (selectedItemModelClone.enum) {
          enums = selectedItemModelClone.enum;
          delete selectedItemModelClone.enum;
          //delete selectedItemModelClone.titleMap;
        } else if (selectedItemModelClone.titleMap) {
          let isSame = this.isSame(selectedItemModelClone.titleMap);
          if (isSame) {
            const enumVal = [];
            forEach(selectedItemModelClone.titleMap, function (map) {
              enumVal.push(map.name);
            });
            enums = enumVal;
            delete selectedItemModelClone.titleMap;
          } else {
            if (selectedItemModelClone.schema.items)
              delete selectedItemModelClone.schema.items.enum;
            delete selectedItemModelClone.schema.enum;
            delete selectedItemModelClone.enum;
          }
        }

        if (enums.length > 0) {
          if (selectedItemModelClone.type === 'multiselect' || selectedItemModelClone.type === 'checkboxes') {
            selectedItemModelClone.schema.items = selectedItemModelClone.schema.items || {};
            selectedItemModelClone.schema.items.enum = enums;
          } else {
            selectedItemModelClone.schema.enum = enums;
          }
        }
      }
      delete selectedItemModelClone.hasEnum;
    },
    onValidationResult: function (validationResult) {
      console.log('onValidation res', validationResult);

      if (validationResult.valid) {
        // this.selectedItemModel.schema = this.resolveConfigItemSchema(this.selectedItemModel);
        let selectedItemModelClone = utils.clone(this.internalSelectedItemModel);//this.selectedItemModel);
        if (!selectedItemModelClone.schema) {
          //console.error('There is no schema for item: ', selectedItemModelClone);
        }
        if (selectedItemModelClone.hasEnum) {
          if (selectedItemModelClone.schema) {
            delete selectedItemModelClone.schema.enum;
            delete selectedItemModelClone.schema.titleMap;
          }
          delete selectedItemModelClone.lookupMethod;
        }

        if (selectedItemModelClone.key) {
          selectedItemModelClone.schemaFieldKey = selectedItemModelClone.key;
          const parsedKey = selectedItemModelClone.key; //ObjectPath.parse(selectedItemModelClone.key);
          selectedItemModelClone.key = parsedKey;
          selectedItemModelClone.schemaKey = parsedKey;
        }

        const keysBelongingToSchema = ['pattern', 'required', 'validationMessage', 'minLength', 'maxLength', 'maximum', 'minimum', 'enum', 'title', 'description', 'default'];

        if (selectedItemModelClone.type !== 'section') {
          if (selectedItemModelClone.schema) {
            this.moveFields(selectedItemModelClone, selectedItemModelClone.schema, keysBelongingToSchema);
          }
        }

        this.removeNullValueFields(selectedItemModelClone);
        if (selectedItemModelClone.schema && selectedItemModelClone.schema.default)
          this.updateDefaultValue(selectedItemModelClone.schema);
        this.updateEnum(selectedItemModelClone);

        this.updateTabsControl(selectedItemModelClone);

        console.log('emitting selectedItemModelApplied', selectedItemModelClone);

        this.$bus.$emit('selectedItemModelApplied', selectedItemModelClone);
      } else {
        let tmpRes = [];
        //  1.
        forEach(validationResult.errors, function (item) {
          tmpRes.push({
            path: item.dataPath,
            message: item.message
          });
        });
        this.selectedItemValidationResult = tmpRes;
      }
    },
    resolveEnumInModel: function () {
      //  console.log('Resolving enum: ' + this.selectedItemModel.lookupMethod, this.selectedItemModel);

      if ((this.selectedItemModel.hasEnum === undefined || this.selectedItemModel.hasEnum === null)
        && this.selectedItemSchema.properties.hasEnum) {
        this.selectedItemModel.hasEnum = true;
      }

      if (this.selectedItemModel.hasEnum) {
        if (this.selectedItemModel.titleMap) {
          this.selectedItemModel.lookupMethod = 'TITLEMAP';
          /*if (!this.selectedItemModel.titleMap)
            vueUtils.vueSet(this.selectedItemModel, 'titleMap', item.titleMap);*/
        } else if (this.selectedItemModel.schema.enum) {
          this.selectedItemModel.lookupMethod = 'ENUM';
          /*if (!this.selectedItemModel.enum)
            vueUtils.vueSet(this.selectedItemModel, 'enum', item.schema.enum);*/
        } else {
          this.selectedItemModel.lookupMethod = 'FUNCTION';
        }
      }
    },
    getDefaultSchema: function (itemType) {

    },
    getSimpleItemConfigSchema: function (selectedItemModelClone) {

      let selectedItemSchemaTmp = {
        type: 'object',
        properties: {
          key: {
            type: 'string',
            title: 'Property Key',
            description: 'Enter object property name'
          },
          title: {
            type: 'string',
            title: 'Title',
            description: 'Enter Title text of the field property'
          },
          description: {
            type: 'string',
            title: 'Description',
            description: 'Enter Description text of the field property'
          },
          placeholder: {
            type: 'string',
            title: 'Placeholder',
            description: 'Enter Placeholder text description'
          },
          pattern: {
            type: 'string',
            title: 'Validation pattern',
            description: 'Enter validation pattern'
          },
          validationMessage: {
            type: 'string',
            title: 'Validation Message',
            description: 'Enter validation message in case of validation error'
          },
          required: {
            type: 'boolean',
            title: 'Required Fields',
            description: 'Check if this field is required',
            default: false
          },
          default: {
            type: 'string',
            title: 'Default value',
            description: 'Enter default value for the property'
          }
        }
      };

      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, utils.clone(this.defaultSchema), utils.clone(this.defaultHtmlClassSchema));

      const excluded = ['key', 'title', 'description', 'onChange', 'options', 'schema', 'schemaFieldKey', 'guid', 'schemaKey', 'type', 'items', 'indexArray', 'arrayIndex', 'condition', 'default', 'readonly', 'hidden'];

      const that = this;

      // const tmpSchemaFormElements = schemaFormElements;
      forEach(selectedItemModelClone, function (value, key) {
        if (excluded.indexOf(key) === -1) {
          let tmpKeyObject = {};
          switch (key) {
            case 'hidden':
            case 'readonly':
            case 'hasEnum':
            case 'isMinimized':
              tmpKeyObject.type = 'boolean';
              break;
            case 'titleMap':
              tmpKeyObject.type = 'array';
              break;
            default:
              tmpKeyObject.type = 'string'

          }

          tmpKeyObject.title = key;
          tmpKeyObject.description = 'Enter ' + key;

          if (!selectedItemSchemaTmp.properties[key]) {
            selectedItemSchemaTmp.properties[key] = tmpKeyObject;
          }
        }
      });

      return selectedItemSchemaTmp;

    },
    handleConfigSpecifics: function (specificElement) {
      // handle form
      const that = this;
      if (specificElement.configFormSpecific) { //NOTE - TODO - we have to be specific here due to our Form configuration with tabs - if we change Tabs concept in config form then we'll need to change this as well

        const notExistingTabs = [];
        forEach(specificElement.configFormSpecific.tabs, function (item) {
          if (that.selectedItemForm[0].tabs.length === item.index) {
            notExistingTabs.push(item);
          } else {
            const tabsAtIndex = that.selectedItemForm[0].tabs[item.index];
            const existing = find(tabsAtIndex.items, item.value);
            if (!existing) {
              if (item.itemIndex) {
                tabsAtIndex.items.splice(item.itemIndex, 0, item.value);
              } else {
                tabsAtIndex.items.push(item.value);
              }
            }
          }
        });
        if (notExistingTabs.length > 0) {
          notExistingTabs.forEach(function (newTab) {
            const objTab = {
              title: newTab.title,
              items: [
                newTab.value
              ]
            }
            that.selectedItemForm[0].tabs.push(objTab);
          });
        }
      }
    },
    resolveDefaultForm: function (type) {
      let defaultFormCopy = [];
      switch (type) {
        case 'item':
          defaultFormCopy = utils.clone(this.defaultForm);
          break;
        case 'titleMapItem':
          defaultFormCopy = utils.clone(this.defaultTitleMapForm);
          break;
        case 'tabs':
          defaultFormCopy = utils.clone(this.defaultTabsForm);
          break;
        case 'help':
          defaultFormCopy = utils.clone(this.defaultHelpForm);
          break;
        case 'array':
          defaultFormCopy = utils.clone(this.defaultArrayForm);
          break;
        case 'tabarray':
          defaultFormCopy = utils.clone(this.defaultTabArrayForm);
          break;
        case 'section':
          defaultFormCopy = utils.clone(this.defaultSectionForm);
          break;
        case 'actions':
          defaultFormCopy = utils.clone(this.defaultActionsForm);
          break;
        case 'button':
          defaultFormCopy = utils.clone(this.defaultButtonForm);
          break;
        case 'submitButton':
          defaultFormCopy = utils.clone(this.defaultSubmitButtonForm);
          break;
        case 'resetButton':
          defaultFormCopy = utils.clone(this.defaultResetButtonForm);
          break;
        case 'schemaFormEditor':
          defaultFormCopy = utils.clone(this.defaultSchemaFormEditorForm);
          break;
      }

      this.appendDefaultCssFormItems(defaultFormCopy);

      const defaultFormActions = utils.clone(this.defaultFormActions);
      defaultFormCopy.push(defaultFormActions);
      return defaultFormCopy;
    },
    appendDefaultCssFormItems: function (defaultFormCopy) {
      console.log('appendDefaultCssFormItems defaultFormCopy', defaultFormCopy);
      const tabs = defaultFormCopy[0].tabs;

      const cssTab = find(tabs, {title: 'CSS'});
      console.log('cssTab', cssTab);
      if (cssTab) {
        this.defaultCssFormItems.forEach(function (item) {
          cssTab.items.push(item);
        });
      }
    },
    onSelectedCommonItemType: function (selectedItemModelClone, itemType) {
      let schemaFieldKey = selectedItemModelClone.schemaFieldKey;
      if (schemaFieldKey.indexOf(".") > -1) {
        const lastIndex = lastIndexOf(schemaFieldKey, '.');
        schemaFieldKey = schemaFieldKey.substring(lastIndex + 1);
      }

      this.selectedItemModel = merge({}, selectedItemModelClone); //item;
      this.selectedItemModel.key = schemaFieldKey;

      const defaultFormCopy = this.resolveDefaultForm(itemType);
      this.selectedItemForm = defaultFormCopy;

      const selectedItemSchemaTmp = this.getSimpleItemConfigSchema(selectedItemModelClone);

      // handle Type
      const specificElement = schemaFormElements.elements[selectedItemModelClone.type];
      if (specificElement.schema)
        selectedItemSchemaTmp.properties.type = specificElement.schema.type;

      selectedItemSchemaTmp.properties = merge({}, specificElement.configSchemaSpecific, selectedItemSchemaTmp.properties);

      this.selectedItemSchema = merge({}, selectedItemSchemaTmp);

      this.resolveEnumInModel();
    },
    onSelectedHelpType: function (selectedItemModelClone) {
      console.log('selected help', selectedItemModelClone);

      const selectedItemSchemaTmp = {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            title: 'Title',
            description: 'Define title for the component'
          },
          helpvalue: {
            type: 'string',
            title: 'Help Text',
            description: 'Enter html enabled value as Help item'
          }
        }
      };

      if (selectedItemModelClone.schemaFieldKey) {
        let schemaFieldKey = selectedItemModelClone.schemaFieldKey;
        if (schemaFieldKey.indexOf(".") > -1) {
          const lastIndex = lastIndexOf(schemaFieldKey, '.');
          schemaFieldKey = schemaFieldKey.substring(lastIndex + 1);
        }

        this.selectedItemModel = merge({}, selectedItemModelClone);
        this.selectedItemModel.key = schemaFieldKey;

        selectedItemSchemaTmp.properties.key = {
          type: 'string',
            title: 'Property Name',
            description: 'Enter property key to show read-only html content from the model'
        };

      }

      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, this.defaultSchema, this.defaultHtmlClassSchema);
      this.selectedItemSchema = merge({}, selectedItemSchemaTmp);

      const defaultFormCopy = this.resolveDefaultForm('help');
      if (!selectedItemModelClone.schemaFieldKey){
       delete defaultFormCopy[0].tabs[0].items[0];
      }
      this.selectedItemForm = defaultFormCopy;
      console.log('Help schema form', this.selectedItemSchema, this.selectedItemForm, this.selectedItemModel)
    },
    onSelectedTitleMapItemType: function (selectedItemModelClone) {
      // this.onSelectedCommonItemType(selectedItemModelClone, 'titleMapItem');
      const selectedItemSchemaTmp = {
        type: 'object',
        properties: {}
      };
      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, this.defaultTitleMapSchema, this.defaultHtmlClassSchema);
      this.mergeSelectedItemSchemaForm(selectedItemModelClone, selectedItemSchemaTmp, 'titleMapItem');
      this.resolveEnumInModel();
    },
    onSelectedSimpleItemType: function (selectedItemModelClone) {
      this.onSelectedCommonItemType(selectedItemModelClone, 'item');
    },
    onSelectedTabsType: function (selectedItemModelClone) {
      const selectedItemSchemaTmp = {
        type: 'object',
        properties: {
          selectedTabHtmlClass: {
            type: 'string',
            title: 'Selected tab html class',
            description: 'Enter html class for selected tab'
          },
          tabListHtmlClass: {
            type: 'string',
            title: 'TabList html class',
            description: 'Enter html class for tab list'
          },
          tabHtmlClass: {
            type: 'string',
            title: 'Tab html class',
            description: 'Enter html class for single tab'
          },
          tabs: {
            title: 'Tabs',
            description: 'Define visible Tabs',
            type: 'array',
            default: [],
            items: {
              type: 'object',
              properties: {
                title: {
                  type: 'string',
                  title: 'Title',
                  description: 'Enter Tab title'
                },
                items: {
                  type: 'array',
                  default: [],
                  items: {
                    type: 'object'
                  }
                }
              }
            }
          }
        }
      };
      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, this.defaultHtmlClassSchema, this.defaultSchema);
      selectedItemSchemaTmp.properties.tabs.items.properties = merge({}, this.defaultSchema, selectedItemSchemaTmp.properties.tabs.items.properties);

      const defaultFormCopy = this.resolveDefaultForm('tabs');

      this.selectedItemForm = defaultFormCopy;
      this.selectedItemModel = merge({}, selectedItemModelClone);
      this.selectedItemSchema = merge({}, selectedItemSchemaTmp);

    },
    onSelectedArrayType: function (selectedItemModelClone) {

      const selectedItemSchemaTmp = {
        type: 'object',
        properties: {}
      };
      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, this.defaultArrayTypeSchema, this.defaultHtmlClassSchema);
      this.mergeSelectedItemSchemaForm(selectedItemModelClone, selectedItemSchemaTmp, 'array');
    },
    mergeSelectedItemSchemaForm: function (selectedItemModelClone, selectedItemSchemaTmp, type) {

      let schemaFieldKey = selectedItemModelClone.schemaFieldKey;
      if (schemaFieldKey.indexOf(".") > -1) {
        const lastIndex = lastIndexOf(schemaFieldKey, '.');
        schemaFieldKey = schemaFieldKey.substring(lastIndex + 1);
      }

      const simpleItemSchemaTmp = this.getSimpleItemConfigSchema(selectedItemModelClone);
      const specificElement = schemaFormElements.elements[selectedItemModelClone.type];
      selectedItemSchemaTmp.properties = merge({}, simpleItemSchemaTmp.properties, specificElement.configSchemaSpecific, selectedItemSchemaTmp.properties);

      this.selectedItemSchema = merge({}, selectedItemSchemaTmp);

      this.selectedItemModel = merge({}, selectedItemModelClone);
      this.selectedItemModel.key = schemaFieldKey;

      const defaultFormCopy = this.resolveDefaultForm(type);
      this.selectedItemForm = defaultFormCopy;
    },
    onSelectedTabArrayType: function (selectedItemModelClone) {

      const selectedItemSchemaTmp = {
        type: 'object',
        properties: {
          tabType: {
            type: 'string',
            title: 'Tab Type',
            description: 'Left or Right tabs',
            enum: ['left', 'right'],
            default: 'left'
          },
        }
      };
      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, this.defaultArrayTypeSchema, this.defaultHtmlClassSchema);

      this.mergeSelectedItemSchemaForm(selectedItemModelClone, selectedItemSchemaTmp, 'tabarray');
    },

    onActionsTypeSelected: function (selectedItemModelClone) {

      this.selectedItemModel = merge({}, selectedItemModelClone);
      const selectedItemSchemaTmp = {
        type: 'object',
        properties: {}
      };
      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, this.defaultHtmlClassSchema);
      this.selectedItemSchema = merge({}, selectedItemSchemaTmp);

      const defaultFormCopy = this.resolveDefaultForm('actions');
      this.selectedItemForm = defaultFormCopy;
    },
    onSectionTypeSelected: function (selectedItemModelClone) {
      this.selectedItemModel = merge({}, selectedItemModelClone);
      const selectedItemSchemaTmp = {
        type: 'object',
        properties: {
          sectionType: {
            type: 'string',
            title: 'Section Type',
            description: 'Choose basic section type or fieldset',
            enum: ['section', 'fieldset'],
            default: 'section'
          },
          title: {
            type: 'string',
            title: 'Title',
            description: 'Use title to show fieldset legend'
          },
          fieldSetClass: {
            type: 'string',
            title: 'Fieldset Class',
            description: 'Fieldset html Class'
          },
          sectionClass: {
            type: 'string',
            title: 'Section Class',
            description: 'Section html Class'
          },
          sectionContainerClass: {
            type: 'string',
            title: 'Section Container Class',
            description: 'Section Container html Class'
          },
          sectionChildClass: {
            type: 'string',
            title: 'Section Child Class',
            description: 'Child html Class. Applicable on all child elements if there is no definition on the element'
          },
          sectionChildStyle: {
            type: 'string',
            title: 'Section Child Style',
            description: 'Child html Style. Applicable on all child elements if there is no definition on the element'
          }
        }
      };
      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, this.defaultSchema, this.defaultHtmlClassSchema);
      this.selectedItemSchema = merge({}, selectedItemSchemaTmp);

      const defaultFormCopy = this.resolveDefaultForm('section');
      this.selectedItemForm = defaultFormCopy;
    },
    onButtonTypeSelected: function (selectedItemModelClone) {
      this.selectedItemModel = merge({}, selectedItemModelClone);
      const selectedItemSchemaTmp = {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            title: 'Title',
            description: 'Enter button Title value'
          },
          icon: {
            type: 'string',
            title: 'Icon',
            description: 'Enter Icon class name'
          },
          btnStyle: {
            type: 'string',
            title: 'Button Class',
            description: 'Enter class for button. Example: btn-primary'
          },
          onClick: {
            type: 'object',
            properties: {
              callback: {
                type: 'string',
                title: 'Callback',
                description: 'Define function to be called and Options that will be provided'
              },
              options: {
                type: 'string',
                title: 'Options',
                description: 'Define options as JSON object that will be provided while calling the callback'
              }
            }
          },
          functionType: {
            type: 'string',
            title: 'Function Type',
            enum: ['FUNCTION_CALL', 'FUNCTION', 'EXPRESSION'],
            description: 'Select Function Type'
          },
          function: {
            type: 'string',
            title: 'Function',
            description: 'Enter function executor'
          }
        }
      };
      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, this.defaultSchema, this.defaultHtmlClassSchema);
      this.selectedItemSchema = merge({}, selectedItemSchemaTmp);

      const defaultFormCopy = this.resolveDefaultForm('button');
      this.selectedItemForm = defaultFormCopy;
    },
    onSubmitButtonTypeSelected: function (selectedItemModelClone) {

      this.selectedItemModel = merge({}, selectedItemModelClone);
      const selectedItemSchemaTmp = {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            title: 'Title',
            description: 'Enter button Title value'
          },
          icon: {
            type: 'string',
            title: 'Icon',
            description: 'Enter Icon class name'
          },
          btnStyle: {
            type: 'string',
            title: 'Button Class',
            description: 'Enter class for button. Example: btn-primary'
          }
        }
      };
      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, this.defaultSchema, this.defaultHtmlClassSchema);
      this.selectedItemSchema = merge({}, selectedItemSchemaTmp);

      const defaultFormCopy = this.resolveDefaultForm('submitButton');
      this.selectedItemForm = defaultFormCopy;

    },
    onResetButtonTypeSelected: function (selectedItemModelClone) {
      this.selectedItemModel = merge({}, selectedItemModelClone);
      const selectedItemSchemaTmp = {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            title: 'Title',
            description: 'Enter button Title value'
          },
          icon: {
            type: 'string',
            title: 'Icon',
            description: 'Enter Icon class name'
          },
          btnStyle: {
            type: 'string',
            title: 'Button Class',
            description: 'Enter class for button. Example: btn-primary'
          }
        }
      };
      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, this.defaultSchema, this.defaultHtmlClassSchema);
      this.selectedItemSchema = merge({}, selectedItemSchemaTmp);

      const defaultFormCopy = this.resolveDefaultForm('resetButton');
      this.selectedItemForm = defaultFormCopy;
    },
    /*onTemplateEditorTypeSelected: function (selectedItemModelClone) {
      this.selectedItemModel = merge({}, selectedItemModelClone);

      const selectedItemSchemaTmp = {
        type: 'object',
        properties:{
          template:{
            type: 'string',
            title: 'Template',
            description: 'Enter Html template and bind to internal state expecting List behaviour'
          }
        }
      }
      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, this.defaultSchema, this.defaultHtmlClassSchema);

      this.selectedItemSchema = merge({}, selectedItemSchemaTmp);

      let defaultFormCopy = this.resolveDefaultForm('template');

      //defaultFormCopy = merge({}, this.defaultForm);

      this.selectedItemForm = defaultFormCopy;
    },*/
    onSchemaFormEditorTypeSelected: function (selectedItemModelClone) {
      this.selectedItemModel = merge({}, selectedItemModelClone);

      const selectedItemSchemaTmp = {
        type: 'object',
        properties: {
          key: {
            type: 'string',
            title: 'Property Key',
            description: 'Enter object property name'
          },
          editorName: {
            type: 'string',
            title: 'Editor Name',
            description: 'Unique editor name used for handle draft versions'
          },
          title: {
            type: 'string',
            title: 'Title',
            description: 'Enter Title text of the field property'
          },
          description: {
            type: 'string',
            title: 'Description',
            description: 'Enter Description text of the field property'
          }
        }
      };
      selectedItemSchemaTmp.properties = merge({}, selectedItemSchemaTmp.properties, this.defaultSchema, this.defaultHtmlClassSchema);

      this.selectedItemSchema = merge({}, selectedItemSchemaTmp);

      const defaultFormCopy = this.resolveDefaultForm('schemaFormEditor');
      this.selectedItemForm = defaultFormCopy;

    },
    onSelectedItem: function (item) {

      this.clearPrevious();

      this.selectedRawItem = item;
      let selectedItemModelClone = utils.clone(item);

      const resolvedElementSchema = schemaFormElements.__resolveElementForm(item.schemaFieldKey, {name: item.type});
      for (const prop in resolvedElementSchema) {
        if (!selectedItemModelClone.hasOwnProperty(prop)) {
          selectedItemModelClone[prop] = resolvedElementSchema[prop];
        }
      }

      if (selectedItemModelClone.default) {
        switch (selectedItemModelClone.schema.type) {
          case 'array':
            selectedItemModelClone.default = JSON.stringify(selectedItemModelClone.default);
            break;
          default:
            selectedItemModelClone.default = selectedItemModelClone.default.toString();
        }
      }

      const elementConfig = schemaFormElements.elements[item.type];
      switch (elementConfig.type) {
        case 'item':
        case 'object':
          this.onSelectedSimpleItemType(selectedItemModelClone);
          break;
        case 'titleMapItem':
          this.onSelectedTitleMapItemType(selectedItemModelClone);
          break;
        case 'tabs':
          this.onSelectedTabsType(selectedItemModelClone);
          break;
        case 'array':
          this.onSelectedArrayType(selectedItemModelClone);
          break;
        case 'tabarray':
          this.onSelectedTabArrayType(selectedItemModelClone);
          break;
        case 'section':
          this.onSectionTypeSelected(selectedItemModelClone);
          break;
        case 'actions':
          this.onActionsTypeSelected(selectedItemModelClone);
          break;
        case 'help':
          this.onSelectedHelpType(selectedItemModelClone);
          break;
        case 'button':
          this.onButtonTypeSelected(selectedItemModelClone);
          break;
        case 'submit':
          this.onSubmitButtonTypeSelected(selectedItemModelClone);
          break;
        case 'reset':
          this.onResetButtonTypeSelected(selectedItemModelClone);
          break;
        case 'schemaFormEditor':
          this.onSchemaFormEditorTypeSelected(selectedItemModelClone);
          break;
        case 'bpmn':
          this.onSelectedSimpleItemType(selectedItemModelClone);
          break
        default:
          throw new Error('Unknown item type for config type: ' + elementConfig.type);
      }

      this.handleConfigSpecifics(elementConfig);

      this.internalSelectedItemModel = Object.assign({}, this.selectedItemModel);
      this.internalSelectedItemSchema = Object.assign({}, this.selectedItemSchema);
      this.internalSelectedItemForm = Object.assign([], this.selectedItemForm);
      //  console.log('completed internalSelectedItemModel', this.internalSelectedItemModel);
    }
  }
}
</script>

<style scoped>

.schema-editor-config-wrapper {
  height: calc(100vh - 107px);
}

.modal-dialog .schema-editor-config-wrapper {
  height: calc(100vh - 350px);
}

</style>
