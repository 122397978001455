import DatasetChart from './base/DatasetChart'

class LineChart extends DatasetChart{
  constructor() {
    super('LineChart');
  }

  updateAxis(chartDefinition){
    super.updateAxis(chartDefinition);
    if (chartDefinition.xAxis && chartDefinition.xAxis.type && chartDefinition.xAxis.type==='category'){
      chartDefinition.xAxis.boundaryGap=false;
    }else if (chartDefinition.yAxis && chartDefinition.yAxis.type && chartDefinition.yAxis.type==='category'){
      chartDefinition.yAxis.boundaryGap=false;
    }
  }
}


export default LineChart
