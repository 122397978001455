var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chart-designer-container" }, [
    _c(
      "div",
      { staticClass: "chart-designer-wrapper bg-white p-4" },
      [
        _c(
          "vertical-accordion",
          [
            _c("accordion-item", {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [_vm._v("\n          Data Panel\n        ")]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("chart-designer-data-panel", {
                        on: { input: _vm.onDataPanelUpdate },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("accordion-item", {
              attrs: { visible: "" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [_vm._v("\n          Chart Panel\n        ")]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("chart-designer-chart-panel", {
                        attrs: {
                          "data-model": _vm.localDataModel,
                          "properties-model": _vm.localPropertiesModel,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("accordion-item", {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [_vm._v("\n          Properties Panel\n        ")]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("chart-designer-properties-panel", {
                        attrs: { "properties-model": _vm.localPropertiesModel },
                        on: { input: _vm.onPropertiesPanelUpdate },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }