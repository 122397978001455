<template>
  <div :key="count">
    <div class="row">
      <div class="col-12">
        <schema-form :form="form" :schema="schema" v-model="dashboardModelName"></schema-form>
      </div>
      <div class="col-12">
        <component :dashboard-name="dashboardName" :include-widgets="includeWidgets" :is="selectedDashboardType"
                   crud-service-name="http"
                   ref="dashboardContainer"
                   v-if="render"></component>
      </div>
      <div class="col-12">
        <debug-event-log :dashboard-name="dashboardName"
                         :dashboard-container-reference="$refs.dashboardContainer"></debug-event-log>
      </div>
    </div>



  </div>
</template>

<script>

/*import Vue from "vue";
import {schemaFormPlugin} from "vue-json-schema-form/src/plugins";
import vueDashboardFrameworkPlugin from "vue-dashboard-framework";
import vueDashboardFrameworkPluginApp from "vue-dashboard-framework-app-components";
import vueDashboardFrameworkIntegrations from "vuedf-integrations";
import VueDfAppHttpService from "vue-dashboard-framework-app-components/src/components/VueDfApp/vueDfAppHttpService";
import VueDfApplicationService
  from "vue-dashboard-framework-app-components/src/components/VueDfApp/vueDfApplicationService";*/
import DebugEventLog from "vue-dashboard-framework/src/components/vuedf/components/DebugEventLog";
import {Portal, PortalTarget} from 'portal-vue'

import initAppViewMixin from '../app/mixins/initAppViewMixin'

export default {
  name: 'vuedf',
  components: {DebugEventLog, Portal, PortalTarget},
  mixins:[initAppViewMixin],
  methods: {
    loadTenantWidgets: function (next) {
      const that = this;
      this.$services[this.crudServiceName].loadWidgets(null, function (widgets) {
        //console.log('loaded widgets', widgets);
        if (widgets) {
          widgets.forEach(function (item) {
            if (item.widget)
              that.$widgetStore.widget(item.widget);
          });
        }
      });
    },
    onSelectChanged: function (event) {
      this.selectedDashboardType = event.target.value;
    },
    onSavedDashboards: function (dashboardModel) {
      const that = this;
      console.log('onSavedDashboards', dashboardModel);
      that.loadDashboardsModel('selectedDashboard', function () {
        that.$nextTick(function () {
          console.log('onSavedDashboards - loadDashboardsModel');
          that.dashboardModelName.selectedDashboard = that.dashboardModelName.dashboardName;
          that.$dashboardBus.$off('DASHBOARD_SAVED::' + that.dashboardName, that.onSavedDashboards);
        });
      });
    },
    onClickNewDashboard: function (event) {
      const that = this;
      if (this.dashboardModelName && this.dashboardModelName.dashboardName) {
        this.dashboardName = this.dashboardModelName.dashboardName;
        console.log('New dashboard name', this.dashboardName, this.dashboardModelName);
        that.render = false
        that.$dashboardBus.$on('DASHBOARD_SAVED::' + this.dashboardName, that.onSavedDashboards);
        this.$nextTick(function () {
          that.render = true;
          that.$nextTick(function () {
            that.$refs.dashboardContainer.addDashboard();
          });
        });
      }
    },
    loadDashboards: function (fnObj) {
      const schemaFormKey = fnObj.key;
      const fn = fnObj.fn;

      this.reloadDashboardsFunctions[schemaFormKey] = fn;
      this.loadDashboardsModel(schemaFormKey);
    },
    loadDashboardsModel: function (schemaFormKey, next) {
      const fields = {
        _id: 1,
        dashboardName: 1
      }
      const fn = this.reloadDashboardsFunctions[schemaFormKey];
      this.$services[this.crudServiceName].loadDashboards(JSON.stringify(fields), function (dashboards) {
        //console.log(dashboards);
        if (dashboards)
          fn(dashboards);
        if (next) {
          next(dashboards);
        }
      });
    }
  },
  watch: {
    'dashboardModelName.selectedDashboard': {
      handler(newVal, oldVal) {
        this.dashboardName = this.dashboardModelName.dashboardName;
        this.render = false;
        this.$nextTick(function () {
          this.render = true;
        });
      }
    }
  },
  created() {
    this.selectedDashboardType = this.dashboardTypes[0];
    this.loadTenantWidgets();
  },
  data() {
    return {
      count: 0,
      includeWidgets: null,//['VueDfFeed'],
      reloadDashboardsFunctions: {},
      crudServiceName: 'http',
      render: true,
      dashboardModelName: {},
      schema: {
        type: 'object',
        properties: {
          dashboardName: {
            type: 'string',
            title: 'Dashboard Name',
            description: 'Add new dashboard name'
          },
          selectedDashboard: {
            type: 'string',
            title: 'Selected Dashboard',
            description: 'Select existing dashboard'
          }
        }
      },
      form: [
        {
          type: 'section',
          sectionContainerClass: "row",
          sectionChildClass: "col",
          items: [
            {
              key: 'dashboardName'
            },
            {
              type: 'button',
              condition: '!model.selectedDashboard || model.selectedDashboard!==model.dashboardName',
              title: 'Create',
              childClass: 'col-2',
              childStyle: 'margin:auto',
              onClick: {
                callback: this.onClickNewDashboard
              }
            },
            {
              key: 'selectedDashboard',
              type: 'select',
              valueKey: 'dashboardName',
              labelKey: 'dashboardName',
              onChange: {
                copyTo: ["dashboardName"]
              },
              options: {
                callbackFunction: "loadDashboards"
              }
            }
          ]
        }
      ],
      dashboardTypes: [
        'VueDfDashboardTabs',
        'VueDfDashboardSingle'
      ],
      selectedDashboardType: null,
      dashboardName: 'vueDfViewDashboard',
      dashboardsModel: []
    }
  }
}
</script>

<style scoped>
</style>

