import ValueChart from './valueChart'
import LineChart from './lineChart'
import BarChart from './barChart'
import PieChart from "./pieChart";
import ScatterChart from "./scatterChart";
import ParallelChart from "./parallelChart";
import FunnelChart from "./funnelChart";
import EffectScatterChart from "./effectScatterChart";
import CandlestickChart from "./candlestickChart";
import MapChart from "./mapChart";
import RadarChart from './radarChart'
import BoxPlotChart from './boxPlotChart';
import HeatMapChart from "./heatMapChart";

class Charts {
  constructor(jsulator) {
    this.jsulator = jsulator;
    this.__init();
  }

  __init() {
    this.charts = {
      'valueChart': ValueChart,
      'lineChart': LineChart,
      'barChart': BarChart,
      'pieChart': PieChart,
      'scatterChart':ScatterChart,
      'parallelChart': ParallelChart,
      'funnelChart':FunnelChart,
      'candlestickChart':CandlestickChart,
      'mapChart':MapChart,
      'radarChart':RadarChart,
      'boxPlotChart': BoxPlotChart,
      'heatMapChart':HeatMapChart
    }
  }

  getChart(chartType) {
    if (this.charts[chartType]) {
      return new this.charts[chartType]();
    } else {
      return null;
    }
  }

  createConfig(chartTypeConfig, reducedDataset, dimensionMetrics, clientAreaSettings) {
    console.log('Chart createConfig', chartTypeConfig, reducedDataset, dimensionMetrics, clientAreaSettings);
    const chartType = chartTypeConfig.context.name;
    const chart = this.getChart(chartType);
    if (chart) {
      chart.setClientArea(clientAreaSettings);
      chart.setData(reducedDataset);
      chart.setDimensionMetrics(dimensionMetrics);
      chart.setChartTypeConfig(chartTypeConfig);
      const chartConfig = chart.getChartDefinition();
      return chartConfig;
    } else {
      return null;
    }
  }
}

export default Charts
