var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-container",
      class: _vm.config.htmlClass,
      style: _vm.config.style,
    },
    [
      _c(
        "vue-df-app-layout",
        {
          attrs: {
            "app-config": _vm.appConfig,
            config: _vm.layoutConfig,
            "security-config": _vm.securityConfig,
            "selected-route-config": _vm.selectedConfig,
          },
          on: { selected: _vm.onSelected },
        },
        [
          _c("vue-df-app-content", {
            attrs: {
              "app-config": _vm.appConfig,
              config: _vm.config,
              "dashboard-edit-mode": _vm.dashboardEditMode,
              "input-context": _vm.inputContext,
              "security-config": _vm.securityConfig,
              "selected-route-config": _vm.selectedConfig,
            },
            on: { dashboardupdated: _vm.onDashboardUpdated },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSidebar.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }