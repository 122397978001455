<template>
  <div class="dashboard-container dashboard-simple">

    <div :class="{'edit' : isEditMode}" class="dashboard" count="dashboardCopy.count">
      <vue-df-dashboard-row
        :continuous-edit-mode="false"
        :crud-service-name="crudServiceName" :dashboard-name="dashboardName"
        :dashid="dashid"
        :dashboard-service-instance="dashboardService"
        :default-mapper="defaultMapper"
        :edit-mode="false"
        :key="index"
        :model="dashboardCopy"
        :options="options"
        :row="row"
        class="main-simple"
        v-for="(row, index) in dashboardCopy.rows">
      </vue-df-dashboard-row>
    </div>
  </div>
</template>

<script>
  import defaultMixins from '../defaultMixin'
  import dashboardMixin from "../dashboardMixin";
  import widgetMixin from "../widgetMixin";

  export default {
    name: "VueDfDashboardSimple",
    mixins: [defaultMixins, dashboardMixin, widgetMixin],
    props: ['permissions']
  }
</script>

<style scoped>

  .dashboard-title a {
    padding: 5px;
  }

  .dashboard-toolbar {
    padding: 2px;
    position: absolute;
    top: 35px;
    right: 35px;
    background-color: white;
    border-radius: 6px;
    z-index: 1;
  }

  .dashboard-toolbar:after {
    content: '';
    position: absolute;
    z-index: -1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    width: 100%;
    left: 0;
    height: 100%;
    bottom: 0;
  }

  .dashboard-toolbar .icon-container {
    font-size: 16px;
  }

  .dashboard-toolbar a {
    padding: 5px;
  }
</style>
