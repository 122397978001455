var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    _vm._l(_vm.all, function (marker) {
      return _c("g", { key: marker.id }, [
        marker.type === "arrow-start"
          ? _c(
              "marker",
              {
                attrs: {
                  id: marker.id,
                  orient: "auto",
                  markerWidth: 13 * marker.scale,
                  markerHeight: 13 * marker.scale,
                  refX: 1 * marker.scale,
                  refY: 5 * marker.scale,
                },
              },
              [
                _c("path", {
                  style: `${marker.style};transform: scale(${marker.scale})`,
                  attrs: { d: "M0,5 L10,10 L10,0 L0,5" },
                }),
              ]
            )
          : _vm._e(),
        marker.type === "arrow-end"
          ? _c(
              "marker",
              {
                attrs: {
                  id: marker.id,
                  orient: "auto",
                  markerWidth: 13 * marker.scale,
                  markerHeight: 13 * marker.scale,
                  refX: 9 * marker.scale,
                  refY: 5 * marker.scale,
                },
              },
              [
                _c("path", {
                  style: `${marker.style};transform: scale(${marker.scale})`,
                  attrs: { d: "M0,0 L0,10 L10,5 L0,0" },
                }),
              ]
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }