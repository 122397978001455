/**
 * This is a sample file that should be replaced with the actual translation.
 *
 * Checkout https://github.com/bpmn-io/bpmn-js-i18n for a list of available
 * translations and labels to translate.
 */
export default {
  'Create EndEvent': 'Create End Event',
  'Create StartEvent': 'Create Start Event',
  'Create ExclusiveGateway': 'Create Exclusive Gateway',
  'Create Gateway': 'Create Gateway',
  'Create UserTask': 'Create User Task',
  'Open minimap': 'Open Minimap',
  'Close minimap': 'Close Minimap',
  'Append UserTask': 'Append User Task',
  'Append Task': 'Append Task',
  'Append Gateway': 'Append Gateway',
  'Remove': 'Remove',
  'Append Sequence': 'Append Sequence',
  'Append EndEvent': 'Append End Event',
  'Append StartEvent': 'Append Start Event',
  'Connect using Association':'Connect using Association'
};
