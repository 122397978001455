<template>
  <schema-form v-model="taskData" :schema="schema" :form="form"></schema-form>
</template>

<script>
import TaskMixin from "../mixins/task.mixin"

export default {
  name: "Task",
  mixins: [TaskMixin]
}
</script>

<style scoped>

</style>
