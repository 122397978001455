var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dashboard-container dashboard-simple" }, [
    _c(
      "div",
      {
        staticClass: "dashboard",
        class: { edit: _vm.isEditMode },
        attrs: { count: "dashboardCopy.count" },
      },
      _vm._l(_vm.dashboardCopy.rows, function (row, index) {
        return _c("vue-df-dashboard-row", {
          key: index,
          staticClass: "main-simple",
          attrs: {
            "continuous-edit-mode": false,
            "crud-service-name": _vm.crudServiceName,
            "dashboard-name": _vm.dashboardName,
            dashid: _vm.dashid,
            "dashboard-service-instance": _vm.dashboardService,
            "default-mapper": _vm.defaultMapper,
            "edit-mode": false,
            model: _vm.dashboardCopy,
            options: _vm.options,
            row: row,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }