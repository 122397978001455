<template>
  <div>
    <div class="row">
      <div class="col-12">
        <vue-df-dashboard-preview crud-service-name="http"></vue-df-dashboard-preview>
      </div>
    </div>
  </div>
</template>

<script>
const VueDfDashboardPreview = () => import('vue-dashboard-framework/src/components/vuedf/containers/VueDfDashboardPreview');
import initAppViewMixin from '../app/mixins/initAppViewMixin'
export default {
  name: "VueDfReadOnly",
  components: {VueDfDashboardPreview},
  mixins:[initAppViewMixin],
  created(){
    console.log('Vue DF read only', this.$route);
  }
}
</script>

<style scoped>

</style>
