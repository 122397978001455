var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chart-builder-properties-container" }, [
    _c("ul", { staticClass: "list-group list-unstyled card" }, [
      _c("li", { attrs: { clas: "list-group-item " } }, [
        _c("div", { staticClass: "group p-2" }, [
          _c("span", [_vm._v("Dimensions")]),
          _c(
            "ul",
            { staticClass: "list-group" },
            [
              _c(
                "draggable",
                {
                  staticClass: "list-group",
                  attrs: {
                    group: { name: "properties", pull: "clone", put: false },
                    move: _vm.onMove,
                    list: _vm.dimensions,
                    clone: _vm.cloneDimensions,
                    id: "draggablePropertiesDimensions",
                    tag: "ul",
                  },
                },
                _vm._l(_vm.dimensions, function (property) {
                  return _c(
                    "li",
                    { key: property.name, staticClass: "list-group-item" },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "mr-2 text-muted",
                            staticStyle: { "font-size": "0.7rem" },
                          },
                          [_c("i", { staticClass: "fas fa-circle-notch" })]
                        ),
                        _c("span", [_vm._v(_vm._s(property.title))]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("li", { attrs: { clas: "list-group-item " } }, [
        _c("div", { staticClass: "group p-2" }, [
          _c("span", [_vm._v("Metrics")]),
          _c(
            "ul",
            { staticClass: "list-group" },
            [
              _c(
                "draggable",
                {
                  staticClass: "list-group",
                  attrs: {
                    group: { name: "properties", pull: "clone", put: false },
                    move: _vm.onMove,
                    list: _vm.metrics,
                    clone: _vm.cloneMetrics,
                    id: "draggablePropertiesMetrics",
                    tag: "ul",
                  },
                },
                _vm._l(_vm.metrics, function (property) {
                  return _c(
                    "li",
                    { key: property.name, staticClass: "list-group-item" },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "mr-2 text-muted",
                            staticStyle: { "font-size": "0.7rem" },
                          },
                          [_c("i", { staticClass: "fas fa-circle-notch" })]
                        ),
                        _c("span", [_vm._v(_vm._s(property.title))]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }