var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scrapper-wrapper" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("schema-form", {
            attrs: { schema: _vm.schema, form: _vm.form },
            on: { validationResult: _vm.onValidation },
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("vue-element-loading", {
          attrs: {
            active: _vm.isLoading,
            "can-cancel": false,
            "is-full-page": "false",
          },
          on: {
            "update:active": function ($event) {
              _vm.isLoading = $event
            },
          },
        }),
        _c("div", { staticClass: "col-12" }, [
          _c("div", { attrs: { id: "operating-table" } }, [
            _c("iframe", {
              staticClass: "hidden-iframe",
              attrs: {
                id: "dissectionSite",
                src: _vm.frameSrc,
                sandbox: "allow-same-origin allow-scripts",
              },
              on: { load: _vm.onLoadedFrame },
            }),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }