var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "schema-form-editor" },
    [
      _c("div", { class: _vm.containerClass }, [
        _vm.enableElements
          ? _c("div", { class: _vm.elementClass }, [
              _c("div", { staticClass: "elements-wrapper dragable-wrapper" }, [
                _c(
                  "div",
                  { ref: "elementsCard", staticClass: "card p-3 rounded-0" },
                  [
                    _vm._m(0),
                    _c(
                      "draggable",
                      {
                        ref: "elements",
                        staticClass: "list-group",
                        attrs: {
                          clone: _vm.clone,
                          group: {
                            name: "components",
                            pull: "clone",
                            put: false,
                          },
                          move: _vm.onMove,
                          id: "dragable",
                          tag: "ul",
                        },
                        on: {
                          change: _vm.log,
                          end: _vm.onEnd,
                          start: _vm.onStart,
                        },
                        model: {
                          value: _vm.components.fieldMapper,
                          callback: function ($$v) {
                            _vm.$set(_vm.components, "fieldMapper", $$v)
                          },
                          expression: "components.fieldMapper",
                        },
                      },
                      [
                        _c(
                          "transition-group",
                          { attrs: { name: "flip-list", type: "transition" } },
                          _vm._l(_vm.components.fieldMapper, function (cmp) {
                            return _c(
                              "li",
                              {
                                key: cmp.name,
                                staticClass: "list-group-item",
                                attrs: { itemref: cmp },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { title: cmp.component.description },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "mr-2 text-muted",
                                        staticStyle: { "font-size": "0.7rem" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-circle-notch",
                                        }),
                                      ]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(cmp.component.title || cmp.name)
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { class: _vm.editorClass },
          [
            _c(
              "portal",
              {
                attrs: { disabled: !_vm.isMaximized, to: "portalDestination" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "schema-form-wrapper card rounded-0",
                    attrs: { id: "schema-form-wrapper" },
                  },
                  [
                    _c(
                      "b-tabs",
                      {
                        attrs: {
                          "content-class":
                            "draggable-tab-content  pb-2 pl-4 pr-4 pt-2",
                        },
                        on: { input: _vm.onTabChanged },
                        scopedSlots: _vm._u([
                          {
                            key: "tabs-end",
                            fn: function () {
                              return [
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "nav-item align-self-center maximize",
                                    attrs: { title: "Maximize Editor" },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "float-right",
                                        attrs: { href: "" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.maximizeEditor()
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          class: !_vm.isMaximized
                                            ? "fa fa-window-maximize"
                                            : "",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c(
                          "b-tab",
                          { attrs: { active: "", title: "Editor" } },
                          [
                            _vm.currentTab === 0
                              ? _c("div", [
                                  _c("div", {}, [
                                    _c("label", [
                                      _vm._v(
                                        "\n                    Edit with drag'drop\n                  "
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "btn-group btn-group-sm pull-right",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.draftName,
                                              expression: "draftName",
                                            },
                                          ],
                                          staticClass:
                                            "form-control form-control-sm",
                                          attrs: {
                                            placeholder: "Enter Draft name",
                                            type: "text",
                                          },
                                          domProps: { value: _vm.draftName },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.draftName =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: { title: "Save draft" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.saveDraft()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-save",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "select",
                                          {
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              "aria-placeholder":
                                                "Select Draft",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.onDraftSelected(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("option", [
                                              _vm._v("Select Draft"),
                                            ]),
                                            _vm._l(
                                              _vm.localDraftNames,
                                              function (draft) {
                                                return _c(
                                                  "option",
                                                  {
                                                    domProps: {
                                                      selected:
                                                        draft.name ===
                                                        _vm.selectedDraftName,
                                                      value: draft.name,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(draft.name) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-warning",
                                            attrs: { title: "Load Previous" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.loadPreviousDraft()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-upload",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger",
                                            attrs: { title: "Remove draft" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeDraft()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-times",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticStyle: { clear: "both" } }),
                                  _c("div", { staticClass: "card" }, [
                                    _c(
                                      "div",
                                      {
                                        key: _vm.count,
                                        staticClass: "card-body",
                                      },
                                      [
                                        _c("schema-form", {
                                          attrs: {
                                            form: _vm.internalSchemaForm.form,
                                            "is-droppable": _vm.isDroppable,
                                            schema:
                                              _vm.internalSchemaForm.schema,
                                          },
                                          on: {
                                            internalSchemaChanged:
                                              _vm.onInternalSchemaChanged,
                                            schemaChanged:
                                              _vm.onDroppedSchemaChanged,
                                          },
                                          model: {
                                            value: _vm.sfData,
                                            callback: function ($$v) {
                                              _vm.sfData = $$v
                                            },
                                            expression: "sfData",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c("b-tab", { attrs: { title: "Preview" } }, [
                          _vm.currentTab === 1
                            ? _c("div", [
                                _c("label", [
                                  _vm._v(
                                    "\n                  It will look like\n                "
                                  ),
                                ]),
                                _c("div", { staticClass: "card" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card-body" },
                                    [
                                      _c(
                                        "b-tabs",
                                        [
                                          _c(
                                            "b-tab",
                                            { attrs: { title: "Schema Form" } },
                                            [
                                              _c("schema-form", {
                                                attrs: {
                                                  form: _vm.droppedSchema.form,
                                                  schema:
                                                    _vm.droppedSchema.schema,
                                                },
                                                model: {
                                                  value: _vm.sfData,
                                                  callback: function ($$v) {
                                                    _vm.sfData = $$v
                                                  },
                                                  expression: "sfData",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-tab",
                                            { attrs: { title: "Data" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "data-container",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "data-wrapper",
                                                    },
                                                    [
                                                      _c("pre", [
                                                        _vm._v(
                                                          _vm._s(_vm.sfData)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c("b-tab", { attrs: { title: "Advanced" } }, [
                          _vm.currentTab === 2
                            ? _c("div", [
                                _c("label", [
                                  _vm._v(
                                    "\n                  Advanced Schema & Form JSON\n                "
                                  ),
                                ]),
                                _c("div", { staticClass: "card" }, [
                                  _c("div", { staticClass: "card-body" }, [
                                    _c(
                                      "div",
                                      [
                                        _c("schema-form", {
                                          attrs: {
                                            form: _vm.schemaFormAdvanced.form,
                                            schema:
                                              _vm.schemaFormAdvanced.schema,
                                          },
                                          on: {
                                            validationResult:
                                              _vm.onAdvancedSchemaValidationResult,
                                          },
                                          model: {
                                            value: _vm.droppedSchemaModel,
                                            callback: function ($$v) {
                                              _vm.droppedSchemaModel = $$v
                                            },
                                            expression: "droppedSchemaModel",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c("b-tab", { attrs: { title: "Generate" } }, [
                          _vm.currentTab === 3
                            ? _c("div", [
                                _c("label", [
                                  _vm._v(
                                    "\n                  Generate from data\n                "
                                  ),
                                ]),
                                _c("div", { staticClass: "card" }, [
                                  _c("div", { staticClass: "card-body" }, [
                                    _c(
                                      "div",
                                      [
                                        _c("schema-form", {
                                          attrs: {
                                            form: _vm.schemaFormGenerate.form,
                                            schema:
                                              _vm.schemaFormGenerate.schema,
                                          },
                                          on: {
                                            validationResult:
                                              _vm.onGenerateSchemaValidationResult,
                                          },
                                          model: {
                                            value: _vm.generateSchemaModel,
                                            callback: function ($$v) {
                                              _vm.generateSchemaModel = $$v
                                            },
                                            expression: "generateSchemaModel",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c("div"),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _vm.enableConfig
          ? _c("div", { class: _vm.configClass }, [
              _c(
                "div",
                { staticClass: "config-wrapper" },
                [
                  _c("sf-component-config", {
                    attrs: { "is-debug": _vm.isDebug },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "portalEditor",
          attrs: {
            "no-enforce-focus": "",
            "hide-header-close": "",
            lazy: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "ok-only": "",
            scrollable: "",
            size: "full",
          },
          on: { ok: _vm.onMaximizeClosed },
        },
        [_c("portal-target", { attrs: { name: "portalDestination" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "list-unstyled" }, [
      _c("li", { staticClass: "list-item float-right" }, [
        _c("i", { staticClass: "fas fa-arrow-left" }),
      ]),
      _c("li", { staticClass: "list-item float-left" }, [
        _c("label", [_vm._v("\n                Elements\n              ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }