<template>
  <div class="card1 data-panel-container">
    <div class="card1-header">
      <h6 class="card1-title">Data Editor</h6>
      <small>
        <p class="text-muted card-category">Data definition loaded manually</p>
      </small>
      <hr/>
    </div>
    <div class="card1-body">
      <schema-form :form="form" :schema="schema" @validationResult="onValidationResult" v-model="localData"/>
    </div>
    <div class="card1-footer">
    </div>
  </div>
</template>

<script>

  export default {
    name: "ChartDesignerDataPanel",
    /*props: {
      dataModel: Object
    },*/
    data() {
      return {
        localData: {},
        aceConfig: {
          lang: 'json'
        },
        schema: {
          type: 'object',
          properties: {
            dataSource: {
              type: 'string',
              title: 'Data',
              description: 'Define data source'
            }
          }
        },
        form: [
          {
            key: 'dataSource',
            type: 'ace',
            style: 'height:400px',
            height: '100%',
            config: {
              lang: 'json',
              showToolbar: false
            }
          },
          {
            type: 'actions',
            items: [
              {
                type: 'submit',
                title: 'Apply'
              }
            ]
          }
        ]
      }
    },
    /*watch: {
      dataModel: {
        handler(newVal, oldVal) {
          if (newVal !== oldVal && this.localData !== newVal)
            this.localData.dataSource = newVal;
        },
        deep: true,
        immediate: true
      }
    },*//*
    computed: {},*/
    watch:{
      'localData.dataSource':{
        handler(newVal, oldValue){
          if (newVal!==oldValue){
            this.$emit('input', newVal);
          }
        }
      }
    },
    methods: {
      onValidationResult: function (event) {
        if (event.valid) {
          console.log('this.localData.dataSource', this.localData.dataSource);
          this.$emit('input', this.localData.dataSource);
        }
      }
    }
  }
</script>

<style scoped>
  /**
* The default size is 600px×400px, for responsive charts
* you may need to set percentage values as follows (also
* don't forget to provide a size for the container).
*/
  .echarts {
    width: 100%;
    height: 100%;
  }

  .data-panel-container{
    min-width: 300px;
  }

</style>
