var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel process-panel" },
    [
      _vm._v("\n  Process Panel\n  "),
      _c("schema-form", {
        attrs: { schema: _vm.schema, form: _vm.form },
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }