<template>
  <div class="container-fluid">
    <draggable :list="internalKanbanList" class="kanban-board" group="kanbanList" tag="div">
      <div class="kanban-col" v-for="kanbanList in internalKanbanList">
        <div class="kanban-col-container">
          <ul class="list-inline mb-0">
            <li @click="removeList(kanbanList)" class="float-right list-inline-item p-1" title="Remove List">
              <strong class="">-</strong>
            </li>
            <li @click.prevent="addItem(kanbanList)" class="float-right list-inline-item p-1" title="Add Item">
              <strong class="">+</strong>
            </li>
          </ul>
          <div class="list-name mb-2">
            {{kanbanList.name}}
          </div>
          <div class="kanban-item-list">
            <draggable :list="items[kanbanList.name]" group="kanbanListItem" tag="div">
              <div class="kanban-item mb-3" v-for="item in items[kanbanList.name]">
                <vue-kanban-item-view :item="item" :template="value.item"></vue-kanban-item-view>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </draggable>
    <div class="kanban-col add-new">
      <button @click="addNewList()" class="btn btn-sm btn-outline" title="Add new list">+</button>
      <div class="kanban-col" v-if="addNewListClicked">
        <div class="input-group mb-3 input-group-sm">
          <input class="form-control" placeholder="Name" type="text" v-model="newListName">
          <div class="input-group-append">
            <button @click="saveNewListName" class="btn btn-success btn-sm" type="button">Save</button>
            <button @click="cancelNewListName" class="btn btn-warning btn-sm" type="button">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <vue-kanban-item-edit :schema-form="value.itemEdit || {}" @onItemEditSubmit="onItemEditSubmit"
                          ref="kanbanEditItem"></vue-kanban-item-edit>
  </div>

</template>

<script>
  /*
    Description:
    After new Kanban created and first List added - it will be saved and Kanban will get Id from the database
   */
  import findIndex from 'lodash/findIndex'
  import {BModal} from 'bootstrap-vue'
  import VueKanbanItemView from "./VueKanbanItemView";
  import VueKanbanItemEdit from "./VueKanbanItemEdit";
  import draggable from 'vuedraggable';

  export default {
    name: "VueKanban",
    props: ['value'],
    components: {VueKanbanItemEdit, VueKanbanItemView, BModal, draggable},
    data() {
      return {
        addNewListClicked: false,
        newListName: undefined,
        internalKanbanList: this.value.list || [],
        currentKanbanList: undefined,
        items: {}
      }
    },
    methods: {
      onItemEditSubmit: function (editItemModel) {
        console.log('onItemEditSubmit', editItemModel);
        if (!this.items[this.currentKanbanList.name]) {
          this.$set(this.items, this.currentKanbanList.name, []);
        }
        this.items[this.currentKanbanList.name].push(editItemModel);
      },
      addNewList: function () {
        this.addNewListClicked = true;
      },
      cancelNewListName: function () {
        this.addNewListClicked = false;
        this.newListName = undefined;
      },
      saveNewListName: function () {
        const obj = {
          name: this.newListName
        }
        this.internalKanbanList.push(obj);
        this.cancelNewListName();
      },
      removeList: function (kanbanList) {
        const index = findIndex(this.internalKanbanList, {name: kanbanList.name});
        if (index > -1) {
          this.internalKanbanList.splice(index, 1);
        }
        console.log('Index', index, this.internalKanbanList, kanbanList);
      },
      addItem: function (kanbanList) {
        console.log('clcied', kanbanList);
        this.$refs['kanbanEditItem'].$refs['editItemModal'].show();
        // const index = findIndex(this.internalKanbanList, {name: kanbanList.name});
        this.currentKanbanList = kanbanList;//this.internalKanbanList[index];

        //this.currentKanbanList = this.items[kanbanList.name];
      }
    }
  }
</script>

<style>

  .container-kanban .container-fluid {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .kanban-board {
    flex: 1;
    white-space: nowrap;
    overflow-x: scroll;
    display: flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .kanban-board > .kanban-col {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex: 1 0 auto;
    width: calc(100vw - 24px);
    max-height: 100%;
    padding-right: 0.75rem;
    padding-bottom: 0.75rem;
    outline: none !important;
  }

  /*@media (min-width: 576px)*/
  .kanban-board > .kanban-col {
    max-width: 19.5rem;
  }

  .kanban-board > .kanban-col .kanban-col-container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .kanban-col-container {
    margin-bottom: 0.75rem;
    border-radius: 0.5rem;
    padding: 1rem;
    background: #dee2e6;
  }

</style>
