<template>
  <vue-df-app-navbar v-model="showNavbar">
    <div class="navbar-wrapper" >
      <div class="navbar-minimize" v-if="!this.layout.excludeSidebar">
        <button @click="minimizeSidebar" class="btn btn-icon btn-round" id="minimizeSidebar">
          <i class=" fas fa-chevron-right text-center visible-on-sidebar-mini"></i>
          <i class=" fas fa-chevron-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <vue-df-app-navbar-toggle-button @click.native="toggleSidebar"></vue-df-app-navbar-toggle-button>
      </div>
      <component :is="homeComponent" :to="homeLink" @click.prevent="onHomeClick" class="navbar-brand">{{title}}</component>
    </div>
    <template slot="navbar-menu">
      <ul class="navbar-nav">
        <slot name="links">
          <vue-df-app-navbar-item :selected-route-config="selectedRouteConfig" :app-config="appConfig" :config="item"
                                  :key="item.title + index" :parent="null"
                                  v-for="(item, index) in resolveItems()" @selected="onSelect">
            <vue-df-app-navbar-item :selected-route-config="selectedRouteConfig" :app-config="appConfig"
                                    :config="subItem" :key="subItem.title+index+'-'+subIndex"
                                    :parent="item"
                                    v-for="(subItem, subIndex) in resolveSubItems(item)" @selected="onSelect">
            </vue-df-app-navbar-item>
          </vue-df-app-navbar-item>
        </slot>
        <li class="nav-link" v-if="userMenuConfig && userMenuConfig.placement==='NAVBAR'">
          <div class="nav-item">
            <vue-df-user-info-component @selected="onSelect" :security-config="securityConfig"
                                        :selected-route-config="selectedRouteConfig"
                                        :app-config="appConfig" :config="userMenuConfig"
                                        tag="div" dropdown-class="dd" dropdown-link-class="aa"
                                        position="right"></vue-df-user-info-component>
          </div>
        </li>
      </ul>

    </template>
  </vue-df-app-navbar>
</template>

<script>
  import VueDfAppNavbar from "./VueDfAppNavbar";
  import VueDfAppNavbarToggleButton from "./VueDfAppNavbarToggleButton";
  import VueDfAppNavbarItem from "./items/VueDfAppNavbarItem";
  import VueDfUserInfoComponent from '../Auth/VueDfUserInfoComponent'
  import cloneDeep from 'lodash/cloneDeep'
  import componentMixin from '../../componentMixin';
  import permissionMixin from '../permissionMixin';

  export default {
    name: "VueDfAppTopNavbar",
    mixins:[componentMixin, permissionMixin],
    components: {VueDfAppNavbarItem, VueDfAppNavbarToggleButton, VueDfAppNavbar, VueDfUserInfoComponent},
    computed: {
      title() {
        return this.config.title;
      }
    },
    data() {
      return {
        activeNotifications: false,
        showNavbar: false
      }
    },
    created() {
    //  console.log('Navbar created', this.config, this.appConfig, this.layout);
    },
    methods: {
      onSelect: function(selected){
        this.$emit('selected', selected);
      },
      toggleSidebar() {
        this.$vueDfAppSidebar.displaySidebar(!this.$vueDfAppSidebar.showSidebar)
      },
      minimizeSidebar() {
        this.$vueDfAppSidebar.toggleMinimize()
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar;
      }
    }
  }
</script>

<style scoped>

</style>
