<template>
  <component :app-config="resolvedAppConfig"
             :selected-route-config="resolvedSelectedRouteConfig"
             :user-menu-config="resolvedUserMenuConfig"
             :config="resolvedConfig" :is="resolveComponent()"
             :parent="parent" @selected="onSelect">
    <slot :name="name" :slot="name" v-for="(_, name) in $slots"/>
  </component>
</template>

<script>
  import VueDfAppNavbarFormItem from "./VueDfAppNavbarFormItem";
  import VueDfAppNavbarLinkItem from './VueDfAppNavbarLinkItem'
  import VueDfAppNavbarTemplateItem from './VueDfAppNavbarTemplateItem'
  import VueDfAppNavbarUserItem from './VueDfAppNavbarUserItem'
  import componentMixin from '../../../componentMixin';

  export default {
    name: "VueDfAppNavbarItem",
    mixins:[componentMixin],
    props: {
      parent: Object
    },
    components:{VueDfAppNavbarFormItem, VueDfAppNavbarLinkItem, VueDfAppNavbarTemplateItem, VueDfAppNavbarUserItem},
    computed:{

      resolvedConfig: function(){
        return this.config;
      },
      resolvedAppConfig: function(){
        return this.appConfig;
      },
      resolvedSelectedRouteConfig: function(){
        return this.selectedRouteConfig;
      },
      resolvedUserMenuConfig: function(){
        return this.userMenuConfig;
      }
    },
    methods: {
      onSelect: function(selected){
        this.$emit('selected', selected);
      },
      resolveComponent: function () {
        const itemType = this.config.itemType;
        let item = 'vue-df-app-navbar-link-item';
        switch (itemType) {
          case 'FORM':
            item = 'vue-df-app-navbar-form-item';
            break;
          case 'TEMPLATE':
            item = 'vue-df-app-navbar-template-item';
            break;
          case 'USER':
            item = 'vue-df-app-navbar-user-item';
            break;

        }
        return item;
      }
    }
  }
</script>

<style scoped>

</style>
