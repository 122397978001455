import { render, staticRenderFns } from "./VueMarkdownViewer.vue?vue&type=template&id=2ab1a864&scoped=true&"
import script from "./VueMarkdownViewer.vue?vue&type=script&lang=js&"
export * from "./VueMarkdownViewer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ab1a864",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ab1a864')) {
      api.createRecord('2ab1a864', component.options)
    } else {
      api.reload('2ab1a864', component.options)
    }
    module.hot.accept("./VueMarkdownViewer.vue?vue&type=template&id=2ab1a864&scoped=true&", function () {
      api.rerender('2ab1a864', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/vue-schema-form-editor/src/aceMarkdown/VueMarkdownViewer.vue"
export default component.exports