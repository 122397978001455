<template>
  <div class="data-normalizer-wrapper p-3">
    <label>Data Normalizer</label>
    <hr class="m-0"/>
    <div class="row">
      <div class="col-3">
        <div class="raw-data-container p-2">
          <label>Raw Data</label>
          <draggable-json-tree :is-draggable=true :data="rawData" :level=3></draggable-json-tree>
        </div>
      </div>
      <div class="col-9">
        <div class="selected-fields-wrapper p-2">
          <div class="d-flex justify-content-between">
            <div>
              <label>Field Mapper</label>
              <input type="number" v-model="transformLevel"> Level
            </div>
            <div>
              <button class="btn btn-success" @click="applyData()">Apply</button>
            </div>
          </div>
          <div>
            <draggable :group="{ name: 'dataFields', pull: true, put: true}"
                       :list="selectedFields"
                       :move="onMove" @add="onAdd" @change="onChange" @end="onEnd"
                       class="list-group list-unstyled list-group-horizontal" tag="ul">
              <div class="drop-zone text-muted d-flex justify-content-center" v-if="!selectedFields.length"><span>Drop Zone</span>
              </div>
              <li class="list-group-item p-1" v-for="(val, prop) in mappedFields">
                <div class="card1">
                  <div :title="JSON.stringify(val)" class="card-body1">
                  <span @click="removeField(prop, val)">
                    <i class="fas fa-times"></i>
                  </span>
                    <div class="form-group">
                      <label class="">
                        {{ prop }}
                      </label>
                      <input class="form-control" type="text" v-model="val.newName">
                    </div>
                  </div>
                </div>
              </li>
            </draggable>
          </div>
        </div>
        <div class="selected-fields-expression p-2">
          <pre>{{ expression }}
            </pre>
        </div>
        <div class="selected-fields-data-result p-2">
          <draggable-json-tree :is-draggable=false :data="transformedData" :level=5></draggable-json-tree>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import DraggableJsonTree from "vue-json-schema-form/src/components/draggableJsonTree/DraggableJsonTree";
import draggable from 'vuedraggable';

export default {
  name: "DataNormalizer",
  props: ['rawData'],
  components: {DraggableJsonTree, draggable},
  data() {
    return {
      selectedFields: [],
      mappedFields: {},
      expression: '',
      transformedData: [],
      transformLevel: 0
    }
  },
  watch: {
    mappedFields: {
      handler(newVal, oldVal) {
        this.doTransformation();
      },
      deep: true
    },
    transformLevel: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.doTransformation();
        }
      }
    }
  },
  methods: {
    applyData() {
      this.$emit('apply', this.transformedData);
    },
    doTransformation() {
      this.expression = this.createExpression(this.mappedFields, this.transformLevel);
      if (!this.mappedFields || !Object.keys(this.mappedFields).length) {
        this.expression = '';
        this.transformedData = {};
      } else {
        console.log('Resolving expression', this.expression, this.mappedFields, this.transformedData);
        if (this.expression) {
          this.transformedData = this.transformData(this.expression, this.rawData);
        }
      }
    },
    removeField(prop, val) {
      console.log('remove ', prop, val, this.mappedFields, this.selectedFields);
      this.$delete(this.mappedFields, prop);
      let index = -1
      this.selectedFields.forEach((field, arrIndex) => {
        if (field.path === prop) {
          index = arrIndex;
        }
      });
      if (index > -1) {
        this.selectedFields.splice(index, 1);
      }
    },
    transformData(expression, data) {
      const result = this.$jsulator.evaluate(expression, data);
      return result;
    },
    createExpression(fields, transformLevel) {
      if (!fields) {
        return '';
      }
      // let rootExpression = '';

      let expression = 'MERGE(\n' +
        '   REMAP(\n' +
        '     THIS(),\n';

      let tmpFieldExpression = '     MAP(\n';

      const keys = Object.keys(fields);
      const commonParentName = this.calculateCommonParent(keys);
      if (keys) {
        keys.forEach((prop, propIndex) => {
          const val = fields[prop];
          /*if (!rootExpression) {
          let dotIndex = prop.indexOf('\\.');
          if (dotIndex > -1) {
            rootExpression += (prop.substring(0, dotIndex).replaceAll('\\[|\\]', ''));
          }
        }*/
          let expr = "        FIELD('" + val.newName + "', '$" + prop + "$')";
          if (propIndex < keys.length - 1) {
            expr += ',\n';
          }

          tmpFieldExpression += expr;
        })
      }


      expression += tmpFieldExpression + '\n      )';
      expression += '\n   )';

      if (transformLevel) {
        let level = Number.parseInt(transformLevel);
        if (level > 0) {
          level++;
          const prefixMerge = 'MERGE(\n\t';
          const prefixRemap = 'REMAP(\n\t\t';
          const prefixThis = 'THIS(),\n';

          /*for(let i=0;i<transformLevel;i++){
          const tabs = Array(i).join('\t');
          expression = tabs+prefixMerge+(tabs)+prefixRemap+tabs+prefixThis+tabs+prefixMerge+tabs+expression+'\n'+tabs+')\n'+tabs+')\n'+tabs+')';
        }*/
          const tabs = Array(level).join('\t');
          const prefix = Array(level).join(prefixMerge + tabs + prefixRemap + tabs + prefixThis);
          const suffix = Array(level).join('\n' + tabs + ')\n' + tabs + ')');
          expression = prefix + expression + suffix;

        }
      }

      expression += '\n)';

      return expression;
    },
    calculateCommonParent(keys) {
      if (!keys || !keys.length)
        return '';

      let common = '';
      keys.forEach((key) => {
        let splited = key.split('\\.');
        if (splited.length) {

        }
      });
    },
    onEnd() {
      console.log('on onEnd', arguments);
    },
    onChange(evt) {
      console.log('on onChange', arguments);
      let element = null;
      let index = -1;
      if (evt.added) {
        element = evt.added.element;
        index = evt.added.newIndex;
      } else if (evt.moved) {
        element = evt.moved.element;
        index = evt.moved.newIndex;
      }
      if (element) {
        const filtered = this.selectedFields.filter((item, index) => {
          return item.path === element.path;
        });
        if (filtered.length > 1) {
          this.selectedFields.splice(index, filtered.length - 1);
        } else {
          this.$set(this.mappedFields, element.path, Object.assign({}, {newName: element.key, payload: element}));
        }
      }
    },
    onAdd() {
      console.log('on onAdd', arguments);
    },
    onMove() {
      console.log('on onMove', arguments);
    }
  }
}
</script>

<style scoped>
.selected-fields-wrapper .list-group {
  min-height: 50px;
  border: 1px solid #d6804a;
  overflow-x: auto;
}

.drop-zone {
  padding: 10px;
}

.card-body {
  padding: 0px 7px 0px 7px;
}
</style>
