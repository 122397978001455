import SeriesChart from './SeriesChart'

class DatasetChart extends SeriesChart{
  constructor(chartType) {
    super(chartType);
  }

  updateDataset(chartDefinition){
    chartDefinition.dataset = [{
      source: this.dataset
    }]
  }

  getChartDefinition(){
    let baseChart = super.getChartDefinition();
    this.updateDataset(baseChart);
    return baseChart;
  }
}


export default DatasetChart
