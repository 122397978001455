import BaseChart from './base/BaseChart'

class FunnelChart extends BaseChart {
  constructor() {
    super('FunnelChart');
  }

  __generateData(metricItem, primaryDimension) {
    const data = this.dataset.map((item) => {
      return {value: item[metricItem.title], name: item[primaryDimension.title]};
    });
    return data;
  }

  __generateLegend(property) {
    const data = this.dataset.map((item) => {
      return  { name: item[property.title] };
    });
    return data;
  }

  __resolveAlign(configData){
    return configData.align?configData.align:'center';
  }

  __resolveOrient(configData){
    return configData.orient?configData.orient:'horizontal';
  }
  __resolveSort(conigData){
    return conigData.sort?conigData.sort: 'none';
  }

  createSeries(chartDefinition) {
    const configData = this.getChartConfigData();
    const primaryDimension = this.dimensions[0];
    const that = this;

    const series = [];
    this.metrics.forEach((item, index) => {
      const newItem = {
        type: 'funnel',
        funnelAlign: that.__resolveAlign(configData),
        orient: that.__resolveOrient(configData),
        sort: that.__resolveSort(configData),
        legendHoverLink: true,
        name: item.title,
        itemStyle: {
          opacity: 0.7 - (index * 0.1)
        },
        label: {
          formatter: '{b}: {a}\n{c}',
          fontStyle: 'bold'
        },
        labelLine: {
          show: false
        },
        emphasis: {
          label: {
            position: 'inside'
          }
        },
        data: that.__generateData(item, primaryDimension)
      }
      if (index > 0) {
        newItem.label = {
          formatter: '{b}: {a}\n{c}',
          fontStyle: 'bold',
          position: 'inside',
          color: '#fff'
        }
        newItem.itemStyle.borderColor = '#fff'
        newItem.itemStyle.borderWidth = 2

      }
      series.push(newItem);
    });

    if (series.length) {
      series[series.length - 1].z = 100
    }

    console.log('seriesMap');

    chartDefinition.series = series;
    chartDefinition.legend = {
      data: this.__generateLegend(primaryDimension)
    }
  }


  getChartDefinition() {
    const baseChart = super.getChartDefinition();
    delete baseChart.xAxis
    delete baseChart.yAxis;
    delete baseChart.dataset;
    this.createSeries(baseChart);

    return baseChart;
  }

}

export default FunnelChart
