var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.widgetDefinition
    ? _c("div", [
        _c(
          "div",
          [
            _c(
              "b-modal",
              {
                ref: "editModal",
                attrs: {
                  centered: _vm.centered,
                  size: _vm.modalSize,
                  title: _vm.title,
                },
                on: { ok: _vm.handleEditOk },
              },
              [
                _c(
                  "b-tabs",
                  [
                    _c(
                      "b-tab",
                      { attrs: { active: "", title: "Edit" } },
                      [
                        _c("vue-df-dashboard-widget", {
                          ref: "editWidget",
                          attrs: {
                            "crud-service-name": _vm.crudServiceName,
                            "dashboard-name": _vm.dashboardName,
                            "dashboard-service-instance":
                              _vm.dashboardServiceInstance,
                            dashid: _vm.dashid,
                            "default-mapper": _vm.defaultMapper,
                            definition: _vm.widgetDefinition,
                            "edit-mode": true,
                            id: _vm.widgetDefinition.id,
                            model: _vm.model,
                            options: _vm.options,
                            "parent-dashid": _vm.parentDashid,
                            "parent-widget-id": _vm.parentWidgetId,
                            permissions: _vm.permissions,
                            "widget-state": _vm.widgetState(),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Preview" } },
                      [
                        _c("vue-df-dashboard-widget", {
                          ref: "previewWidget",
                          attrs: {
                            "crud-service-name": _vm.crudServiceName,
                            "dashboard-name": _vm.dashboardName,
                            "dashboard-service-instance":
                              _vm.dashboardServiceInstance,
                            dashid: _vm.dashid,
                            "default-mapper": _vm.defaultMapper,
                            definition: _vm.widgetDefinition,
                            "edit-mode": false,
                            id: _vm.widgetDefinition.id,
                            model: _vm.model,
                            options: _vm.options,
                            "parent-dashid": _vm.parentDashid,
                            "parent-widget-id": _vm.parentWidgetId,
                            permissions: _vm.permissions,
                            "widget-state": _vm.widgetState(),
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "b-modal",
              {
                ref: "viewModal",
                attrs: {
                  centered: _vm.centered,
                  size: _vm.modalSize,
                  title: _vm.title,
                },
                on: { ok: _vm.handleViewOk },
              },
              [
                _vm.widgetDefinition && _vm.widgetDefinition.id
                  ? _c("vue-df-dashboard-widget", {
                      ref: "viewWidget",
                      attrs: {
                        "crud-service-name": _vm.crudServiceName,
                        "dashboard-name": _vm.dashboardName,
                        "dashboard-service-instance":
                          _vm.dashboardServiceInstance,
                        dashid: _vm.dashid,
                        "default-mapper": _vm.defaultMapper,
                        definition: _vm.widgetDefinition,
                        "edit-mode": false,
                        id: _vm.widgetDefinition.id,
                        "input-context": _vm.inputContext,
                        model: _vm.widgetDefinition,
                        options: _vm.options,
                        "parent-dashid": _vm.parentDashid,
                        "parent-widget-id": _vm.parentWidgetId,
                        permissions: _vm.permissions,
                        "widget-state": _vm.widgetState(),
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }