<template>
  <div>
    <b-modal :scrollable="true" :title="widget.title" @ok="handleEditConfirmed" lazy ref="modalEdit" size="full">
      <form>
        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="widgetTitle" v-bind:text="translate('ADF_COMMON_TITLE')">Title</label>
              <input class="form-control" id="widgetTitle" placeholder="Enter title" required=""
                     type="text"
                     v-model="editDefinition.title">
            </div>
          </div>
          <div class="col-1">
            <div class="form-group float-left">
              <label>Hide Title?</label>
              <input class="form-control" placeholder="Hide title"
                     type="checkbox"
                     v-model="editDefinition.hideTitle">
            </div>
          </div>
        </div>

      </form>
      <template v-if="widgetEditMode">
        <component :crud-service-name="crudServiceName"
                   :dashboard-service-instance="dashboardServiceInstance"
                   :edit-mode="widgetEditMode" :is="resolveComponent('Edit')"
                   :model="editDefinition"
                   ref="widgetEditBody"
                   :input-context="inputContext"
                   :options="options"></component>
      </template>
    </b-modal>
   <vue-df-dashboard-widget-instance-modal :crud-service-name="crudServiceName"
                                           :dashboard-service-instance="dashboardServiceInstance"
                                           :edit-mode="widgetEditMode"
                                           :default-mapper="defaultMapper"
                                           :model="editDefinition"
                                           :options="options"
                                           :input-context="inputContext"
                                           :permissions="permissions"
                                           :dashboard-name="dashboardName"
                                           :dashid="dashid"
                                           ref="widgetInstanceModal"></vue-df-dashboard-widget-instance-modal>
  </div>
</template>

<script>
  import internalMixins from './internalMixin'
  import {BModal} from 'bootstrap-vue'
  import VueDfDashboardWidgetInstanceModal from "./VueDfDashboardWidgetInstanceModal";

  export default {
    name: "VueDfDashboardWidgetEdit",
    components: {VueDfDashboardWidgetInstanceModal, BModal},
    mixins: [internalMixins],
    props: ['definition', 'defaultMapper', 'widget', 'crudServiceName', 'dashid', 'dashboardServiceInstance', 'dashboardName','inputContext'],
    data() {
      return {
        editDefinition: {},
        widgetEditMode: false
      }
    },
    methods: {
      loadEditableSettings: function (next) {
        const that = this;
        // load from database and update if appropriate in case it is changed/updated
        if (this.editDefinition.isTenantCreated) {
          this.$services[this.crudServiceName].loadWidgetByType(this.editDefinition.type, function (result) {
            console.log('loaded custom widget by id', result);
            if (result && result.widget && result.widget.editableSettings) {
              that.editDefinition.editableSettings = result.widget.editableSettings;
              next();
            } else {
              next();
            }
          });
        } else {
          next();
        }
      },
      edit: function () {
        const that = this;
        this.$widgetStore.vueJsonMerge(this.editDefinition, this.definition);
        this.loadEditableSettings(function () {
          that.prepareAndShowEdit();
        });
      },
      prepareAndShowEdit: function () {
        this.widgetEditMode = true;
        this.$refs['modalEdit'].show();
      },
      handleEditConfirmed: function (event) {
        event.preventDefault();


        const hasErrors = this.$refs.widgetEditBody.onBeforeEditOk(this.editDefinition);
        if (hasErrors){
          console.log('Has errors');
          return;
        }
        this.$emit('widgetupdated', this.editDefinition);
        this.widgetEditMode = false;

        this.$nextTick(() => {
          if (this.$refs['modalEdit'])
            this.$refs['modalEdit'].hide()
        })
      }
    }
  }
</script>

<style scoped>

</style>
