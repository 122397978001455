var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "applications-container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c("div", { staticClass: "card" }, [
          _vm._m(0),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "ul",
              { staticClass: "list-unstyled" },
              _vm._l(_vm.applications, function (app) {
                return _c("li", [
                  _c("div", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(app.tenantName) +
                          " - " +
                          _vm._s(app.applicationName)
                      ),
                    ]),
                  ]),
                  _c("div", {}, [
                    _c("p", [_vm._v("Application routes")]),
                    _c("hr"),
                    _c(
                      "ul",
                      { staticClass: "list-unstyled" },
                      _vm._l(app.routes, function (route) {
                        return _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: route.routeKey.split("=").join("/"),
                                  },
                                },
                              },
                              [_vm._v(_vm._s(route.item.name))]
                            ),
                            _c("div", [
                              _c("pre", { staticClass: "text-muted small" }, [
                                _vm._v(_vm._s(route)),
                              ]),
                            ]),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("p", [_vm._v("Applications")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }