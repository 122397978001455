<template>
  <div class="chart-builder-filter-container">
  </div>
</template>

<script>
  export default {
    name: "Filters"
  }
</script>

<style scoped>

</style>
