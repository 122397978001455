const internalMixin = {
  props: ['options', 'permissions'],
  methods:{
    resolveWidgetDef: function(){
      let componentName = this.definition.type;
      if (this.defaultMapper[this.definition.type]) {
        componentName = this.defaultMapper[this.definition.type];
      }
      const widgetDef = this.$widgetStore.getWidgetByType(componentName);
      return widgetDef;
    },
    resolveComponent: function (suffix) {
      const widgetDef = this.resolveWidgetDef();
      let widgetComponentName;
      if (widgetDef){
        if(suffix==='View' && widgetDef.viewComponent){
          widgetComponentName = widgetDef.viewComponent;
        }else if (suffix==='Edit' && widgetDef.editComponent){
          widgetComponentName = widgetDef.editComponent;
        }else{
          console.error('There is no defined component for View: '+suffix+'. Please check the widget configuration for component typr: '+widgetDef.type)
        }
      }else{
        widgetComponentName = this.definition.viewComponent;
      }

      const kebab = this.camelToKebab(widgetComponentName);
      return kebab;
    },
    camelToKebab: function(str){
      return str
        .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
        .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
        .toLowerCase();
    },
    translate: function (key) {

    }
  }
}

export default internalMixin
