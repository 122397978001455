const widgetMixin = {
  data() {
    return {
      widgetsToSelect:[]
    }
  },
  created(){
    this.fillWidgetsToAllow();
  },
  methods: {
    getWidgets: function(){
      return this.widgetsToSelect;
    },
    fillWidgetsToAllow: function(){
      const widgets = this.$widgetStore.widgets;
      if(widgets){
        const tmp = [];
        for(let type in widgets) {///.forEach(function(widget){
        //  console.log('filling widget', widgets[type]);
          const obj = {
            name: widgets[type].title,
            value: widgets[type].type,
            category: widgets[type].category
          }
          tmp.push(obj);
        }
        this.widgetsToSelect = tmp;
      }
    },
    onValidationSchemaConfigResult: function (event) {
      if (event.valid) {

      }
    }
  }
}

export default widgetMixin
