import Modeler from 'bpmn-js/lib/Modeler'

import inherits from 'inherits'

import EtlModule from './etl'

export default function EtlModeler(options) {
  Modeler.call(this, options)

  this._customElements = []
}

inherits(EtlModeler, Modeler)

EtlModeler.prototype._modules = [].concat(EtlModeler.prototype._modules, [
  EtlModule
])
