<template>
  <div class="conf-popup" :style="{top: top+'px', left: left+'px'}">
    <div class="group" v-for="group in conf">
        <div class="group-item" v-for="item in group.tools">
          <span :class="item.class" :title="item.title" @click.stop="confItemAction(item)"></span>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "ElementConfPopup",
  props: {
    selection: Object
  },
  data() {
    return {
      conf:[
        {
          group:'model',
          tools:[
            {
              title: 'Append EndEvent',
              type: 'appendEndEvent',
              class: 'entry bpmn-icon-end-event-none'
            },
            {
              title: 'Append Gateway',
              type: 'appendGateway',
              class: 'entry bpmn-icon-gateway-none'
            },
            {
              title: 'Append Task',
              type: 'appendTask',
              class: 'entry bpmn-icon-task'
            },
            {
              title: 'Append Intermediate/Boundary Event',
              type: 'appendIntermediateBoundary',
              class: 'entry bpmn-icon-intermediate-event-none'
            },
            {
              title: 'Append Intermediate/Boundary Event',
              type: 'appendTextAnnotation',
              class: 'entry bpmn-icon-text-annotation'
            }
          ]
        },
        {
          group:'edit',
          tools:[
            {
              title: 'Change Type',
              type: 'changeType',
              class: 'entry bpmn-icon-screw-wrench'
            },
            {
              title: 'Remove',
              type: 'remove',
              class: 'entry bpmn-icon-trash'
            }
          ]
        },
        {
          group:'connect',
          tools:[
            {
              title: 'Connect using Sequence/MessageFlow or Association',
              type: 'connect',
              class: 'entry bpmn-icon-connection-multi'
            }
          ]
        }
      ]
    }
  },
  computed: {
    top() {
      return this.selection && this.selection.height?-(this.selection.height): 0;
    },
    left() {
      return this.selection && this.selection.width ? this.selection.width+15 : 0; //0;//this.selection && this.selection.y?this.selection.y+this.selection.width+20: 0;
    }
  },
  methods: {
    confItemAction(item){
     // console.log('item clicked',item);
      this.$emit('ontoolclicked', item);
    }
  }
}
</script>

<style scoped>
.conf-popup {
  position: relative;
}

.group {
  list-style: none;
  padding: 0px;
  margin-bottom: 2px;
}

.entry:hover{
  background-color: rgba(11, 46, 19, 0.15);
}

.group > .group-item {
  display: inline-block;
  /* You can also add some margins here to make it look prettier */
  zoom:1;
  *display:inline;
  /* this fix is needed for IE7- */
  width: 16px;
  height: 16px;
  text-align: center;

  font-size: 16px;
  margin: 0 2px 2px 0;
  cursor: default;
  pointer-events: all;
}

.entry {

}
</style>
