import inherits from 'inherits'

import ContextPadProvider from 'bpmn-js/lib/features/context-pad/ContextPadProvider'

import { is } from 'bpmn-js/lib/util/ModelUtil'

import { assign,
  isArray} from 'min-dash'

inherits(CustomContextPadProvider, ContextPadProvider)

CustomContextPadProvider.$inject = [
  'injector',
  'connect',
  'translate'
]

export default function CustomContextPadProvider(injector, connect, translate) {
  injector.invoke(ContextPadProvider, this)



  // var cached = bind(this.getContextPadEntries, this)
  // var rules = this._rules
  const elementFactory = this._elementFactory
  const create = this._create
  const autoPlace = this._autoPlace
  const modeling = this._modeling
  const popupMenu = this._popupMenu;
  const contextPad = this._contextPad;
  const canvas = this._canvas;
  const rules= this._rules;

  this.getContextPadEntries = function(element) {

    var actions = {}
    var businessObject = element.businessObject

    function startConnect(event, element, autoActivate) {
      connect.start(event, element, autoActivate)
    }

    function appendAction(type, className, title, options) {
      if (typeof title !== 'string') {
        options = title
        title = translate('Append {type}', { type: type.replace(/^bpmn:/, '') })
      }

      function appendStart(event, element) {
        var shape = elementFactory.createShape(assign({ type: type }, options))
        create.start(event, shape, {
          source: element
        })
      }

      var append = autoPlace
        ? function(event, element) {
            var shape = elementFactory.createShape(
              assign({ type: type }, options)
            )

            autoPlace.append(element, shape)
          }
        : appendStart

      return {
        group: 'model',
        className: className,
        title: title,
        action: {
          dragstart: appendStart,
          click: append
        }
      }
    }

    function removeElement() {
      modeling.removeElements([element])
    }

    function getReplaceMenuPosition(element) {

      var Y_OFFSET = 5;

      var diagramContainer = canvas.getContainer(),
        pad = contextPad.getPad(element).html;

      var diagramRect = diagramContainer.getBoundingClientRect(),
        padRect = pad.getBoundingClientRect();

      var top = padRect.top - diagramRect.top;
      var left = padRect.left - diagramRect.left;

      var pos = {
        x: left,
        y: top + padRect.height + Y_OFFSET
      };

      return pos;
    }

    if (is(businessObject, 'bpmn:StartEvent')) {
      assign(actions, {
        'append.gateway': appendAction(
          'bpmn:ExclusiveGateway',
          'bpmn-icon-gateway-none',
          translate('Append Gateway')
        ),
        'append.task': appendAction(
          'bpmn:Task',
          'bpmn-icon-task',
          translate('Append Task')
        )
      })
    } else {
      if (is(businessObject, 'bpmn:ExclusiveGateway')) {
        assign(actions, {
          'append.task': appendAction(
            'bpmn:Task',
            'bpmn-icon-task',
            translate('Append Task')
          )
        })
      } else if (!is(businessObject, 'bpmn:EndEvent')) {
        assign(actions, {
          'append.end-event': appendAction(
            'bpmn:EndEvent',
            'bpmn-icon-end-event-none',
            translate('Append EndEvent')
          ),
          'append.gateway': appendAction(
            'bpmn:ExclusiveGateway',
            'bpmn-icon-gateway-none',
            translate('Append Gateway')
          ),
          'append.task': appendAction(
            'bpmn:Task',
            'bpmn-icon-task',
            translate('Append Task')
          )
        })
      }
    }

    if (!popupMenu.isEmpty(element, 'bpmn-replace')) {

      // Replace menu entry
      assign(actions, {
        'replace': {
          group: 'edit',
          className: 'bpmn-icon-screw-wrench',
          title: translate('Change type'),
          action: {
            click: function(event, element) {

              const position = assign(getReplaceMenuPosition(element), {
                cursor: { x: event.x, y: event.y }
              });

              popupMenu.open(element, 'bpmn-replace', position);
            }
          }
        }
      });
    }

    if (!is(businessObject, 'bpmn:EndEvent')) {
      assign(actions, {
        connect: {
          group: 'connect',
          className: 'bpmn-icon-connection-multi',
          title: translate('Append Sequence'),
          action: {
            click: startConnect,
            dragstart: startConnect
          }
        }
      })
    }


    // delete element entry, only show if allowed by rules
    let deleteAllowed = rules.allowed('elements.delete', { elements: [ element ] });
    if (isArray(deleteAllowed)) {
      // was the element returned as a deletion candidate?
      deleteAllowed = deleteAllowed[0] === element;
    }
    if (deleteAllowed) {
      assign(actions, {
        delete: {
          group: 'edit',
          className: 'bpmn-icon-trash',
          title: translate('Remove'),
          action: {
            click: removeElement
          }
        }
      })
    }
    return actions
  }
}
