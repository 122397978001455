var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chart-definition-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "chart-definition-body row" }, [
      _c(
        "div",
        { staticClass: "chart-definition-editor data-editor-container col-6" },
        [
          _c("label", { staticClass: "control-label" }, [
            _vm._v("Chart Editor"),
          ]),
          _c("vue-ace-editor", {
            attrs: {
              config: _vm.editorConfig,
              content: _vm.strContent,
              height: "95%",
              position: "absolute",
              width: "95%",
            },
            on: {
              "change-content": function ($event) {
                return _vm.onAceValueChanged($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "chart-definition-preview col-6 pt-3 mt-3" },
        [
          _vm.renderChart
            ? _c("vue-df-e-chart-wrapper", {
                ref: "chartWrapper",
                attrs: {
                  id: _vm.chartId,
                  height: 400,
                  "chart-config": _vm.chartConfig,
                  dataset: _vm.chartDataset,
                  dimensions: _vm.chartDimensions,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "chart-definition-header row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h6", [
          _vm._v("Define chart based on "),
          _c(
            "a",
            {
              attrs: {
                href: "https://echarts.apache.org/examples/en/index.html",
                target: "_blank",
              },
            },
            [_vm._v("ECharts")]
          ),
        ]),
        _c("p", { staticClass: "text-muted" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }