var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ace-markdown-wrapper" }, [
    _c("div", { staticClass: "ace-markdown-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6 col-xs-12" }, [
          _c("div", { staticClass: "ace-markdown-editor-wrapper" }, [
            _c(
              "div",
              { staticClass: "ace-markdown-editor-container" },
              [
                _c("vue-ace-editor", {
                  attrs: {
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    content: _vm.content,
                    config: _vm.config,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.onAceValueChanged($event)
                    },
                    "change-content": function ($event) {
                      return _vm.onAceValueChanged($event)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "col-md-6 col-xs-12" }, [
          _c("div", { staticClass: "ace-markdown-viewer-wrapper" }, [
            _c(
              "div",
              { staticClass: "ace-markdown-viewer-container" },
              [
                _c("markdown-it-vue", {
                  attrs: { content: _vm.markdownContent },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }