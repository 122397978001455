import PanelMixin from "vue-diagram/src/bpmn/panel/components/mixins/panel.mixin";
export default {
  mixins: [PanelMixin],
  data() {
    return {
      startEndSchema: {
        type: Object,
        properties: {}
      },
      startEndForm: [],
      startEndData: {}
    }
  },
  mounted() {
    console.log('mounted Task Panel')
    this.mountSchemaForm()

    const skip = ['$type'];
    for(let prop in this.formData){
      if (skip.indexOf(prop)===-1){
        this.$set(this.startEndData, prop, this.formData[prop]);
      }
    }

  },
  watch: {
    startEndData: {
      handler(newVal, oldVal) {
        console.log('changed startEndData data', newVal);
        this.updateData(newVal);
      },
      deep: true
    }
  },
  methods: {
    updateData(localData) {
      const skipUpdate = ['id','name','documentation','$type'];
      for(let prop in localData){
        this.$set(this.formData, prop, localData[prop]);
        if (skipUpdate.indexOf(prop)===-1){
          const obj = {};
          obj[prop] = localData[prop];
          this.updateProperties(obj);
        }
      }
    },
    mountSchemaForm() {
      this.mergeObjects(this.schema.properties, this.startEndSchema.properties);
      this.form.push(...this.startEndForm);
    }
  }
}
