<template>
  <div class="chart-builder-container">
    <b-tabs>
      <b-tab active title="Data">
        <data-editor :value="content" @input="onDataEditorInput"></data-editor>
      </b-tab>
      <b-tab title="Editor">
        <div class="chart-builder-editor">
          <div class="row chart-builder-header">
            <div class="col">
              <chart-types :current-type="chartType.context" @contextchanged="onChartTypeContextChanged"></chart-types>
            </div>
          </div>
          <div class="row chart-builder-header">
            <div class="col">
              <builder-header :data="content" @contextchanged="onContextChanged"
                              @calculated="onDimensionMetricsCalculated"></builder-header>
            </div>
          </div>
          <div class="row chart-builder-body">
            <div class="col">
              <div class="row">
                <div class="col-2 chart-builder-left">
                  <div class="chart-builder-properties">
                    <properties v-model="properties"></properties>
                  </div>
                  <div class="chart-builder-filter">
                    <filters></filters>
                  </div>
                </div>
                <div class="col-8 chart-builder-editor">
                  <div class="editor-container">
                    <chart-editor @contextchanged="onContextChanged" :chart-type="chartType"
                                  :dimensions-metrics="dimMetrics"
                                  :data="content"></chart-editor>
                  </div>
                </div>
                <div class="col-2 chart-builder-context">
                  <chart-builder-context @changedconfig="onChangedChartConfig"
                                         v-model="currentContext"></chart-builder-context>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Preview">
          Nothin to show
      </b-tab>
      <b-tab title="Generator">
        <data-chart-generator></data-chart-generator>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>

import DataChartGenerator  from "./generator/DataChartGenerator";

const DataEditor = () => import('./components/DataEditor')
const Properties = () => import('./components/Properties')
const BuilderHeader = () => import('./components/BuilderHeader')
const Filters = () => import ('./components/Filters')
const ChartTypes = () => import('./components/ChartTypes')
const ChartEditor = () => import('./components/ChartEditor')
const ChartBuilderContext = () => import('./ChartBuilderContext')

import {BTabs, BTab} from 'bootstrap-vue'

export default {
  name: "ChartBuilderEditor",
  components: {
    DataChartGenerator,
    DataEditor, Properties, BuilderHeader, Filters, ChartTypes, ChartEditor, BTabs, BTab, ChartBuilderContext
  },
  data() {
    return {
      content: undefined,
      properties: [],
      dimMetrics: undefined,
      chart: undefined,
      chartType: {},
      currentContext: {}
    }
  },
  methods: {
    onDimensionMetricsCalculated: function (dimMetrics) {
      this.dimMetrics = dimMetrics;
    },
    onDataEditorInput: function (event) {
      console.log('onDataEditorInput', event);
      if (event) {
        if (event.content)
          this.content = event.content;
        if (event.properties)
          this.properties = event.properties;
      }
    },
    onChartTypeContextChanged: function (chartType) {
      this.chartType = chartType;
      this.currentContext = chartType;
    },
    onChangedChartConfig: function (chartConfig) {
      console.log('onChangedChartConfig', chartConfig);
    },
    onContextChanged: function (newContext) {
      this.currentContext = newContext;
    }
  }
}
</script>

<style scoped>


</style>
