<template>
  <v-runtime-template :template="template"></v-runtime-template>
</template>

<script>
  import VRuntimeTemplate from "v-runtime-template";
  import templateMixin from './templateMixin'

  export default {
    name: "VTemplateWrapper",
    components:{  VRuntimeTemplate },
    mixins:[templateMixin],
    props: {
      model: Object,
      meta: Object,
      dataModel: null,
      template: String
    },
    data() {
      return {
        componentType: 'v-runtime-template'
      }
    },
    methods: {
      emitEvent: function (eventName, data) {
       // console.log('Clicked to publish event', eventName, data);
        this.$emit('emitevent', {
          eventName: eventName,
          context: data
        });
      },
      goBack() {
        window.history.length > 1
          ? this.$router.go(-1)
          : this.$router.push('/')
      }
    }
  }
</script>

<style scoped>

</style>
