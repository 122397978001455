<template>
  <div class="diagram-elements-container">
    <label>Elements</label>

    <div class="elements-wrapper dragable-wrapper">
      <draggable :clone="clone" :group="{ name: 'workflow', pull: 'clone', put: false }" :move="onMove"
                 @end="onEnd"
                 @start="onStart" class="list-group"
                 id="dragable" ref="elements" tag="ul" v-model="elements">

        <transition-group :name="'flip-list'" type="transition">
          <li :key="item.name" class="list-group-item" v-bind:itemref="item" v-for="item in elements">
            <div :title="item.description" class="">
                    <span class="mr-2  text-muted" style="font-size: 0.7rem;">
                    <i :class="item.icon || 'fas fa-circle-notch'"></i>
                    </span>
              <span>{{item.title || item.name}}</span>
            </div>
          </li>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import schemaFormElements from "vue-json-schema-form/src/components/schemaForm/schemaFormElements";

export default {
  name: "VueDiagramElements",
  components:{draggable},
  props: {
    elements: Array
  },
  data(){
    return {
      isDragging: false
    }
  },
  methods:{
    clone: function (evt) {
      const obj = {
        component: evt
      }
      return obj;
    },
    onEnd: function (evt) {
      //console.log(evt);
      this.isDragging = false
    },
    onStart: function (evt) {
      //  console.log(evt);
      this.isDragging = true;
    },
    onMove: function (evt) {
    //  console.log('on move from elements', evt);//relatedContext, draggedContext);
      //return relatedContext.component.$el.id !== 'dragable';
    }

  }
}
</script>

<style scoped>

.card {
  min-height: 50px;
}

.schema-form-wrapper, .config-wrapper {
  overflow-y: scroll;
}

.schema-form-wrapper .tabs {
  height: calc(100vh - 107px);
}

.modal-dialog .schema-form-wrapper .tabs {
  height: calc(100vh - 350px);
}

.modal-dialog .vue-portal-target .schema-form-wrapper .tabs {
  height: calc(100vh - 140px);
}

.modal-dialog .dragable-wrapper .list-group {
  height: calc(100vh - 420px);
}

.dragable-wrapper .list-group {
  height: calc(100vh - 180px);
}

.dragable-wrapper span {
  overflow-y: scroll;
}

.dropable-wrapper .list-group.has-elements {
  border: 1px dotted #393939;
  height: 50px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}

.data-wrapper pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
}

li.maximize {
  position: absolute;
  right: 35px;
}
</style>
