import jsnotevil from "jsnotevil";
const templateMixin = {
  methods: {
    compileFunctions: function(funcStr, context) {
      if (funcStr) {
        const func = jsnotevil.Function('context', funcStr);
        const objApi = func(context);
        if (objApi){
          const tmpObjApi = {};
          for(let fnName in objApi){
            const fn = objApi[fnName];
            tmpObjApi[fnName] = function(...event){
              return fn(context, ...event);
            }
            context.this[fnName] = tmpObjApi[fnName];
          }
          return tmpObjApi;
        }
        return objApi;
      } else {
        return {}
      }
    }
  }
}

export default templateMixin
