import {parse, stringify} from 'css'

const dynamicCssManager = {
  prefix: 'css-style-',
  contains(str, separator) {
    const indexOf = str.indexOf(separator);
    return indexOf === -1 ? false : true;
  },
  prepareSelector(selector, scopeId) {
  //  console.log('Selector', selector, scopeId);
    const scop = '[' + scopeId + ']';

    const matcher = selector.replace(/^[^\s]+/, "$&" + scop);

    return matcher; //selector + '[' + scopeId + ']';
  },
  updateCss(cssString, scopeId) {
    const that = this;
    const cssAst = parse(cssString);
    let cssStringified = cssString;
    if (cssAst && cssAst.stylesheet && cssAst.stylesheet.rules) {
      cssAst.stylesheet.rules.forEach(function (rule) {
        if (rule && rule.selectors) {
          const tmp = [];
          rule.selectors.forEach(function (selector) {
            const newSelector = that.prepareSelector(selector.trim(), scopeId);
            tmp.push(newSelector);
          });
          rule.selectors = tmp;
        }
      });
      cssStringified = stringify(cssAst);
     // console.log('CSS AST', cssAst, cssStringified);
    }
    return cssStringified;
  },
  appendChild(component, nodeId, cssString) {
    if (component.$el.nodeName!== "#comment") {
      let style = document.getElementById(this.prefix + nodeId);
      if (!style) {
        style = document.createElement("style");
        style.id = this.prefix + nodeId;
        style.type = "text/css";

        component.$el.appendChild(style);
      }
      // let cssRule = `#vue-ribbon:before { background-color: ${this.color}}`;
      const scopeId = nodeId; //component.$options._scopeId;


      const cssStringified = this.updateCss(cssString, scopeId);

      style.innerHTML = cssStringified;
    }
  },
  removeChild(nodeId) {
    let style = document.getElementById(this.prefix + nodeId);
    if (style) {
      document.removeChild(style);
    }
  },
  appendFile(href, id) {
    // check first is already existing - remove it and create new one - use to switch themes
    // check this: https://github.com/terence55/themes-switch
    let file = document.createElement('link');
    file.rel = 'stylesheet';
    file.href = href;//'myfile.css'
    file.id = this.prefix + id;
    document.head.appendChild(file)
  }
}

export default dynamicCssManager;
