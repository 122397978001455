const jsonObjectAnalyzer = {
  __moneyLetters: ['$'],
  __dateKeyNamesPrefix: ['create', 'update', 'time', 'date', 'dt', 't', 'd'],
  __toDate(val) {

    try {
      return new Date(val);
    } catch (e) {
    }

  },
  __toNumber(val) {
    if (val && typeof val === 'string') {
      let tmp = val.trim();
      try {
        tmp = Number(tmp);
      } catch (e) {

      }
      return tmp;
    }
  },
  __isDate(key, val) {
    let isPotentialDate = this.__dateKeyNamesPrefix.some(kname => key.startsWith(kname));
    if (isPotentialDate) {
      const tmpDate = this.__toDate(val);
      if (tmpDate) {
        return {
          valueType: 'date',
          val: tmpDate
        }
      }
    }
  },
  __isMoney(key, val) {
    if (typeof val === 'string') {
      return this.__moneyLetters.map(ml => {
        if (val.startsWith(ml)) {
          return {
            valueType: 'money',
            val: this.__toNumber(val)
          };
        }
      })[0];
    }
  },
  __resolveMetricFromString(key, val) {
    if (val) {
      let tmp = val.trim();
      let valueType = this.__isMoney(key, tmp);
      if (valueType) {
        return valueType;
      }
      valueType = this.__isDate(key, val);
      if (valueType) {
        return valueType;
      }
    }
    return null;
  },
  resolveCustomDimension(key, val){
    let customMetricType = this.__isDate(key, val);
    if (customMetricType){
      return customMetricType;
    }
  },
  resolveAndFillType(key, val, dimensions, metrics) {
    const obj = {
      key: key,
      val: val
    }

    const type = typeof val;
    console.log('type of: ', val, type);
    const customDimension = this.resolveCustomDimension(key, val);
    if (customDimension){
      obj.type = 'dimension';
      obj.valueType = customDimension.valueType;
      obj.val = customDimension.val;
      dimensions.push(obj);
    }else {
      if (type === 'string') {
        const metricValueType = this.__resolveMetricFromString(key, val);
        if (metricValueType) {
          obj.type = 'metric';
          obj.valueType = metricValueType.valueType;
          obj.val = metricValueType.val;
          metrics.push(obj);
        } else {
          obj.type = 'dimension';
          obj.valueType = type;
          dimensions.push(obj);
        }
      } else if (type === 'number' || type === 'boolean') {
        obj.type = 'metric';
        obj.valueType = type;
        metrics.push(obj);
      }
    }
  },
  analyzeDimensionsAndMetrics(data) {
    console.log("data to analyze", data);
    let obj = data;
    if (Array.isArray(data)) {
      obj = data[0];
    }
    if (!obj) {
      return {}
    }
    console.log("data to analyze FIRST row", obj);

    const dimensions = [];
    const metrics = [];

    Object.keys(obj).forEach(key => {
      const val = obj[key];
      this.resolveAndFillType(key, val, dimensions, metrics)
    });

    console.log('dimensions & metrics', dimensions, metrics);
    return {
      dimensions: dimensions,
      metrics: metrics
    };
  },

}

export default jsonObjectAnalyzer
