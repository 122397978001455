/*
Single class per Dashboard. Child dashboards will inherit Dashboard Service Instance
 */
class DashboardService {

  constructor(dashid, dashboardName, parentService, dashboardTitle) {
    this.dashid = dashid;
    this.dashboardName = dashboardName;
    this.widgetInstances = {};
    this.parentService = parentService;
    this.dashboardTitle = dashboardTitle;
  }

  getRootDashboardId(){
    if (this.parentService){
      const rootDashboardId = this.parentService.getRootDashboardId();
      return rootDashboardId;
    }else{
      return this.dashid
    }
  }

  addWidgets(widgets) {
    if (widgets) {
      const that = this;
      widgets.forEach(function (widget) {
        that.addWidget(widget);
      });
    }
  }

  addWidget(widget) {
    this.widgetInstances[widget.id] = widget;
    this.widgetInstances[widget.id].dashid = this.dashid;
    this.widgetInstances[widget.id].dashboardName = this.dashboardName;
    this.widgetInstances[widget.id].dashboardTitle = this.dashboardTitle;
    this.widgetInstances[widget.id].fullTitle = this.widgetInstances[widget.id].title + ' ('+this.widgetInstances[widget.id].id+')';
  }

  removeWidget(widget) {
    delete this.widgetInstances[widget.id];
  }

  _getAllInstancesRecursively(resultArray, dashboardService){
    if (this.widgetInstances){
      resultArray.push(...Object.values(this.widgetInstances));
    }
    if (dashboardService.parentService){
      this._getAllInstancesRecursively(resultArray, (dashboardService.parentService));
    }
  }

  getAllIncludingParent(){
    let resultArray = [];
    this._getAllInstancesRecursively(resultArray, this);
    return resultArray;
  }

  getAll() {
    return this.widgetInstances;
  }

  getAllTypes(){
    const types = [];
    if (this.widgetInstances){
      for(let prop in this.widgetInstances){
        const widget = this.widgetInstances[prop];
        const index = types.indexOf(widget.type);
        if(index===-1){
          types.push(widget.type);
        }
      }
    }
    return types;
  }
}

export default DashboardService;
