var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.resolveComponent(),
    {
      tag: "component",
      attrs: {
        "app-config": _vm.resolvedAppConfig,
        "selected-route-config": _vm.resolvedSelectedRouteConfig,
        "user-menu-config": _vm.resolvedUserMenuConfig,
        config: _vm.resolvedConfig,
        parent: _vm.parent,
      },
      on: { selected: _vm.onSelect },
    },
    [
      _vm._l(_vm.$slots, function (_, name) {
        return _vm._t(name, null, { slot: name })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }