<template>
  <div>
    DataSource designer
  </div>
</template>

<script>
    export default {
        name: "DataSourceDesigner"
    }
</script>

<style scoped>

</style>
