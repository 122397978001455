
class DatasetReduce {
  constructor(jsulator) {
    this.jsulator = jsulator;
  }
  __expressionEval(expression, objectMap){
    return this.jsulator.evaluate(expression, objectMap);
  }
  __groupBy(array, dimensions) {
    let groups = {};
    console.log('__groupBy', array, dimensions);
    array.forEach(function (item) {
      const dimensionKey = [];
      dimensions.forEach(function(dim){
        if (item[dim.name] !== undefined && item[dim.name]!==null){
          const obj = {};
          obj[dim.title]=item[dim.name];
          dimensionKey.push(obj);
        }
      });
      if (dimensionKey.length===dimensions.length){
        let group = JSON.stringify(dimensionKey);
        groups[group] = groups[group] || [];
        groups[group].push(item);
      }
    });
    return groups;
  }
  __min(items, property){
    console.log('__min', items, property);
    if (!items || items.length===0)
      return;

    let min = Number.MAX_VALUE;
    items.forEach(function(item){
      if (item[property]!==undefined || item[property]!==null){
        min = Math.min(min, item[property]);
      }
    });
    return min;
  }
  __max(items, property){
    console.log('__max', items, property);
    if (!items || items.length===0)
      return;

    let min = Number.MIN_VALUE;
    items.forEach(function(item){
      if (item[property]!==undefined || item[property]!==null){
        min = Math.max(min, item[property]);
      }
    });
    return min;
  }
  __std_dev(items, property){
    console.log('__std_dev', items, property);
    if (!items || items.length===0)
      return;

    const arr = [];
    items.forEach(function(item){
      if (item[property]!==undefined || item[property]!==null){
        const val = item[property];
        if (val!==undefined || val!==null)
          arr.push(val);
      }
    });
    let std_dev = null;
    if (arr.length){
      const n = arr.length;
      const mean = arr.reduce((a, b) => a + b) / n;
      std_dev =  Math.sqrt(arr.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
      std_dev = (Math.round(std_dev * 100) / 100).toFixed(2);
      std_dev = Number.parseFloat(std_dev);
    }

    return std_dev;
  }
  __sum(items, property){
    console.log('__sum', items, property);
    let res = null;
    if (!items || items.length===0)
      return;

    items.forEach(function(item){
      if (item[property]!==undefined || item[property]!==null){
        res+=item[property];
      }
    });
    return res;
  }
  __avg(items, property){
    console.log('__avg', items, property);
    let res = null;
    if (!items || items.length===0)
      return;

    items.forEach(function(item){
      if (item[property]!==undefined || item[property]!==null){
        res+=item[property];
      }
    });
    return res/items.length;
  }
  __count(items, property){
    console.log('__count', items, property);
    if (!items) return 0;
    let res = 0;
    items.forEach(function(item){
      if (item.hasOwnProperty(property)){
        res++;
      }
    });
    return res;
  }
  __summarize(group, metrics){
    const final = {};
    const that = this;
    for(const p in group){
      const value = group[p];
      let metricRes = {};
      console.log('__summarize', group, metrics);
      if (metrics && metrics.length) {
        metrics.forEach(function (metric) {
          switch (metric.metricType) {
            case 'SUM':
              metricRes[metric.title] = that.__sum(value, metric.name);
              break;
            case 'AVG':
              metricRes[metric.title] = that.__avg(value, metric.name);
              break;
            case 'COUNT':
              metricRes[metric.title] = that.__count(value, metric.name);
              break;
            case 'MIN':
              metricRes[metric.title] = that.__min(value, metric.name);
              break;
            case 'MAX':
              metricRes[metric.title] = that.__max(value, metric.name);
              break;
            case 'STD_DEV':
              metricRes[metric.title] = that.__std_dev(value, metric.name);
              break;
            default:
              metricRes[metric.title] = that.__count(value, metric.name);
          }

        });
      }else{
        metricRes["Count"] = value?value.length:0;
      }
      final[p]= metricRes;
    };

    return final;
  }
  __normalize(sumarizedGroupedResult){
    const final = [];
    console.log('__normalize', sumarizedGroupedResult);
    for (const p in sumarizedGroupedResult){
      const val = sumarizedGroupedResult[p];
      const finalObjParsed = JSON.parse(p);
      const finalObj = {};
      finalObjParsed.forEach(function(final){
        for(const f in final){
          finalObj[f]=final[f];
        }
      });
      if (finalObjParsed.length===0){
        finalObj['All'] = 'Total';
      }
      for (const r in val){
        finalObj[r]=val[r];
      }
      final.push(finalObj);
    }
    return final;
  }
  __calculateFields(data, definition){
    const that = this;
    definition.forEach((item)=>{
      if (item.isCalculated && item.isCalculated==='true' && item.calculatedField){
        data = data.map(function(dataItem){
          const value = that.__expressionEval(item.calculatedField, dataItem);
          dataItem[item.name]= value;
          return dataItem;
        });
      }
    });
    return data;
  }

  __calculateData(data, dimensions, metrics){
    data = this.__calculateFields(data, dimensions);
    data = this.__calculateFields(data, metrics);
    return data;
  }
  reduce(data, dimensions, metrics){
    if ((!dimensions || !dimensions.length) && (!metrics || !metrics.length)){
      return data;
    }
    const calculatedData = this.__calculateData(data, dimensions, metrics);
    const grouped = this.__groupBy(calculatedData, dimensions);
    const summarized = this.__summarize(grouped, metrics);
    const normalized = this.__normalize(summarized);
    return normalized;
  }
}

export default DatasetReduce;
