var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: _vm.count }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("schema-form", {
            attrs: { form: _vm.form, schema: _vm.schema },
            model: {
              value: _vm.dashboardModelName,
              callback: function ($$v) {
                _vm.dashboardModelName = $$v
              },
              expression: "dashboardModelName",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.render
            ? _c(_vm.selectedDashboardType, {
                ref: "dashboardContainer",
                tag: "component",
                attrs: {
                  "dashboard-name": _vm.dashboardName,
                  "include-widgets": _vm.includeWidgets,
                  "crud-service-name": "http",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("debug-event-log", {
            attrs: {
              "dashboard-name": _vm.dashboardName,
              "dashboard-container-reference": _vm.$refs.dashboardContainer,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }