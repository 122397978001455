<template>
  <div class="chart-builder-properties-container">
    <ul class="list-group list-unstyled card">
      <li clas="list-group-item ">
        <div class="group p-2">
          <span>Dimensions</span>
          <ul class="list-group">
            <draggable :group="{ name: 'properties', pull: 'clone', put: false }"
                       :move="onMove" :list="dimensions" :clone="cloneDimensions"
                       class="list-group" id="draggablePropertiesDimensions" tag="ul">
              <li :key="property.name" class="list-group-item" v-for="property in dimensions">
                <div>
                    <span class="mr-2  text-muted" style="font-size: 0.7rem;">
                      <i class="fas fa-circle-notch"></i>
                    </span>
                  <span>{{property.title}}</span>
                </div>
              </li>
            </draggable>
          </ul>
        </div>
      </li>
      <li clas="list-group-item ">
        <div class="group p-2">
          <span>Metrics</span>
          <ul class="list-group">
            <draggable :group="{ name: 'properties', pull: 'clone', put: false }"
                       :move="onMove" :list="metrics" :clone="cloneMetrics"
                       class="list-group" id="draggablePropertiesMetrics" tag="ul">
              <li :key="property.name" class="list-group-item" v-for="property in metrics">
                <div>
                    <span class="mr-2  text-muted" style="font-size: 0.7rem;">
                      <i class="fas fa-circle-notch"></i>
                    </span>
                  <span>{{property.title}}</span>
                </div>
              </li>
            </draggable>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  import draggable from 'vuedraggable';

  export default {
    name: "Properties",
    props: ['value'],
    components:{draggable},
    data: function () {
      return {
        dimensions: [],
        metrics: [],
        properties: undefined
      }
    },
    watch: {
      value: function (newVal, oldVal) {
        console.log('props changed for Properties', newVal);
            this.setProperties(newVal);
      }
    },
    created() {
      console.log('vakye prop', this.value);
      if (this.value && this.value.length) {
        this.setProperties(this.value);
      }
    },
    methods: {
      cloneDimensions: function(event){
        console.log('Clone dim', event);
        const newEvent = cloneDeep(event);
        newEvent.guid=this.generateUUID();
        newEvent.originalTitle=newEvent.title;
        return newEvent;
      },
      cloneMetrics: function(event){
        console.log('Clone metrics', event);
        const newEvent = cloneDeep(event);
        newEvent.guid=this.generateUUID();
        newEvent.originalTitle=newEvent.title;

        let metricTypeName = newEvent.metricType.toLowerCase();
        metricTypeName = metricTypeName[0].toUpperCase() + newEvent.metricType.substring(1).toLowerCase();
        newEvent.title = metricTypeName + '_' + newEvent.originalTitle;
        console.log('updated metric type and title', newEvent, newEvent.metricType);
       // return newEvent.metricType + '(' + property.originalTitle + ')';
        return newEvent;

      },
      setProperties: function(props){
        this.properties = props;
        const grouped = this.groupBy('type');
        if (grouped){
          this.dimensions=grouped.dimension;
          this.metrics = grouped.metric;
        }
      },
      onMove: function ({relatedContext, draggedContext}) {
        //console.log('onMove', relatedContext.component.$el.id, relatedContext, draggedContext);
        return relatedContext.component.$el.id !== 'draggablePropertiesMetrics' && relatedContext.component.$el.id !== 'draggablePropertiesDimensions';
      },
      groupBy: function(propName){
        if (this.properties){
          const groups = {};
          this.properties.forEach(function(p){
            const val = p[propName];
            let group = groups[val];
            if (!group){
              group = [];
            }
            group.push(p);
            groups[val] = group;
          });
          return groups;
        }
      },
      generateUUID: function () {
        let d = new Date().getTime();
        if (window.performance && typeof window.performance.now === "function") {
          d += performance.now(); //use high-precision timer if available
        }
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          let r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
      },
    }
  }
</script>

<style scoped>

</style>
