<template>
<div>
  <flow-designer></flow-designer>
</div>
</template>

<script>

export default {
  name: "FlowGenericDiagram"
}
</script>

<style scoped>

</style>
