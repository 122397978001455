<template>
  <div class="pl-4 pr-4">
    <schema-form class="form-inline" :form="form" :schema="schema" @validationResult="onValidationResult" v-model="formModel"/>
  </div>
</template>

<script>
  import componentMixin from '../../../componentMixin';
  export default {
    name: "VueDfAppNavbarFormItem",
    props: {parent: Object},
    mixins:[componentMixin],
    data() {
      return {
        formModel: {}
      }
    },
    computed: {
      formSettings: function () {
        return this.config.itemFormSettings || {};
      },
      schemaForm: function () {
        return this.formSettings.schemaForm || {};
      },
      schema: function () {
        return this.schemaForm.schema;
      },
      form: function () {
        return this.schemaForm.form;
      }
    },
    methods: {
      onValidationResult: function (event) {
        console.log('Event in form', event);
      }
    }
  }
</script>

<style scoped>

</style>
