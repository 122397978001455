import DatasetChart from './base/DatasetChart'
import BaseChart from "./base/BaseChart";

class HeatMapChart extends BaseChart {
  constructor() {
    super('HeatMapChart');
  }

  getVisualMap() {
    const maxMin = this.getMinMaxLastPropSource();

    const visualMap = {
      min: maxMin.min!=undefined?maxMin.min:-100,
      max: maxMin.max!=undefined?maxMin.max: 100,
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      bottom: '15%'
    };
    return visualMap;
  }

  getSeries() {
    const series = {
      type: 'heatmap',
      label: {
        show: true
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    };

    return [series];
  }

  updateDataset(chartDefinition){
    chartDefinition.dataset = [{
      source: this.dataset
    }]
  }

  getMinMaxLastPropSource(){
    if (this.dataset && this.dataset.length && this.dataset[0]){
      const item = this.dataset[0];
      const lastKey = Object.keys(item)[Object.keys(item).length-1];

      let lowest = Number.POSITIVE_INFINITY;
      let highest = Number.NEGATIVE_INFINITY;

      let tmp;
      for (let i=this.dataset.length-1; i>=0; i--) {
        tmp = this.dataset[i][lastKey];
        if (tmp < lowest) lowest = tmp;
        if (tmp > highest) highest = tmp;
      }

      return {
        min: lowest,
        max: highest
      }
    }
  }

  getChartDefinition() {
    const baseChart = super.getChartDefinition();

    baseChart.visualMap = this.getVisualMap();
    baseChart.series = this.getSeries();

    baseChart.grid = {
      height: '50%',
      top: '10%'
    }
    baseChart.xAxis = {
      type: "category",
      splitArea: {
        show: true
      }
    }

    baseChart.yAxis = {
      type: 'category',
      splitArea: {
        show: true
      }
    }

    this.updateDataset(baseChart);

    return baseChart;
  }

}


export default HeatMapChart
