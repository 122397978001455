<template>
  <div>
    <div class="p-3">
      <h5>Generate and analyze data</h5>
      <div class="nav btn-group">
        <div class="nav-item">
          <button class="btn btn-primary" @click="refreshData">Generate</button>
        </div>
      </div>
    </div>
    <hr />
    <div class="container-fluid">
      <div class="row">
        <div class="col-4 p-2">
          <div class="card">
            <h6 class="label p-2">Generated Data</h6>
            <draggable-json-tree :is-draggable=false :data="generatedData" :level=1></draggable-json-tree>
          </div>
        </div>
        <div class="col-4 p-2">
          <div class="card">
            <h6 class="label p-2">Analyzed Data</h6>
            <draggable-json-tree :is-draggable=false :data="analyzedData" :level=1></draggable-json-tree>
          </div>
        </div>
        <div class="col-4 p-2">
          <div class="card">
            <h6 class="label p-2">Grouped Data</h6>
            <draggable-json-tree :is-draggable=false :data="groupedDate" :level=1></draggable-json-tree>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const {generateJson} = require('json-generator');
import DraggableJsonTree from "vue-json-schema-form/src/components/draggableJsonTree/DraggableJsonTree";
// https://github.com/ninelhodzic/json-generator
import jsonObjectAnalyzer  from "./jsonObjectAnalyzer";

export default {
  name: "DataChartGenerator",
  components: {DraggableJsonTree},
  data() {
    return {
      groupedDate:{},
      analyzedData: {},
      generatedData: [],
      jsonSchema: {
        id: "id;objectId",
        children: [
          5,
          {
            name: "fullName",
            age: "int;0;10"
          }
        ],
        currentJob: {
          title: 'random;["developer", "medic", "teacher", "CEO"]',
          salary: "money;$ #.###,##"
        },
        jobs: [
          2,
          {
            title: 'random;["developer", "medic", "teacher", "CEO"]',
            salary: "money;$ #.###,##"
          }
        ],
        maxRunDistance: "float;1;20;1",
        cpf: "cpf",
        cnpj: "cnpj",
        pretendSalary: "money;$ #.###,##",
        age: "int;20;80",
        gender: "gender",
        firstName: "firstName",
        lastName: "lastName",
        phone: "maskInt;+55 (83) 9####-####",
        address: "address",
        hairColor: "color",
        year: "int;1970;2022",
        month: "int;1;12",
        hour: "int;0;23",
        timestamp: "int;1437747281000;1637747281000",
        location: {
          lat: "maskInt;##.##",
          lon: "maskInt;##.##"
        }
      }
    }
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    analyzeData() {
      this.analyzedData = jsonObjectAnalyzer.analyzeDimensionsAndMetrics(this.generatedData);
    },
    refreshData() {
      this.generatedData = this.generateArray(50, 60);
      this.analyzeData();
    },
    generateArray(from = 0, to = 10) {
      const rndInt = Math.floor(Math.random() * to) + from
      const that = this;
      const res = [];
      for (let i = 0; i < rndInt; i++) {
        const generatedVal = generateJson(that.jsonSchema);
        res.push(generatedVal);
      }

      return res;
    }
  }
}
</script>

<style scoped>

</style>
