import { render, staticRenderFns } from "./ServiceTask.vue?vue&type=template&id=f27b2b6c&scoped=true&"
import script from "./ServiceTask.vue?vue&type=script&lang=js&"
export * from "./ServiceTask.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f27b2b6c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f27b2b6c')) {
      api.createRecord('f27b2b6c', component.options)
    } else {
      api.reload('f27b2b6c', component.options)
    }
    module.hot.accept("./ServiceTask.vue?vue&type=template&id=f27b2b6c&scoped=true&", function () {
      api.rerender('f27b2b6c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/vue-diagram/src/bpmn/panel/components/tasks/ServiceTask.vue"
export default component.exports