var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "properties-panel",
      class: { active: _vm.detailsConf.isOpen },
    },
    [
      _c(
        "span",
        {
          staticClass: "specification-toggle-button",
          attrs: { title: "Show Details" },
          on: { click: _vm.specificationToggle },
        },
        [_c("i", { staticClass: "fas fa-cog" })]
      ),
      _vm.detailsConf.isOpen
        ? _c(
            "div",
            { staticClass: "specification-wrapper" },
            [
              _vm.element
                ? _c(_vm.getComponent, {
                    tag: "component",
                    attrs: { element: _vm.element, modeler: _vm.modeler },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }