class BaseChart {
  constructor(chartType) {
    this.chartType = chartType;
    this.chartDefinition = {
      legend: {},
      tooltip: {},
      grid: {containLabel: true},
      xAxis: {type: 'category', boundaryGap: true},
      yAxis: {}
    }
  }

  setClientArea(clientArea){
    this.clientArea = clientArea;
  }

  autoFontSize(scaleVal) {
    let width = this.clientArea.offsetWidth;
    let ratio = this.clientArea.offsetWidth/this.clientArea.offsetHeight;
    console.log(width, ratio);
    if (width>=500){
      return scaleVal;
    }else {
      let size = ratio * scaleVal/1.5; //(width>=700) ? val : '' + width / 11;
      return size;
    }
  };

  setData(dataset) {
    this.dataset = dataset;
  }

  setDimensionMetrics(dimensionMetrics) {
    if (dimensionMetrics) {
      this.dimensions = dimensionMetrics.dimensions;
      this.metrics = dimensionMetrics.metrics;
    }
  }

  setChartTypeConfig(chartTypeConfig) {
    this.chartTypeConfig = chartTypeConfig;
  }

  getChartConfigData(){
    return this.chartTypeConfig.context.data;
  }

  getConfigForm() {
    return {
      schema: {},
      form: []
    }
  }

  updateAxis(chartDefinition) {
    console.log('Update Axis', this.chartTypeConfig);
    const xAxis = chartDefinition.xAxis;
    const yAxis = chartDefinition.yAxis;
    if (this.getChartConfigData().columnBar) {

      chartDefinition.yAxis = xAxis; //{type: 'category', boundaryGap: true};
      chartDefinition.xAxis = yAxis //{}
    } else {
      chartDefinition.xAxis = xAxis;// {type: 'category', boundaryGap: true};
      chartDefinition.yAxis = yAxis;//{}
    }
  }

  getChartDefinition() {
    this.updateAxis(this.chartDefinition)
    return this.chartDefinition;
  }
}

export default BaseChart
