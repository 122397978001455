var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row", class: _vm.row.styleClass, style: _vm.row.style },
    _vm._l(_vm.row.columns, function (column, index) {
      return _c("vue-df-dashboard-column", {
        key: index,
        attrs: {
          id: _vm.getId(),
          model: _vm.model,
          "edit-mode": _vm.editMode,
          dashid: _vm.dashid,
          "continuous-edit-mode": _vm.continuousEditMode,
          "dashboard-name": _vm.dashboardName,
          "input-context": _vm.inputContext,
          "dashboard-service-instance": _vm.dashboardServiceInstance,
          options: _vm.options,
          permissions: _vm.permissions,
          "crud-service-name": _vm.crudServiceName,
          "default-mapper": _vm.defaultMapper,
          "parent-widget-id": _vm.parentWidgetId,
          "parent-dashid": _vm.parentDashid,
          column: column,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }