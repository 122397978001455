var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c(
        "div",
        {
          ref: "toggle" + _vm.localId_,
          staticClass: "card-header",
          class: { collapsed: !_vm.active },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.onItemHeadClick.apply(null, arguments)
            },
          },
        },
        [_vm._t("header")],
        2
      ),
      _c(
        "b-collapse",
        {
          ref: "collapse" + _vm.localId_,
          attrs: {
            accordion: _vm.accordion,
            id: _vm.localId_,
            visible: _vm.visible,
          },
        },
        [
          _c(
            "div",
            { staticClass: "collapse show width", style: _vm.getStyles },
            [_c("div", { staticClass: "card-body" }, [_vm._t("default")], 2)]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }