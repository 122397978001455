<template>
  <div class="applications-container">
    <div class="row">
      <div class="col-4">
        <div class="card">
          <div class="card-header">
            <p>Applications</p>
          </div>
          <div class="card-body">
            <ul class="list-unstyled">
              <li v-for="app in applications">
                <div>
                  <h4>{{app.tenantName}} - {{app.applicationName}}</h4>
                </div>
                <div class="">
                  <p>Application routes</p>
                  <hr />
                  <ul class="list-unstyled">
                    <li v-for="route in app.routes">
                      <router-link :to="{ name: route.routeKey.split('=').join('/') }">{{route.item.name}}</router-link>
                      <div><pre class="text-muted small">{{ route }}</pre></div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "ApplicationsView",
    data(){
      return {
        appServiceName: 'vueDfApplicationService',
        applications: []
      }
    },
    created() {
      const that = this;
   //   console.log('APP CREATED - here we should load routes');
      this.$services[this.appServiceName].loadAllRoutes();

      this.$dashboardBus.$on('APPLICATIONS_LOADED', that.onApplicationsLoaded);
    },
    beforeDestroy(){
      const that = this;
      this.$dashboardBus.$off('APPLICATIONS_LOADED', that.onApplicationsLoaded);
    },
    methods:{
      onApplicationsLoaded: function(applications){

        const apps = this.$services[this.appServiceName].getApplications();
        console.log('Current Applications', apps);
        this.applications = Object.assign({}, apps);

      }
    }
  }
</script>

<style scoped>

</style>
