<template>
  <div>
    <b-modal @show="clear()" hide-header-close lazy no-close-on-backdrop no-close-on-esc ref="editItemModal" size="xl" @ok="onOk"
             title="Edit Item">
      <div>
        <schema-form @validationResult="onValidationResult" :form="schemaForm.form"
                     :schema="schemaForm.schema" v-model="model"></schema-form>
      </div>
    </b-modal>

  </div>
</template>

<script>
  import {BModal} from 'bootstrap-vue'
  export default {
    name: "VueKanbanItemEdit",
    props: ['schemaForm'],
    components: {BModal},
    data() {
      return {
        model: {},

      }
    },
    mounted() {
    },
    methods: {
      clear: function(){
        this.model={};
      },
      onValidationResult: function (event) {
        if (event.valid) {
          //this.$emit('onItemEditSubmit', this.model);
        }
      },
      onOk: function(){
        this.$emit('onItemEditSubmit', this.model);
      }
    }
  }
</script>

<style scoped>

</style>
