<template>
  <div class="scrapper-wrapper">

    <div class="row">
      <div class="col-12">
        <schema-form :schema="schema" :form="form" v-model="model" @validationResult="onValidation"></schema-form>
      </div>
    </div>
    <div class="row" >
      <vue-element-loading :active.sync="isLoading"
                           :can-cancel="false"
                           is-full-page="false"></vue-element-loading>
      <div class="col-12">
        <div id="operating-table">
          <iframe id="dissectionSite" :src="frameSrc" class="hidden-iframe"
                  sandbox="allow-same-origin allow-scripts" @load="onLoadedFrame"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'src/assets/css_old/scraper.css'

export default {
  name: "ScraperComponent",
  data() {
    return {
      schema: {
        type: 'object',
        properties: {
          url: {
            type: 'string',
            title: 'Url',
            description: 'Enter url to scrape'
          }
        }
      },
      form: [
        {
          key: 'url',
        },
        {
          type: 'actions',
          items: [
            {
              type: 'submit',
              value: 'Load'
            }
          ]
        }
      ],
      model: {url: 'https://www.saff.ba/'},
      frameSrc: null,
      load: null,
      isLoading: true,
      reload: true
    }
  },
  mounted() {
    let jquery = document.createElement('script');
    jquery.setAttribute('src', '/scripts/jquery.js')
    document.head.appendChild(jquery);

    const that = this;
    setTimeout(function(){
      that.loadSetup();
    }, 1000);
  },
  methods: {
    loadSetup: function(){
      const that = this;
      let scraper = document.createElement('script');
      scraper.setAttribute('src', '/scripts/scraper.js')
      document.head.appendChild(scraper);

      let generatorData = document.createElement('script');
      generatorData.type = 'text/javascript';
      const code = 'var generatorData = {"url":"https:\/\/www.saff.ba\/","rssId":null,"selectors":{"news":"","title":"","content":"","date":"","author":"","link":"","pic":""},"options":[],"copySelectors":[],"token":"571567804774.22134"};';
      try {
        generatorData.appendChild(document.createTextNode(code));
        document.body.appendChild(generatorData);
      } catch (e) {
        generatorData.text = code;
        document.body.appendChild(generatorData);
      }
      that.onValidation({});
    },
    onValidation: function (event) {
      console.log(event, this.model);
      //url: /#/fetcher?url=https://www.klix.ba/
      this.load = false;
      const that = this;
     /* this.reload = false;
      this.isLoading = true;*/
      if (this.model.url) {
        this.frameSrc = 'fetcher?url=' + this.model.url;
      }
      setTimeout(function(){
        that.isLoading = false;
        that.reload = false;
      },1000);
    },
    onLoadedFrame: function(){
      console.log('Frame loaded');
    }
  }
}
</script>

<style scoped>

</style>
