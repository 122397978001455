import inherits from 'inherits'

import ReplaceMenuProvider from 'bpmn-js/lib/features/popup-menu/ReplaceMenuProvider';

inherits(CustomReplaceMenuProvider, ReplaceMenuProvider);

CustomReplaceMenuProvider.$inject = ['injector', 'popupMenu', 'modeling', 'moddle', 'bpmnReplace', 'rules', 'translate'];

const listToFilter = [
  'replace-with-none-intermediate-throwing', 'replace-with-conditional-start',
  'replace-with-complex-gateway', 'replace-with-event-based-gateway',
  'replace-with-rule-task'
];

const loopToFilter = []

export default function CustomReplaceMenuProvider(injector, popupMenu, modeling, moddle, bpmnReplace, rules, translate) {
  injector.invoke(ReplaceMenuProvider, this);

  CustomReplaceMenuProvider.prototype._createEntries = function (element, replaceOptions) {
    let options = ReplaceMenuProvider.prototype._createEntries.call(this, element, replaceOptions);
    options = options.filter(function (option) {
     // console.log('Removing option menu', option.id);
      return listToFilter.indexOf(option.id) === -1;
    });
    return options;
  }

  CustomReplaceMenuProvider.prototype._getLoopEntries = function (element){
    let options = ReplaceMenuProvider.prototype._getLoopEntries.call(this, element);
    options = options.filter(function (option) {
     // console.log('Removing Loop option menu', option.id, option);
      return loopToFilter.indexOf(option.id) === -1;
    });
    return options;
  }
}
