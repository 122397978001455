var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "diagram-editor-content-wrapper" },
    [
      _c(
        "draggable",
        {
          staticClass: "droppable-container",
          attrs: {
            tag: "div",
            move: _vm.onMove,
            list: _vm.elements,
            group: { name: "workflow", pull: false, put: true },
          },
          on: { add: _vm.onAdd, change: _vm.onChange, end: _vm.onEnd },
        },
        [
          _c(
            "div",
            {
              ref: "screenContainer",
              staticClass: "screen-container",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.screenSelect()
                },
              },
            },
            [
              _c("div", [_vm._v(_vm._s(_vm.coords))]),
              _c(
                "screen",
                { ref: "screen" },
                [
                  _vm.drawLineItem
                    ? _c("line", {
                        attrs: {
                          x1: _vm.drawLineItem.x1,
                          y1: _vm.drawLineItem.y1,
                          x2: _vm.drawLineItem.x2,
                          y2: _vm.drawLineItem.y2,
                          stroke: "black",
                        },
                      })
                    : _vm._e(),
                  _vm._l(_vm.graph.edges, function (edge) {
                    return _c(
                      "g",
                      {
                        key: edge.id,
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.select(edge)
                          },
                        },
                      },
                      [
                        _c("edge", {
                          class:
                            _vm.selection &&
                            _vm.selection.id === edge.id &&
                            "selected",
                          attrs: { data: edge, nodes: _vm.graph.nodes },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.graph.nodes, function (node) {
                    return _c(
                      "g",
                      {
                        key: node.id,
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.select(node)
                          },
                        },
                      },
                      [
                        _c(
                          "node",
                          {
                            ref: "node",
                            refInFor: true,
                            class:
                              _vm.selection &&
                              _vm.selection.id === node.id &&
                              "selected",
                            attrs: { data: node },
                            on: { onstopdrag: _vm.onStopDrag },
                          },
                          [
                            _c("node-type-component", {
                              attrs: { node: node },
                            }),
                            _vm.selection && _vm.selection.id === node.id
                              ? _c("element-conf-popup", {
                                  attrs: { selection: _vm.selection },
                                  on: { ontoolclicked: _vm.onToolClicked },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }