var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "data-normalizer-wrapper p-3" }, [
    _c("label", [_vm._v("Data Normalizer")]),
    _c("hr", { staticClass: "m-0" }),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "raw-data-container p-2" },
          [
            _c("label", [_vm._v("Raw Data")]),
            _c("draggable-json-tree", {
              attrs: { "is-draggable": true, data: _vm.rawData, level: 3 },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "col-9" }, [
        _c("div", { staticClass: "selected-fields-wrapper p-2" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("div", [
              _c("label", [_vm._v("Field Mapper")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.transformLevel,
                    expression: "transformLevel",
                  },
                ],
                attrs: { type: "number" },
                domProps: { value: _vm.transformLevel },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.transformLevel = $event.target.value
                  },
                },
              }),
              _vm._v(" Level\n          "),
            ]),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  on: {
                    click: function ($event) {
                      return _vm.applyData()
                    },
                  },
                },
                [_vm._v("Apply")]
              ),
            ]),
          ]),
          _c(
            "div",
            [
              _c(
                "draggable",
                {
                  staticClass: "list-group list-unstyled list-group-horizontal",
                  attrs: {
                    group: { name: "dataFields", pull: true, put: true },
                    list: _vm.selectedFields,
                    move: _vm.onMove,
                    tag: "ul",
                  },
                  on: { add: _vm.onAdd, change: _vm.onChange, end: _vm.onEnd },
                },
                [
                  !_vm.selectedFields.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "drop-zone text-muted d-flex justify-content-center",
                        },
                        [_c("span", [_vm._v("Drop Zone")])]
                      )
                    : _vm._e(),
                  _vm._l(_vm.mappedFields, function (val, prop) {
                    return _c("li", { staticClass: "list-group-item p-1" }, [
                      _c("div", { staticClass: "card1" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-body1",
                            attrs: { title: JSON.stringify(val) },
                          },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removeField(prop, val)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-times" })]
                            ),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", {}, [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(prop) +
                                    "\n                    "
                                ),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: val.newName,
                                    expression: "val.newName",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: val.newName },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      val,
                                      "newName",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "selected-fields-expression p-2" }, [
          _c("pre", [_vm._v(_vm._s(_vm.expression) + "\n          ")]),
        ]),
        _c(
          "div",
          { staticClass: "selected-fields-data-result p-2" },
          [
            _c("draggable-json-tree", {
              attrs: {
                "is-draggable": false,
                data: _vm.transformedData,
                level: 5,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }