<template>

  <div class="card1 properties-panel-container">
    <div class="card1-header">
      <h6 class="card1-title">Properties Editor</h6>
      <small>
        <p class="text-muted card-category">Properties related to the Chart.</p>
      </small>
      <hr/>
    </div>
    <div class="card1-body">
      <schema-form :form="form" :schema="schema" @validationResult="onValidationResult"
                   v-model="dataModel"></schema-form>
    </div>
    <div class="card1-footer">
    </div>
  </div>
</template>

<script>
  export default {
    name: "ChartDesignerPropertiesPanel",
    props: {
      propertiesModel: Object
    },
    data() {
      return {
        schema: {},
        form: [],
        dataModel: {}
      }
    },
    methods: {
      onValidationResult: function (event) {
        if (event.valid) {

        }
      }
    }
  }
</script>

<style scoped>
  .properties-panel-container {
    min-width: 200px;
  }
</style>
