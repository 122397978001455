<template>
  <div class="custom-widget-editor">
    <div class="card">
      <div class="card-header">
        <h6>Widgets</h6>
      </div>
      <div class="card-body ">
        <ul class="list-unstyled list-inline list">
          <li class="ml-2 list-item p-2 border border-radius-large" v-for="w in customWidgets">
            <div class="item-container ">
              <div class="item content">
                <div class="">
                  <label class="font-weight-bold">
                    {{w.widget.name}}
                  </label>
                </div>


              </div>
              <div class="item card-header1 button-header">
                <div class="toolbar btn-group btn-group">
                  <a @click.prevent="clickToEdit(w)" class="btn btn-sm btn-success" href><small><i
                    class="fas fa-cog"></i></small></a>
                  <a @click.prevent="clickToRemove(w)" class="btn btn-sm btn-danger" href><small><i
                    class="fas fa-times"></i></small></a>
                </div>
              </div>
            </div>
            <small><span class="font-weight-bold">Category:</span> {{w.widget.category}}</small>
          </li>
        </ul>
      </div>
    </div>
    <vue-df-dashboard-widget-save-as :dashboard-name="dashboardName" :crud-service-name="crudServiceName" :definition="widgetDefinition" :is-edit="true" :widget="widgetDefinition"
                                     ref="saveAsWidget">
    </vue-df-dashboard-widget-save-as>
  </div>
</template>

<script>
  import swal from "sweetalert2";

  const VueDfDashboardWidgetSaveAs = () => import('./VueDfDashboardWidgetSaveAs');
  export default {
    name: "VueDfDashboardEditCustomWidget",
    props: ['widgets', 'crudServiceName', 'dashboardName'],
    components: {VueDfDashboardWidgetSaveAs},
    data() {
      return {
        customWidgets: [],
        selectedWidgetDefinition: null
      }
    },
    created() {
      this.loadCustomWidgets();
    },
    computed: {
      widgetDefinition() {
        return this.selectedWidgetDefinition ? this.selectedWidgetDefinition.widget : {};
      }
    },
    methods: {
      clickToRemove(widget) {
        const that = this;
        swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover the item!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
          heightAuto: false
        }).then((result) => {
          if (result.value) {
            console.log('result', result);
            that.$refs.saveAsWidget.deleteWidget(widget, function (result) {
              that.loadCustomWidgets();
            });
          }
        });
      },
      clickToEdit(widget) {
        this.selectedWidgetDefinition = widget;
        const that = this;
        this.$nextTick(function () {
          that.$refs.saveAsWidget.saveAs();
        });

      },
      loadCustomWidgets() {
        const that = this;
        this.$services[this.crudServiceName].loadWidgets(null, function (widgets) {
          console.log('loaded custom widgets', widgets);
          if (widgets) {
            that.customWidgets = widgets;
          }
        });
      }
    }
  }
</script>

<style scoped>

  .button-header .btn {
    padding: 0px 7px 0px 7px;
  }

  .item-container {
    display: flex;
    justify-content: flex-end;
    min-width: 150px;
  }

  .item {
    flex: 1;
  }

  .button-header {
    text-align: end;
  }

</style>
