import { render, staticRenderFns } from "./VueDfAppTopNavbar.vue?vue&type=template&id=21a0cf68&scoped=true&"
import script from "./VueDfAppTopNavbar.vue?vue&type=script&lang=js&"
export * from "./VueDfAppTopNavbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a0cf68",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21a0cf68')) {
      api.createRecord('21a0cf68', component.options)
    } else {
      api.reload('21a0cf68', component.options)
    }
    module.hot.accept("./VueDfAppTopNavbar.vue?vue&type=template&id=21a0cf68&scoped=true&", function () {
      api.rerender('21a0cf68', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/vue-dashboard-framework-app-components/src/components/VueDfApp/components/UIComponents/Navbar/VueDfAppTopNavbar.vue"
export default component.exports