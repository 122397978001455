<template>
  <div class="card">
    <div :class="{collapsed: !active}" :ref="'toggle'+localId_" @click.prevent="onItemHeadClick" class="card-header">
      <slot name="header"/>
    </div>
    <b-collapse :accordion="accordion" :id="localId_" :ref="'collapse'+localId_" :visible="visible">
      <div :style="getStyles" class="collapse show width">
        <div class="card-body">
          <slot/>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
  import idMixin from "./idMixin";
  import {BCollapse} from 'bootstrap-vue'
  import {VBToggle} from 'bootstrap-vue'
  import {CollapseTransition, FadeTransition} from 'vue2-transitions';
  import VueResizable from 'vue-resizable'

  export default {
    name: "AccordionItem",
    mixins: [idMixin],
    components: {BCollapse, CollapseTransition, FadeTransition, VueResizable},
    directives: {'b-toggle': VBToggle},
    inject: {
      vAccordion: {
        default: () => ({})
      }
    },
    props: {
      width: {},
      accordion: {},
      visible: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      console.log('Accordion Item mounted');
      this.registerItem();
    },
    destroyed() {
      this.unregisterItem();
    },
    data() {
      return {
        localActive: this.active && !this.disabled,
        show: false
      }
    },
    computed: {
      _isItem() {
        // For parent sniffing of child
        return true
      }
    },
    methods: {
      getStyles() {
        return {};
      },
      onItemHeadClick() {
        const collapseRef = this.$refs['collapse' + this.localId_];
        const toggleRef = this.$refs['toggle' + this.localId_];

        collapseRef.toggle();

        console.log('Item Head click', collapseRef, toggleRef);

        this.vAccordion.onItemHeadClick && this.vAccordion.onItemHeadClick(this);
      },
      registerItem() {
        this.vAccordion.registerItem && this.vAccordion.registerItem(this)
      },
      unregisterItem() {
        this.vAccordion.unregisterItem && this.vAccordion.unregisterItem(this)
      },
      activate() {
        if (this.vAccordion.activateItem && !this.disabled) {
          return this.vAccordion.activateItem(this)
        } else {
          // Not inside a <vertical-accordion> component or item is disabled
          return false
        }
      },
      deactivate() {
        if (this.vAccordion.deactivateItem && this.localActive) {
          return this.vAccordion.deactivateItem(this)
        } else {
          // Not inside a <vertical-accordion> component or not active to begin with
          return false
        }
      }
    },
    watch: {
      localActive(newVal) {
        // Make 'active' prop work with `.sync` modifier
        this.$emit('update:active', newVal)
      },
      active(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal) {
            // If activated post mount
            this.activate()
          } else {
            /* istanbul ignore next */
            if (!this.deactivate()) {
              // Tab couldn't be deactivated, so we reset the synced active prop
              // Deactivation will fail if no other tabs to activate
              this.$emit('update:active', this.localActive)
            }
          }
        }
      },
      disabled(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal && this.localActive && this.vAccordion.firstItem) {
            this.localActive = false
            this.vAccordion.firstItem()
          }
        }
      }
    },
  }
</script>

<style scoped>
  .card-body.full {
    width: 100%;
  }

  .card-header {
    min-height: 400px;
  }
</style>
