var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "conf-popup",
      style: { top: _vm.top + "px", left: _vm.left + "px" },
    },
    _vm._l(_vm.conf, function (group) {
      return _c(
        "div",
        { staticClass: "group" },
        _vm._l(group.tools, function (item) {
          return _c("div", { staticClass: "group-item" }, [
            _c("span", {
              class: item.class,
              attrs: { title: item.title },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.confItemAction(item)
                },
              },
            }),
          ])
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }