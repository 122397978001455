import DatasetChart from './base/DatasetChart'

class PieChart extends DatasetChart {
  constructor() {
    super('PieChart');
  }

  updateSeries(config) {
    const data = this.getChartConfigData();
    if (config.series && config.series.length) {
      config.series.forEach((series) => {
        if (data.pieType) {
          switch (data.pieType) {
            case 'Doughnut':
              series.radius = ['40%', '70%'];
              series.avoidLabelOverlap = false;
              series.label = {
                show: false,
                position: 'center'
              };
              series.emphasis = {
                label: {
                  show: true,
                  fontSize: '40',
                  fontWeight: 'bold'
                }
              }
              series.labelLine = {
                show: false
              }
              break;
            case 'Rose':
              series.roseType = 'area';
              series.radius= [20, 150];
              break;
          }
        }
      });
    }
  }

  getChartDefinition() {
    let baseChart = super.getChartDefinition();
    delete baseChart.xAxis
    delete baseChart.yAxis;
    return baseChart;
  }
}


export default PieChart
