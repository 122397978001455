<template>
  <div class="panel task-panel">
    Task Panel - {{ elementType }}
    <component :is="taskComponent" :modeler="modeler" :element="element"></component>
  </div>
</template>

<script>
import ReceiveTask from "vue-diagram/src/bpmn/panel/components/tasks/ReceiveTask";
import UserTask from "vue-diagram/src/bpmn/panel/components/tasks/UserTask";
import ServiceTask from "vue-diagram/src/bpmn/panel/components/tasks/ServiceTask";
import SendTask from "vue-diagram/src/bpmn/panel/components/tasks/SendTask";
import Task from 'vue-diagram/src/bpmn/panel/components/tasks/Task'


import PanelMixin from './mixins/panel.mixin'
export default {
  name: "TaskPanel",
  mixins:[PanelMixin],
  components:{ReceiveTask,Task,SendTask,ServiceTask,UserTask},
  computed:{
    taskComponent(){
      if (['ReceiveTask', 'SendTask','ServiceTask','UserTask'].includes(this.elementType)){
        return this.elementType
      }else{
        return 'Task'
      }
    }
  }
}
</script>

<style scoped>

</style>
