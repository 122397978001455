<template>
  <div ref="container" class="containers">

    <div ref="canvas" class="canvas"></div>
<!--    <bpmn-editor-panel v-if="modeler" id="properties-panel-parent" class="properties-panel-parent"
                         :options="panelOptions" :modeler="modeler"></bpmn-editor-panel>-->
    <div id="properties-panel-parent" class="properties-panel-parent"></div>
  </div>
</template>

<script>
import EtlModeler from "vue-diagram/src/bpmn/components/modeler";
import CustomTranslate from './components/translate'
import camundaModdleDescriptor from "camunda-bpmn-moddle/resources/camunda";
import propertiesPanelModule from 'bpmn-js-properties-panel';
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda';
import camundaExtensionModule  from 'camunda-bpmn-moddle/lib';
import minimapModule from "diagram-js-minimap";
import CliModule from 'bpmn-js-cli';
import {debounce} from "min-dash";
import BpmnEditorPanel from "vue-diagram/src/bpmn/panel/BpmnEditorPanel";
import flowableModdle from './flowable/flowable.json'
import etlExtensionModdle from './flowable/etlextension.json'

let customTranslateModule = {
  translate: ["value", CustomTranslate]
};

export default {
  name: "BpmnEditor",
  components: {BpmnEditorPanel},
  props: {
    value: String,
    panelOptions: Object
  },
  data() {
    return {
      modeler: null,
      xmlData: "",
      isSvg: false,
    };
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal !== this.xmlData) {
          this.openDiagram(newVal);
        }
      }
    }
  },
  async mounted() {
    const that = this;
    let canvas = this.$refs["canvas"];
    let additionalModules = [
      customTranslateModule,
      /*CliModule,
      propertiesPanelModule,
      propertiesProviderModule,*/
      propertiesPanelModule,
      propertiesProviderModule,
      camundaExtensionModule
    ]
    /*if (this.propertiesPanel === '' || this.propertiesPanel) {
      additionalModules = additionalModules.concat([
        propertiesPanelModule,
        propertiesProviderModule
      ])
    }*/
    /*camunda: camundaModdleDescriptor*/
    this.modeler = new EtlModeler({
      container: canvas,
      additionalModules: additionalModules,
      cli: {
        bindTo: 'cli'
      },
      moddleExtensions: {
        /*flowable: flowableModdle,
        etl: etlExtensionModdle,*/
        camunda: camundaModdleDescriptor
      },
      propertiesPanel: {
        parent: '#properties-panel-parent'
      }
    });
    await this.openDiagram(this.diagramXML).then(() => {

      let _self = this;
      let exportArtifacts = debounce(async () => {

        try {
          const {xml} = await _self.modeler.saveXML({format: true})
          _self.xmlData = xml;
        } catch (error) {
          console.log(`saveXML error ${err}`)
        }
        _self.$emit('input', _self.xmlData)
      }, 10);
      this.modeler.on("commandStack.changed", exportArtifacts);
      exportArtifacts()
    });

   /* setTimeout(function(){
      that.updateButtons()
    },500);*/
    this.modeler.on('element.click', function(event){
     // console.log('element.click', event);
      that.updateButtons();
    });
    this.modeler.on('propertiesPanel.changed', function(event){
     // console.log('propertiesPanel.changed', event);
      that.updateButtons();
    });
  },
  methods: {
    updateButtons(){
      const buttons = document.getElementById("properties-panel-parent").getElementsByTagName("button");
      buttons.forEach(function(button){
        button.setAttribute("type", "button");
      });
    },
    openDiagram(xml) {
      return new Promise(async (resolve, reject) => {
        if (xml) {
          try {
            const result = await this.modeler.importXML(xml);
            //console.log('rendered');
            resolve()
          } catch (err) {
            reject(err);
          }
          this.xmlData = xml;
        } else {
          this.modeler.createDiagram();
          setTimeout(() => {
            let modelerCanvas = this.modeler.get("canvas");
            let rootElement = modelerCanvas.getRootElement();
            let modeling = this.modeler.get("modeling");

            rootElement.children.forEach(n => {
              if (n.type === 'bpmn:StartEvent') {
                modeling.updateProperties(n, {
                  name: 'Start Event',
                });
              } else if (n.type === 'bpmn:EndEvent') {
                modeling.updateProperties(n, {
                  name: 'End Event',
                });
              }
            })
            resolve();
          });
        }
      })
    },
    saveSVG(done) {
      this.modeler.saveSVG(done);
    },
    // todo
    focusOut(event) {
      let layerBase = document.querySelector('.layer-base')
      let zoom = layerBase.parentNode.getBoundingClientRect();
      if (event.pageX < zoom.left || event.pageX > (zoom.left + zoom.width + 100) || event.pageY < zoom.top || event.pageY > (zoom.top + zoom.height + 40)) {

        let directEditing = this.modeler.injector.get('directEditing', false);
        directEditing.complete()
        let eventBus = this.modeler.injector.get('eventBus', false);
        eventBus.fire('element.click', '')
      }
    }
  }
}
</script>

<style lang="less" scoped>
// @import "../../styles/app.less";
@import "~bpmn-js/dist/assets/diagram-js.css";
@import "~bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
@import "~bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";
@import "~bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
@import "~diagram-js-minimap/assets/diagram-js-minimap.css";
@import '~bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';

.containers {
  background-color: #ffffff;
  height: calc(100vh - 165px);
  display: flex;
}

.canvas {
  width: 100%;
  height: 100%;
}

/*.specification-wrapper {

}*/

.properties-panel-parent {
  background-color: #f8f8f8;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 400px;
  z-index: 10;
  padding: 10px;
  border-left: 1px solid #ccc;
  overflow: auto;

  &:empty {
    display: none;
  }

  > .djs-properties-panel {
    padding-bottom: 70px;
    min-height: 100%;
  }

  /deep/ .form-control {
    font-size: small
  }

  /deep/ .bpp-textfield input {
    padding-right: 0;
  }

  /deep/ .bpp-properties-panel {
    .bpp-properties {
      .bpp-properties-header {
        .label {
          word-wrap: break-word;
        }
      }
    }
  }

  /deep/ .bpp-properties-panel [type=text], /deep/ .bpp-properties-panel textarea {
    width: calc(100% - 6px)
  }

  /deep/ .bpp-properties-panel [contenteditable] {
    width: calc(100% - 12px)
  }

  /deep/ .bpp-table-row > label.bpp-table-row-columns-2.bpp-table-row-removable, /deep/ .bpp-table-row > input.bpp-table-row-columns-2.bpp-table-row-removable {
    width: calc(50% - 12px);
  }
}
</style>
