import DatasetChart from './base/DatasetChart'

class MapChart extends DatasetChart{
  constructor() {
    super('MapChart');
  }

}


export default MapChart
