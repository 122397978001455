import forEach from "lodash/forEach"
import merge from "lodash/merge"
import utils from "../../utils/utils"

const elementDefaults = {
  fieldset: {
    title: 'FieldSet',
    icon: 'vaadin:modal-list',
    description: 'Use to create composite object',
    type: 'object',
    children: ['item', 'object', 'array'],
    schema: {
      type: 'object',
      properties: {}
    },
    form: {
      items: []
    }
  },
  text: {
    title: 'Text',
    description: 'Use to enter text',
    icon: 'vaadin:input',
    type: 'item',
    schema: {
      type: 'string'
    },
    configSchemaSpecific: {
      default: {
        type: 'string',
        title: 'Default Value',
        description: 'Enter default value'
      }
    },
    configFormSpecific: {
      tabs: [
        {
          index: 3,// tab index
          itemIndex: undefined,//can be 0-x  - if not specified it will be 'last'
          value: 'default'
        }
      ]
    }
  },
  password: {
    title: 'Password',
    description: 'Use for password inputs',
    icon: 'vaadin:password',
    type: 'item',
    schema: {
      type: 'string'
    },
    form: {}
  },
  number: {
    title: 'Number',
    description: 'Use for numbers',
    icon: '',
    type: 'item',
    schema: {
      type: 'number'
    }
  },
  integer: {
    title: 'Integer',
    description: 'Use for integers',
    type: 'item',
    schema: {
      type: 'integer'
    }
  },
  textarea: {
    title: 'Textarea',
    icon: 'vaadin:text-input',
    description: 'Use to enter longer text',
    type: 'item',
    schema: {
      type: 'string'
    }
  },
  radios: {
    title: 'Radios',
    description: '',
    type: 'titleMapItem',
    schema: {
      type: 'string',
      enum: ['radio1', 'radio2']
    },
    configSchemaSpecific: {

    },
    configFormSpecific: {
    }
  },
  radiosinline: {
    title: 'Radios inline',
    type: 'titleMapItem',
    schema: {
      type: 'string'
    },
    form: {
      titleMap: [
        {value: "one", name: "One"},
        {value: "two", name: "More..."}
      ]
    },
    configSchemaSpecific: {

    },
    configFormSpecific: {

    }
  },
  checkbox: {
    title: 'Checkbox',
    type: 'item',
    schema: {
      type: 'boolean'
    }
  },
  checkboxes: {
    title: 'Checkboxes',
    type: 'titleMapItem',
    schema: {
      type: 'array',
      items: {
        type: 'string',
        enum: ['check1', 'check2']
      },
      default: ['check1']
    },
    configSchemaSpecific: {

    },
    configFormSpecific: {

    }
  },
  select: {
    title: 'Select',
    type: 'titleMapItem',
    schema: {
      type: 'string',
      enum: ['option1', 'option2']
    },
    configSchemaSpecific: {
      options: {
        type: 'object',
        properties: {
          filter: {
            type: 'string',
            title: 'Filter',
            description: 'Define expression (as condition) to filter items'
          }
        }
      }
    },
    configFormSpecific: {
      tabs: [
        {
          index: 5,
          title: 'Filter',
          itemIndex: 0,
          value:{
            key: 'options.filter',
            type: 'textarea'
          }
        }
      ]
    }
  },
  multiselect: {
    title: 'Multi select',
    type: 'titleMapItem',
    schema: {
      type: 'array',
      items: {
        enum: ['option1', 'option2', 'option3']
      }
    },
    configSchemaSpecific: {

    },
    configFormSpecific: {

    }
  },
  tags: {
    title: 'Tags',
    type: 'item',
    schema: {
      type: 'array',
      items: {
        type: 'string'
      }
    }
  },
  slider: {
    title: 'Slider',
    type: 'item',
    schema: {
      type: 'array',
      items: {
        type: 'any'
      }
    }
  },
  daterange:{
    title: 'DateTimeRange',
    type: 'item',
    schema: {
      type: 'object',
      properties:{}
    }
  },
  datetime: {
    title: 'Datetime',
    type: 'item',
    schema: {
      type: 'string'
    }
  },
  date: {
    title: 'Date',
    type: 'item',
    schema: {
      type: 'string'
    }
  },
  ace: {
    title: 'Ace JSON',
    type: 'item',
    schema: {
      type: 'string'
    },
    form: {
      style: 'min-height:300px'
    }
  },
  markdown: {
    title: 'Markdown',
    type: 'item',
    schema: {
      type: 'string'
    }
  },
  help: {
    title: 'Help text',
    type: 'help',
    schema: {
      type: 'string'
    }
  },
  section: {
    title: 'Section layout',
    type: 'section',
    children: ['item', 'object', 'array'],
    schema: {
      type: 'section'
    },
    form: {
      items: []
    }
  },
  tabs: {
    title: 'Tabs',
    type: 'tabs',
    children: ['tab'],
    schema: {
      type: 'tabs'
    },
    form: {
      tabs: [
        {
          title: 'Tab1',
          items: []
        }
      ]
    }
  },
  array: {
    title: 'Array',
    type: 'array',
    children: ['item', 'object', 'array'],
    schema: {
      type: 'array',
      items: {}
    },
    form: {
      items: []
    }
  },
  tabarray: {
    title: 'Tab array',
    type: 'tabarray',
    children: ['item', 'object', 'array'],
    schema: {
      type: 'array',
      items: {}
    },
    form: {
      type: 'tabarray',
      add: 'New',
      remove: 'Delete',
      btnStyle: {
        remove: 'btn-danger'
      },
      title: "{{ 'Tab '+$index }}",
      items: []
    }
  },
  button: {
    title: 'Button',
    type: 'button',
    allowedIn: ['actions'],
    form: {
      type: 'button',
      title: 'Ok'
    }
  },
  submit: {
    title: 'Submit',
    type: 'submit',
    allowedIn: ['actions'],
    form: {
      type: 'submit',
      title: 'Submit'
    }
  },
  reset: {
    title: 'Reset',
    type: 'reset',
    allowedIn: ['actions'],
    form: {
      type: 'reset',
      title: 'Reset'
    }
  },
  actions: {
    title: 'Actions',
    description: 'Use to organize buttons',
    type: 'actions',
    children: ['button'],
    schema: {
      type: 'actions'
    },
    form: {
      items: []
    }
  },
  schemaFormEditor: {
    title: 'Schema Form editor',
    type: 'schemaFormEditor',
    schema: {
      type: 'object',
      properties: {}
    }
  },
  queryBuilder: {
    title: 'QueryBuilder editor',
    type: 'queryBuilder',
    schema: {
      type: 'object',
      properties: {}
    }
  },
  bpmn: {
    title: 'BPMN editor',
    type: 'bpmn',
    schema: {
      type: 'string'
    }
  },
  templatelist:{
    title: 'Template List  (experimental)',
    type: 'titleMapItem',
    template: '<div>This is the template</div>',
    schema: {
      type: 'array',
      items: {
        enum: ['option1', 'option2', 'option3']
      }
    },
    configSchemaSpecific: {
      template:{
        type: 'string',
        title: 'Template',
        description: 'Enter Html template and bind to internal state expecting List behaviour',
        default:'<div>The template requires ROOT element</div>'
      },
      script:{
        type: 'string',
        title: 'Script',
        description: 'Script to be used within the template',
        default: 'const obj = {\n  func1: function(context){\n      return context.fieldForm;     \n  } \n} \nreturn obj;'
      }
    },
    configFormSpecific: {
      tabs: [
        {
          index: 5,
          title: 'Template',
          itemIndex: 0,
          value:{
            type: 'tabs',
            tabs:[
              {
                title: 'Template',
                items:[
                  {
                    key: 'template',
                    type: 'ace',
                    style: 'height:400px',
                    config:{
                      lang: 'html'
                    }
                  }
                ]
              },
              {
                title: 'Script',
                items:[
                  {
                    key: 'script',
                    type: 'ace',
                    style: 'height:400px',
                    config:{
                      lang: 'javascript'
                    }
                  }
                ]
              }
            ]
          }
        }
      ]
    }
  },
  template: {
    title: 'Template (experimental)',
    type: 'item',
    schema: {
      type: 'string'
    }
  },
}

const schemaFormElements = {
  internalElementFormList: [],
  elements: elementDefaults,
  commonDefault: {},
  itemSchemaDefault: {
    schema: {},
    form: []
  },
  typeSpecifics: {
    array: {
      schema: {},
      form: []
    },
    item: {
      schema: {},
      form: []
    },
    object: {
      schema: {},
      form: []
    }
  },
  itemFormDefault: {
    childClass: undefined,
    htmlClass: undefined,
    fieldHtmlClass: undefined,
    labelHtmlClass: undefined,
    descriptionHtmlClass: undefined,
    style: undefined,
    readonly: false,
    hidden: false,
    placeholder: 'Placeholder for: {}'
  },
  containerDefault: {},
  generateUUID: function () {
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now(); //use high-precision timer if available
    }
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  },
  mergeSchemaFormChildEvent: function (schema, form, schemaFormEvt) {
    let formClone = utils.clone(form);
    let schemaClone = utils.clone(schema);

    schemaClone.properties = schemaFormEvt.schema.properties;
    formClone.items = schemaFormEvt.form.items;

    delete formClone.schema;
    delete formClone.key;
    delete formClone.schemaKey;
    formClone.key = formClone.schemaFieldKey;
    delete formClone.schemaFieldKey;

    return {schema: schemaClone, form: formClone};
  },
  resolveElements: function () {
    const elems = [];
    const that = this;
    forEach(elementDefaults, function (value, key, index) {
      elems.push({name: key, component: value, id: that.generateUUID()});
     // console.log('key', key);
    });
    return elems;
  },
  __resolveElementSchemaDefault: function (element) {
    let schemaDefault = {
      type: 'string',
      title: element.name,
      description: 'Description for: ' + element.name
    }
    return schemaDefault;
  },
  __resolveElementSchema: function (element) {
    const elementConfig = elementDefaults[element.name];
    const mergedSchema = merge({}, this.__resolveElementSchemaDefault(element), elementConfig.schema);
    return mergedSchema;
  },
  __resolveElementForm: function (key, element) {
    const elementConfig = elementDefaults[element.name];

    let elementForm = {
      type: element.name
    };
    let formDefault = {};
    switch (elementConfig.type) {
      case 'tabs':
      case 'actions':

      case 'button':
      case 'reset':
      case 'submit':
      case 'section':
        break;
      default:
        formDefault = this.itemFormDefault;
        elementForm.key = key;
        break;
    }
    elementForm = merge({}, formDefault, elementConfig.form, elementForm);

    return elementForm;
  },
  resolveSchema: function (list) {
    const that = this;
    let schema = {
      type: 'object',
      properties: {}
    };

    that.internalElementFormList = [];

    forEach(list, function (item, index) {

      if (item.component) {// this is dropped from left repository
        let key = item.name + '___' + that.generate_random_string(7);//(++elNumber);
        console.log('new Key generated: ' + key);
        let mergedSchema = that.__resolveElementSchema(item);
        schema.properties[key] = mergedSchema;
        let element = that.__resolveElementForm(key, item);
        element.guid = item.guid;
        that.internalElementFormList.push(element);
      } else {
        delete item.component;
        that.internalElementFormList.push(item);
      }
    });
    return schema;
  },
  generate_random_string: function (string_length) {
    let random_string = '';
    let random_ascii;
    let ascii_low = 65;
    let ascii_high = 90;
    for (let i = 0; i < string_length; i++) {
      random_ascii = Math.floor((Math.random() * (ascii_high - ascii_low)) + ascii_low);
      random_string += String.fromCharCode(random_ascii)
    }
    return random_string.toLowerCase()
  },
  resolveForm: function (list) {
    let form = [];
    const that = this;
    forEach(that.internalElementFormList, function (item, index) {
      form.push(item);
    });
    return form;
  },
  mergeSchemaFormBuilderArray: function (schemaArray) {

    let form = [];
    let schema = {
      type: 'object',
      properties: {}
    }

    const that = this;

    forEach(schemaArray, function (item, index) {
      let formItem = utils.clone(item);
      let formSchema = utils.clone(formItem.schema);
      if (item.type) {
        switch (item.type) {
          case 'button':
          case 'submit':
          case 'reset':
          case 'actions':

            break;
          case 'section':
          case 'tabs':

            let localSchema = {
              type: 'object',
              properties: {}
            }
            that.rebuildSchemaRecursively(localSchema, item);
            if (localSchema.properties){
              for (const prop in localSchema.properties) {
                schema.properties[prop] = localSchema.properties[prop];
              }
            }

            delete formItem.key;
            break;
          default:
            formItem.key = item.schemaFieldKey;
            schema.properties[formItem.key] = formSchema;
            break;
        }
      }
      form.push(formItem);
    });

    return {schema: schema, form: form};
  },
  rebuildSchemaRecursively: function(schema, item){
    const that = this;
    if (item.schema){
      if (item.schemaFieldKey){
        schema.properties[item.schemaFieldKey]=utils.clone(item.schema);
      }else{
        for(const p in item.schema){
          schema[p]= item.schema[p];
        }
      }

    }else if (item.items){
      item.items.forEach(function(itemInTabItems){
        that.rebuildSchemaRecursively(schema, itemInTabItems);
      });
    }else if (item.tabs){
      item.tabs.forEach(function(tab) {
        if (tab.items){
          tab.items.forEach(function(itemInTabItems){
            that.rebuildSchemaRecursively(schema, itemInTabItems);
          });
        }
      });
    }
  },
  rearrangeArray: function (arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

}

export default schemaFormElements;
