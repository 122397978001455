var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["diagram-editor-container", _vm.containerClass] },
    [
      _vm.enableElements
        ? _c(
            "div",
            { class: _vm.elementClass },
            [_c("vue-diagram-elements", { attrs: { elements: _vm.elements } })],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { class: _vm.editorClass },
        [
          _c("vue-diagram-editor-content", {
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
      _vm.enableConfig
        ? _c("div", { class: _vm.configClass }, [_c("vue-diagram-config")], 1)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }