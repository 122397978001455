import { render, staticRenderFns } from "./VueDfUserInfoComponentItem.vue?vue&type=template&id=77e05a14&scoped=true&"
import script from "./VueDfUserInfoComponentItem.vue?vue&type=script&lang=js&"
export * from "./VueDfUserInfoComponentItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77e05a14",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77e05a14')) {
      api.createRecord('77e05a14', component.options)
    } else {
      api.reload('77e05a14', component.options)
    }
    module.hot.accept("./VueDfUserInfoComponentItem.vue?vue&type=template&id=77e05a14&scoped=true&", function () {
      api.rerender('77e05a14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/vue-dashboard-framework-app-components/src/components/VueDfApp/components/UIComponents/Auth/items/VueDfUserInfoComponentItem.vue"
export default component.exports