var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "wrapper",
      class: _vm.layoutConfig.htmlClass,
      style: _vm.layoutStyle,
    },
    [
      _vm.layoutStructure === "sLeftNTop"
        ? [
            !_vm.layoutConfig.excludeSidebar
              ? _c("vue-df-app-sidebar", {
                  attrs: {
                    "security-config": _vm.securityConfig,
                    "selected-route-config": _vm.selectedRouteConfig,
                    "user-menu-config": _vm.userMenuSettings,
                    "app-config": _vm.appConfig,
                    layout: _vm.layoutConfig,
                    config: _vm.sidebarConfig,
                  },
                  on: { selected: _vm.onSelectedSidebarItem },
                })
              : _vm._e(),
            _c(
              "div",
              {
                class: [
                  "app-main-panel",
                  { "sidebar-include": !_vm.layoutConfig.excludeSidebar },
                ],
              },
              [
                !_vm.layoutConfig.excludeNavBar
                  ? _c("vue-df-app-top-navbar", {
                      attrs: {
                        "security-config": _vm.securityConfig,
                        "selected-route-config": _vm.selectedRouteConfig,
                        "user-menu-config": _vm.userMenuSettings,
                        "app-config": _vm.appConfig,
                        layout: _vm.layoutConfig,
                        config: _vm.navBarConfig,
                      },
                      on: { selected: _vm.onSelectedSidebarItem },
                    })
                  : _vm._e(),
                _vm._t("default"),
                !_vm.layoutConfig.excludeFooter
                  ? _c("vue-df-app-footer", {
                      attrs: {
                        "security-config": _vm.securityConfig,
                        "selected-route-config": _vm.selectedRouteConfig,
                        "user-menu-config": _vm.userMenuSettings,
                        "app-config": _vm.appConfig,
                        layout: _vm.layoutConfig,
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm.layoutStructure === "nTopSLeft"
        ? [
            !_vm.layoutConfig.excludeNavBar
              ? _c("vue-df-app-top-navbar", {
                  attrs: {
                    "security-config": _vm.securityConfig,
                    "selected-route-config": _vm.selectedRouteConfig,
                    "user-menu-config": _vm.userMenuSettings,
                    "app-config": _vm.appConfig,
                    layout: _vm.layoutConfig,
                    config: _vm.navBarConfig,
                  },
                  on: { selected: _vm.onSelectedSidebarItem },
                })
              : _vm._e(),
            !_vm.layoutConfig.excludeSidebar
              ? _c("vue-df-app-sidebar", {
                  style: _vm.calculateSideBarStyle,
                  attrs: {
                    "security-config": _vm.securityConfig,
                    "selected-route-config": _vm.selectedRouteConfig,
                    "user-menu-config": _vm.userMenuSettings,
                    "app-config": _vm.appConfig,
                    layout: _vm.layoutConfig,
                    config: _vm.sidebarConfig,
                  },
                  on: { selected: _vm.onSelectedSidebarItem },
                })
              : _vm._e(),
            _c(
              "div",
              {
                class: [
                  "app-main-panel",
                  { "sidebar-include": !_vm.layoutConfig.excludeSidebar },
                ],
              },
              [
                _vm._t("default"),
                !_vm.layoutConfig.excludeFooter
                  ? _c("vue-df-app-footer", {
                      attrs: {
                        "security-config": _vm.securityConfig,
                        layout: _vm.layoutConfig,
                        "selected-route-config": _vm.selectedRouteConfig,
                        "user-menu-config": _vm.userMenuSettings,
                        "app-config": _vm.appConfig,
                        config: _vm.footerConfig,
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }