var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "collapsible-container collapse-bar" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _vm._t("header", function () {
            return [
              _c("span", [
                _c("button", { on: { click: _vm.toogle } }, [
                  _c("i", { staticClass: "fas fa-cog" }),
                ]),
              ]),
            ]
          }),
        ],
        2
      ),
      _c(
        "collapse-transition",
        [
          _vm._t("default", function () {
            return [_c("div", [_vm._v("This is some content")])]
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _vm._t("footer", function () {
            return [_c("div", [_vm._v("this is footer content")])]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }