<template>
  <div class="wrapper" :style="layoutStyle" :class="layoutConfig.htmlClass">
    <template v-if="layoutStructure==='sLeftNTop'">
      <vue-df-app-sidebar :security-config="securityConfig" :selected-route-config="selectedRouteConfig"
                          :user-menu-config="userMenuSettings" :app-config="appConfig"
                          :layout="layoutConfig"
                          :config="sidebarConfig" v-if="!layoutConfig.excludeSidebar" @selected="onSelectedSidebarItem"></vue-df-app-sidebar>
      <div :class="['app-main-panel', {'sidebar-include' : !layoutConfig.excludeSidebar}]">
        <vue-df-app-top-navbar :security-config="securityConfig" :selected-route-config="selectedRouteConfig"
                               :user-menu-config="userMenuSettings" :app-config="appConfig"
                               :layout="layoutConfig"
                               :config="navBarConfig" v-if="!layoutConfig.excludeNavBar"
                               @selected="onSelectedSidebarItem"></vue-df-app-top-navbar>
        <slot :style="calculateContentSlotStyle"></slot>
        <vue-df-app-footer :security-config="securityConfig" :selected-route-config="selectedRouteConfig"
                           :user-menu-config="userMenuSettings" :app-config="appConfig"
                           :layout="layoutConfig"
                           v-if="!layoutConfig.excludeFooter"></vue-df-app-footer>
      </div>
    </template>
    <template v-if="layoutStructure==='nTopSLeft'">
      <vue-df-app-top-navbar :security-config="securityConfig" :selected-route-config="selectedRouteConfig"
                             :user-menu-config="userMenuSettings" :app-config="appConfig"
                             @selected="onSelectedSidebarItem"
                             :layout="layoutConfig"
                             v-if="!layoutConfig.excludeNavBar" :config="navBarConfig"></vue-df-app-top-navbar>
      <vue-df-app-sidebar :security-config="securityConfig" :selected-route-config="selectedRouteConfig"
                          :user-menu-config="userMenuSettings"
                          :app-config="appConfig" v-if="!layoutConfig.excludeSidebar"
                          :layout="layoutConfig"
                          :config="sidebarConfig"  :style="calculateSideBarStyle"
                          @selected="onSelectedSidebarItem"></vue-df-app-sidebar>
      <div :class="['app-main-panel', {'sidebar-include' : !layoutConfig.excludeSidebar}]">
        <slot :style="calculateContentSlotStyle"></slot>
        <vue-df-app-footer :security-config="securityConfig" :layout="layoutConfig" :selected-route-config="selectedRouteConfig" :user-menu-config="userMenuSettings"
                           :app-config="appConfig" v-if="!layoutConfig.excludeFooter" :config="footerConfig"></vue-df-app-footer>
      </div>
    </template>
  </div>
</template>

<script>

  import VueDfAppFooter from "../Footer/VueDfAppFooter";
  import VueDfAppTopNavbar  from "../Navbar/VueDfAppTopNavbar"
  import componentMixin from '../../componentMixin';

  export default {
    name: "VueDfAppLayout",
    mixins:[componentMixin],
    components: {VueDfAppFooter, VueDfAppTopNavbar},
    methods:{
      onSelectedSidebarItem: function(selected){
        this.$emit('selected', selected);
      }
    },
   /* mounted(){
      console.log('Layout mounted', this.config, this.selectedRouteConfig);
    },*/
    computed:{
      layoutConfig: function(){
        return this.config || {};
      },
      userMenuSettings: function(){
        return this.config.userMenuSettings;
      },
      calculateContentSlotStyle: function(){
        let style = 'height: calc(100vh - 165px);overflow-y: scroll;';
        return style;
      },
      calculateSideBarStyle: function(){
        let style = 'height: calc(100vh - 62px);top:62px;';
        return style;
      },
      layoutStyle: function(){
        let style = this.layoutConfig.style || '';
        if (style.indexOf('background-color')===-1){
          style+=this.backgroundColor;
        }
        return style;
      },
      backgroundColor: function(){
        let color = this.layoutConfig.backgroundColor || '#f4f3ef'
        return 'background-color:'+color;
      },
      layoutStructure: function(){
        return this.layoutConfig.layoutType || 'sLeftNTop';
      },
      sidebarConfig: function () {
        return this.layoutConfig.sidebarSettings || {};
      },
      navBarConfig: function(){
        return this.layoutConfig.navBarSettings || {};
      },
      footerConfig: function(){
        return this.layoutConfig.footerSettings || {};
      }
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped>
</style>
