var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "diagram-container" },
    [
      _c("vue-diagram-editor", {
        attrs: { name: _vm.name, elements: _vm.elements },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v("\n  final val\n  " + _vm._s(_vm.value) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }