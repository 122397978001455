<template>
  <div></div>
</template>

<script>

  import editorMixins from "./editorMixins";

  export default {
    name: "VDEditorPanel",
    mixins: {editorMixins},
    data(){
      return {}
    },
    computed:{},
    methods:{

    }
  }
</script>

<style scoped>

</style>
