<template>
  <div :class="{active:detailsConf.isOpen}" class="properties-panel">
    <span @click="specificationToggle" class="specification-toggle-button" title="Show Details">
      <i class="fas fa-cog"></i>
    </span>
    <div v-if="detailsConf.isOpen" class="specification-wrapper">
      <component :is="getComponent" v-if="element" :element="element" :modeler="modeler"></component>
    </div>
  </div>
</template>

<script>
import gatewayPanelSchemaForm from './schemaForm/gatewayPanelSchemaForm.json'
import processPanelSchemaForm from './schemaForm/processPanelSchemaForm.json'
import sequenceFlowPanelSchemaForm from './schemaForm/sequenceFlowPanelSchemaForm.json'
import startEndPanelSchemaForm from './schemaForm/startEndPanelSchemaForm.json'
import taskPanelSchemaForm from './schemaForm/taskPanelSchemaForm.json'

import TaskPanel from './components/TaskPanel'
import GatewayPanel from "./components/GatewayPanel";
import ProcessPanel from "./components/ProcessPanel";
import SequenceFlowPanel from "./components/SequenceFlowPanel";
import StartEndPanel from "./components/StartEndPanel";

// check this https://github.com/zglong182/bpmn-js-properties-panel-vue-custom/blob/master/src/pages/processDesigner/custom/index.vue
export default {
  name: "BpmnEditorPanel",
  components: {TaskPanel, GatewayPanel, ProcessPanel, SequenceFlowPanel, StartEndPanel},
  props: {
    modeler: {
      type: Object,
      required: true
    },
    options: {
      type: Object
    }
  },
  data() {
    return {
      detailsConf: {
        isOpen: false
      },
      element: null,
      schemaFormTypes: {
        gatewayPanel: gatewayPanelSchemaForm,
        processPanel: processPanelSchemaForm,
        sequenceFlowPanel: sequenceFlowPanelSchemaForm,
        startEndPanel: startEndPanelSchemaForm,
        taskPanel: taskPanelSchemaForm
      },
      schema: {},
      form: []
    }
  },
  mounted() {
    console.log('Mounted in panel modelere', this.modeler);
    this.handleModeler()
  },
  created() {
    console.log('Created in panel modelere', this.modeler);

  },
  computed: {
    getSchemaForm() {

    },
    getComponent() {
      const type = this.element.type
      console.log('element', this.element);

      if (['bpmn:IntermediateThrowEvent', 'bpmn:StartEvent', 'bpmn:EndEvent'].includes(type)) {
        return 'startEndPanel'
      }
      if ([
        'bpmn:UserTask',
        'bpmn:Task',
        'bpmn:SendTask',
        'bpmn:ReceiveTask',
        'bpmn:ManualTask',
        'bpmn:BusinessRuleTask',
        'bpmn:ServiceTask',
        'bpmn:ScriptTask'
        // 'bpmn:CallActivity',
        // 'bpmn:SubProcess'
      ].includes(type)) {
        return 'taskPanel'
      }
      if (type === 'bpmn:SequenceFlow') {
        return 'sequenceFlowPanel'
      }
      if ([
        'bpmn:InclusiveGateway',
        'bpmn:ExclusiveGateway',
        'bpmn:ParallelGateway',
        'bpmn:EventBasedGateway'
      ].includes(type)) {
        return 'gatewayPanel'
      }
      if (type === 'bpmn:Process') {
        return 'processPanel'
      }
      return null
    }
  },
  methods: {
    specificationToggle() {
      this.detailsConf.isOpen = !this.detailsConf.isOpen;
     // console.log('in panel specificationToggle', this.detailsConf.isOpen);
    },
    loadSchemaForm() {
      const type = this.getComponent;

      if (type) {
        const schemaForm = this.schemaFormTypes[type];
        this.schema = schemaForm.schema;
        this.form = schemaForm.form;
      }

    },
    handleModeler() {
      if (this.modeler) {
        this.modeler.on('root.added', e => {
          console.log('on add', e)
          if (e.element.type === 'bpmn:Process') {
            this.element = null
            this.$nextTick().then(() => {
              this.element = e.element
            })
          }
        })
        this.modeler.on('element.click', e => {
          const {element} = e
          console.log('on click', element)
          if (element.type === 'bpmn:Process') {
            this.element = element
          }
        })
        this.modeler.on('selection.changed', e => {
          this.element = null
          const element = e.newSelection[0]
          console.log('on selection changed', element);
          if (element) {
            this.$nextTick().then(() => {
              this.element = element
            })
          }
        })
      }
    }
  }

}
</script>

<style lang="less" scoped>

.specification-wrapper-active {
  /* width: 360px;*/
}

.specification-toggle-button {
  float: right;
}

.specification-wrapper {

}

.properties-panel.active {
  width: 400px;
}

.properties-panel {
  background-color: #f8f8f8;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 40px;
  z-index: 10;
  padding: 10px;
  border-left: 1px solid #ccc;
  overflow: auto;
}

</style>
