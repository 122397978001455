var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-widget-editor" },
    [
      _c("div", { staticClass: "card" }, [
        _vm._m(0),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "ul",
            { staticClass: "list-unstyled list-inline list" },
            _vm._l(_vm.customWidgets, function (w) {
              return _c(
                "li",
                {
                  staticClass: "ml-2 list-item p-2 border border-radius-large",
                },
                [
                  _c("div", { staticClass: "item-container" }, [
                    _c("div", { staticClass: "item content" }, [
                      _c("div", {}, [
                        _c("label", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(w.widget.name) +
                              "\n                "
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "item card-header1 button-header" },
                      [
                        _c(
                          "div",
                          { staticClass: "toolbar btn-group btn-group" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-sm btn-success",
                                attrs: { href: "" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.clickToEdit(w)
                                  },
                                },
                              },
                              [_vm._m(1, true)]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-sm btn-danger",
                                attrs: { href: "" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.clickToRemove(w)
                                  },
                                },
                              },
                              [_vm._m(2, true)]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("small", [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Category:"),
                    ]),
                    _vm._v(" " + _vm._s(w.widget.category)),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("vue-df-dashboard-widget-save-as", {
        ref: "saveAsWidget",
        attrs: {
          "dashboard-name": _vm.dashboardName,
          "crud-service-name": _vm.crudServiceName,
          definition: _vm.widgetDefinition,
          "is-edit": true,
          widget: _vm.widgetDefinition,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [_vm._v("Widgets")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [_c("i", { staticClass: "fas fa-cog" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [_c("i", { staticClass: "fas fa-times" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }