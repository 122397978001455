var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-info-wrapper" },
    [
      _c(
        "vue-df-app-dropdown",
        {
          staticClass: "dropdown",
          class: _vm.dropdownClass,
          attrs: {
            position: _vm.position,
            tag: _vm.dropdownTag,
            direction: "none",
            icon: "fas fa-user",
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function ({ isOpen }) {
                return _c(
                  "a",
                  {
                    staticClass: "dropdown-toggle",
                    class: _vm.dropdownLinkClass,
                    attrs: {
                      "aria-expanded": isOpen,
                      "aria-haspopup": "true",
                      "data-toggle": "dropdown",
                    },
                  },
                  [
                    _c("p", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.fullName)),
                    ]),
                    _c("i", { class: _vm.icon }),
                  ]
                )
              },
            },
          ]),
        },
        [
          _vm._t("default", function () {
            return [
              _vm.config.items && _vm.config.items.length
                ? _vm._l(_vm.resolveItems(), function (item, index) {
                    return _c("vue-df-user-info-component-item", {
                      key: index,
                      attrs: {
                        "app-config": _vm.appConfig,
                        config: item,
                        "selected-route-config": _vm.selectedRouteConfig,
                      },
                      on: { selected: _vm.onSelect },
                    })
                  })
                : _vm._e(),
            ]
          }),
          _vm.hasSeparator
            ? _c("li", [_c("a", { attrs: { href: "#" } }, [_c("hr")])])
            : _vm._e(),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: { href: "" },
                on: {
                  click: function ($event) {
                    return _vm.logout()
                  },
                },
              },
              [_c("p", { staticClass: "ml-2" }, [_vm._v("Logout")])]
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }