var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "node-type-component" }, [
    _c("span", {
      staticClass: "node-content",
      domProps: { innerHTML: _vm._s(_vm.node.component.name) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }