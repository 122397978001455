<template>
  <schema-form v-model="formData" :schema="schema" :form="form"></schema-form>
</template>

<script>
import TaskMixin from "vue-diagram/src/bpmn/panel/components/mixins/task.mixin";

export default {
  name: "ReceiveTask",
  mixins: [TaskMixin]
}
</script>

<style scoped>

</style>
