<template>
  <div class="user-info-wrapper">
    <vue-df-app-dropdown :class="dropdownClass" :position="position"
               :tag="dropdownTag"
               class="dropdown"
               direction="none"
               icon="fas fa-user">
      <a :aria-expanded="isOpen"
         :class="dropdownLinkClass"
         aria-haspopup="true"
         class="dropdown-toggle"
         data-toggle="dropdown"
         slot="title"
         slot-scope="{isOpen}">
        <p class="mr-1">{{fullName}}</p>
        <i :class="icon"></i>
      </a>
      <slot>
        <template v-if="config.items && config.items.length">
          <vue-df-user-info-component-item :app-config="appConfig"
                                           :config="item"
                                           :selected-route-config="selectedRouteConfig" @selected="onSelect"
                                           v-for="(item, index) in resolveItems()" :key="index"></vue-df-user-info-component-item>
        </template>

      </slot>
      <li v-if="hasSeparator">
        <a  href="#"> <!--v-if="hasSeparator"-->
          <hr/>
        </a>
      </li>
      <li class="nav-item">
        <a  @click="logout()" class="nav-link" href="">
          <p class="ml-2">Logout</p>
        </a>
      </li>
    </vue-df-app-dropdown>
  </div>
</template>

<script>

  import componentMixin from '../../componentMixin';
  import VueDfUserInfoComponentItem from './items/VueDfUserInfoComponentItem'
  import permissionMixin from "../permissionMixin";

  export default {
    name: "VueDfUserInfoComponent",
    components: {VueDfUserInfoComponentItem},
    mixins: [componentMixin, permissionMixin],
    props: {
      position: {
        type: String,
        default: function () {
          return 'right';
        }
      },
      dropdownTag: {
        type: String,
        default: function () {
          return 'div'
        }
      },
      dropdownClass: {
        type: String,
        default: function () {
          return 'nav-item'
        }
      },
      dropdownLinkClass: {
        type: String,
        default: function () {
          return 'nav-link';
        }
      }
    },
    data() {
      return {
        user: {},
        context: 'oauth2 context',
        code: this.$route.query.code,
        type: this.$route.params.type,
        redirect: '/'
      }
    },
    mounted() {
      this.code = this.$route.query.code;
      this.type = this.$route.params.type;
      this.redirect = this.$route.query.redirect;

      if (this.code) {
        const that = this;
        console.log('code in topnavbar ', this.code);
        this.$auth.oauth2({
          code: true,
          provider: this.type,
          params: {
            code: this.code,
          },
          success: function (res) {
            that.$router.push(that.redirect);
            console.log('success ' + this.context);
          },
          error: function (res) {
            console.log('error ' + this.context);
          }
        });
      }
    },
    created: function () {
      const that = this;
      if (this.$auth.isAuthenticated()) {
        this.resolveUser();
      }
    },
    computed: {
      icon: function () {
        return this.config.icon || 'fas fa-user';
      },
      fullName: function () {
        return this.user.name ? (this.user.name + ' ' + this.user.lastName) : this.user.username;
      },
      hasSeparator: function () {
        const len = this.config.items.length;
        return len>0;
      },
    /*  items: function(){
        return this.config.items;
      }*/
    },
    methods: {
      /*resolveItems: function(){
        return this.items;
      },*/
      onSelect: function(selected){
        this.$emit('selected', selected);
      },
      logout: function () {
        this.$auth.logout(null, 'etl');
      },
      resolveUser: function () {
        const that = this; // TODO can we use this.$userService.getUser()???
        this.$nextTick(function () {
          that.$auth.getUser('etl')
            .then(function (user) {
              that.user = user;
            }).catch(function (error) {
            console.error(error);
          });
        });
      }
    }
  }
</script>

<style scoped>
  .user-info-wrapper .dropdown hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }

</style>
