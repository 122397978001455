<template>
  <div class="structure-preview" :class="{selected: selected}">
    <h4>{{title}}</h4>
    <!-- Show structures -->
    <vue-df-dashboard-row
      :model="preview"
      :row="row"
      v-for="(row, index) in preview.rows" :key="index">
    </vue-df-dashboard-row>

  </div>
</template>

<script>
  import VueDfDashboardRow from './VueDfDashboardRow'
  import cloneDeep from 'lodash/cloneDeep'


  export default {
    name: "VueDfDashboardStructurePreview",
    props: ['title', 'selected', 'structure'],
    components:{
      'vue-df-dashboard-row': VueDfDashboardRow
    },
    data(){
      return {
        preview: {}
      }
    },
    created(){

        let s = cloneDeep(this.structure);
        this.adjustRowHeight(s);
        this.preview = s;
    },
    methods: {
      adjustRowHeight: function (container) {
        if (container.rows && container.rows.length > 0){
          const height = 100 / container.rows.length;
          for(let i=0;i<container.rows.length;i+=1){
            let row = container.rows[i];
            row.style = {
              height: height + '%'
            }
            if (row.columns){
              for(let j=0;j<row.columns.length;j+=1){
                let column = row.columns[j];
                this.adjustRowHeight(column);
              }
            }
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
