var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c(
        "draggable",
        {
          staticClass: "kanban-board",
          attrs: {
            list: _vm.internalKanbanList,
            group: "kanbanList",
            tag: "div",
          },
        },
        _vm._l(_vm.internalKanbanList, function (kanbanList) {
          return _c("div", { staticClass: "kanban-col" }, [
            _c("div", { staticClass: "kanban-col-container" }, [
              _c("ul", { staticClass: "list-inline mb-0" }, [
                _c(
                  "li",
                  {
                    staticClass: "float-right list-inline-item p-1",
                    attrs: { title: "Remove List" },
                    on: {
                      click: function ($event) {
                        return _vm.removeList(kanbanList)
                      },
                    },
                  },
                  [_c("strong", {}, [_vm._v("-")])]
                ),
                _c(
                  "li",
                  {
                    staticClass: "float-right list-inline-item p-1",
                    attrs: { title: "Add Item" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.addItem(kanbanList)
                      },
                    },
                  },
                  [_c("strong", {}, [_vm._v("+")])]
                ),
              ]),
              _c("div", { staticClass: "list-name mb-2" }, [
                _vm._v("\n          " + _vm._s(kanbanList.name) + "\n        "),
              ]),
              _c(
                "div",
                { staticClass: "kanban-item-list" },
                [
                  _c(
                    "draggable",
                    {
                      attrs: {
                        list: _vm.items[kanbanList.name],
                        group: "kanbanListItem",
                        tag: "div",
                      },
                    },
                    _vm._l(_vm.items[kanbanList.name], function (item) {
                      return _c(
                        "div",
                        { staticClass: "kanban-item mb-3" },
                        [
                          _c("vue-kanban-item-view", {
                            attrs: { item: item, template: _vm.value.item },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "kanban-col add-new" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline",
            attrs: { title: "Add new list" },
            on: {
              click: function ($event) {
                return _vm.addNewList()
              },
            },
          },
          [_vm._v("+")]
        ),
        _vm.addNewListClicked
          ? _c("div", { staticClass: "kanban-col" }, [
              _c("div", { staticClass: "input-group mb-3 input-group-sm" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newListName,
                      expression: "newListName",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "Name", type: "text" },
                  domProps: { value: _vm.newListName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.newListName = $event.target.value
                    },
                  },
                }),
                _c("div", { staticClass: "input-group-append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn-sm",
                      attrs: { type: "button" },
                      on: { click: _vm.saveNewListName },
                    },
                    [_vm._v("Save")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning btn-sm",
                      attrs: { type: "button" },
                      on: { click: _vm.cancelNewListName },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("vue-kanban-item-edit", {
        ref: "kanbanEditItem",
        attrs: { "schema-form": _vm.value.itemEdit || {} },
        on: { onItemEditSubmit: _vm.onItemEditSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }