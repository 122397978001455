<template>
  <div></div>
</template>

<script>
    import editorMixins from "./editorMixins";
    import vdPropertiesDefinition from './vdPropertiesDefinition'

    export default {
        name: "VDPropertiesPanel",
      mixins: {editorMixins},
      data(){
        return {}
      },
      computed:{},
      methods:{

      }
    }
</script>

<style scoped>

</style>
