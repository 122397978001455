<template>
  <div>
    <div class="">
      <visualisation-designer></visualisation-designer>
    </div>
  </div>
</template>

<script>
  const VisualisationDesigner = () => import('vue-dashboard-framework-components/src/components/visualisationDesigner/VisualisationDesigner')
  export default {
    name: "ChartDesignerView",
    components: {VisualisationDesigner}
  }
</script>

<style scoped>

</style>
