<template>
  <li class="nav-item">
    <user-info-component>
    </user-info-component>
  </li>
</template>

<script>
  export default {
    name: "VueDfAppNavbarUserItem",
    props: {config: Object, parent: Object}
  }
</script>

<style scoped>

</style>
