<template>
    
</template>

<script>
  import componentMixin from '../../../componentMixin';
    export default {
        name: "VueDfAppNavbarTemplateItem"
    }
</script>

<style scoped>

</style>
