var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("schema-form", {
        attrs: { schema: _vm.schema, form: _vm.form },
        model: {
          value: _vm.startEndData,
          callback: function ($$v) {
            _vm.startEndData = $$v
          },
          expression: "startEndData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }