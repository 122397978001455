var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dataset || _vm.chartConfig
    ? _c("div", { staticClass: "chart-container" }, [
        _vm._v("\n  THIS IS INTERESTING\n  "),
        _c(
          "div",
          {
            staticClass: "chart-wrapper p-2",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onContextChanged({
                  computedChart: _vm.computedChart,
                  chartConfig: _vm.chartConfig,
                  dataset: _vm.dataset,
                  reducedDataset: _vm.reducedDataset,
                  dimensions: _vm.dimensions,
                })
              },
            },
          },
          [
            _c("v-chart", {
              ref: "eChart",
              attrs: {
                options: _vm.computedChart,
                "init-options": _vm.initOptions,
                autoresize: "",
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }