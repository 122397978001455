<template>
  <div class="schema-form-editor">
    <div :class="containerClass">
      <div :class="elementClass" v-if="enableElements">
        <div class="elements-wrapper dragable-wrapper">
          <div class="card p-3 rounded-0" ref="elementsCard">
            <ul class="list-unstyled">
              <li  class="list-item float-right">
                <i class="fas fa-arrow-left"></i>
              </li>
              <li class="list-item float-left">
                <label>
                  Elements
                </label>
              </li>
            </ul>
            <draggable :clone="clone" :group="{ name: 'components', pull: 'clone', put: false }" :move="onMove"
                       @change="log"
                       @end="onEnd"
                       @start="onStart" class="list-group"
                       id="dragable" ref="elements" tag="ul" v-model="components.fieldMapper">

              <transition-group :name="'flip-list'" type="transition">
                <li :key="cmp.name" class="list-group-item" v-bind:itemref="cmp" v-for="cmp in components.fieldMapper">
                  <div :title="cmp.component.description" class="">
                    <span class="mr-2  text-muted" style="font-size: 0.7rem;">
                    <i class="fas fa-circle-notch"></i>
                    </span>
                    <span>{{cmp.component.title || cmp.name}}</span>
                  </div>
                </li>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
      <div :class="editorClass">
        <portal :disabled="!isMaximized" to="portalDestination">
          <div class="schema-form-wrapper card rounded-0" id="schema-form-wrapper">
            <b-tabs @input="onTabChanged" content-class="draggable-tab-content  pb-2 pl-4 pr-4 pt-2">
              <b-tab active title="Editor">
                <div v-if="currentTab===0">
                  <div style="">
                    <label>
                      Edit with drag'drop
                    </label>
                    <div class="btn-group btn-group-sm pull-right">
                      <input class="form-control form-control-sm" placeholder="Enter Draft name" type="text"
                             v-model="draftName">
                      <button @click="saveDraft()" class="btn btn-primary" title="Save draft">
                        <i class="fas fa-save"></i>
                      </button>
                      <select @change="onDraftSelected($event)" aria-placeholder="Select Draft"
                              class="form-control form-control-sm">
                        <option>Select Draft</option>
                        <option :selected="draft.name===selectedDraftName" :value="draft.name"
                                v-for="draft in localDraftNames">
                          {{draft.name}}
                        </option>
                      </select>
                      <button @click="loadPreviousDraft()" class="btn btn-warning" title="Load Previous">
                        <i class="fas fa-upload"></i>
                      </button>
                      <button @click="removeDraft()" class="btn btn-danger" title="Remove draft">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div style="clear: both;"></div>
                  <div class="card">
                    <div :key="count" class="card-body">
                      <schema-form :form="internalSchemaForm.form"
                                   :is-droppable="isDroppable" :schema="internalSchemaForm.schema"
                                   @internalSchemaChanged="onInternalSchemaChanged"
                                   @schemaChanged="onDroppedSchemaChanged"
                                   v-model="sfData"></schema-form>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Preview">
                <div v-if="currentTab===1">
                  <label>
                    It will look like
                  </label>
                  <div class="card">
                    <div class="card-body">
                      <b-tabs>
                        <b-tab title="Schema Form">
                          <schema-form :form="droppedSchema.form" :schema="droppedSchema.schema"
                                       v-model="sfData"></schema-form>
                        </b-tab>
                        <b-tab title="Data">
                          <div class="data-container">
                            <div class="data-wrapper">
                              <pre>{{sfData}}</pre>
                            </div>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Advanced">
                <div v-if="currentTab===2">
                  <label>
                    Advanced Schema & Form JSON
                  </label>
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <schema-form :form="schemaFormAdvanced.form" :schema="schemaFormAdvanced.schema"
                                     @validationResult="onAdvancedSchemaValidationResult"
                                     v-model="droppedSchemaModel"></schema-form>
                        <!-- <pre>{{droppedSchema}}</pre>-->
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Generate">
                <div v-if="currentTab===3">
                  <label>
                    Generate from data
                  </label>
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <schema-form :form="schemaFormGenerate.form" :schema="schemaFormGenerate.schema"
                                     @validationResult="onGenerateSchemaValidationResult"
                                     v-model="generateSchemaModel"></schema-form>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <template v-slot:tabs-end>
                <li class="nav-item align-self-center maximize" title="Maximize Editor">
                  <a @click.prevent="maximizeEditor()" class="float-right" href><i
                    :class="!isMaximized?'fa fa-window-maximize':''"></i></a>
                </li>
              </template>
            </b-tabs>
            <div>

            </div>
          </div>
        </portal>
      </div>
      <div :class="configClass" v-if="enableConfig">
        <div class="config-wrapper">
          <sf-component-config :is-debug="isDebug"></sf-component-config>
        </div>
      </div>
    </div>
    <b-modal :ref="'portalEditor'" @ok="onMaximizeClosed" no-enforce-focus hide-header-close lazy no-close-on-backdrop no-close-on-esc
             ok-only scrollable size="full">
      <portal-target name="portalDestination"></portal-target>
    </b-modal>
  </div>
</template>

<script>
  import {Portal, PortalTarget} from 'portal-vue'

  import schemaFormElements from 'vue-json-schema-form/src/components/schemaForm/schemaFormElements'
  import SfComponentConfig from './components/SfComponentConfig'
  import mergeInUtils from 'vue-json-schema-form/src/utils/mergeInComponentUtils'
  import merge from 'lodash/merge'
  import forEach from 'lodash/forEach'
  import findIndex from 'lodash/findIndex'
  import utils from 'vue-json-schema-form/src/utils/utils'
  import ObjectPath from 'vue-json-schema-form/src/utils/ObjectPath'
  import schemaFormGenerator from "./schemaFormGenerator";
  import { BModal } from 'bootstrap-vue'
  import { BTabs } from 'bootstrap-vue'
  import { BTab } from 'bootstrap-vue'

  export default {
    name: "SchemaFormEditor",

    components: {
      SfComponentConfig: SfComponentConfig,
      Portal, PortalTarget, BModal, BTabs, BTab
    },
    props: {
      isDroppable: {
        type: Boolean,
        default: function () {
          return true;
        }
      },
      containerClass: {
        type: String,
        default: function () {
          return 'container-class row';
        }
      },
      elementClass: {
        type: String,
        default: function () {
          return 'element-class col-2'
        }
      },
      editorClass: {
        type: String,
        default: function () {
          return 'editor-class col'
        }
      },
      configClass: {
        type: String,
        default: function () {
          return 'config-class col-3'
        }
      },
      enableElements: {
        type: Boolean,
        default: function () {
          return true;
        }
      },
      enableConfig: {
        type: Boolean,
        default: function () {
          return true;
        }
      },
      isDebug: {
        type: Boolean,
        default: function () {
          return true;
        }
      },
      value: {
        type: Object
      },
      name: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        isMaximized: false,
        localDraftNames: [],
        selectedDraftName: {},
        draftName: undefined,
        currentTab: 0,
        count: 1,
        sfData: {},
        schema: {},
        form: [],
        elNumber: 10,
        components: {
          fieldMapper: []
        },
        movedComponents: [],
        isDragging: false,
        delayedDragging: false,
        internalSchemaForm: {},
        droppedSchema: {},
        droppedSchemaModel: {},
        generateSchemaModel: {},
        schemaFormGenerate: {
          schema: {
            type: 'object',
            properties: {
              type: {
                type:'string',
                title: 'Type',
                description: 'Select type of the input to generate schema form',
                enum:['JsonData', "JsonSchema"],
                default: 'JsonData'
              },
              data: {
                type: 'string',
                title: 'Data',
                description: 'Enter data as JSON object to generate schema form that you can re-organize in the editor'
              }
            }
          },
          form: [
            {
              key: 'type',
              type: 'radiosinline'
            },
            {
              key: 'data',
              type: 'ace',
              style: 'height:400px'
            },
            {
              type: 'actions',
              items: [
                {
                  type: "submit",
                  title: "Apply"
                },
                {
                  type: "reset",
                  title: "Clear"
                }
              ]
            }
          ]
        },
        schemaFormAdvanced: {
          schema: {
            type: 'object',
            properties: {
              schema: {
                type: 'string',
                title: 'Schema'
              },
              form: {
                type: 'string',
                title: 'Form'
              }
            }
          },
          form: [
            {
              type: 'section',
              sectionContainerClass: 'row',
              sectionChildClass: 'col',
              items: [
                {
                  key: 'schema',
                  type: 'ace',
                  style: 'height:400px'
                },
                {
                  key: 'form',
                  type: 'ace',
                  style: 'height:400px'
                }
              ]
            },
            {
              type: 'actions',
              items: [
                {
                  type: "submit",
                  title: "Apply"
                },
                {
                  type: "reset",
                  title: "Clear"
                }
              ]
            }
          ]
        }
      }
    },
    created() {
      const that = this;
      that.components.fieldMapper = schemaFormElements.resolveElements();

      if (this.isDroppable) {
      //  console.log('created')
        this.$bus.$on('selectedItemModelApplied', that.selectedItemModelApplied);
      }

      if (this.value) {
        if (!this.value.schema) {
          console.warn('Value of schema is not defined')
        }
        if (!this.value.form) {
          console.warn('Value of form is not defined')
        }
        /*this.internalSchemaForm.schema = this.value.schema;
        this.internalSchemaForm.form = this.value.form;*/
        this.onInternalSchemaChanged(this.value);
      }

      this.loadPreviousDraft();
    },

    beforeDestroy() {
      if (this.isDroppable) {
        const that = this;
        this.$bus.$off('selectedItemModelApplied', that.selectedItemModelApplied);
      //  console.log('beforeDestroy')
      }

    },
    computed: {
      /*maximizeClass: function(){
        return this.isMaximized?'fa fa-window-minimize':'fa fa-window-maximize';
      },*/
      draftNamespace: function () {
        const namespace = 'formEditorDrafts:' + this.name;
        return namespace;
      },
      selectedItem: function () {
        return this.$schemaFormEditorStore.selectedItem;
      },
      resolvedForm: function () {
     //   console.log('Resolved form', this.internalSchemaForm.form);
        return this.internalSchemaForm.form;
      },
      resolvedSchema: function () {
     //   console.log('Resolved schema', this.internalSchemaForm.schema);
        return this.internalSchemaForm.schema;
      },
      dragOptions() {
        return {
          animation: 0,
          group: {name: 'components', pull: 'clone', put: false},
          ghostClass: "ghost"
        };
      }
    },
    methods: {
      maximizeEditor: function () {
        this.isMaximized = true;
        this.$refs['portalEditor'].show();
      },
      onMaximizeClosed: function () {
        this.isMaximized = false;
      },
      onPreviewSchemaFormDataClick: function () {
        this.sfData = {};
      },
      onDraftSelected: function (event) {
     //   console.log('Event value', event.target.value);

        if (event.target.value) {
          //  const namespace = 'formEditorDrafts:'+this.name;
          this.draftName = event.target.value;
          const fullName = this.draftNamespace + ':' + this.draftName;

          const fullObject = this.$ls.get(fullName);
          this.selectedDraftName = this.draftName;

          if (fullObject) {
            this.recursivelyUpdateGuid(fullObject.schemaForm.form, true);
            this.onInternalSchemaChanged(fullObject.schemaForm);
          } else {
            console.warn('There is no stored schema for fullName: ' + fullName);
            this.onInternalSchemaChanged({schema: {}, form: []});
          }
        } else {
          this.onInternalSchemaChanged({schema: {}, form: []});
        }
      },
      removeDraft: function () {
        let draftNames = this.$ls.get(this.draftNamespace);
        if (draftNames) {

          const that = this;
          const index = findIndex(draftNames, function (item) {
            return item.name === that.draftName;
          });
          if (index > -1) {
            draftNames.splice(index, 1);

            this.$ls.set(this.draftNamespace, draftNames);
            const fullName = this.draftNamespace + ':' + this.draftName;
            this.$ls.remove(fullName);

            this.localDraftNames = draftNames;
            this.draftName = undefined;
            this.onInternalSchemaChanged({schema: {}, form: []});
          }
        }

      },
      saveDraft: function () {
        // const namespace = 'formEditorDrafts:'+this.name;
        const fullName = this.draftNamespace + ':' + this.draftName;

        let draftNames = this.$ls.get(this.draftNamespace);
        if (!draftNames)
          draftNames = [];

        const that = this;
        const index = findIndex(draftNames, function (item) {
          return item.name === that.draftName;
        });
        if (index === -1) {
          draftNames.push({name: this.draftName});
          this.$ls.set(this.draftNamespace, draftNames);
        }

        const fullObject = {
          namespace: this.draftNamespace,
          name: this.draftName,
          schemaForm: this.internalSchemaForm
        }

        this.$ls.set(fullName, fullObject);

        this.$set(this, 'localDraftNames', draftNames);

        this.selectedDraftName = this.draftName;
      },
      loadPreviousDraft: function () {
        //const namespace = 'formEditorDrafts:'+this.name;
        this.localDraftNames = this.$ls.get(this.draftNamespace);
      },
      selectedItemModelApplied: function (item) {
    //    console.log('Received selectedItemModelApplied', item, arguments);
        const that = this;
        /*this.$nextTick(function () {

        })*/
        that.mergeInConfigItem(item);
      },
      mergeInConfigItem: function (item) {
        // we should recursively find item by Guid
        // then update it's name and all it's children
      //  console.log('mergeInConfigItem', item, {form: this.resolvedForm, schema: this.resolvedSchema});

        // mergin form
        const schemaForm = mergeInUtils.updateSchemaFormRecursively(item, utils.clone(this.internalSchemaForm.schema), utils.clone(this.internalSchemaForm.form));

        this.internalSchemaForm = {};
        this.internalSchemaForm = schemaForm;


        this.onDroppedSchemaChanged(this.internalSchemaForm);

        ++this.count;
     //   console.log('after mergin', {form: this.resolvedForm, schema: this.resolvedSchema});
      },
      recursivelyUpdateGuid: function (form, newGuid) {
        const that = this;
        forEach(form, function (item) {
          if (!item)
            console.error('There is no valid item in the form', form, that);
          if (newGuid) {
            item.guid = schemaFormElements.generateUUID();
          } else {
            if (!item.guid) {
              item.guid = schemaFormElements.generateUUID();
              if (!item.type) {
                item.type = 'text'
              }
              if (!item.name) {
                item.name = item.type;
              }

              const fieldFormDefault = schemaFormElements.__resolveElementForm(item.key, {name: item.type});
              item = merge({}, fieldFormDefault, item);
            }
          }

          if (item.items) {
            that.recursivelyUpdateGuid(item.items, newGuid);
          }

        });
      },
      onGenerateSchemaValidationResult: function (evt) {
        let data = this.generateSchemaModel.data;
        if (typeof data === 'string') {
          data = JSON.parse(data);
        }
        const dataType = this.generateSchemaModel.type;
        let generatedSchemaForm =  { schema: {}, form: []}
        if (dataType==='JsonSchema'){
          const form = schemaFormGenerator.generateFromSchema(data);
          generatedSchemaForm.form = form;
          generatedSchemaForm.schema = data;

        }else {
          generatedSchemaForm = schemaFormGenerator.generate(data);
        }
    //    console.log('generatedSchemaForm', generatedSchemaForm);

        this.internalSchemaForm.form = generatedSchemaForm.form;
        this.internalSchemaForm.schema = generatedSchemaForm.schema;
        this.onDroppedSchemaChanged(this.internalSchemaForm);

        this.count += 1;
      },
      onAdvancedSchemaValidationResult: function (evt) {

        if (typeof this.droppedSchemaModel.form === 'string') {
          this.droppedSchemaModel.form = JSON.parse(this.droppedSchemaModel.form);
        }
        if (typeof this.droppedSchemaModel.schema === 'string') {
          this.droppedSchemaModel.schema = JSON.parse(this.droppedSchemaModel.schema);
        }

        this.recursivelyUpdateGuid(this.droppedSchemaModel.form.form);

        this.internalSchemaForm.form = this.droppedSchemaModel.form.form;
        this.internalSchemaForm.schema = this.droppedSchemaModel.schema.schema;
        this.onDroppedSchemaChanged(this.internalSchemaForm);

        this.count += 1;
      },
      onTabChanged: function (evt) {
        this.currentTab = evt;
      },
      onInternalSchemaChanged: function (schema) {
        this.internalSchemaForm = schema;
        this.onDroppedSchemaChanged(schema);
      },
      recursivelyCleanDroppedSchemaForm: function (form) {
        if (form) {
        //  console.log('recursivelyCleanDroppedSchemaForm', form);
          const that = this;
          forEach(form, function (formItem) {
            delete formItem.schema;
            delete formItem.schemaKey;
            delete formItem.schemaFieldKey;
            delete formItem.arrayIndex;
            delete formItem.isMinimized;

            if (formItem.key) {
              if (Array.isArray(formItem.key)) {
                formItem.key = ObjectPath.dotted(formItem.key);
              }
            }


            if (formItem.items) {
              that.recursivelyCleanDroppedSchemaForm(formItem.items);
            }
            if (formItem.tabs) {
              that.recursivelyCleanDroppedSchemaForm(formItem.tabs);
            }
          });
        }
      },
      applySchemaDroppedModel: function (schema) {
        this.droppedSchema = {};
        this.droppedSchemaModel = {};

        this.droppedSchema = schema;
        this.droppedSchemaModel = {
          schema: {
            schema:
              {
                properties: schema.schema.properties,
                type: 'object',
                required: schema.schema.required
              }
          },
          form: {form: schema.form}
        };
        ++this.count;
      },
      onDroppedSchemaChanged: function (schema) {
        const newSchema = utils.clone(schema);
        this.recursivelyCleanDroppedSchemaForm(newSchema.form)

        //   TODO - required not propagated into schema after "apply" to item
        this.applySchemaDroppedModel(newSchema);
        this.$emit('onSchemaChanged', this.droppedSchema);
      },
      clone: function (evt) {
        const obj = {
          name: evt.name,
          component: evt.component,
          guid: schemaFormElements.generateUUID()
        }

        return obj;
      },
      onEnd: function (evt) {
         // console.log(evt);
        this.isDragging = false
      },
      onStart: function (evt) {
        //  console.log(evt);
        this.isDragging = true;
      },
      onMove: function ({relatedContext, draggedContext}) {
        return relatedContext.component.$el.id !== 'dragable';
      },
      log: function (evt) {
      },
      resolveSchema: function () {
        const schema = schemaFormElements.resolveSchema(this.movedComponents);
        //console.log('schema', schema);
        return schema;
      },
      resolveForm: function () {
        const form = schemaFormElements.resolveForm(this.movedComponents);
        return form;
      }
    },
    watch: {
      value: {
        handler(newVal, oldVal) {
  //        console.log('NewVal - SchemaFormEditor', utils.clone(newVal));
          //if (newVal !== oldVal) {
          //this.onInternalSchemaChanged(newVal);
          if (newVal !== this.internalSchemaForm)
            this.internalSchemaForm = newVal;

          this.applySchemaDroppedModel(newVal);
          //}
        },
        deep: true
      },
      isDragging(newValue) {
        if (newValue) {
          this.delayedDragging = true;
          return;
        }
        this.$nextTick(() => {
          this.delayedDragging = false;
        });
      }
    }
  }
</script>

<style scoped>

  .card {
    min-height: 50px;
  }

  .schema-form-wrapper, .config-wrapper {
    overflow-y: scroll;
  }

  .schema-form-wrapper .tabs {
    height: calc(100vh - 107px);
  }

  .modal-dialog .schema-form-wrapper .tabs {
    height: calc(100vh - 350px);
  }

  .modal-dialog .vue-portal-target .schema-form-wrapper .tabs {
    height: calc(100vh - 140px);
  }

  .modal-dialog .dragable-wrapper .list-group {
    height: calc(100vh - 420px);
  }

  .dragable-wrapper .list-group {
    height: calc(100vh - 180px);
  }

  .dragable-wrapper span {
    overflow-y: scroll;
  }

  .dropable-wrapper .list-group.has-elements {
    border: 1px dotted #393939;
    height: 50px;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .list-group {
    min-height: 20px;
  }

  .list-group-item {
    cursor: move;
  }

  .list-group-item i {
    cursor: pointer;
  }

  .data-wrapper pre {
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: break-all;
  }

  li.maximize {
    position: absolute;
    right: 35px;
  }

</style>
