<template>
  <div class="panel start-end-panel">
    Start End Panel - {{ elementType }}
    <component :is="taskComponent" :modeler="modeler" :element="element"></component>
  </div>
</template>

<script>
import PanelMixin from "vue-diagram/src/bpmn/panel/components/mixins/panel.mixin";
import StartEvent from "vue-diagram/src/bpmn/panel/components/startEnd/StartEvent";
import EndEvent from "vue-diagram/src/bpmn/panel/components/startEnd/EndEvent";
import IntermediateThrowEvent from "vue-diagram/src/bpmn/panel/components/startEnd/IntermediateThrowEvent";

export default {
  name: "StartEndPanel",
  mixins:[PanelMixin],
  components:{StartEvent, IntermediateThrowEvent, EndEvent},
  computed:{
    taskComponent(){
      return this.elementType
    }
  }
}
</script>

<style scoped>

</style>
