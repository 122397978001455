var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modalEdit",
          attrs: {
            scrollable: true,
            title: _vm.widget.title,
            lazy: "",
            size: "full",
          },
          on: { ok: _vm.handleEditConfirmed },
        },
        [
          _c("form", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-11" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "widgetTitle",
                        text: _vm.translate("ADF_COMMON_TITLE"),
                      },
                    },
                    [_vm._v("Title")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editDefinition.title,
                        expression: "editDefinition.title",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "widgetTitle",
                      placeholder: "Enter title",
                      required: "",
                      type: "text",
                    },
                    domProps: { value: _vm.editDefinition.title },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.editDefinition,
                          "title",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "col-1" }, [
                _c("div", { staticClass: "form-group float-left" }, [
                  _c("label", [_vm._v("Hide Title?")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editDefinition.hideTitle,
                        expression: "editDefinition.hideTitle",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "Hide title", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.editDefinition.hideTitle)
                        ? _vm._i(_vm.editDefinition.hideTitle, null) > -1
                        : _vm.editDefinition.hideTitle,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.editDefinition.hideTitle,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.editDefinition,
                                "hideTitle",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.editDefinition,
                                "hideTitle",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.editDefinition, "hideTitle", $$c)
                        }
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _vm.widgetEditMode
            ? [
                _c(_vm.resolveComponent("Edit"), {
                  ref: "widgetEditBody",
                  tag: "component",
                  attrs: {
                    "crud-service-name": _vm.crudServiceName,
                    "dashboard-service-instance": _vm.dashboardServiceInstance,
                    "edit-mode": _vm.widgetEditMode,
                    model: _vm.editDefinition,
                    "input-context": _vm.inputContext,
                    options: _vm.options,
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("vue-df-dashboard-widget-instance-modal", {
        ref: "widgetInstanceModal",
        attrs: {
          "crud-service-name": _vm.crudServiceName,
          "dashboard-service-instance": _vm.dashboardServiceInstance,
          "edit-mode": _vm.widgetEditMode,
          "default-mapper": _vm.defaultMapper,
          model: _vm.editDefinition,
          options: _vm.options,
          "input-context": _vm.inputContext,
          permissions: _vm.permissions,
          "dashboard-name": _vm.dashboardName,
          dashid: _vm.dashid,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }