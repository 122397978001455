<template>
  <div v-if="widgetDefinition">
    <div>
      <b-modal :centered="centered" :size="modalSize" :title="title" @ok="handleEditOk" ref="editModal">
        <b-tabs>
          <!--:model="model"-->
          <b-tab active title="Edit">
            <vue-df-dashboard-widget
              :crud-service-name="crudServiceName"
              :dashboard-name="dashboardName"
              :dashboard-service-instance="dashboardServiceInstance"
              :dashid="dashid"
              :default-mapper="defaultMapper"
              :definition="widgetDefinition"
              :edit-mode=true
              :id="widgetDefinition.id"
              :model="model"
              ref="editWidget"
              :options="options"
              :parent-dashid="parentDashid"
              :parent-widget-id="parentWidgetId"
              :permissions="permissions"
              :widget-state="widgetState()">
            </vue-df-dashboard-widget>
          </b-tab>
          <b-tab title="Preview">
            <vue-df-dashboard-widget
              :crud-service-name="crudServiceName"
              :dashboard-name="dashboardName"
              :dashboard-service-instance="dashboardServiceInstance"
              :dashid="dashid"
              ref="previewWidget"
              :default-mapper="defaultMapper"
              :definition="widgetDefinition"
              :edit-mode=false
              :id="widgetDefinition.id"
              :model="model"
              :options="options"
              :parent-dashid="parentDashid"
              :parent-widget-id="parentWidgetId"
              :permissions="permissions"
              :widget-state="widgetState()">
            </vue-df-dashboard-widget>
          </b-tab>
        </b-tabs>

      </b-modal>
    </div>
    <div>
      <b-modal :centered="centered" :size="modalSize" :title="title" @ok="handleViewOk" ref="viewModal">
        <vue-df-dashboard-widget :crud-service-name="crudServiceName"
                                 :dashboard-name="dashboardName"
                                 :dashboard-service-instance="dashboardServiceInstance"
                                 :dashid="dashid"
                                 :default-mapper="defaultMapper"
                                 :definition="widgetDefinition"
                                 :edit-mode=false
                                 :id="widgetDefinition.id"
                                 :input-context="inputContext"
                                 :model="widgetDefinition"
                                 :options="options"
                                 :parent-dashid="parentDashid"
                                 :parent-widget-id="parentWidgetId"
                                 :permissions="permissions"
                                 :widget-state="widgetState()"
                                 ref="viewWidget"
                                 v-if="widgetDefinition && widgetDefinition.id">
        </vue-df-dashboard-widget>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {BModal, BTabs, BTab} from 'bootstrap-vue'
import jsnotevil from "jsnotevil";
import cloneDeep from 'lodash/cloneDeep'
import ObjectPath from "vue-json-schema-form/src/utils/ObjectPath";
import notifyMixin from "./notifyMixin";


const VueDfDashboardWidget = () => import('./VueDfDashboardWidget');
export default {
  name: "VueDfDashboardWidgetInstanceModal",
  props: ['dashid', 'dashboardName', 'options', 'model', 'defaultMapper',
    'dashboardServiceInstance', 'permissions', 'parentWidgetId', 'parentDashid', 'crudServiceName'],
  components: {BModal, BTabs, BTab, VueDfDashboardWidget},
  mixins:[notifyMixin],
  data() {
    return {
      widgetModalOptions: {},
      widgetDefinition: null,
      modalSize: 'lg',
      centered: true,
      isEditing: true,
      /*  editMode: null,*/
      inputContext: null,
      closeListner: {}
    }
  },
  computed: {
    context: function () {
      return this.widgetModalOptions.context || {};
    },
    data: function () {
      return this.context.data || {};
    },
    widgetType: function () {
      return this.data.widgetByType || '';
    },
    title: function () {
      return this.widgetDefinition.title;
    }
  },
  methods: {
    handleViewOk: function (bvModalEvt) {
      console.log('handleViewOk', this.closeListner.fn);
      bvModalEvt.preventDefault();
      if (this.closeListner.fn) {
        this.closeListner.fn();
      }
    },
    handleEditOk: function () {
      console.log('handled modal Ok', this.widgetDefinition, this.widgetModalOptions);
      //this.$set(this.widgetModalOptions.context.data, 'widgetTypeDefinition', this.widgetDefinition);
      const schemaFormService = this.widgetModalOptions.context.schemaFormService;
      const options = this.widgetModalOptions.options;

      const that = this;
      this.$services[this.crudServiceName].saveWidgetModalInstance(this.widgetDefinition, function (response) {
        // send event to update form
        console.log('Saved widget instance response', response);
        const targetKey = that._prepareKeyForChange(options.targetKey, that.context.parentFieldWithKey); //jsnotevil.safeEval(options.targetKey, {model: that.context.parentFieldWithKey});
        console.log('target key', targetKey, options.targetKey, that.context.parentFieldWithKey);

        schemaFormService.publishEvent(targetKey, 'reload', {});
        setTimeout(function () {
          schemaFormService.publishEvent(targetKey, 'set', that.widgetDefinition.id);
        }, 1000);

      });

    },
    _prepareKeyForChange: function (itemKey, fieldForm) {
      let keyArray = itemKey;
      if (!Array.isArray(itemKey)) {
        keyArray = ObjectPath.parse(itemKey);
      }
      let tmp = [];
      keyArray.forEach(function (k) {
        if (k.indexOf('form.key[') > -1) {
          const val = jsnotevil.safeEval(k, {form: fieldForm});
          tmp.push(val);
        } else {
          tmp.push(k);
        }
      });
      return tmp;
    },
    widgetState: function () {
      return {}
    },
    setWidgetModalOptions: function (options, editMode, next) {
      console.log('setWidgetModalOptions', options, editMode);
      this.widgetModalOptions = options;
      this.isEditable = editMode !== 'view';
      //this.editMode = editMode;
      if (this.isEditable) {
        this.initEdit(next);
      } else {
        this.initView(next);
      }
    },
    initView: function (next) {
      if (this.widgetModalOptions.widgetId) {
        const that = this;
        this.$services[this.crudServiceName].loadWidgetModalInstanceById(this.widgetModalOptions.widgetId, function (response) {
       //   console.log('loaded view widget id: ' + that.widgetModalOptions.widgetId, response);
          if (response) {
            that.widgetDefinition = response;
            that.inputContext = that.widgetModalOptions.eventContext;
            next()
          }

        });
      }
    },
    initEdit: function (next) {
      console.log('init', this.widgetModalOptions);
      const that = this;
      if (this.widgetModalOptions.options && this.widgetModalOptions.options.fieldName) {
        const value = this.data[this.widgetModalOptions.options.fieldName];
        if (value) {
          this.$services[this.crudServiceName].loadWidgetModalInstanceById(value, function (response) {
            console.log('loaded edit widget id: ' + that.widgetModalOptions.widgetId, response);
            if (response) {
              that.widgetDefinition = response;
              next()
            }

          });
        }
      } else {
        const widgetDefinition = cloneDeep(this.$widgetStore.widgets[this.widgetType]);
        console.log('widgetDefinition', widgetDefinition);
        if (widgetDefinition) {
          widgetDefinition.id = 'widget-' + this.$widgetStore.id();
          this.widgetDefinition = Object.assign({}, widgetDefinition);
          this.widgetDefinition.hideTitle = true;
          next();
        }

      }


    },
    deleteWidgetInstance: function (widgetModalOptions) {
      const data = widgetModalOptions.context ? widgetModalOptions.context.data : {};
      const parentFieldWithKey = widgetModalOptions.context ? widgetModalOptions.context.parentFieldWithKey : null;
      const fieldName = widgetModalOptions.options ? widgetModalOptions.options.fieldName : null;
      const value = data[fieldName];
      if (value) {
        const that = this;
        const schemaFormService = widgetModalOptions.context.schemaFormService;
        const options = widgetModalOptions.options;

        console.log('Deleting widget modal Instance; ' + value, widgetModalOptions);
        this.$services[this.crudServiceName].deleteWidgetModalInstance(value, function (response) {
          console.log('delete response', response);
          if (parentFieldWithKey) {
            const targetKey = that._prepareKeyForChange(options.targetKey, parentFieldWithKey); //jsnotevil.safeEval(options.targetKey, {model: that.context.parentFieldWithKey});
            console.log('delete refresh target key', targetKey, options.targetKey, parentFieldWithKey);
            schemaFormService.publishEvent(targetKey, 'reload', {});
            setTimeout(function () {
              schemaFormService.publishEvent(targetKey, 'set', null);
            }, 100);
          }
        });
      }
    },
    showEditModal: function () {
      const that = this;
      this.$nextTick(function () {
        that.$refs['editModal'].show();
      });
    },
    showViewModal: function () {
      const that = this;
      return new Promise((resolve, reject) => {
        that.$nextTick(function () {
          that.$refs['viewModal'].show();

       //   console.log('that.closeListner.fn', that.closeListner.fn);

          if (!that.closeListner.fn) {
            that.closeListner.fn = function () {


              const viewDataModel = that.$refs['viewWidget'].getViewComponentDataModel();

              if (!viewDataModel.valid && viewDataModel.errors && viewDataModel.errors.length>0) {
                that.errorMessage(viewDataModel.message || "Data not valid");//, 'bottom', 'right', 'warning');
              } else {
                resolve(viewDataModel);
                that.$nextTick(() => {
                  that.$refs['viewModal'].hide();
                })
              }
            }
          }
        });
      });

    }
  }
}
</script>

<style scoped>

</style>
