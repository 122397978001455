import {commonParse} from "vue-diagram/src/bpmn/panel/parseElement";

export default {
  props:{
    element:{
      type: Object,
      required: true
    },
    modeler:{
      type: Object,
      required: true
    }
  },
  data(){
    return {
      schema: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
            title: 'Id',
            description: 'Element Id'
          },
          name:{
            type: 'string',
            title: 'Name',
            description: 'Element description'
          },
          documentation:{
            type: 'string',
            title: 'Documentation',
            description: 'Process documentation'
          }
        }
      },
      form: [
        {
          type: 'section',
          sectionType: 'fieldset',
          title:'General',
          items:[
            'id','name', { key: 'documentation', type: 'textarea' }
          ]
        }
      ],
      formData:{}
    }
  },
  created(){
    this.formData = commonParse(this.element)
  },
  computed:{
    elementType(){
      return this.element.type.replace('bpmn:', '')
    }
  },
  watch:{
    'formData.id': function(val) {
      this.updateProperties({ id: val })
    },
    'formData.name': function(val) {
      this.updateProperties({ name: val })
    },
    'formData.documentation': function(val) {
      //this.updateProperties({ description: val })
      if (!val) {
        this.updateProperties({ documentation: [] })
        return
      }
      const documentationElement = this.modeler.get('moddle').create('bpmn:Documentation', { text: val })
      this.updateProperties({ documentation: [documentationElement] })
    }
  },
  methods:{
    mergeObjects(to, from){
      for(const
    /*formData:{
      handler(newVal, oldVal){
        console.log('formData changed', newVal, oldVal);
        this.updateProperties({ 'flowable:processCategory': val })
      },
      deep: true
    }*/ n in from){
        to[n] = from[n];
      }
    },
    updateProperties(properties){
      const modeling = this.modeler.get('modeling')
      console.log('update properties', modeling, this.element, properties);
      modeling.updateProperties(this.element, properties)
    }
  }
}
