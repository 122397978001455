import DatasetChart from './base/DatasetChart'

class ScatterChart extends DatasetChart{
  constructor() {
    super('ScatterChart');
  }

}


export default ScatterChart
