<template>
  <div class="dashboard-preview-container">
    <vue-df-dashboard-simple v-if="selectedDashboard" :categories="selectedDashboard.categories"
                             :collapsible="false"
                             continuous-edit-mode="false"
                             :dashid="selectedDashboard.dashid"
                             :default-mapper="defaultMapper"
                             :edit-mode="false"
                             :editable="false"
                             :maximizable="false"
                             :options="options"
                             :dashboard-name="dashboardName"

                             crud-service-name="http"
                             :model="selectedDashboard">
    </vue-df-dashboard-simple>
  </div>
</template>

<script>
const VueDfDashboardSimple = () => import("./VueDfDashboardSimple");

export default {
  name: "VueDfDashboardPreview",
  components: {VueDfDashboardSimple},
  props: ['crudServiceName'],
  data() {
    return {
      vueDfAppServiceName: 'vueDfApplicationService',
      selectedDashboard: {},
      defaultMapper: {},
      dashboardName: null,
      options: {
        editable: false,
        maximizable: false,
        collapsible: false,
        refreshable: false,
        isComponentMode: false
      }
    }
  },
  created() {
    console.log('in dash preview', this.$route.params);

    if (this.$route.params.id) {
      const id = this.$route.params.id;
      const that = this;
      this.$services[this.crudServiceName].loadDashboardPreviewItem(id, null, function (response) {
        console.log('dashboard preview response loaded', response);
        /*if (response && response.length && response[0].value && response[0].value.length){
          that.selectedDashboard = response[0].value[0] || {};
        }*/
        if (response) {
          const dashboard = response.dashboard || {};
          that.loadRoutes(dashboard, function () {
            that.selectedDashboard = dashboard || {};
            that.dashboardName = response.dashboardName;
            that.init();
          });
        }
      });
    }
  },
  methods: {
    init() {
      this.options = {
        editable: false,
        maximizable: this.selectedDashboard.maximizable,
        collapsible: this.selectedDashboard.collapsible,
        refreshable: this.selectedDashboard.refreshable,
        isComponentMode: true
      }
    },
    loadRoutes: function (dashboard, next) {
     // const widget = this.$jsulator.evaluate('rows[0].columns[0].widgets[0]', dashboard);

      /*if (widget.type === 'VueDfApp') {
      //TODO implement to load routes only for single application and add routes - isComponentMode==true then it will be editable for end user
        const isAuthenticated = this.$auth.isAuthenticated();
        if (isAuthenticated) {
          const appName = this.$jsulator.evaluate('$config.data.applicationName$', widget);
          console.log('loading routes for app', appName, widget);
          this.$services[this.vueDfAppServiceName].loadRoutesForApplication(appName, function (routes) {
            console.log('loaded routes for app', appName, routes);
            next();
          });
        }else{
          this.$notify('Not authorized to access');
          next();
        }

      } else {
        next();
      }*/
      next();
    }
  }
}
</script>

<style scoped>

</style>
