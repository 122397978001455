var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    staticClass: "edge",
    attrs: { d: _vm.path, id: _vm.data.id },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }