var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { ref: "screen", staticClass: "screen" }, [
    _c("defs", [_c("markers", { attrs: { markers: _vm.markers } })], 1),
    _c("g", [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }