<template>
  <vue-resizable class="resizable-chart" ref="resizableComponent"
                 :style="customStyle"
                 :active="resizableActiveHandlers"
                 :width=computedWidth
                 :height=computedHeight
                 :disable-attributes="disabledAttributes"

                 @resize:end="eHandler"
  >
    <!--    :fit-parent=true-->
    <div class="chart-wrapper" :class="activeClass">
      <v-chart :id="id" :group="group" :init-options="initOptions" :manual-update="manualUpdate" :options="chartConfig"
               :theme="theme" :autoresize=true ref="eChart"/>
    </div>
  </vue-resizable>
</template>

<script>
import ECharts from './ECharts'

import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/map'
import 'echarts/lib/chart/radar'
import 'echarts/lib/chart/scatter'
import 'echarts/lib/chart/effectScatter'
import 'echarts/lib/chart/boxplot'
import 'echarts/lib/chart/funnel'
import 'echarts/lib/chart/gauge'
import 'echarts/lib/chart/heatmap'
import 'echarts/lib/chart/sunburst'
import 'echarts/lib/chart/sankey'
import 'echarts/lib/chart/tree'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/polar'
import 'echarts/lib/component/geo'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/component/visualMap'
import 'echarts/lib/component/dataset'
import 'zrender/lib/svg/svg'


import isEmpty from 'lodash/isEmpty'

import VueResizable from 'vue-resizable'

// built-in theme
import 'echarts/theme/dark'

export default {
  name: "VueDfEChartWrapper",
  components: {
    'v-chart': ECharts,
    VueResizable
  },
  props: {
    id: String,
    resizable: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    allowedResizeDirections: {
      type: Array,
      default: function () {
        return ['r', 'rb', 'b', 'lb', 'l', 'lt', 't', 'rt'];
      }
    },
    height: {
      type: [String, Number],
      default: function () {
        return '100%';
      }
    },
    width: {
      type: [String, Number],
      default: function () {
        return '100%';
      }
    },
    chartConfig: Object,
    dataset: {
      type: Object,
      default: function () {
        return {};
      }
    },
    dimensions: {
      type: Array,
      default: function () {
        return undefined;
      }
    },
    group: String,
    theme: Object,
    manualUpdate: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  computed: {
    computedWidth: function () {
      return this.width;
    },
    computedHeight: function () {
      return this.height;
    },
    activeClass: function () {
      return this.resizable ? 'bordered-edit' : '';
    },
    initOptions: function () {
      return {
        renderer: 'canvas'
      }// can be 'canvas'
    },
    computedChart: function () {
      //  console.log('Computing the chart', this.chartConfig);
      return this.computeChartConfig();
    },
    customStyle: function () {
      return {
        width: '100%'
      }
    },
    resizableActiveHandlers: function () {
      return this.resizable ? this.allowedResizeDirections : []
    }
  },
  data() {
    return {
      disabledAttributes: ['w']
    }
  },

  methods: {
    computeChartConfig: function () {
      console.log('computing chart', this.chartConfig, this.dataset, this.dimensions);
      if (!isEmpty(this.chartConfig)) {
        let val = this.chartConfig;
        if (!isEmpty(this.dataset)) {
          val.dataset = this.dataset;
          if (!isEmpty(this.dimensions)) {
            val.dataset.dimensions = this.dimensions;
          }
        }
        const objVal =  this.$widgetStore.getObject(val);
        console.log('computed chart', objVal);
        return objVal;
      }
    },
    resize: function () {

    },
    eHandler: function (data) {
      this.$refs.eChart.resize();
      this.$emit('onresize', data);
    }
  }
}
</script>

<style scoped>
.chart-wrapper .echarts {
  width: 100%;
  height: 100%;
}

.chart-wrapper {
  width: 100%;
  height: 100%;

}

.bordered-edit {
  border: 1px dotted rgba(0, 0, 0, 0.27);
}

</style>
