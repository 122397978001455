<template>
  <div class="bpmn-container">

  </div>
</template>

<script>


export default {
  name: "BpmnEditorModelerView"
}
</script>

<style scoped>

</style>
