var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "structure-preview", class: { selected: _vm.selected } },
    [
      _c("h4", [_vm._v(_vm._s(_vm.title))]),
      _vm._l(_vm.preview.rows, function (row, index) {
        return _c("vue-df-dashboard-row", {
          key: index,
          attrs: { model: _vm.preview, row: row },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }