var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "widget-container" }, [
    _c(
      "div",
      _vm._b(
        {
          staticClass: "widget",
          class: _vm.widgetClasses(_vm.widget, _vm.definition),
          style: _vm.widgetStyle(_vm.widget, _vm.definition),
          attrs: { id: _vm.id },
        },
        "div",
        _vm.dataId,
        false
      ),
      [
        _c("vue-element-loading", {
          attrs: {
            active: _vm.isWidgetLoading,
            "can-cancel": false,
            "is-full-screen": false,
          },
          on: {
            "update:active": function ($event) {
              _vm.isWidgetLoading = $event
            },
          },
        }),
        _c(
          "portal",
          {
            attrs: {
              disabled: !_vm.isMaximized,
              to: "portalWidgetDestination",
            },
          },
          [
            _c("div", { staticClass: "widget-inner-container" }, [
              _vm.showTitle || _vm.showToolbar
                ? _c(
                    "div",
                    {
                      staticClass: "widget-header card-header clearfix",
                      class: {
                        edit: _vm.editMode,
                        view: !_vm.editMode && !_vm.showToolbar,
                      },
                      attrs: { "data-background-color": _vm.editMode },
                    },
                    [
                      _vm.showTitle
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "widget-header-item widget-title-container",
                            },
                            [
                              _c(
                                "h6",
                                { staticClass: "widget-title card-title" },
                                [
                                  _vm.editMode
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.dashid) +
                                            " - " +
                                            _vm._s(_vm.id) +
                                            "  "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("span", [_vm._v(_vm._s(_vm.title) + " ")]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.showToolbar
                        ? _c(
                            "div",
                            {
                              staticClass: "widget-header-item widget-toolbar",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon-container pull-right" },
                                [
                                  _vm.options.refreshable
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            title:
                                              _vm.translate(
                                                "ADF_WIDGET_TOOLTIP_REFRESH"
                                              ) || "Reload widget content",
                                            href: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.reload()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-redo",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.editMode
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "adf-move",
                                          attrs: {
                                            title:
                                              _vm.translate(
                                                "ADF_WIDGET_TOOLTIP_MOVE"
                                              ) || "Move widget",
                                            href: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-arrows-alt",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.options.collapsible &&
                                  !_vm.isCollapsed &&
                                  !_vm.editMode &&
                                  !_vm.isMaximized
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            title:
                                              _vm.translate(
                                                "ADF_WIDGET_TOOLTIP_COLLAPSE"
                                              ) || "Collapse widget",
                                            href: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.toCollapse(true)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-minus",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.options.collapsible &&
                                  _vm.isCollapsed &&
                                  !_vm.isMaximized &&
                                  !_vm.editMode
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            title:
                                              _vm.translate(
                                                "ADF_WIDGET_TOOLTIP_EXPAND"
                                              ) || "Expand widget",
                                            href: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.toCollapse(false)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-plus",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isEditable
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            title:
                                              _vm.translate(
                                                "ADF_WIDGET_TOOLTIP_EDIT"
                                              ) || "Edit",
                                            href: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.edit()
                                            },
                                          },
                                        },
                                        [_c("i", { staticClass: "fas fa-cog" })]
                                      )
                                    : _vm._e(),
                                  _vm.isEditable
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            title:
                                              _vm.translate(
                                                "ADF_WIDGET_TOOLTIP_SAVE_AS"
                                              ) || "Save as",
                                            href: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.saveAs()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "far fa-save",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isEditable
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            title:
                                              _vm.translate(
                                                "ADF_WIDGET_TOOLTIP_SAVE_AS"
                                              ) || "Copy",
                                            href: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.copyWidget()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "far fa-copy",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isEditable
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            title:
                                              _vm.translate(
                                                "ADF_WIDGET_TOOLTIP_EXPORT"
                                              ) || "Export Widget",
                                            href: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.exportWidget()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-file-export",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.options.maximizable &&
                                  !_vm.isMaximized &&
                                  !_vm.editMode
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            title:
                                              _vm.translate(
                                                "ADF_WIDGET_TOOLTIP_FULLSCREEN"
                                              ) || "Maximize",
                                            href: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.openFullScreen()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-window-maximize",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.editMode
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            title:
                                              _vm.translate(
                                                "ADF_WIDGET_TOOLTIP_REMOVE"
                                              ) || "Remove",
                                            href: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.remove()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-trash",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              !_vm.isCollapsed
                ? _c(
                    "div",
                    {
                      class: {
                        "widget-body card-body":
                          !_vm.widget.frameless || _vm.editMode,
                      },
                    },
                    [
                      !_vm.isMinimized && _vm.shouldReloadWidgetContentTrigger
                        ? _c(_vm.resolveComponent("View"), {
                            ref: "viewComponent",
                            tag: "component",
                            attrs: {
                              "base-config": _vm.dashboardCommonWidgetConfig,
                              "dashboard-edit-mode": _vm.editMode,
                              "dashboard-name": _vm.dashboardName,
                              dashid: _vm.dashid,
                              id: _vm.id,
                              "is-editable": _vm.isEditable,
                              maximizedArea: _vm.maximizedArea,
                              "dashboard-service-instance":
                                _vm.dashboardServiceInstance,
                              "parent-widget-id": _vm.parentWidgetId,
                              "widget-settings": _vm.widgetSettings,
                              "parent-dashid": _vm.parentDashid,
                              "crud-service-name": _vm.crudServiceName,
                              "input-context": _vm.inputContext,
                              "is-maximized": _vm.isMaximized,
                              model: _vm.definition,
                              options: _vm.options,
                              permissions: _vm.permissions,
                            },
                            on: { loading: _vm.updateWidgetLoading },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("vue-df-dashboard-widget-edit", {
              ref: "editWidget",
              attrs: {
                "crud-service-name": _vm.crudServiceName,
                "dashboard-name": _vm.dashboardName,
                "dashboard-service-instance": _vm.dashboardServiceInstance,
                dashid: _vm.dashid,
                "input-context": _vm.inputContext,
                "default-mapper": _vm.defaultMapper,
                definition: _vm.definition,
                options: _vm.options,
                permissions: _vm.permissions,
                widget: _vm.widget,
              },
              on: {
                widgetupdated: function ($event) {
                  return _vm.widgetUpdated($event)
                },
              },
            }),
            _c("vue-df-dashboard-widget-save-as", {
              ref: "saveAsWidget",
              attrs: {
                "dashboard-name": _vm.dashboardName,
                definition: _vm.definition,
                "crud-service-name": _vm.crudServiceName,
                widget: _vm.widget,
              },
              on: {
                widgetsavedas: function ($event) {
                  return _vm.widgetSavedAs($event)
                },
              },
            }),
            _c("vue-df-dashboard-widget-instance-modal", {
              ref: "widgetInstanceModal",
              attrs: {
                "crud-service-name": _vm.crudServiceName,
                "dashboard-name": _vm.dashboardName,
                "dashboard-service-instance": _vm.dashboardServiceInstance,
                "default-mapper": _vm.defaultMapper,
                options: _vm.options,
                permissions: _vm.permissions,
                dashid: _vm.dashid,
              },
            }),
          ],
          1
        ),
        _c(
          "b-modal",
          {
            ref: "portalWidget",
            attrs: {
              "hide-header-close": "",
              lazy: "",
              "no-close-on-backdrop": "",
              "no-close-on-esc": "",
              "ok-only": "",
              scrollable: "",
              size: "full",
            },
            on: { ok: _vm.onWidgetMaximizeClosed },
          },
          [
            _c("portal-target", {
              staticClass: "widget maximized",
              class: _vm.widgetClasses(_vm.widget, _vm.definition),
              attrs: { name: "portalWidgetDestination" },
            }),
            _c("resize-observer", { on: { notify: _vm.handleMaximized } }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }