var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chart-builder-editor-container" },
    [
      _c("chart-builder-e-chart-wrapper", {
        attrs: {
          "chart-config": _vm.computedChartType,
          dataset: _vm.computedDataset,
          dimensions: _vm.computedDimensions,
        },
        on: { contextchanged: _vm.onContextChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }