<template>
  <div></div>
</template>

<script>
  import editorMixins from "./editorMixins";
  import vdTypesDefinition from './vdTypesDefinition'
  export default {
    name: "VDTypesPanel",
    mixins: {editorMixins},
    data() {
      return {}
    },
    computed: {},
    methods: {}
  }
</script>

<style scoped>

</style>
