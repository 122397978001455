<template>
  <div class="row" :class="row.styleClass" :style="row.style">
    <vue-df-dashboard-column :id="getId()" :model="model" :edit-mode="editMode" :dashid="dashid"
                             :continuous-edit-mode="continuousEditMode"
                             :dashboard-name="dashboardName"
                             :input-context="inputContext"
                             :dashboard-service-instance="dashboardServiceInstance"
                             :options="options" :permissions="permissions" v-for="(column, index) in row.columns"
                             :key="index" :crud-service-name="crudServiceName"
                             :default-mapper="defaultMapper"
                             :parent-widget-id="parentWidgetId"
                             :parent-dashid="parentDashid"
                             :column="column">
    </vue-df-dashboard-column>
  </div>

</template>

<script>
  import VueDfDashboardColumn from "./VueDfDashboardColumn";
  import defaultMixins from './defaultMixin'
  import internalMixins from './internalMixin'

  export default {
    name: "vue-df-dashboard-row",
    props: ['row', 'continuousEditMode', 'crudServiceName'],
    mixins: [defaultMixins, internalMixins],
    components: {
      'vue-df-dashboard-column': VueDfDashboardColumn
    },
    computed:{

    },
    methods:{

    }
  }
</script>

<style scoped>

  /*.main-simple.row > .column.col{
    padding: 0px;
  }*/
  .dashboard-simple .row {
    margin-left: 0px;
    margin-right: 0px;
  }

</style>
