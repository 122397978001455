import { render, staticRenderFns } from "./VueDfAppNavbarLinkItem.vue?vue&type=template&id=5b9959d6&scoped=true&"
import script from "./VueDfAppNavbarLinkItem.vue?vue&type=script&lang=js&"
export * from "./VueDfAppNavbarLinkItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9959d6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b9959d6')) {
      api.createRecord('5b9959d6', component.options)
    } else {
      api.reload('5b9959d6', component.options)
    }
    module.hot.accept("./VueDfAppNavbarLinkItem.vue?vue&type=template&id=5b9959d6&scoped=true&", function () {
      api.rerender('5b9959d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/vue-dashboard-framework-app-components/src/components/VueDfApp/components/UIComponents/Navbar/items/VueDfAppNavbarLinkItem.vue"
export default component.exports