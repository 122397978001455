var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "data-editor-container" },
    [
      _c(
        "b-tabs",
        [
          _c("b-tab", { attrs: { active: "", title: "Test Data" } }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-3" }, [
                _c(
                  "div",
                  { staticClass: "ace-editor-wrapper raw-data ml-2" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Raw Data Editor"),
                    ]),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard",
                            value: () => {
                              return this.rawContent
                            },
                            expression: "() => {return this.rawContent; }",
                          },
                        ],
                        staticClass: "btn btn-sm btn-outline-secondary",
                        attrs: { type: "button" },
                      },
                      [_vm._v("\n              Copy!\n            ")]
                    ),
                    _c("vue-ace-editor", {
                      attrs: {
                        config: _vm.editorConfig,
                        content: _vm.strRawContent,
                        height: "94%",
                        position: "absolute",
                        width: "95%",
                      },
                      on: {
                        "change-content": function ($event) {
                          return _vm.onRawDataAceValueChanged($event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      { staticClass: "ace-editor-wrapper expression-editor" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Expression"),
                        ]),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "clipboard",
                                rawName: "v-clipboard",
                                value: () => {
                                  return this.strExpression
                                },
                                expression:
                                  "() => {return this.strExpression; }",
                              },
                            ],
                            staticClass: "btn btn-outline-secondary",
                            attrs: { type: "button" },
                          },
                          [
                            _vm._v(
                              "\n                  Copy!\n                "
                            ),
                          ]
                        ),
                        _c("vue-ace-editor", {
                          attrs: {
                            config: _vm.editorConfig,
                            content: _vm.strExpression,
                            height: "80%",
                            position: "absolute",
                            width: "95%",
                          },
                          on: {
                            "change-content": function ($event) {
                              return _vm.onExpressionAceValueChanged($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mb-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: { click: _vm.onExpressionExecuteClick },
                        },
                        [_vm._v("Execute")]
                      ),
                      _c("hr"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "ace-editor-wrapper expression-result-editor",
                      },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("1 Expression Result"),
                        ]),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "clipboard",
                                rawName: "v-clipboard",
                                value: () => {
                                  return this.strExpressionResult
                                },
                                expression:
                                  "() => {return this.strExpressionResult; }",
                              },
                            ],
                            staticClass: "btn btn-outline-secondary",
                            attrs: { type: "button" },
                          },
                          [
                            _vm._v(
                              "\n                  Copy!\n                "
                            ),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-warning",
                            on: {
                              click: function ($event) {
                                return _vm.applyTransformedData()
                              },
                            },
                          },
                          [_vm._v("Apply")]
                        ),
                        _c("vue-ace-editor", {
                          attrs: {
                            config: _vm.editorConfig,
                            content: _vm.strExpressionResult,
                            height: "90%",
                            position: "absolute",
                            width: "95%",
                          },
                          on: {
                            "change-content": function ($event) {
                              return _vm.onExpressionResultAceValueChanged(
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c(
            "b-tab",
            { attrs: { title: "Data extractor" } },
            [
              _c("data-normalizer", {
                attrs: { "raw-data": _vm.rawContent },
                on: { apply: _vm.onNormalizedDataApplied },
              }),
            ],
            1
          ),
          _c("b-tab", { attrs: { title: "Data" } }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-3" }, [
                _c(
                  "div",
                  { staticClass: "ace-editor-wrapper raw-data ml-2" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Sample Data Editor"),
                    ]),
                    _c("vue-ace-editor", {
                      attrs: {
                        config: _vm.editorConfig,
                        content: _vm.strContent,
                        height: "95%",
                        position: "absolute",
                        width: "95%",
                      },
                      on: {
                        "change-content": function ($event) {
                          return _vm.onAceValueChanged($event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "col-9" },
                [
                  _c("property-editor", {
                    attrs: { value: _vm.content },
                    on: { input: _vm.onPropertiesChanged },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }