<template>
  <div class="widget-container">
    <div :class="widgetClasses(widget, definition)" :id="id" :style="widgetStyle(widget, definition)" class="widget"
         v-bind="dataId">

      <vue-element-loading :active.sync="isWidgetLoading"
                           :can-cancel="false"
                           :is-full-screen="false"></vue-element-loading>

      <portal :disabled="!isMaximized" to="portalWidgetDestination">
        <div class="widget-inner-container">
          <div v-if="showTitle || showToolbar" :data-background-color="editMode"
               :class="{edit: editMode, view: !editMode && !showToolbar}" class="widget-header card-header clearfix">
            <div v-if="showTitle" class="widget-header-item widget-title-container">
              <h6 class="widget-title card-title">
                <span v-if="editMode">{{ dashid }} - {{ id }}  </span>
                <span>{{ title }}&nbsp;</span>
              </h6>
            </div>
            <div v-if="showToolbar" class="widget-header-item widget-toolbar">
            <span class="icon-container pull-right">
            <a :title="translate('ADF_WIDGET_TOOLTIP_REFRESH') || 'Reload widget content'" @click.prevent="reload()"
               href=""
               v-if="options.refreshable">
              <i class="fas fa-redo"></i>
            </a>
            <a :title="translate('ADF_WIDGET_TOOLTIP_MOVE') || 'Move widget'" @click.prevent="" class="adf-move" href=""
               v-if="editMode">
              <i class="fas fa-arrows-alt"></i>
            </a>

            <a :title="translate('ADF_WIDGET_TOOLTIP_COLLAPSE') || 'Collapse widget'" @click.prevent="toCollapse(true)"
               href=""
               v-if="options.collapsible && !isCollapsed && !editMode && !isMaximized">
              <i class="fas fa-minus"></i>
            </a>

            <a :title="translate('ADF_WIDGET_TOOLTIP_EXPAND') || 'Expand widget'" @click.prevent="toCollapse(false)"
               href=""
               v-if="options.collapsible && isCollapsed && !isMaximized && !editMode">
              <i class="fas fa-plus"></i>
            </a>

            <a :title="translate('ADF_WIDGET_TOOLTIP_EDIT') || 'Edit'" @click.prevent="edit()" href=""
               v-if="isEditable">
              <i class="fas fa-cog"></i>
            </a>
              <a :title="translate('ADF_WIDGET_TOOLTIP_SAVE_AS') || 'Save as'" @click.prevent="saveAs()" href=""
                 v-if="isEditable">
              <i class="far fa-save"></i>
            </a>
              <a :title="translate('ADF_WIDGET_TOOLTIP_SAVE_AS') || 'Copy'" @click.prevent="copyWidget()" href=""
                 v-if="isEditable">
                <i class="far fa-copy"></i>
              </a>
              <a :title="translate('ADF_WIDGET_TOOLTIP_EXPORT') || 'Export Widget'" @click.prevent="exportWidget()" href=""
                 v-if="isEditable">
                <i class="fas fa-file-export"></i>
              </a>

            <a :title="translate('ADF_WIDGET_TOOLTIP_FULLSCREEN') || 'Maximize'" @click.prevent="openFullScreen()"
               href=""
               v-if="options.maximizable && !isMaximized &&  !editMode">
             <i class="fas fa-window-maximize"></i>
            </a>

            <a :title="translate('ADF_WIDGET_TOOLTIP_REMOVE') || 'Remove'" @click.prevent="remove()" href=""
               v-if="editMode">
              <i class="fas fa-trash"></i>
            </a>
          </span>
            </div>
          </div>
          <div :class="{'widget-body card-body':!widget.frameless || editMode}" v-if="!isCollapsed">

            <component :base-config="dashboardCommonWidgetConfig" :dashboard-edit-mode="editMode"
                       :dashboard-name="dashboardName" :dashid="dashid"
                       :id="id" :is-editable="isEditable"
                       :maximizedArea="maximizedArea"
                       :dashboard-service-instance="dashboardServiceInstance"
                       :parent-widget-id="parentWidgetId"
                       ref="viewComponent"
                       :widget-settings="widgetSettings"
                       :parent-dashid="parentDashid"
                       :crud-service-name="crudServiceName"
                       :input-context="inputContext"
                       @loading="updateWidgetLoading"
                       :is="resolveComponent('View')" :is-maximized="isMaximized" :model="definition"
                       :options="options" :permissions="permissions"

                       v-if="!isMinimized && shouldReloadWidgetContentTrigger"></component>

          </div>
        </div>
        <!--TODO - change defintion Title into ID that will be changed when widget moved or created-->
        <vue-df-dashboard-widget-edit :crud-service-name="crudServiceName"
                                      :dashboard-name="dashboardName"
                                      :dashboard-service-instance="dashboardServiceInstance"
                                      :dashid="dashid"
                                      :input-context="inputContext"
                                      :default-mapper="defaultMapper" :definition="definition" :options="options"
                                      :permissions="permissions"
                                      :widget="widget" ref="editWidget"
                                      v-on:widgetupdated="widgetUpdated($event)">
        </vue-df-dashboard-widget-edit>
        <vue-df-dashboard-widget-save-as :dashboard-name="dashboardName" :definition="definition"
                                         :crud-service-name="crudServiceName"
                                         :widget="widget" ref="saveAsWidget"
                                         v-on:widgetsavedas="widgetSavedAs($event)">
        </vue-df-dashboard-widget-save-as>

        <vue-df-dashboard-widget-instance-modal :crud-service-name="crudServiceName"
                                                :dashboard-name="dashboardName"
                                                :dashboard-service-instance="dashboardServiceInstance"

                                                :default-mapper="defaultMapper"

                                                :options="options"
                                                :permissions="permissions"
                                                :dashid="dashid"
                                                ref="widgetInstanceModal"></vue-df-dashboard-widget-instance-modal>
      </portal>
      <b-modal :ref="'portalWidget'" @ok="onWidgetMaximizeClosed" hide-header-close lazy no-close-on-backdrop
               no-close-on-esc ok-only
               scrollable size="full">
        <portal-target :class="widgetClasses(widget, definition)" class="widget maximized"
                       name="portalWidgetDestination"></portal-target>
        <resize-observer @notify="handleMaximized"/>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {Portal, PortalTarget} from 'portal-vue'
import defaultMixins from './defaultMixin'
import internalMixins from './internalMixin'
import {ResizeObserver} from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
import {saveAs} from "file-saver";

const VueDfDashboardWidgetEdit = () => import('./VueDfDashboardWidgetEdit')
const VueDfDashboardWidgetSaveAs = () => import('./VueDfDashboardWidgetSaveAs');
import VueDfDashboardWidgetInstanceModal from "./VueDfDashboardWidgetInstanceModal";
import {BModal} from 'bootstrap-vue'
import cloneDeep from "lodash/cloneDeep";
import dynamicCssManager from "./utils/dynamicCssManager";

export default {
  name: "VueDfDashboardWidget",
  components: {
    VueDfDashboardWidgetEdit,
    VueDfDashboardWidgetSaveAs,
    VueDfDashboardWidgetInstanceModal,
    Portal,
    PortalTarget,
    BModal,
    ResizeObserver
  },
  mixins: [defaultMixins, internalMixins],
  props: ['definition', 'widgetState', 'vueDfModel', 'column', 'id', 'index', 'crudServiceName', 'inputContext'],
  data() {
    return {
      widgetSettings: {
        widgetVisible: true
      },
      maximizedArea: null,
      dashboardCommonWidgetConfig: {},
      _widgetState: {},
      editDefinition: {},
      fullscreen: false,
      isMaximized: false,
      isMinimized: false,
      shouldReloadWidgetContentTrigger: true,
      isCollapsed: false,
      isLoaderActive: false,
      isWidgetLoading: false
    }
  },
  computed: {
    showToolbar: function () {
      return (this.options.refreshable || this.options.maximizable || this.options.collapsible) || this.editMode
    },
    showTitle: function () { //TODO: this is not in use - we should check do we need to show/hide title - we are creating space in nested dashboards
      return (!this.definition.hideTitle || this.editMode) && (this.title || this.editMode);
    },
    title: function () {
      return this.definition.title
    },
    dataId: function () {
      return {
        [this.id]: ''
      }
    },
    widget: function () {
      const widget = this.$widgetStore.widgets[this.definition.type];
      if (widget) {
        return widget;
      } else {
        this.$nextTick(function () {
          this.errorMessage('Widget type: "' + this.definition.type + '" has been removed. You will need to remove it from the dashboard');
        });
        //
        return {};
      }
    },
    isEditable: function () {
      let isEditable = this.isDashboardDefinedEditable();
      if (isEditable) {
        // check permissions only if allowed;
        isEditable = this.isPermissionsDefinedEditable();
      }
      isEditable = isEditable && this.editMode;
      //this.$set(this.options, 'editable', isEditable);
      return isEditable;
    }
  },
  mounted() {
    this.loadCssStyling(this.definition);
  },
  methods: {
    updateWidgetLoading: function (isLoading) {
      this.isWidgetLoading = isLoading;
    },
    injectComponent: function (component) {

    },
    getViewComponentDataModel: function () {
      const viewComponentRef = this.$refs['viewComponent'];
      return viewComponentRef.getValidateDataModel();
    },
    handleMaximized: function (maximized) {
      this.maximizedArea = maximized;
    },
    loadCssStyling: function (def) {
      if (def) {
        const cssStyle = this.$jsulator.evaluate('$config.data.styling.css$', def);
        if (null !== cssStyle && undefined !== cssStyle) {
          const that = this;
          dynamicCssManager.appendChild(that, def.id, cssStyle);
        }
      }
    },
    isDashboardDefinedEditable: function () {
      const isEditableByDefaultConfiguration = this.widget.editableSettings && this.widget.editableSettings.editable;
      if (isEditableByDefaultConfiguration && this.model.widgetsConfigModel && !this.model.widgetsConfigModel.editableAllWidgets) {
        // check if editable by Dashboard configuration
        let isEditable = true;
        if (!this.model.widgetsConfigModel.editableWidgets) {
          // if disabled all on dashboard - permission cannot do anything
          isEditable = false;
        } else {
          const index = this.model.widgetsConfigModel.editableWidgets.indexOf(this.widget.type);
          // check if defined on dashboard and do we need to filter it our by permission - what is defined on dashboard directly has highest proority - then we filter out with permissions
          // if allowed on dashboard we should check on
          isEditable = index > -1 ? this.editMode : false;
        }
        return isEditable;
      }
      return isEditableByDefaultConfiguration && this.editMode;
    },
    isPermissionsDefinedEditable: function () {
      if (this.permissions && !this.permissions.disableEdit && !this.permissions.editableAllWidgets && this.permissions.editableWidgets) {
        let found = false;
        for (let i = 0; i < this.permissions.editableWidgets.length; i++) {
          const widgetTypes = this.permissions.editableWidgets[i].widgetType;
          if (widgetTypes) {
            const index = widgetTypes.indexOf(this.widget.type);
            if (index > -1) {
              return true;
            }
          }
        }
        return found;
      }
      return true;
    },
    onResize: function () {
      // console.log('onResize', arguments);
    },
    toCollapse: function (status) {
      this.isCollapsed = status;
      //   console.log(this.isCollapsed);
    },
    onWidgetMaximizeClosed: function () {
      this.isMaximized = false;
      this.maximizedArea = null
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
    openFullScreen: function () {
      //   console.log('Maximixed', this.definition, this.widget);
      this.isMaximized = true;
      this.$refs['portalWidget'].show();
    },
    remove: function () {
      const toRemove = {index: this.index, id: this.id, dashid: this.dashid};
      this.dashboardServiceInstance.removeWidget(toRemove);
      this.$emit('widgetremove', toRemove);
    },
    exportWidget: function(){
      const data = JSON.stringify(this.definition);
      const blob = new Blob([data], {type: 'application/json;charset=utf-8'})
      saveAs(blob, 'Widget-'+this.definition.type+'-'+this.definition.title +'-'+this.id+'.json');
    },
    copyWidget: function () {
      console.log('CopyWidget', this.index, this.id, this.dashid, this.definition);
      const newId = 'widget-' + this.$widgetStore.id();

      const newDef = cloneDeep(this.definition);
      //this.$widgetStore.vueJsonMerge(newDef, Object.assign({}, this.definition));
      newDef.id = newId;

      this.column.widgets.push(newDef);
      this.dashboardServiceInstance.addWidget(newDef);
      console.log('Added new widget', newDef);
    },
    saveAs: function () {
      this.$refs.saveAsWidget.saveAs();
    },
    edit: function () {
      this.$refs.editWidget.edit();
    },
    widgetSavedAs: function (event) {
      //    console.log('widgetSavedAs', event);
    },
    widgetUpdated: function (event) {
      // let isNotEqual = !isEqual(event,this.definition);

      for (const prop in this.definition) {
        this.$delete(this.definition, prop);
      }
      // console.log('Before widget udpate into def', this.definition, event);

      this.$widgetStore.vueJsonMerge(this.definition, event);

      this.loadCssStyling(this.definition);

      //if(isNotEqual){
      this.$dashboardBus.$emit('UPDATED_EDIT_WIDGET:' + this.id, this.definition);
      this.reload();
      //}
    },
    reload: function () {
      //   console.log('Emitting RELOAD_WIDGET for id: ' + this.id);

      this.shouldReloadWidgetContentTrigger = false; //!this.shouldReloadWidgetContentTrigger;

      const that = this;
      this.$nextTick(function () {
        that.shouldReloadWidgetContentTrigger = true;
        that.$dashboardBus.$emit('RELOAD_WIDGET:' + this.id, {});
      });

    },
    widgetClasses: function (widget, definition) {
      let classes = definition.styleClass || ' ';

      classes += definition.type.toLowerCase();

      if (!widget || !widget.frameless || this.editMode) {
        //  console.log('Resolving style classes', widget, definition);
        const cardClass = this.$jsulator.evaluate('$config.data.styling.card.widgetCard$', definition);
        if (cardClass) {
          classes += ' ' + cardClass;
        } else {
          classes += ' card ';
        }
      }

      if (this.model && this.model.dashboardThemeModel && this.model.dashboardThemeModel.widgetTheme) {
        classes += ' ' + this.model.dashboardThemeModel.widgetTheme;
      }

      classes += ' ' + definition.id;

      // console.log('Resolved classes: ' + classes);

      return classes;
    },
    widgetStyle: function (widget, definition) {
      let definitionStyle = definition.style || {};
      let widgetStyle = widget.style || {};
      let newStyle = Object.assign(widgetStyle, definitionStyle);
      return newStyle;
    }
  }
}
</script>

<style scoped>

.card .card-header {
  padding: 5px;
}

.card-header a {
  padding: 5px;
}

.card-header .icon-container {
  font-size: 14px;
}

.resize-handle {
  position: absolute;
  right: 7px;
  bottom: 2px;
  color: gainsboro;
  padding: 5px;
}


.widget-body {
  height: 100%;
}

.maximized {
  height: 100%;
}

.card .card-header.widget-header {
  padding: 0px 15px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card .widget-toolbar {
  margin-top: 10px;
  margin-bottom: 0.75rem;
}

.widget-header-item {
  flex: 1;
}

.widget-inner-container {
  padding: 5px;
}

</style>
