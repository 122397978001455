<template>
  <div class="chart-definition-container">
    <div class="chart-definition-header row">
      <div class="col">
        <h6>Define chart based on <a href="https://echarts.apache.org/examples/en/index.html"
                                     target="_blank">ECharts</a></h6>
        <p class="text-muted">

        </p>
      </div>
    </div>
    <div class="chart-definition-body row">
      <div class="chart-definition-editor data-editor-container col-6">
        <label class="control-label">Chart Editor</label>
        <vue-ace-editor :config="editorConfig"
                        :content="strContent" :height="'95%'"
                        :position="'absolute'"
                        :width="'95%'"
                        @change-content="onAceValueChanged($event)"></vue-ace-editor>
      </div>
      <div class="chart-definition-preview col-6 pt-3 mt-3">
        <vue-df-e-chart-wrapper :id="chartId" v-if="renderChart" :height=400 :chart-config="chartConfig" :dataset="chartDataset"
                                :dimensions="chartDimensions"
                                ref="chartWrapper"></vue-df-e-chart-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import chartingUtils from './chartingUtils'
import isEmpty from 'lodash/isEmpty'

const VueAceEditor = () => import('vue-json-schema-form-editor/src/aceEditor/VueAceEditor')
const VueDfEChartWrapper = () => import("../shared/VueDfEChartWrapper");

export default {
  name: "ChartDefinition",
  props: ['config', 'data'],
  components: {VueDfEChartWrapper, VueAceEditor},
  data() {
    return {
      chartId: 'chart-'+this.$widgetStore.id(),
      renderChart: true,
      editorConfig: {
        lang: 'javascript',
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        onInput: false
      },
      strContent: null,
      chartConfig: null,
      chartDataset: null,
      chartDimensions: null,
      defaultChartContentDefinition: {
        legend: {},
        tooltip: {},
        dataset: {
          source: [
            ['product', '2015', '2016', '2017'],
            ['Matcha Latte', 43.3, 85.8, 93.7],
            ['Milk Tea', 83.1, 73.4, 55.1],
            ['Cheese Cocoa', 86.4, 65.2, 82.5],
            ['Walnut Brownie', 72.4, 53.9, 39.1]
          ]
        },
        xAxis: {type: 'category'},
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {type: 'bar'},
          {type: 'bar'},
          {type: 'bar'}
        ]
      }
    }
  },
  computed: {
    chart: function () {
      return this.$refs.chartWrapper ? this.$refs.chartWrapper.$refs.eChart : {};
    }
  },
  created() {

  },
  mounted() {
    this.onCreate();
    this.renderTheChart();
  },
  methods: {
    onCreate: function () {
      if (isEmpty(this.config)) {
        this.init(this.defaultChartContentDefinition);
      } else {
        this.init(this.config);
      }
    },
    renderTheChart: function () {
      const that = this;
      /*this.renderChart = false;
      this.$nextTick(function () {
        that.renderChart = true;
      })*/
    },
    init: function (config) {
      let tmpDefault = config;
      if (chartingUtils.isObject(config)) {
        tmpDefault = JSON.stringify(config, null, 2);
        this.strContent = "const option=" + tmpDefault + "; \n\n return option;";
      } else {
        this.strContent = config;
      }

      this.applyContentToChart(this.strContent);
    },
    applyContentToChart: function (chartDefString) {
      const that = this;
      this.$nextTick(function () {

      //  console.log('chartWrapper', that.$refs.chartWrapper.$refs.eChart);
        const res = chartingUtils.safeEval(chartDefString, null, this.chart, {
          http: that.$http,
          jsulator: that.$jsulator
        }, this.chartId);
        that.chartConfig = res || {};
       /// that.renderTheChart();

      });
    },
    onAceValueChanged: function (contentValue) {
      //console.log('AceValue changed');
      this.applyContentToChart(contentValue);
      this.$emit('chartconfigupdated', contentValue);
    }

  }
}
</script>

<style scoped>
/**
* The default size is 600px×400px, for responsive charts
* you may need to set percentage values as follows (also
* don't forget to provide a size for the container).
*/
.echarts {
  width: 100%;
  height: 100%;
}
</style>
