<template>
  <div class="node-type-component">
    <span class="node-content" v-html="node.component.name"></span>
  </div>
</template>

<script>
export default {
  name: "NodeTypeComponent",
  props:{
    node: Object
  },
  data(){
    return {}
  },
  methods:{

  }
}
</script>

<style scoped>

.node-type-component {
  display: table;
  text-align: center;
  height: inherit;
  width: inherit;
}

.node-content {
  vertical-align: middle;
  display: table-cell;
}
</style>
