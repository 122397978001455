import DatasetChart from './base/DatasetChart'

class CandlestickChart extends DatasetChart{
  constructor() {
    super('CandlestickChart');
  }

}


export default CandlestickChart
