var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card1 data-panel-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "card1-body" },
      [
        _c("schema-form", {
          attrs: { form: _vm.form, schema: _vm.schema },
          on: { validationResult: _vm.onValidationResult },
          model: {
            value: _vm.localData,
            callback: function ($$v) {
              _vm.localData = $$v
            },
            expression: "localData",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "card1-footer" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card1-header" }, [
      _c("h6", { staticClass: "card1-title" }, [_vm._v("Data Editor")]),
      _c("small", [
        _c("p", { staticClass: "text-muted card-category" }, [
          _vm._v("Data definition loaded manually"),
        ]),
      ]),
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }