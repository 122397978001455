<template>
  <div class="content">
    <div class="row" v-if="dashboardEditMode">
      <div class="col">
        <schema-form :form="typeForm" :schema="typeSchema" v-model="dashboardTypeModel"></schema-form>
        <hr/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="not-authorized d-flex" v-if="!routeEnabled">
          <div class="w-100 d-flex justify-content-center align-items-center">
            <h6>You are not authorized to access this page</h6>
          </div>
        </div>
        <div v-if="routeEnabled">
          <component :permissions="routePermissions" :dashboard-edit-mode="dashboardEditMode" :dashboard-name="dashboardName"
                     :default-mapper="defaultMapper" :input-context="inputContext"
                     :disable-add="disableAdd" :disable-edit="disableEdit"
                     :is="resolveDashboardType()"
                     :key="key" crud-service-name="http"
                     v-if="dashboardName"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import componentMixin from '../../componentMixin'
  import isEmpty from 'lodash/isEmpty'

  export default {
    name: "VueDfAppContent",
    props: {
      dashboardEditMode: {
        type: Boolean
      }
    },
    mixins: [componentMixin],
    data() {
      return {
        key: 0,
        dashboardName: undefined,
        defaultMapper: {},
        dashboardTypeModel: {},
        typeSchema: {
          type: 'object',
          properties: {
            dashboardType: {
              type: 'string',
              title: 'Dashboard Type',
              enum: ['VueDfDashboardTabs', 'VueDfDashboardSingle'],
              default: 'VueDfDashboardSingle'
            }
          }
        },
        typeForm: [
          {
            key: 'dashboardType', type: 'radiosinline'
          }
        ]
      }
    },
    created() {
      //  console.log('Created App Content', this.appConfig, this.config);
    },
    methods: {
      resolveDashboardType: function () {
        if (this.dashboardTypeModel.dashboardType)
          return this.camelToKebab(this.dashboardTypeModel.dashboardType);
      },
      init() {
      //  console.log('Init in AppContent', this.selectedRouteConfig);
        const routeEnabled = this.isRouteEnabled(this.selectedRouteConfig);
      //  console.log('in Init app - route enabled', routeEnabled);


        if (this.selectedRouteConfig.item && this.selectedRouteConfig.item.dashboard) {
          this.$set(this, 'dashboardName', this.selectedRouteConfig.item.dashboard.dashboardName);
          this.$set(this.dashboardTypeModel, 'dashboardType', this.selectedRouteConfig.item.dashboard.dashboardType);
        }
        /*else{
          const dashboardItemSettings = this.getDashboardItemSettings();
          this.$set(this, 'dashboardName', dashboardItemSettings.dashboardName);//this.selectedRouteConfig.item.dashboard.dashboardName);
          this.$set(this.dashboardTypeModel, 'dashboardType', dashboardItemSettings.dashboardType);//this.selectedRouteConfig.item.dashboard.dashboardType);
        }*/
     //   console.log('after init', this.dashboardName, this.dashboardTypeModel, this.selectedRouteConfig);

      },
      getDashboardItemSettings: function(){
        let dashboardName = this.appConfig.appDashboardName + '::';
        dashboardName += (this.selectedRouteConfig.parent ? this.selectedRouteConfig.parent.name : '') + '=';
        dashboardName += (this.selectedRouteConfig.item?this.selectedRouteConfig.item.name : '');

        const dashboardType = this.dashboardTypeModel.dashboardType;

        const dashboardItemSettings = {
          dashboardName: dashboardName,
          dashboardType: dashboardType
        }
        return dashboardItemSettings;
      },
      updateSettings: function () {
        if (!this.dashboardEditMode) {
       //     console.log('Not yet in dashboard edit mode');
          return;
        }
        if (isEmpty(this.selectedRouteConfig)) {
     //     console.log('Select Config is null or undefined', this.selectedRouteConfig);
          return;
        }
        if (this.selectedRouteConfig.item && !this.selectedRouteConfig.item.name) {
          console.warn('Selected Item should have a name', this.selectedRouteConfig.item);
          return;
        }
       // console.log('updating settings', this.dashboardTypeModel, this.selectedRouteConfig, this.appConfig, this.config);
        // need to: update dashboard type in selected Item config, update appDashboardName in selected Item
        // we'll load dashboard by: appDashboardName+':'+selectedPareht+'/'+selecteItem.name+'::'+dashboardType

        const dashboardItemSettings = this.getDashboardItemSettings();

       // console.log('updated dashboard settings', dashboardItemSettings);

        const dash = Object.assign({}, dashboardItemSettings);


        this.$set(this.selectedRouteConfig.item, 'dashboard', dash);
        this.dashboardName = dashboardItemSettings.dashboardName;

        this.$emit('dashboardupdated', this.selectedRouteConfig);
      }
    },
    computed: {
      chosenDashboardType: function () {
        const dashboardType = this.selectedRouteConfig.dashboard && this.selectedRouteConfig.dashboard.dashboardType
          ? this.selectedRouteConfig.dashboard.dashboardType : null || this.dashboardTypeModel.dashboardType;// || 'VueDfDashboardSingle';

        return dashboardType;
      },
      disableEdit: function () {
        if (this.appConfig.isComponentMode)
          return false;
//        console.log('Resolving disable edit', this.routePermissions);

        if(this.routePermissions){
          if (this.isDefined(this.routePermissions.disableEdit)){
            return this.routePermissions.disableEdit;
          }
        }

        let disableEdit = this.appConfig.disableEdit;
        if (this.selectedRouteConfig && this.selectedRouteConfig.item && this.selectedRouteConfig.item.disableEdit !== undefined) {
          disableEdit = this.selectedRouteConfig.item.disableEdit;
        }
        return disableEdit;
      },
      disableAdd: function () {
        if (this.appConfig.isComponentMode)
          return false;

        if(this.routePermissions){
          if (this.isDefined(this.routePermissions.disableAdd)){
            return this.routePermissions.disableAdd;
          }
        }

        let disableAdd = this.appConfig.disableAdd;
        if (this.selectedRouteConfig && this.selectedRouteConfig.item && this.selectedRouteConfig.item.disableAdd !== undefined) {
          disableAdd = this.selectedRouteConfig.item.disableEdit;
        }
        return disableAdd;
      }
    },
    watch: {
      dashboardName: {
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            ++this.key;
          }
        }
      },
      selectedRouteConfig: {
        handler(newVal, oldVal) {
      //    console.log('selectedRouteConfig changed', this.selectedRouteConfig, newVal, oldVal, this.dashboardEditMode, this.isRouteEnabled(newVal));
          this.dashboardName = undefined;
          if (newVal.item && !newVal.item.dashboard && this.dashboardEditMode) {
            this.updateSettings();
          } else if (newVal) {
            this.init();
          }
        },
        immediate: true
      },
      dashboardTypeModel: {
        handler(newVal, oldVal) {
    //      console.log('dashboardTypeModel changed', this.dashboardTypeModel);
          this.updateSettings();
        },
        deep: true
      },
      dashboardEditMode: {
        handler(newVal, oldVal) {
          if (newVal !== oldVal && newVal) {
      //      console.log('we are in dashboardEditMode');
            this.updateSettings();
          }
        }
      }
    }
  }
</script>

<style scoped>

  .not-authorized{
    min-height: 300px;
    background-color: white;
  }

</style>
