var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chart-builder-container" },
    [
      _c(
        "b-tabs",
        [
          _c(
            "b-tab",
            { attrs: { active: "", title: "Data" } },
            [
              _c("data-editor", {
                attrs: { value: _vm.content },
                on: { input: _vm.onDataEditorInput },
              }),
            ],
            1
          ),
          _c("b-tab", { attrs: { title: "Editor" } }, [
            _c("div", { staticClass: "chart-builder-editor" }, [
              _c("div", { staticClass: "row chart-builder-header" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("chart-types", {
                      attrs: { "current-type": _vm.chartType.context },
                      on: { contextchanged: _vm.onChartTypeContextChanged },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row chart-builder-header" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("builder-header", {
                      attrs: { data: _vm.content },
                      on: {
                        contextchanged: _vm.onContextChanged,
                        calculated: _vm.onDimensionMetricsCalculated,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row chart-builder-body" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-2 chart-builder-left" }, [
                      _c(
                        "div",
                        { staticClass: "chart-builder-properties" },
                        [
                          _c("properties", {
                            model: {
                              value: _vm.properties,
                              callback: function ($$v) {
                                _vm.properties = $$v
                              },
                              expression: "properties",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "chart-builder-filter" },
                        [_c("filters")],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-8 chart-builder-editor" }, [
                      _c(
                        "div",
                        { staticClass: "editor-container" },
                        [
                          _c("chart-editor", {
                            attrs: {
                              "chart-type": _vm.chartType,
                              "dimensions-metrics": _vm.dimMetrics,
                              data: _vm.content,
                            },
                            on: { contextchanged: _vm.onContextChanged },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-2 chart-builder-context" },
                      [
                        _c("chart-builder-context", {
                          on: { changedconfig: _vm.onChangedChartConfig },
                          model: {
                            value: _vm.currentContext,
                            callback: function ($$v) {
                              _vm.currentContext = $$v
                            },
                            expression: "currentContext",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("b-tab", { attrs: { title: "Preview" } }, [
            _vm._v("\n        Nothin to show\n    "),
          ]),
          _c(
            "b-tab",
            { attrs: { title: "Generator" } },
            [_c("data-chart-generator")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }