<template>
  <scraper-component></scraper-component>
</template>

<script>
  const ScraperComponent = ()=>import("../components/Scraper/ScraperComponent");
  export default {
    name: "Scraper",
    components: {ScraperComponent}
  }
</script>

<style scoped>

</style>
