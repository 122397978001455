var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.baseComponent,
    {
      tag: "component",
      staticClass: "nav-item",
      class: { active: _vm.isActive },
      attrs: { to: _vm.elementTo(_vm.config), exact: "", tag: "li" },
    },
    [
      _vm.isMenu
        ? _c(
            "vue-df-app-dropdown",
            {
              staticClass: "dropdown nav-link",
              attrs: {
                icon: _vm.config.icon,
                tag: "span",
                position: "right",
                direction: "none",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function ({ isOpen }) {
                      return _c(
                        "a",
                        {
                          staticClass: "dropdown-toggle",
                          attrs: {
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": isOpen,
                          },
                        },
                        [
                          _vm.config.icon
                            ? _c("i", { class: _vm.config.icon })
                            : _vm._e(),
                          _c("p", { staticClass: "ml-1 mr-1" }, [
                            _vm._v(_vm._s(_vm.config.title)),
                          ]),
                        ]
                      )
                    },
                  },
                ],
                null,
                false,
                1586585397
              ),
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm.children.length === 0 && !_vm.$slots.default && _vm.config.link
        ? _vm._t("title", function () {
            return [
              _c(
                _vm.elementType(_vm.config.link, false),
                {
                  tag: "component",
                  staticClass: "nav-link",
                  attrs: {
                    to: _vm.elementTo(_vm.config),
                    exact: "",
                    target:
                      _vm.config.linkTarget === "NEW_WINDOW"
                        ? "_blank"
                        : "_self",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onItemClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.parent
                    ? [
                        _vm.config.icon
                          ? _c("i", { class: _vm.config.icon })
                          : _vm._e(),
                        !_vm.config.icon
                          ? _c("span", { staticClass: "sidebar-mini-icon" }, [
                              _vm._v(_vm._s(_vm.config.title.substring(0, 1))),
                            ])
                          : _vm._e(),
                        _c("span", { staticClass: "sidebar-normal" }, [
                          _vm._v(_vm._s(_vm.config.title)),
                        ]),
                      ]
                    : [
                        _c("i", { class: _vm.config.icon }),
                        _c("p", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(_vm.config.title)),
                        ]),
                      ],
                ],
                2
              ),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }