import SchemaFormEditor from './SchemaFormEditor'
import SfComponentConfig from './components/SfComponentConfig'
import {schemaFormPlugin} from 'vue-json-schema-form/src/plugins'
import schemaFormGenerator from "./schemaFormGenerator";


export let SchemaFormEditorStore = {
  selectedItem: {},
  setSelected: function(fieldForm){
    this.selectedItem = fieldForm
  }
};

const functions = {
  TO_SCHEMAFORM: {
    minArgumentCount: 1, maxArgumentCount: 2,
    fn: function (operands, argumentList, evaluationContext) {
      const data = operands[0];
      console.log('Run TO_SCHEMAFORM', operands);

      if (data===null || data === undefined){
        return
      }
      let type = 'DATA';
      if (operands.length===2){
        type = operands[1];
      }
      let res = null;
      switch (type) {
        case 'DATA':
          res = schemaFormGenerator.generate(data);
          break;
        case 'SCHEMA':
          res = schemaFormGenerator.generateFromSchema(data);
          break;
      }
      return res;
    }
  }
}

const schemaFormEditorPlugin = {
  install(Vue, options) {

   // console.log('Installing schema form editor');

    Vue.schemaFormEditorStore = SchemaFormEditorStore;
    Vue.use(schemaFormPlugin);

    Object.defineProperty(Vue.prototype, '$schemaFormEditorStore', {
      get() {
        return SchemaFormEditorStore;
      }
    })

    if (Vue.prototype.$jsulator){
  //    console.log('redefining JSULATOR');
      Vue.prototype.$jsulator.buildExtend({functions: functions});
    }

    Vue.component(SchemaFormEditor.name, SchemaFormEditor);
    Vue.component(SfComponentConfig.name, SfComponentConfig);
  }
}

export default schemaFormEditorPlugin
