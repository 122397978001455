<template>
  <!--<div class="accordion width" ref="verticalAccordion">-->
    <div class="accordion width">
      <slot></slot>
    </div>
  <!--</div>-->

</template>

<script>
  // https://codepen.io/elchininet/pen/wLMxpB
  import idMixin from "./idMixin";
  import VueResizable from 'vue-resizable'

  export default {
    name: "VerticalAccordion",
    mixins: [idMixin],
    components: {VueResizable},
    provide() {
      return {
        vAccordion: this
      }
    },
    data() {
      return {
        registeredItems: []
      }
    },
    mounted() {
      console.log('Mounted slots', this.$slots);
    },
    methods: {
      calculateWith(){

      },
      onItemHeadClick(item) {
        console.log('Item clicked', item, this.$el.clientWidth);

        this.calculateWith();
      },
      registerItem(item) {
        if (this.registeredItems.indexOf(item) === -1) {
          this.registeredItems.push(item);
          item.$once('hook:destroyed', () => {
            this.unregisterItem(item)
          })
        }
      },
      unregisterItem(item) {
        this.registeredItems = this.registeredItems.slice().filter(t => t !== item);
      },
      stableSort(array, compareFn) {
        // Using `.bind(compareFn)` on the wrapped anonymous function improves
        // performance by avoiding the function call setup. We don't use an arrow
        // function here as it binds `this` to the `stableSort` context rather than
        // the `compareFn` context, which wouldn't give us the performance increase.
        return array
          .map((a, index) => [index, a])
          .sort(
            function (a, b) {
              return this(a[1], b[1]) || a[0] - b[0]
            }.bind(compareFn)
          )
          .map(e => e[1])
      },
      getItems() {
        // We use registeredTabs as the source of truth for child tab components. And we
        // filter out any BTab components that are extended BTab with a root child BTab.
        // https://github.com/bootstrap-vue/bootstrap-vue/issues/3260
        const items = this.registeredItems.filter(
          item => item.$children.filter(t => t._isItem).length === 0
        )
        // DOM Order of Tabs
        let order = []
        /*if (this.isMounted && items.length > 0) {
          // We rely on the DOM when mounted to get the 'true' order of the b-tab children.
          // querySelectorAll(...) always returns elements in document order, regardless of
          // order specified in the selector.
          const selector = items.map(item => `#${item.safeId()}`).join(', ')
          order = selectAll(selector, this.$el)
            .map(el => el.id)
            .filter(identity)
        }*/
        // Stable sort keeps the original order if not found in the
        // `order` array, which will be an empty array before mount.
        return this.stableSort(items, (a, b) => {
          return order.indexOf(a.safeId()) - order.indexOf(b.safeId())
        })
      }
    }
  }
</script>

<style>

  .accordion.width {
    border: 1px solid rgba(0, 0, 0, 0.125);
    display: flex;
  }

  .accordion.width .card {
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: min-content;
    border-radius: 0px;
  }

  .accordion.width .card .card-header {
    cursor: pointer;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 0px;
    padding: 15px;
    border: 0;
  }


  .accordion.width .card .card-header:not(.collapsed) {
    pointer-events: none;
  }

  .collapsing.width {
    transition: width 0.35s ease;
    height: auto;
    width: 0;
  }

</style>
