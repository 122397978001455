var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-preview-container" },
    [
      _vm.selectedDashboard
        ? _c("vue-df-dashboard-simple", {
            attrs: {
              categories: _vm.selectedDashboard.categories,
              collapsible: false,
              "continuous-edit-mode": "false",
              dashid: _vm.selectedDashboard.dashid,
              "default-mapper": _vm.defaultMapper,
              "edit-mode": false,
              editable: false,
              maximizable: false,
              options: _vm.options,
              "dashboard-name": _vm.dashboardName,
              "crud-service-name": "http",
              model: _vm.selectedDashboard,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }