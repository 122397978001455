var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("\n      Header\n      "),
          _c("hr"),
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c("legend", [_vm._v("Body")]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-2" }),
            _c("div", { staticClass: "col" }, [
              _c("textarea", {
                staticStyle: { width: "100%" },
                attrs: { cols: "10", rows: "10" },
              }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }