<template>

  <div class="event-log-container resizable-content">
    <div class="event-log-wrapper">
      <div class="card1">
        <div class="card-header1">
          <button class="btn btn-sm" @click="isVisible = !isVisible">X</button>
          <span>
            DEBUG WINDOW - Event Log
          </span>
          <button @click="clear" class="btn btn-sm btn-danger float-right">
            <i class="fas fa-trash"></i>
          </button>
        </div>
        <div class="card-log-body" v-if="isVisible">
          <div class="card-log-body-container p-2">
            <b-table sticky-header="400px" striped hover :items="items" :fields="fields">
              <template #cell(commands)="row">
                <button @click="row.toggleDetails" class="btn btn-sm btn-info mr-2">
                  {{ row.detailsShowing ? 'Hide' : 'Show' }} Full
                </button>
              </template>

              <template #row-details="row">
                <json-tree :data="row.item" :level=2></json-tree>
                <button class="btn btn-sm btn-warning" @click="row.toggleDetails">Hide Details</button>
              </template>

              <template #cell(emittedAt)="data">
                <span>
                  {{ $jsulator.evaluate("TO_STRING(TO_DATE($value$), 'TT')", data) }}
                  </span>
              </template>
              <template #cell(definition)="data">
                <span v-if="!isSmallData(data)">
                  {{ getSmallData(data.value) }}
                </span>
                <span v-if="isSmallData(data)">
                  {{ prepareBigData(data.value) }}
                </span>
              </template>
              <template #cell(context)="data">
                 <span v-if="!isSmallData(data)">
                  {{ getSmallData(data.value) }}
                </span>
                <span v-if="isSmallData(data)">
                  {{ prepareBigData(data.value) }}
                </span>
              </template>
              <template #cell(value)="data">
                 <span v-if="!isSmallData(data)">
                  {{ getSmallData(data.value) }}
                </span>
                <span v-if="isSmallData(data)">
                  {{ prepareBigData(data.value) }}
                </span>
              </template>
            </b-table>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import debugEventLogMixin from "../mixins/debugEventLogMixin";
import {BTable} from 'bootstrap-vue'
/*import JsonTree from 'vue-json-tree'*/
import DraggableJsonTree from "vue-json-schema-form/src/components/draggableJsonTree/DraggableJsonTree";

export default {
  name: "DebugEventLog",
  mixins: {debugEventLogMixin},
  components: {BTable,  'json-tree': DraggableJsonTree},
  props: ['dashboardName', 'dashboardContainerReference'],
  data() {
    return {
      eventLogName: '__df_eventLog',
      isVisible: false,
      items: [],
      fields: ['dashid','widgetId','method', 'name', 'emittedAt', 'definition', 'context', 'value', 'commands']
    }
  },
  created() {
    const that = this;
    that.$widgetBus.$on(that.eventLogName, that.subscribeEvents);
  },
  beforeDestroy() {
    const that = this;
    that.$widgetBus.$off(that.eventLogName, that.subscribeEvents);
  },
  methods: {

    stringData(value) {
      const strValue = JSON.stringify(value, null, 2);
      return strValue;
    },
    isSmallData(value) {
      if (!value)
        return value;

      const strValue = JSON.stringify(value);
      return strValue.length < 50;
    },
    getSmallData(value) {
      if (!value)
        return value;

      const strValue = JSON.stringify(value);
      return strValue.substring(0, 50);
    },
    prepareBigData(value) {
      return value;
    },
    onRowCommand(command) {

    },
    subscribeEvents(event) {
     // console.log('event', event);
      this.items.push(event);
      if (this.items.length>=30){
        this.items.splice(0, 1);
      }
    },
    clear() {
      this.items = [];
    }
  }
}
</script>

<style scoped>
.event-log-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2050;
  left: 0;
}


.event-log-wrapper {
  background-color: snow;
}

.card-log-body-container {

}

.card-log-body {
  border: 1px solid #0b2e13;
  /*height: 400px;*/
}
</style>
