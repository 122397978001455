<template>
  <div class="diagram-editor-content-wrapper">
    <draggable class="droppable-container" tag="div" :move="onMove" @add="onAdd" @change="onChange" @end="onEnd"
               :list="elements"
               :group="{ name: 'workflow', pull: false, put: true }">
      <div ref="screenContainer" class="screen-container" @click.stop="screenSelect()">
        <div>{{ coords }}</div>
        <screen ref="screen">

          <line v-if="drawLineItem" :x1="drawLineItem.x1" :y1="drawLineItem.y1"
                :x2="drawLineItem.x2" :y2="drawLineItem.y2" stroke="black"/>

          <g v-for="edge in graph.edges" @click.stop="select(edge)" :key="edge.id">
            <edge :class="selection && selection.id === edge.id && 'selected'"
                  :data="edge"
                  :nodes="graph.nodes">
            </edge>
          </g>
          <g v-for="node in graph.nodes" @click.stop="select(node)" :key="node.id">
            <node :data="node" ref="node" @onstopdrag="onStopDrag"
                  :class="selection && selection.id === node.id && 'selected'">
              <node-type-component :node="node"></node-type-component>
              <element-conf-popup @ontoolclicked="onToolClicked" v-if="selection && selection.id === node.id"
                                  :selection="selection"></element-conf-popup>
            </node>
          </g>

        </screen>
      </div>
    </draggable>
  </div>
</template>

<script>

import Screen from '../vnodes/components/Screen'
import Node from '../vnodes/components/Node'
import Edge from '../vnodes/components/Edge'
import graph from '../vnodes/graph'
import uuid from 'uuid'
import NodeTypeComponent from "vue-diagram/src/components/editor/components/nodes/NodeTypeComponent";
import ElementConfPopup from "vue-diagram/src/components/editor/components/utils/ElementConfPopup";

export default {
  name: "VueEditorContent",
  components: {
    ElementConfPopup,
    NodeTypeComponent,
    Screen,
    Node,
    Edge
  },
  props: {
    renderType: String,
    value: Array
  },
  data() {
    return {
      coords: null,
      selection: null,
      elements: [],
      graph: new graph(),
      droppedItem: null,
      drawLineItem: null
    }
  },
  mounted() {
    this.elements = this.value;
  },
  methods: {
    onToolClicked(tool) {
      console.log('On Tool Clicked', tool);

      if (tool.type === 'connect') {
        this.drawLine(tool);
      }
    },
    drawLine(tool) {
      const svg = this.$refs.screen.$refs.screen;
      //   const container = this.$refs.screenContainer;
      console.log('On Draw Line', tool, svg);
      if (this.selection) {

        const coordinate = {
          x1: this.selection.x + this.selection.width,
          y1: this.selection.y + this.selection.height / 2,
          x2: this.selection.x + this.selection.width + 100,
          y2: this.selection.y + this.selection.height / 2
        }

        this.drawLineItem = Object.assign({}, coordinate);

        svg.addEventListener('mousemove', this.onMouseMoveDrawLine);
      }
    },
    onMouseMoveDrawLine(evt) {
      //console.log('onMouseMoveDriveLine',attributes);
      //  console.log((evt.screenX) + 'screenX-screeY' + (evt.screenY));
      if (this.selection) {
        const rect = this.$el.getBoundingClientRect();

        console.log((evt.x) + ' - ' + (evt.y), evt);
        this.coords = this.drawLineItem.x1 + ' ' + this.drawLineItem.x2 + ' | ' + rect.x + ' ' + rect.y + ' | ' + evt.x + ' ' + evt.y + ' | ' + evt.offsetX + ' ' + evt.offsetY + ' | ' + evt.clientX + ' ' + evt.clientY + ' | ' + this.$el.clientHeight + ' - ' + this.$el.clientWidth;

        this.drawLineItem.x2 = evt.x - rect.x - 5;
        this.drawLineItem.y2 = evt.y - rect.y - 30;

      }
    },
    screenSelect() {
      // console.log('screen select');
      this.selection = null;
    },
    onStopDrag(evt) {
      // console.log('onStopDrag', evt);
      const indexOf = this.elements.findIndex(x => x.node.id === evt.id);
      if (indexOf > -1) {
        this.$set(this.elements[indexOf], 'node', evt);
      }
    },
    select(obj) {
      if (this.selection && this.selection.id === obj.id) {
        this.selection = null;
      } else {
        this.selection = obj;
      }
    },
    generateId() {
      return Math.trunc(Math.random() * 1000);
    },
    onMove: function (evt) {
      console.log('onMove', evt);
    },
    onEnd: function (evt) {
      console.log('onEnd', evt);
    },
    onChange: function (evt) {
      // console.log('onChange', evt);
      if (evt.added) {
        this.droppedItem = evt.added;
      } else if (evt.removed) {

      }
    },
    onAdd: function (evt) {
      //  console.log('onAdd', evt);
      const node = {
        id: uuid(),
        component: this.droppedItem.element.component,
        x: evt.originalEvent.offsetX, //evt.originalEvent.screenX - evt.originalEvent.x,
        y: evt.originalEvent.offsetY, //evt.originalEvent.screenY - evt.originalEvent.y,
        width: 100,
        height: 100
      }

      this.$set(this.droppedItem.element, 'node', node);
      delete this.droppedItem.element.component;

      this.graph.createNode(this.droppedItem.element.node);
      this.droppedItem = null;
      this.$emit('input', this.elements);
    }
  }
}
</script>

<style scoped>

.screen-container {
  width: inherit;
  height: inherit;
}

.selected {
  border: 1px dashed rgba(0, 0, 255, 0.46);
  border-radius: 12px;
}

.diagram-editor-content-wrapper {
  min-height: 200px;
  min-width: 200px;
  height: 100%;
  width: 100%;
}

.droppable-container {
  min-height: 100px;
  border: 1px dotted #dc3545;
  padding: 5px 5px 20px 5px;
  height: 100%;
  width: 100%;
}

</style>
