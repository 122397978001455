<template>
  <vertical-accordion>
    <accordion-item>
      <template v-slot:header>
        Types
      </template>
      <template v-slot>
        <v-d-types-panel></v-d-types-panel>
      </template>
    </accordion-item>
    <accordion-item>
      <template v-slot:header>
        Editor
      </template>
      <template v-slot>
        <v-d-editor-panel></v-d-editor-panel>
      </template>
    </accordion-item>
    <accordion-item>
      <template v-slot:header>
        Properties
      </template>
      <template v-slot>
        <v-d-properties-panel></v-d-properties-panel>
      </template>
    </accordion-item>
  </vertical-accordion>
</template>

<script>
    import VerticalAccordion from "../accordion/VerticalAccordion";
    import AccordionItem from "../accordion/AccordionItem";
    import VDTypesPanel from "./panels/VDTypesPanel";
    import VDEditorPanel from "./panels/VDEditorPanel";
    import VDPropertiesPanel from "./panels/VDPropertiesPanel";
    export default {
        name: "VisualDesignerEditor",
      components: {VDPropertiesPanel, VDEditorPanel, VDTypesPanel, AccordionItem, VerticalAccordion}
    }
</script>

<style scoped>

</style>
