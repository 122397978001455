<template>
  <component :is="baseComponent"
             :to="elementTo(config)"
             class="nav-item"
             exact
             tag="li">
    <drop-down v-if="isMenu" :icon="config.icon" :tag="'span'"
               :position="'right'"
               direction="none"
               class="dropdown nav-link">
      <a slot="title"
         slot-scope="{isOpen}"
         class="dropdown-toggle"
         data-toggle="dropdown"
         aria-haspopup="true"
         :aria-expanded="isOpen">
        <i v-if="config.icon" :class="config.icon"></i>
        <p class="ml-1 mr-1">{{config.title}}</p>
      </a>
      <slot></slot>
    </drop-down>
    <slot name="title" v-if="children.length === 0 && !$slots.default && config.link">
      <component
        :to="elementTo(config)"
        :is="elementType(config.link, false)"
        class="nav-link" exact
        :target="config.linkTarget==='NEW_WINDOW'?'_blank':'_self'"
        @click.prevent="onItemClick">
        <template v-if="parent">
          <i v-if="config.icon" :class="config.icon"></i>
          <!--<span v-if="!config.icon" class="sidebar-mini-icon">{{config.title.substring(0, 1)}}</span>-->
          <span class="sidebar-normal">{{config.title}}</span>
        </template>
        <template v-else>
          <!--<span v-if="!config.icon" class="sidebar-mini-icon">{{config.title.substring(0, 1)}}</span>-->
          <i  v-if="config.icon" :class="config.icon"></i>
          <p class="ml-2">{{config.title}}</p>
        </template>
      </component>
    </slot>
  </component>
</template>

<script>
  import { CollapseTransition } from 'vue2-transitions';
  import componentMixin from '../../../componentMixin';

  export default {
    name: "VueDfUserInfoComponentLinkItem",
    mixins:[componentMixin],
    components: {
      CollapseTransition
    },
    props: { parent: Object},
    data() {
      return {
        collapsed: true
      }
    },
    computed: {
      hasSeparator: function(){
        const res  = this.$slots.default;
        return !res===false;
      },
      baseComponent() {
        return this.isMenu || this.config.isRoute ? 'li' : 'router-link'
      },
      isMenu() {
        return this.children.length > 0;// || this.config.itemType === 'DROP_DOWN'
      },
      dropDownSettings(){
        return this.config.itemDropDownSettings || {};
      },
      children(){
        const res = this.dropDownSettings && this.dropDownSettings.items?this.dropDownSettings.items:[];
        return res;
      },
      isActive() {
        return false;
      }
    },
    methods:{
      elementTo: function(config){
        if (this.appConfig.isComponentMode===true){
          return '#';
        }
        if (config.isRoute===false){
          return config.link;
        }else{
          const that = this;
          let currentRoute = this.$router.currentRoute;//.options.routes.find(r => r.path === that.$route.path);
         // console.log('Current route ', currentRoute, this.config);

          const def = currentRoute.meta.def;
          const name = (this.parent?this.parent.name:'')+'/'+this.config.name;
       //   console.log('Route nanme', name, def.routeKey.split('=').join('/'));

          const newRoute = { name:  name};
//          console.log('New Route: ', newRoute);
          return newRoute;
        }
      },
      elementType(link, isParent = true) {
     //   console.log('Link is: '+this.appConfig.isComponentMode+', link: '+link+', isParent: '+isParent);
        if (this.appConfig.isComponentMode===true){
          return  isParent ? 'li' : 'a';
        }
        let itemType = 'router-link';
        if (link.isRoute === false) {
          itemType = isParent ? 'li' : 'a'
        } else {
          itemType = 'router-link';
        }
      //  console.log('itemType link elementType: ', isParent, link, itemType);
        return itemType;
      },
      collapseMenu() {
        this.collapsed = !this.collapsed
      },
      onItemClick() {
    //    console.log('Clicked item', this.config, this.parent);
        this.$emit('selected', { item: this.config, parent: this.parent});
      }
    }

  }
</script>

<style scoped>

</style>
